/*global google*/
import GoogleMapReact from 'google-map-react';
import React from 'react';
import { WithGoogleMapProps } from 'react-google-maps';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import useDeepCompareEffect from 'use-deep-compare-effect';

import I18nMessage from '../components/I18nMessages';
import { AvgHelper } from '../components/map/classes/avg-helper.class';
import { GPSDataExWithDate, GPSDataWithDate } from '../components/map/classes/gps-data.class';
import { InfoWindow } from '../components/map/classes/info-window';
import Spinner from '../components/Spinner';
import {  gMapCircleLongStay, gMapMarkerLongStay, gMapPolylineDefault } from '../lib';
import {
  calculateMinMaxCoordinates,
  getColorForIdx2,
  getRadiusStay,
  removeCircle,
  speedToColorIdx,
} from '../lib/localizationMapUtlis';
import { toLocaleSpeed } from '../lib/utils';


const PIN_WIDTH = 50;
const PIN_HEIGHT = 50;
const DEFAULT_ZOOM = 10
const DEFAULT_CORDS = {lat: 50.0646501, lng: 19.9449799};

export type CalculatedMinMaxCoords = {
  maxLat: number
  maxLng: number
  minLat: number
  minLng: number
}

type Props = WithGoogleMapProps & InjectedIntlProps & {
  data: GPSDataWithDate[]
  dataEx: GPSDataExWithDate[]
  hoursFilter: [number, number]
  showLoading: boolean
  isCurrentLocalization: boolean
  apiKey: string,
  profileGender: number,
  showSpeed: boolean
}

const LocalizationMap: React.FC<Props> = ({
  data,
  dataEx,
  apiKey,
  profileGender,
  showLoading,
  hoursFilter,
  isCurrentLocalization,
  intl,
  showSpeed
}) => {

  function getMarkerIcon(): google.maps.Icon {
    return {
      url: `/avatar/pin-active-${profileGender}.png`,
      scaledSize: new (window as any).google.maps.Size(PIN_WIDTH, PIN_HEIGHT, "px", "px")
    }
  }

  function getMarkerGrayIcon(): google.maps.Icon {
    return {
      url: `/avatar/pin-no-active-${profileGender}.png`,
      scaledSize: new (window as any).google.maps.Size(PIN_WIDTH, PIN_HEIGHT, "px", "px")
    }
  }

  function drawPolySpeed(map: google.maps.Map, legend: any, gpsDataWithDate: GPSDataWithDate[]) {
    if (!infoWindow) {
      return;
    }

    const avgSpeed: AvgHelper = new AvgHelper();

    const lines: google.maps.Polyline[] = [];

    gpsDataWithDate.forEach((item) => {
      avgSpeed.add(item.v);
    })

    // 30 km/h
    if( avgSpeed.max < 8.335 )
      avgSpeed.max = 8.335

    const legendPoints = 18;

    // LEGEND start
    while (legend.firstChild) legend.removeChild(legend.firstChild);

    var elem = document.createElement('div');
    elem.innerText = toLocaleSpeed(avgSpeed.max, intl) 
    legend.appendChild(elem);

    for (var i = 0; i < legendPoints; i++) {
      var color = getColorForIdx2(1.0 - (i / legendPoints));

      elem = document.createElement('div');
      elem.style.width = "20px";
      elem.style.height = "20px";
      elem.style.backgroundColor = color;

      legend.appendChild(elem);
    }

    elem = document.createElement('div');
    elem.innerText = avgSpeed.min;
    legend.appendChild(elem);

    // LEGEND end

    const segment = new Array<GPSDataWithDate>();
    let lastColor = -1;
    // let segmentsCount = 0;

    gpsDataWithDate.forEach((item, idx, array) => {
      var isLast = idx === array.length - 1;

      var colIdx = speedToColorIdx(item.v, avgSpeed.min, avgSpeed.max, 10);

      segment.push(item);

      if (colIdx === lastColor && !isLast)
        return;

      if (segment.length > 1 || isLast) {
        // segmentsCount++;

        var poly = new google.maps.Polyline({
          path: segment,

          strokeColor: getColorForIdx2(lastColor),
          strokeOpacity: 0.8,
          strokeWeight: 8,

          zIndex: 9,
          map: map
        });

        // set tooltip
        infoWindow.setEvents(poly, infoWindow.getSpeedLabel(item, intl), map)

        lines.push(poly);

        segment.length = 0;
        segment.push(item);
      }

      lastColor = colIdx;
    });

    setPolylineMap(lines)
  }

  const filteredData = data.filter(item => {

    const hours = item.date.getHours()

    if (hours >= hoursFilter[0] && hours <= hoursFilter[1]) {
      return true
    }

    return false
  })

  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [maps, setMaps] = React.useState<any>(null)
  const [circlesCollection, setCirclesCollection] = React.useState<[]>([])
  const [markersCollection, setMarkersCollection] = React.useState<[]>([])
  const [polylineMap, setPolylineMap] = React.useState<google.maps.Polyline[] | null>(null)
  const [infoWindow, setInfoWindow] = React.useState<InfoWindow>()

  const fillMap = (map: google.maps.Map, maps: any) => {
    //  Clear map
    circlesCollection.forEach((circle: any) => {
      removeCircle(circle)
    })

    markersCollection.forEach((marker: any) => {
      marker.setMap(null);
    })

    polylineMap?.forEach((line) => {
      line.setMap(null);
    });
    //  Clear map - end

    const circles: any = [];
    const markers: any = [];

    const lastFilteredItem = filteredData.length > 0 && filteredData[0]

    // Fit Bounds
    if (data.length > 0) {
      const minMaxCoords = calculateMinMaxCoordinates(data)
      const bounds: google.maps.LatLngBounds = new maps.LatLngBounds()
      bounds.extend({ lat: (minMaxCoords.minLat - 0.001), lng: minMaxCoords.minLng - 0.001 })
      bounds.extend({ lat: (minMaxCoords.maxLat + 0.001), lng: minMaxCoords.maxLng + 0.001 })
      map.fitBounds(bounds);
    }
    // Fit Bounds End

    if (isCurrentLocalization && infoWindow) {
        const item = filteredData[0];
        if (item && isCurrentLocalization && (hoursFilter[0] === 0 && hoursFilter[1] === 23)) {
          const marker = new maps.Marker({
            position: { lat: item.lat, lng: item.lng },
            map,
            icon: getMarkerIcon(),
            zIndex: 9999
          })
          markers.push(marker)
          infoWindow.setEvents(marker, infoWindow.getTootlipDateTimeLabel(item.ts, intl), map, { lat: item.lat, lng: item.lng })
        }

      dataEx.forEach((item, i) => {
        const circle: google.maps.Circle = new maps.Circle({
          ...gMapCircleLongStay,
          map,
          center: { lat: item.lat, lng: item.lng },
          radius: getRadiusStay(item)
        });
        circles.push(circle);

        // set tooltip
        infoWindow.setEvents(circle, infoWindow.getToolTipLongStayLabel(item, intl), map)

        const marker = new maps.Marker({
          ...gMapMarkerLongStay,
          position: { lat: item.lat, lng: item.lng },
          map,
        })

        // set tooltip
        infoWindow.setEvents(marker, infoWindow.getToolTipLongStayLabel(item, intl), map)

        markers.push(marker)
      });
    }

    if (!isCurrentLocalization && lastFilteredItem && infoWindow) {
      const marker = new maps.Marker({
        icon: getMarkerGrayIcon(),
        position: { lat: lastFilteredItem.lat, lng: lastFilteredItem.lng },
        map,
        zIndex: 9999,
        optimized: false,
      })

      // set tooltip
      infoWindow.setEvents(marker, infoWindow.getTootlipDateTimeLabelWithDay(lastFilteredItem.ts, intl), map, { lat: lastFilteredItem.lat, lng: lastFilteredItem.lng })

      markers.push(marker)
    }

    setCirclesCollection(circles)
    setMarkersCollection(markers)
  }

  useDeepCompareEffect(() => {
    if (!map || !maps || !infoWindow) {
      return;
    }

    var el = document.getElementById('gm-legend');
    el?.remove();

    polylineMap?.forEach((line) => {
      line.setMap(null);
    });

    infoWindow.close();

    if (showSpeed) {
      // legend
      const legend = document.createElement('div');
      legend.id = 'gm-legend'
      legend.style.margin = "10px";
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
      // legend end

      drawPolySpeed(map, legend, filteredData)
    }
    else {
      let lines: google.maps.Polyline[] = [];

      filteredData.forEach((data, index, array) => {
        if (!array[index+1]) {
          return;
        }
        const line: google.maps.Polyline = new maps.Polyline({
          path: [{lat: data.lat, lng: data.lng}, {lat: array[index+1].lat, lng: array[index+1].lng}],
          options: gMapPolylineDefault
        });

        // set tooltip
        infoWindow.setEvents(line, infoWindow.getTootlipDateTimeLabel(data.ts, intl), map)

        line.setMap(map);
        lines.push(line)
      })

      // lines.setMap(map);
      setPolylineMap(lines)
    }

  }, [showSpeed, data, dataEx, hoursFilter, map, maps, intl, isCurrentLocalization]);

  useDeepCompareEffect(() => {
    let isMounted = true

    if (!map || !maps) {
      return;
    }

    if (isMounted) {
      fillMap(map, maps)
    }

    return () => {
      isMounted = false
    }
  }, [data, dataEx, hoursFilter, map, maps, intl, isCurrentLocalization]);

  const handleApiLoaded = (mapData: google.maps.Map, mapsData: any) => {
    setInfoWindow(new InfoWindow(google.maps.InfoWindow));
    setMap(mapData);
    setMaps(mapsData);
  };

  const mapOptions = {
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      mapTypeIds: ["roadmap", "terrain", "satellite", "hybrid"]
    },
    streetViewControl: true
  }

  return (
    <div className="google-maps-wrapper">
      {showLoading && (
        <div className="map-overlay d-flex justify-content-center">
          <div className="align-self-center">
            <Spinner />
          </div>
        </div>
      )}

      {!showLoading && filteredData.length === 0 && (
        <div className="map-overlay d-flex justify-content-center">
          <div className="align-self-center display-4">
            <I18nMessage id="localization-page.map-no-data" tagName="strong" />
          </div>
        </div>
      )}

      { <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={DEFAULT_CORDS}
        defaultZoom={DEFAULT_ZOOM}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        options={mapOptions}
      >
      </GoogleMapReact>}

    </div>
  )
}

export default injectIntl(LocalizationMap)
