// https://stripe.com/docs/stripe-js/react

// https://www.npmjs.com/package/@stripe/react-stripe-js
// npm install @stripe/react-stripe-js @stripe/stripe-js

// https://stripe.com/docs/billing/subscriptions/build-subscription?ui=elements

// remove country
// https://stackoverflow.com/questions/70843839/in-stripe-checkout-page-display-country-or-region-fields-how-to-remove-it

// PHP PDF
// https://ourcodeworld.com/articles/read/226/top-5-best-open-source-pdf-generation-libraries-for-php#disqus_thread

// USA:
// https://www.avalara.com/us/en/learn/whitepapers/zip-codes-dont-mean-zip-for-ecommerce-sellers.html
// https://www.quora.com/Why-do-some-companies-ask-for-your-name-or-zip-code-when-using-a-credit-card-and-others-only-need-the-credit-card-number-and-exp-date

// Stripe currency:
// You cannot combine currencies on a single customer. This customer has had a subscription, coupon, or invoice item with currency usd


import React from 'react'
import { Modal, ModalHeader, ModalBody,  Card,  CardBody } from 'reactstrap'
import Spinner from '../../components/Spinner'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import I18nMessage from '../../components/I18nMessages'
import { InjectedBenServiceProps, withBenService, BuyLicenseS } from '../../providers/benServiceProvider'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { useMountedState } from '../../lib'
import { formatAmount, formatInterval } from '../../lib/utils'
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe, StripeElementsOptions, StripePaymentElement, StripeElementLocale } from '@stripe/stripe-js';
import { PaymentElement, Elements, useStripe, useElements,  } from '@stripe/react-stripe-js';
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider';
import { InjectedBenHelperProps, withBenHelper } from '../../providers/benHelperProvider';
import AsyncButton from '../../components/AsyncButton';
import ShieldIconPL from '../../assets/media/30-dni.svg';
import ShieldIconEN from '../../assets/media/30-days.svg';
import StripeLogo from '../../assets/media/StripeLogo.png';


type CheckoutFormProps =  InjectedIntlProps & 
{
  returnUrl: string

  license: BuyLicenseS|null

  onSuccess: () => void
  onCancel: () => void
  onError: (msg:string) => void
}

const CheckoutForm : React.FC<CheckoutFormProps> = ({ 
      intl, 
      returnUrl, 
      license,
      onSuccess, 
      onCancel, 
      onError }) => 
{  
  const stripe = useStripe()
  const elements = useElements()

  const [isProcessing, setProcessing] = React.useState(false)
  const [isLoading, setLoading] = React.useState(true)
  const isMounted = useMountedState()
  const [errorMsg, setErrorMsg] = React.useState('')
  

  const showError = (msg:string) => 
  {
    setErrorMsg(msg)
  }

  const handleElementReady = (element: StripePaymentElement) => 
  {
    setLoading(false)
  }

  const handleCancel = async() =>
  {
    onCancel()
  }

  
  const handleSubmit = async (event:any) => 
  {
    event.preventDefault();

    setErrorMsg('')

    if (!stripe || !elements || !license) 
    {
      const msg = "Missing object..."
      showError(msg)
      onError(msg)
      return;
    }

    setProcessing(true)

    let countryCode = license.countryCode
    if( !countryCode || countryCode.startsWith('-') )
      countryCode = 'US'
    else
      countryCode = countryCode.toUpperCase()

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,          
        payment_method_data: 
        {
          billing_details:
          {
            email: license.email,
            address:
            {
              country: countryCode,
              postal_code: '',
              state: '',
              city :'',
              line1:'',
              line2:''
            }
          }
        }
      },
    });

    if (result.error) 
    {
      const msg = result.error.message ? result.error.message : 'Error...'
      showError(msg)
      onError(msg) 
      //console.log('ERROR SAYS: ' + result.error.message);
    }
    else
    {
      onSuccess()
    }

    //console.log(result)

    setProcessing(false)
  };
  

  return (

    <React.Fragment>

      { (isLoading ) && (
            <Spinner/>
      )}

      <form id="signup-form" onSubmit={handleSubmit}>

        <div className="CheckoutForm">

          <div className="purchase-checkout-card">
            <PaymentElement onReady={handleElementReady} options={{ 
              fields: 
              {
                billingDetails: 
                {
                  name: 'auto',
                  email: 'never',
                  phone: 'auto',
                  address: 'never'
                },          
              },
              readOnly:false
            }} />
          </div>

          <div className="purchase-checkout-error"><span>{errorMsg}</span></div>

          <div className="purchase-checkout-buttons">
            <AsyncButton className="purchase-checkout-button" color="primary" disabled={isProcessing || isLoading || !isMounted() } showSpinner={isProcessing} id="submit" > 
              <I18nMessage id='purchase-checkout.subscribe' />
            </AsyncButton>
            <AsyncButton className="purchase-checkout-button" color="light" disabled={isProcessing || isLoading || !isMounted() } onClick={handleCancel} > 
              <I18nMessage id='purchase-checkout.cancel' />
            </AsyncButton>
          </div>
          
        </div>
      </form>

    </React.Fragment>
  )
}




type PurchaseCheckoutPageProps = 
      InjectedBenHelperProps & 
      RouteComponentProps & 
      InjectedBenServiceProps & 
      InjectedIntlProps & 
      InjectedBenAccountProps & 
{
  plan:number|undefined,
  planYears:number|undefined
  isOpen: boolean
  onToggle: () => void
}


const PurchaseCheckoutPage: React.FC<PurchaseCheckoutPageProps> = (
  {
    benHelper,
    benService, 
    benAccount, 
    history, 
    intl, 
    plan, 
    planYears,
    isOpen,
    onToggle

  }) => {

    const isMounted = useMountedState()
    const [isLoading, setLoading] = React.useState(true)


    const [stripePromise, setStripePromise] = React.useState<Promise<Stripe|null>|undefined> (undefined)
    const [options, setOptions] = React.useState<StripeElementsOptions | undefined> (undefined)
    const [license, setLicense] = React.useState<BuyLicenseS|null>(null)

    const continueUrl = document.location.origin + '/account/purchase-status?session_id={CHECKOUT_SESSION_ID}'
    
    const formatCurrency = (license: BuyLicenseS) => {
      return license.currency.toUpperCase()
    }

    const onError = (msg:string) => {
      console.log('ERROR: ' + msg)
    }

    const onCancel = () => {
      // console.log('CANCEL')
      setLicense(null)
      benAccount.changeSettings({ ...benAccount.settings, skipPurchase: true, selectedPlan: 0, selectedPlanYears:0 })

      onToggle()
    }

    const onSuccess = () => {
      // console.log('SUCCESS')
    }    

    React.useEffect(() => {

      setLicense(null)
      setLoading(true)

      let selectedPlan      = plan
      let selectedPlanYears = planYears

      if( !selectedPlan || !selectedPlanYears )
        return
      
      benService.buyLicenseS(selectedPlan, selectedPlanYears, benHelper.promoCode, intl.locale )
      .then( (result) => {

        if( isMounted() )
        {
          setOptions( {
            locale: result.data.lang as StripeElementLocale,  //"pl",
            clientSecret: result.data.cs                      //"pi_3KYgRSARTMmeIR7Q2Mw1eOOH_secret_ecf23unv6Z8EpFN8XG6FdoJ2K",
          } )

          setStripePromise( loadStripe( result.data.pk ) ); // 'pk_test_51Jc8lhARTMmeIR7QM7f9at1ZWO2D70BXID06FnP0tf7b9ki6TUPDs8TufmhWOI9oxnxr94HSZhkV6M3x5kcUm1NH00vlpPE6gm' 

          setLicense(result.data)          
        }
      })
      .catch( () => {
        //console.log("Exception...")
        onToggle()
      })
      .finally( () => {
        setLoading(false)
      })

    }, [benService, intl.locale, plan, planYears]) // eslint-disable-line react-hooks/exhaustive-deps


    function getShieldIcon()
    {
      if( intl.locale.toLowerCase().startsWith('pl') )
        return ShieldIconPL
      else
        return ShieldIconEN
    }    
    
    return (

      <Modal isOpen={isOpen} toggle={onToggle} size="lg" >

        <ModalHeader>
            <div className="modal-large-title">
              <I18nMessage id='purchase-checkout.buy-subscription' />
            </div>
        </ModalHeader>

        <ModalBody className="checkout-body">

          <Card body className="shadow checkout-card" >

            <CardBody className="checkout-card-body">

              { isLoading && (
                <Spinner/>
              )}

              { stripePromise && options && license && (

                  <React.Fragment>

                    <img src={getShieldIcon()} className="purchase-checkout-shield" alt="30 days money back" />

                    <span className="purchase-checkout-subscribe-description"> 
                      {license.description} 
                    </span>

                    <span className="purchase-checkout-subscribe-devices"> 

                      { intl.formatMessage({ id:'purchase-checkout.up-to-devices' }, { devices:license.devices }) }
                      {/* Up to {license.devices} devices */}
                    </span>

                    <span className="purchase-checkout-subscribe-price" > 

                    { intl.formatMessage({ id:'purchase-checkout.subscribtion-price' }, 
                                            { amount:formatAmount(license.amount/100, license.currency), 
                                              period:formatInterval(license.intervalName, license.intervalCnt, intl) }) }

                      {/* { formatAmount(license.amount/100, license.currency) +
                       ' every ' + 
                       formatInterval(license.intervalName, license.intervalCnt, intl) }  */}
                    </span>

                    <span className="purchase-checkout-subscribe-currency"> 
                      { formatCurrency(license) } 
                    </span>                

                    <Elements stripe={stripePromise} options={options}>
                      <CheckoutForm onSuccess={onSuccess} 
                                    onError={onError} 
                                    onCancel={onCancel} 
                                    license={license}
                                    returnUrl={continueUrl}
                                    intl={intl} />
                    </Elements>

                    <img src={StripeLogo} className="purchase-checkout-stripe" alt="Stripe" />

                  </React.Fragment>
              )}
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    )
}

export default withBenHelper(withRouter(injectIntl(withBenService( withBenAccount(PurchaseCheckoutPage)))))
