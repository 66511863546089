import React from 'react'


export enum APP_TYPE {
    UNDEFINED             = -1,
    APP_TYPE_UNKNOWN      = 0,
    APP_TYPE_ANDROID      = 1,
    APP_TYPE_ANDROID_APK  = 2,
    APP_TYPE_WINDOWS      = 3
  }

  
  export enum ActionCode {
    badRequest = -1,
    ok = 0,
    CODE_SUCCESS = 1,
    badCredentials = 2,
    unauthorized = 3,
    accessDenied = 5,
    wrongPassword = 6,
    captchaError = 10,
    alreadyRegistered = 11,
    registerError = 12,
    maxProfilesError = 13,
    profileNotEmpty = 15,
    notFound = 16,
    passwordToShort = 17,
  
    CODE_WRONG_LIC = 18,
    CODE_PAYU_ERROR = 19,
    CODE_PAYU_BUY_FAILED = 20,
    CODE_IPRESSO_TOKEN = 21,
    CODE_IPRESSO_NO_CONTACT_ID = 22,
    CODE_IPRESSO_NO_AGREEMENTS = 23,
    CODE_IPRESSO_NO_AGREEMENTS2 = 24,
    CODE_WRONG_REWARD = 25,
    CODE_REWARD_TAKEN = 26,
  
    CODE_PAYU_PENDING = 30,
  
    CODE_WRONG_CONFIRM_VAL = 31,
    CODE_ACCOUNT_NOT_CONFIRMED = 32,
  
    CODE_WRONG_CODE = 33
  
  }
  
  export enum LoginType {
    email = 1,
    facebook = 2
  }
  
  export enum AccountType {
    accountNone = 0,
    demoExpired = 1,
    licExpired = 10,
    demo = 50,
    reward = 90,
    minimal = 100,
    regular = 200,
    premium = 300,
    edu = 1000,
    minimal_edu = 1100,
    regular_edu = 1200,
    premium_edu = 1300,
    topup_1 = 20001,
    topup_2 = 20002,
    topup_3 = 20003
  }
  