import React from 'react'
import { ChartData, ChartOptions, ChartPluginsOptions } from 'chart.js'
import Chart from 'react-chartjs-2'
//import I18nMessages from '../../../components/I18nMessages'
import { getColorForChartBackground, PieChartDefaults } from '../../../lib'
import Spinner from '../../../components/Spinner'
import { AiStatTime, AiStats } from '../../../providers/aiServiceProvider'
import { getEduSubjectsFromStr, getSubjectName } from '../../edu/SubjectButton'
import { describeTimeElapsedHM } from '../../../lib/utils'
import { InjectedIntlProps, injectIntl } from 'react-intl'


type Props = InjectedIntlProps & {
  aiStats : AiStats | null | undefined
}

const EducationChartSubjectTime : React.FC<Props> = ({
  aiStats,
  intl
}) => {

    const dataMap = React.useRef<Map<string, AiStatTime> | undefined>(undefined)
    const dataArr = React.useRef<Array<AiStatTime>|undefined>(undefined)

    function getChartDataSubjects(s : AiStats ) : ChartData 
    {
        let myMap  = new Map<string, AiStatTime>()
        let myArr = new Array<AiStatTime>()

        dataMap.current = myMap
        dataArr.current = myArr

        for(let i of s.time)
        {
          let obj = myMap.get(i.subject)
          if( obj === undefined )
          {
            obj = { dte:i.dte, subject:i.subject, elapsed:0, cnt:0 } 
            myMap.set( i.subject, obj )
            myArr.push(obj)
          }
          
          obj.elapsed += i.elapsed
          obj.cnt     += i.cnt
        }

        // myArr.map( i => describeTimeElapsedHM(i.elapsed, intl) )  

        const data: ChartData = {
            labels:  myArr.map(item => getSubjectName( getEduSubjectsFromStr(item.subject), false)),
            datasets: [{
              borderWidth: 1,
              label: 'Count',
              backgroundColor: myArr.map((_, index) => getColorForChartBackground(index)),
              data: myArr.map( i=> i.elapsed )
            }]
        }
      
        return data
    }

    const adjustPosition = (x: number, y: number, centerX: number, centerY: number, factor: number) => {
      const deltaX = x - centerX;
      const deltaY = y - centerY;
      return {
        x: centerX + deltaX * factor,
        y: centerY + deltaY * factor,
      };
    };

    const chartPlugins: ChartPluginsOptions = {
      plugins: [{
        id: 'customText',
        afterDraw(chart: any) {
  
          // console.log(chart.tooltip._active)
  
          if( chart.tooltip._active && chart.tooltip._active.length > 0 )
            return
  
          const { ctx, chartArea } = chart;
          const dataset = chart.data.datasets[0];
          const meta = chart.getDatasetMeta(0);
          const centerX = (chartArea.right-chartArea.left) / 2;
          const centerY = (chartArea.bottom-chartArea.top) / 2;

          let sum = 0
          dataset.data.forEach((v: number, index: number) =>
          {
            const arc = meta.data[index];
            if( !arc.hidden )
              sum += v
          })
    
          chart.data.labels.forEach((label: string, index: number) => {
            const arc = meta.data[index];
            let position = arc.tooltipPosition();
            position = adjustPosition( position.x, position.y, centerX, centerY, 1.4 )
            
            
            const value = dataset.data[index];
  
            if( !arc.hidden && value > 0.10 * sum )
            {    
              ctx.fillStyle = '#fff'; // Kolor tekstu
              ctx.font = 'bold 0.9rem Arial'; // 
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';

              let time = Math.round(value/60)
              let text = describeTimeElapsedHM(time * 60, intl)
      
              //ctx.fillText(text, position.x, position.y);

              const textWidth = ctx.measureText(text).width;
              const textHeight = 16; 
  
              ctx.fillStyle   = 'rgba(0, 0, 0, 0.6)';
              ctx.strokeStyle = "rgba(0, 0, 0, 1.0)";
              
              //ctx.fillRect(position.x - textWidth / 2 - 4, position.y - textHeight / 2 - 4, textWidth + 8, textHeight + 8);
  
              ctx.beginPath();
              ctx.roundRect(position.x - textWidth / 2 - 4, position.y - textHeight / 2 - 4, textWidth + 8, textHeight + 8, 5);
              //ctx.stroke();
              ctx.fill();
      
              ctx.fillStyle = '#fff'; 
              ctx.fillText(text, position.x, position.y);
            }            
          });
        }
      }]
    }    

    function tooltipTitleCallback (items: Chart.ChartTooltipItem[], data: ChartData): string 
    {
      return 'Czas nauki'
    }

    function tooltipLabelCallback (tooltipItem: Chart.ChartTooltipItem, tooltipData: ChartData): string {
  
      // tooltipItem{"xLabel":"","yLabel":"","label":"","value":"","index":2,"datasetIndex":0,"x":796.38,"y":138.51999999999998}

      if( dataArr.current !== undefined && tooltipItem.index !== undefined && tooltipData.labels !== undefined)
      {
        let idx = tooltipItem.index

        let time = Math.round(dataArr.current[idx].elapsed/60)

        return tooltipData.labels[idx] + " : "+ describeTimeElapsedHM(time * 60, intl)
      }

      return '---'
    }

    const optionsQuestions: ChartOptions = {
      showLines: false,
      responsive: true,
      maintainAspectRatio: false,
      title: {
          display: false,
          text: 'Spędzony czas'
      },
      legend: {
          display: true,

      },
      tooltips: {
        callbacks: {
          title: tooltipTitleCallback,
          label: tooltipLabelCallback
        }
      }
    }
 
  return (

    <React.Fragment>

        { aiStats === undefined && (
            <div className="chart-container d-flex align-items-center justify-content-center text-center">
              <Spinner/>
            </div>
        )}

        { ( aiStats && aiStats.subjects.length === 0 ) && (
            <div className="chart-container d-flex align-items-center justify-content-center text-center">
                Brak danych. Dane jeszcze nie zostały zebrane.
            </div>
        )}

        { ( aiStats && aiStats.subjects.length !== 0 ) && (

            <div className="chart-container">
                <Chart
                    type="pie"
                    legend={PieChartDefaults.legend}
                    options={optionsQuestions}
                    plugins={chartPlugins.plugins}
                    data={getChartDataSubjects(aiStats)}
                />
            </div>
        )}     

    </React.Fragment>
  )
}

export default injectIntl(EducationChartSubjectTime)
