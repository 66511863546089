/* eslint no-eval: 0 */

import React from 'react'
import { format } from 'date-fns'
import { enGB, enUS, es, pl } from 'date-fns/locale'
import {MouseEvent } from 'react'
import { InjectedIntl } from 'react-intl'
import getMessage from '../language/getMessage'
import { BenAccountContextValue } from '../providers/benAccountProvider'
import { AccountType, APP_TYPE } from '../providers/providersDefs'
import { PlansItem } from '../providers/benServiceProvider'



export function isKnownGPTModel(gptModel: string) : boolean
{
    if( !gptModel )
        return true

    const known = new Set([
        "",
        "gpt-3.5-turbo",
        "gpt-3.5-turbo-0125",
        "gpt-4o-mini-2024-07-18",
        "dall-e-2",
        "gpt-4o-mini",
        "gpt-4-1106-preview",
        "tts-1-hd-1106",
        "tts-1-hd",
        "dall-e-3",
        "whisper-1",
        "text-embedding-3-large",
        "text-embedding-ada-002",
        "gpt-4-turbo",
        "gpt-4o-2024-05-13",
        "gpt-4-0125-preview",
        "gpt-4-turbo-2024-04-09",
        "gpt-4-turbo-preview",
        "tts-1",
        "tts-1-1106",
        "gpt-3.5-turbo-16k",
        "gpt-4o",
        "gpt-3.5-turbo-1106",
        "gpt-3.5-turbo-instruct-0914",
        "gpt-4",
        "gpt-4-0613",
        "gpt-3.5-turbo-instruct",
        "chatgpt-4o-latest",
        "babbage-002",
        "davinci-002",
        "text-embedding-3-small",
        "gpt-4o-2024-08-06"
        ])

    return known.has(gptModel)
}


export function arrayBufferToBase64(buffer: ArrayBuffer): Promise<string> 
{
    return new Promise((resolve, reject) => {
  
        const blob = new Blob([buffer])

        const reader = new FileReader()

        reader.onloadend = () => 
        {
            const result = reader.result as string
            
            //const base64String = result.split(',')[1]
            //resolve(base64String)

            resolve(result)
        }
        
        reader.onerror = reject

        reader.readAsDataURL(blob)
    })
}


export function useInterval(callback:any, delay:number) 
{
    const intervalRef = React.useRef(0);
    const callbackRef = React.useRef(callback);
  
    // Remember the latest callback:
    //
    // Without this, if you change the callback, when setInterval ticks again, it
    // will still call your old callback.
    //
    // If you add `callback` to useEffect's deps, it will work fine but the
    // interval will be reset.
  
    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
  
    // Set up the interval:
  
    React.useEffect(() => {
      
        intervalRef.current = window.setInterval( () => callbackRef.current(), delay);
  
        // Clear interval if the components is unmounted or the delay changes:
        return () => { 
            // console.log("window.clearInterval")
            window.clearInterval(intervalRef.current);
        }      
    }, [delay]);

    return intervalRef;
}

export function useEventListener(what:string, callback:any ) : void
{
    const callbackRef = React.useRef(callback);
  
    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
 
    React.useEffect(() => 
    {      
        window.addEventListener( what, () => callbackRef.current() );  
        return () => {
            // console.log("window.removeEventListener")
            window.removeEventListener(what, callbackRef.current)
        }      
    }, [what]);
}


export function setCookie(name:string, val:string, days:number)
{
    var then = new Date();
    var ts = then.getTime() + days * 24 * 3600 * 1000;
    then.setTime(ts);

    var domain = window.location.hostname

    const pattern = /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/
    if( ! pattern.test(domain) ) 
    {
        const items = domain.split('.');
        const cnt = items.length
        if( cnt > 2 ) domain = items[cnt-2] + '.' + items[cnt-1]
    }

    // console.log(domain)
    // console.log(document.location.protocol)
    
    if(document.location.protocol !== 'http:')
        document.cookie=name + '=' + val + '; expires='+then.toUTCString()+'; path=/; secure; Secure; SameSite=Lax; domain=' + domain
    else
        document.cookie=name + '=' + val + '; expires='+then.toUTCString()+'; path=/; SameSite=Lax; domain=' + domain
}


function processParams(searchParams:URLSearchParams)
{
    const known = ['gclid', 'utm_source', 'utm_medium', 'utm_campaign']
    
    for(const k of known)
    {
        if( searchParams.has(k) === true )
        {
            const id = searchParams.get(k)

            if( id === null )
                continue

            var id2 = ''
            const pattern = /[0-9A-Za-z_,+.-]/

            for( const c of id )
            {
                if( pattern.test(c) )
                    id2 += c
            }

            if( id2 !== '' )
                setCookie(k, id2, 90)
        }
    }
}

export function setUtmClidCookies()
{
    if( !URLSearchParams )
        return

    if( window.location.search )
    {
        const paramsString = window.location.search.substring(1)
        const searchParams = new URLSearchParams(paramsString)
        processParams(searchParams)
    }

    if( window.location.hash )
    {
        const paramsString = window.location.hash.substring(1)
        const searchParams = new URLSearchParams(paramsString)    
        processParams(searchParams)
    }        
}


export function getUrlParam(name: string, defVal:string = '') : string
{
    const paramsString = window.location.search.substring(1)
    var searchParams = new URLSearchParams(paramsString)

    var val = searchParams.get(name)
    if( val === null )
        return defVal

    return val
}

export function getUrlWithoutParam(name: string) : string
{
    const paramsString = window.location.search.substring(1)
    var searchParams = new URLSearchParams(paramsString)
    searchParams.delete(name)

    let sp = searchParams.toString()

    return window.location.protocol + 
            '//' + 
            window.location.host + 
            (window.location.port ? ':' + window.location.port: '') + 
            window.location.pathname + 
            (sp ? "?" + sp : "")
}

export function getPathWithoutParam(name: string) : string
{
    const paramsString = window.location.search.substring(1)
    var searchParams = new URLSearchParams(paramsString)
    searchParams.delete(name)

    let sp = searchParams.toString()

    return  window.location.pathname + 
            (sp ? "?" + sp : "")
}


export function getCookie(name:string, def : string | null = '') : string | null
{
    const dc = decodeURIComponent(document.cookie)

    const items = dc.split(';');

    for( const item of items )
    {
        const items2 = item.trim().split('=');

        if( items2.length===2 && items2[0] === name )
            return items2[1]
    }

    return def
}

export function getCookie2(name:string, def : string = '') : string
{
    const dc = decodeURIComponent(document.cookie)

    const items = dc.split(';');

    for( const item of items )
    {
        let s = item.trim()
        let idx = s.indexOf('=')

        if( idx > 0 )
        {
            let n = s.substring(0, idx)
            if( n === name )
                return s.substring(idx+1)
        }
    }

    return def
}

export function isEduPath() : boolean
{
    let isEdu = document.location.pathname.indexOf('/panel/education') >= 0

    return isEdu
}

export function getCreditsToQuestions(tokens: number) : number
{
    let t = Number( tokens )

    if( tokens < 1 )
        return 0

    let questionsCount = Math.round( t / 1200 )

    if( questionsCount < 50 ) 
    {
        questionsCount = Math.round( questionsCount / 5 ) * 5

        if( questionsCount < 1 )
            questionsCount = 1

        return questionsCount
    }

    if( questionsCount < 500 ) 
    {
        questionsCount = Math.round( questionsCount / 10 ) * 10
        return questionsCount
    }

    questionsCount = Math.round( questionsCount / 10 ) * 10
    return questionsCount
}


export function getCreditsLeftDescription(tokens: number, intl: InjectedIntl) : string
{
    let questionsCount = getCreditsToQuestions(tokens)

    if( tokens < 1 ) 
        return `Już nie można rozwiązać zadań, pozostało ${tokens} Punktów Wiedzy` 

    if( questionsCount < 1 )
        return `Można rozwiązać niewiele zadań, pozostało ${tokens} Punktów Wiedzy` 

    if( questionsCount < 30 )
        return `Można jeszcze rozwiązać około ${questionsCount} zadań, pozostało ${tokens} Punktów Wiedzy`

    return `Możesz jeszcze rozwiązać około ${questionsCount} zadań, pozostało ${tokens} Punktów Wiedzy`
}

export function getCreditsInfo(plan: PlansItem) : string
{
    const tokens = plan.eduTokens
    const tasks  = getCreditsToQuestions(tokens)
    return `Asystent edukacyjny pozwala rozwiązać około ${tasks} zadań, zawiera ${tokens} Punktów Wiedzy` 
}

export function getCreditsInfoBuy(plan: PlansItem) : string
{
    let questionsCount = getCreditsToQuestions(plan.eduTokens)

    return `Za tę kwotę będziesz mógł rozwiązać około ${ questionsCount } zadań, dostaniesz ${plan.eduTokens} Punktów Wiedzy`
    
}


export function justDigits(str : string) : string
{
    var s = ''

    for(let i=0; i<str.length; i++)
    {
        let c = str[i]
        if( c>='0' && c<='9')
            s += c
    }

    return s
}

export function dashString(str : string, partLen: number) : string
{
    var s = ''
    let l = 0

    for(let i=0; i<str.length; i++)
    {
        let c = str[i]
        if( c>='0' && c<='9')
        {
            if( l > 0 && l%partLen===0 )
                s += '-'

            s += c
            l++
        }
    }

    return s
}


export interface KeyVal {
    key: string
    val: string
}

export function getCookies() : KeyVal[]
{
    const dc = decodeURIComponent(document.cookie)

    const items = dc.split(';');

    let arr : KeyVal[] = [];

    for( const item of items )
    {
        const items2 = item.trim().split('=');

        if( items2.length===2 )
            arr.push( {key:items2[0], val:items2[1]} )
    }

    return arr
}


export function translateScreenErrorMessage(msg: string, intl: InjectedIntl) {

    if( msg === 'deactivated' )
        return getMessage('screes-pages.device-deactivated', intl) // 'Ochrona została czasowo deaktywowana'

    if( msg === 'offline')
        return getMessage('screes-pages.device-offline', intl) // 'Urządzenie jest wyłączone'

    if( msg === 'timeout')
        return getMessage('screes-pages.device-timeout', intl) // 'Urządzenie nie odpowiada'

    if( msg === 'screenoff')
        return getMessage('screes-pages.device-screenoff', intl) // 'Urządzenie ma wyłączony ekran'

    if( msg === 'intent')
        return getMessage('screes-pages.device-notallowed', intl) // 'Brak uprawnien'

    if( msg === 'error')
        return getMessage('screes-pages.device-error', intl) // 'Wystąpił błąd'

    return msg
}

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
const isSafari = navigator.userAgent?.indexOf('Safari/') >= 0 &&
                 navigator.userAgent?.indexOf('Chrome/') < 0  &&
                 navigator.userAgent?.indexOf('Chromium/') < 0 


const getLoc = (intl: InjectedIntl ) : Locale => 
{
    let loc = enUS // i18n[intl.locale].locale

    if( intl.locale.startsWith('pl') )
        loc = pl

    if( intl.locale.startsWith('es') )
        loc = es

    return loc
}

const mpsToKmph = (mps: number) => {
    return Math.floor(3.6 * mps)
}

const mpsToMph = (mps: number) => {
    return Math.floor(mps/0.44704)
}

export function toLocaleSpeed(mps: number, intl: InjectedIntl ): string {
    if( intl.locale.startsWith('en') )
    {
        return mpsToMph(mps) + ' mph'
    }
    else
    {
        return mpsToKmph(mps) + ' km/h';
    }
}


export function toLocaleDate2(date: Date, intl: InjectedIntl, mode:number) : string
{
    let options : any = undefined

    if( mode === 1 ) 
        options = {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        }
    else if( mode === 2 ) 
        options = {
            weekday: "short",
            month: "2-digit",
            day: "numeric"
        }
    else if ( mode === 3 ) 
        options = {
            month: "2-digit",
            day: "numeric"
        }
    else if ( mode === 4 ) 
        options = {
            dateStyle: "medium"
        }
    else if ( mode === 5 )
        options = {
            dateStyle: "short"
        }
    else if ( mode === 6 )
        options = {
            day: 'numeric', 
            month: 'short'
        }


    let loc = getLoc(intl).code
    return date.toLocaleDateString(loc, options as Intl.DateTimeFormatOptions ) 
}

export function toLocaleTime(date : Date, intl: InjectedIntl )
{
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat

    // https://github.com/microsoft/TypeScript/issues/38266

    // const o : Intl.DateTimeFormatOptions = { timeStyle:"short" }  
    // const q : any = { timeStyle:"short" }
    // const f = new Intl.DateTimeFormat( intl.defaultLocale, q );
    // return f.format(date)
    // faktycznie na Safari to nie dziala...
    // if( isSafari )
    // {        
    //     return date.toLocaleTimeString()
    // }
    // else
    // {
    //     const q : any = { timeStyle:"short" }
    //     const f = new Intl.DateTimeFormat( intl.defaultLocale, q );
    //     return f.format(date)
    // }
    
    

    return format(date, 'p', {locale:getLoc(intl)} )
}

export function toLocaleDate(date : Date, intl: InjectedIntl )
{
    // if( isSafari )
    // {
    //     return date.toLocaleDateString()
    // }
    // else
    // {
    //     const q : any = { dateStyle:"medium" }
    //     const f = new Intl.DateTimeFormat( intl.defaultLocale, q );
    //     return f.format(date)
    // }
   
    return format(date, 'P', {locale:getLoc(intl)} )
}

export function toLocaleDateTime(date : Date, intl: InjectedIntl )
{
    // if( isSafari )
    // {
    //     return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()        
    // }
    // else
    // {
    //     const q : any = { timeStyle:"short", dateStyle:"medium" }
    //     const f = new Intl.DateTimeFormat( intl.defaultLocale, q );
    //     return f.format(date)
    // }

    return format(date, 'PPp', {locale:getLoc(intl)} )
}

export function toLocaleHour(hour: number, intl: InjectedIntl ) : String
{
    if( intl.locale.startsWith('en') )
    {
        const date = new Date()
        date.setHours(hour, 0, 0 ,0)
        return format(date, 'haaa', {locale:enUS} ) 
    }
    else
    {
        return String(hour)
    }
}

export function paddString(str : string, len : number, char : string) : string
{
    while( str.length < len )
        str = char + str;

    return str;
}

export function formatHourHHMM(d : Date) : string
{
    var hours = d.getHours();
    var min = d.getMinutes();

    return paddString( hours.toString(), 2, '0' ) + ':' + paddString( min.toString(), 2, '0' );
}


export function describeTimeElapsedHM(elapsedSeconds : number, intl: InjectedIntl) : string
{
    var elapsed = Math.floor(elapsedSeconds/60);

    var elapsedStr;
    var h = Math.floor(elapsed/60);
    var m = (elapsed % 60 );    
    
    if( h > 0 )
        elapsedStr = intl.formatMessage( {id: 'applications-page.total-hours-minutes-label'}, {hours:h, minutes:m} )  //   h + " h " + m + " min";
    else
        elapsedStr = intl.formatMessage( {id: 'applications-page.total-minutes-label'}, {minutes:m} )  //  m + " min";

    return elapsedStr;
}


export function describeTimeElapsedHMS(elapsedSeconds : number, intl: InjectedIntl) : string
{
    var elapsedStr
    var h,m,s
    var elapsed = Math.floor(elapsedSeconds);

    s = elapsed % 60
    elapsed = Math.floor(elapsed / 60)

    m = elapsed % 60
    elapsed = Math.floor(elapsed / 60)

    h = elapsed
   
    if( h > 0 )
        elapsedStr = intl.formatMessage( {id: 'applications-page.total-hours-minutes-label'}, {hours:h, minutes:m} ) //  `${h} h ${m} min`
    else if( m > 10)
        elapsedStr = intl.formatMessage( {id: 'applications-page.total-minutes-label'}, { minutes:m} ) //  `${m} min`
    else if( m > 0)
        elapsedStr = intl.formatMessage( {id: 'applications-page.total-minutes-seconds-label'}, {minutes:m, seconds:s} ) //  `${m} min ${s} sek`
    else
        elapsedStr = intl.formatMessage( {id: 'applications-page.total-seconds-label'}, {seconds:s} ) //  `${s} sek`

    return elapsedStr;
}

export function getCurrentYear() : number
{
    return new Date().getFullYear()
}

export function getBuildConfigStr(id : string) : string
{
    const procEnv : any =  process.env;
    let val = procEnv[id] 

    if( val !== undefined )
        return val

    val = procEnv['REACT_APP_' + id]

    if( val !== undefined )
        return val

    return ""
}

export function isAvosmart(): boolean
{
    return getBuildConfigStr('TYPE') === 'A'
}
 
export function isBeniamin(): boolean
{
    return getBuildConfigStr('TYPE') === 'B'
}
 
export function translateByConfigStr( id : string, intl: InjectedIntl ) : string
{
    const app = getBuildConfigStr('NAME');
    const appLite = getBuildConfigStr('NAME_LITE');
    const privacy = getBuildConfigStr('PRIVACY_URL');
    const marketing = getBuildConfigStr('MARKETUING_URL');
    const regulations = getBuildConfigStr('REGULATIONS_URL');
    const domain = getBuildConfigStr('NAME_DOMAIN');
    const panel = getBuildConfigStr('NAME_DOMAIN_PANEL');

    const appType = getBuildConfigStr('TYPE');

    var s = intl.formatMessage( {id}, { app, appLite, privacy, marketing, regulations, domain, panel } )

    if( intl.locale !== 'en' && appType === 'A' )
    {
        const s1 = 'https://' + domain.toLowerCase() + '/'
        const s2 = 'https://' + domain.toLowerCase() + '/' + intl.locale + '/'

        s = s.replaceAll( s1, s2 )
    }
    
    return s
}

export function getDefaultLang() : string
{
    let urlLang : string = ""
    let force : boolean = false
    const p1 = "^(/[a-zA-Z-]{3,999})?/([a-z]{2})(/.*)?$"

    const p = document.location.pathname
    var found = p.match(new RegExp(p1))

    if(found)
    {
        // console.log(found)

        urlLang = found[2]
        // console.log( 'getDefaultLang REF ' + lang )

        if( !urlLang || !getAllowedLanguages().includes(urlLang) )
        {
            urlLang = ""
            // console.log( 'getDefaultLang FALLBACK TO BUILD ' + lang )
        }
        else
        {
            if( !found[1] || found[1].startsWith("/login") || found[1].startsWith("/register") )
            {
                force = true
            }
        }
    }

    if( force )
    {
        setDefaultLang(urlLang)
        return urlLang
    }

    let lang = localStorage.getItem('lang')

    if( lang == null )
    {
        lang = urlLang

        if( !lang )
            lang = getBuildConfigStr('DEFAULT_LANG')
    }

    return lang
}

export function setDefaultLang(lang : string) 
{
    //console.log( 'setDefaultLang ' + lang )
    localStorage.setItem('lang', lang)
}

export function getAllowedLanguages() : string[]
{
    const langs = getBuildConfigStr('LANGUAGES')
    return langs.split(',')
}


export function getPkgStoreLink(pkg : string) : string
{
    return `https://play.google.com/store/apps/details?id=${pkg}`
}

export function getPkgStoreOpen(e: MouseEvent,  pkg : string) : boolean
{
  let url = `https://play.google.com/store/apps/details?id=${pkg}`
  window.open(url)
  e.preventDefault()
  e.stopPropagation()
  return false
}

export function getYoutubeVideoLink(videoId:string) : string
{
    if( videoId )
        return "https://www.youtube.com/watch?v=" + videoId

    return "https://www.youtube.com/"
}

export function openYoutubeVideoLink(e: MouseEvent,  videoId:string) : boolean
{
    let url = getYoutubeVideoLink(videoId)
    window.open(url)
    e.preventDefault()
    e.stopPropagation()
    return false
}



export function getAvatarUrl(avatar : number) : string
{
    return `/avatar/${avatar}.png`
}


export function getStatusName(status : number, intl: InjectedIntl) : string
{
    switch(status)
    {
        case 0:     return getMessage('transactiion-status-0', intl)  // "Oczekująca";
        case 1:     return getMessage('transactiion-status-1', intl)  // "Zakończona";
        case -1:    return getMessage('transactiion-status--1', intl)  // "Anulowana";
        case -2:    return getMessage('transactiion-status--2', intl)  // "Anulowana - zwrot";
        case -3:    return getMessage('transactiion-status--3', intl)  // "Oczekująca na potwierdzenie";
        case -100:  return getMessage('transactiion-status--100', intl)  // "Rozpoczęta";

        default:
            return getMessage('transactiion-status-0', intl)  // "Inny";
    }
}

export function getPlanName(planType: number, intl: InjectedIntl, linesMask : number = 0xFF ) : string 
{
    let id : string = 'plan-name-demoExpired'

    switch(planType)
    {
        case AccountType.demoExpired:   id = 'plan-name-demoExpired'; break
        case AccountType.licExpired:    id = 'plan-name-licExpired' ; break
        case AccountType.reward:        id = 'plan-name-reward'     ; break
        case AccountType.demo:          id = 'plan-name-demo'       ; break
        case AccountType.minimal:       id = 'plan-name-minimal'    ; break
        case AccountType.regular:       id = 'plan-name-regular'    ; break
        case AccountType.premium:       id = 'plan-name-premium'    ; break
        case AccountType.edu:           id = 'plan-name-edu'        ; break
        case AccountType.minimal_edu:   id = 'plan-name-minimal-edu'; break
        case AccountType.regular_edu:   id = 'plan-name-regular-edu'; break
        case AccountType.premium_edu:   id = 'plan-name-premium-edu'; break

        default:
            return "-"
    }

    let id2 = id + '2'
    let msg = ''

    if( (linesMask & 0x01) > 0 ) {
        msg += intl.formatMessage( {id:id} ).trim();
    }

    if( (linesMask & 0x02) > 0 ) {
        if( msg.length > 0 ) 
            msg += ' '
        msg += intl.formatMessage( {id:id2} ).trim();
    }

    return msg;
}

export function formatAmount(amount: number, currency: string)
{
    switch( currency.toUpperCase() )
    {
        case "USD": return "$" + String(amount)
        case "EUR": return String(amount) + "€"
        case "GBP": return "£" + String(amount)
        case "PLN": return String(amount) + "zł"
        default: return amount + " " + currency
    }
}

export function formatInterval(interval: string, intervalCount: number, intl: InjectedIntl) 
{
    let intervalName = ''

    if( intervalCount === 1 )
    {
      switch(interval)
      {
        case "year":    intervalName = getMessage('interval-name-year',  intl); break;
        case "month":   intervalName = getMessage('interval-name-month', intl); break;
        case "week":    intervalName = getMessage('interval-name-week',  intl); break;
        case "day":     intervalName = getMessage('interval-name-day',   intl); break;
        default:        intervalName = interval;
      }

      return intervalName
    }
    else
    {
      switch(interval)
      {
        case "year":    intervalName = getMessage('interval-name-years',  intl); break;
        case "month":   intervalName = getMessage('interval-name-months', intl); break;
        case "week":    intervalName = getMessage('interval-name-weeks',  intl); break;
        case "day":     intervalName = getMessage('interval-name-days',   intl); break;
        default:        intervalName = interval;
      }

      return intervalCount + ' ' + intervalName
    }
  }

export function fd(timestamp: number, intl: InjectedIntl) : string
{
    const d = new Date(timestamp*1000)

    return toLocaleDate(d, intl)
}


// JSWrapper js = new JSWrapper(this);
// webView.getSettings().setJavaScriptEnabled(true);
// webView.getSettings().setJavaScriptCanOpenWindowsAutomatically(true);
// webView.getSettings().setDomStorageEnabled(true);
// webView.addJavascriptInterface( js, "Android" );

declare var Android: any;

export function isAndroid() : boolean
{
    if( typeof Android === 'undefined' )
        return false;

    if( typeof Android.close === 'undefined' )
        return false;

    if( typeof Android.success === 'undefined' )
        return false;

    return true;
}

export function isIOS() : boolean
{
    var w = window as any

    // window.webkit.messageHandlers.ben
    if( typeof w.webkit === 'undefined' || 
        typeof w.webkit.messageHandlers === 'undefined' ||
        typeof w.webkit.messageHandlers.ben === 'undefined' )
    {
        return false
    }

    return true
}

export function isIOS2() : boolean
{
    if( [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ) return true
      
      // iPad on iOS 13 detection
      if( navigator.userAgent.includes("Mac") && "ontouchend" in document )
        return true

    return false
}

export function isWebView() : boolean
{
    return isAndroid() || isIOS()
}

export function callWebViewClose()  : boolean
{
    if( isAndroid() ) 
    {
        Android.close()
        return true
    } 
    else if( isIOS() ) 
    {
        var w = window as any
        w.webkit.messageHandlers.ben.postMessage("close")
        return true
    }    
    else 
    {
        return false
    }
}

export function callWebViewSuccess() : boolean
{
    if( isAndroid() ) 
    {
        Android.success()
        return true
    }
    else if( isIOS() ) 
    {
        var w = window as any;
        w.webkit.messageHandlers.ben.postMessage("success");
        return true;
    }    
    else 
    {
        return false
    }
}

export function expandIcoFileName(ico:string) : string
{
    var hostName = window.location.protocol + '//' + 
                   window.location.hostname + 
                   (window.location.port ? ':' + window.location.port: '');

    return hostName + ico
}

export function mapAndroidVersion (osVer: string) : string 
{
    switch (osVer) {
        case '30': return '11'
        case '29': return '10'
        case '28': return 'Pie 9'
        case '27': return 'Oreo 8.1'
        case '26': return 'Oreo 8.0'
        case '25': return 'Nougat 7.1'
        case '24': return 'Nougat 7.0'
        default: return osVer
    }
}






export function hasDeviceFeature(benAccount:BenAccountContextValue, feature:string, strict:boolean = false)
{
  if( !strict && (!benAccount.currentProfile.deviceId || !benAccount.currentProfile.profileId ) )
    return true

  const selectedProfile = benAccount.profiles.find(profile => benAccount.currentProfile.profileId === profile.id )

  if( !selectedProfile )
    return false

  const selectedDevice = selectedProfile.devices.find(device => benAccount.currentProfile.deviceId === device.id )

  if( !selectedDevice || !selectedDevice.appFeatures )
    return false

  if( selectedDevice.appFeatures.indexOf(feature) < 0 )
    return false

  return true
}


export function isDeviceAndroid(benAccount:BenAccountContextValue)
{
  if( !benAccount.currentProfile.deviceId || !benAccount.currentProfile.profileId ) 
    return true

  const selectedProfile = benAccount.profiles.find(profile => benAccount.currentProfile.profileId === profile.id )

  if( !selectedProfile )
    return false

  const selectedDevice = selectedProfile.devices.find(device => benAccount.currentProfile.deviceId === device.id )

  if( !selectedDevice || !selectedDevice.osName )
    return false

  return selectedDevice.osName === 'Android'
}

export function getDeviceAppType(benAccount:BenAccountContextValue) : APP_TYPE
{
  if( !benAccount.currentProfile.deviceId || !benAccount.currentProfile.profileId ) 
    return APP_TYPE.UNDEFINED

  const selectedProfile = benAccount.profiles.find(profile => benAccount.currentProfile.profileId === profile.id )

  if( !selectedProfile )
    return APP_TYPE.UNDEFINED

  const selectedDevice = selectedProfile.devices.find(device => benAccount.currentProfile.deviceId === device.id )

  if( !selectedDevice || !selectedDevice.osName )
    return APP_TYPE.UNDEFINED

  return selectedDevice.appType
}

export function shouldDisplaySmsScreensAnyway(benAccount:BenAccountContextValue)
{
  const at = getDeviceAppType(benAccount)

  if( at === APP_TYPE.APP_TYPE_ANDROID )
    return true

  if( at === APP_TYPE.APP_TYPE_UNKNOWN && isDeviceAndroid(benAccount) )
    return true

  return false
}


export function array2string(arr:number[], size:number) : string
{
    if( arr.length !== size )
        arr.length = size

    return arr.join(',')
}

export function string2array(str:string|undefined, size:number) : number[]
{
    let tokens : string[]
    
    if(str)
        tokens = str.split(',')
    else
        tokens = []

    let arr : number[] = []

    for( let i = 0; i<size; i++)
    {
        if( tokens.length > i )
            arr.push( Number(tokens[i]) )
        else
            arr.push( 0 )
    }

    return arr
}


export function getTimezoneOffset(timeZone:string) : number | null
{
    try
    {
        const now = new Date()
        const tzString = now.toLocaleString('en-US', { timeZone })
        const localString = now.toLocaleString('en-US')
        const diff = (Date.parse(localString) - Date.parse(tzString)) / 60000
        const offset = diff + now.getTimezoneOffset() 
        return -offset
    }
    catch(err)
    {
        return null
    }
}


export const GROUP_APP_MESSENGERS = new Set<string>([

        'com.google.android.apps.tachyon',
        'com.whatsapp',
        'com.facebook.orca',
        'com.discord',
        'com.enflick.android.TextNow',
        'org.telegram.messenger',
        'com.facebook.mlite',
        'com.ringapp',
        'com.whatsapp.w4b',
        'com.facebook.talk',
        'com.google.android.talk',
        'com.google.android.apps.messaging',
        'com.google.android.apps.googlevoice',
        'com.groupme.android',
        'walkie.talkie.among.us.friends',
        'org.thoughtcrime.securesms',
        'com.callapp.contacts',
        'com.google.android.contacts',
        'com.viber.voip',
        'com.truecaller',
        'kik.android',
        'com.skype.raider',
        'com.talkatone.android',
        'sms.mms.messages.text.free',
        'com.imo.android.imous',
        'jp.naver.line.android',
        'com.verizon.messaging.vzmsgs',
        'com.tencent.mm',
        'com.textfun.text.free.call',
        'ws.coverme.im',
        'com.textmeinc.textme3',
        'com.helixdev.onesocial',
        'com.juphoon.justalk',
        'me.nextplus.smsfreetext.phonecalls',
        'com.bingo.livetalk',
        'sg.bigo.live',
        'com.myyearbook.m',
        'com.google.android.apps.meetings',
        'com.microsoft.teams',
        'us.zoom.videomeetings',        
    
    ])


export const GROUP_APP_SOCIAL = new Set<string>([
        'com.facebook.katana', 
        'io.faceapp', 
        'com.instagram.android',
        'com.zhiliaoapp.musically',
        'com.instagram.android',
        'com.snapchat.android',
        'com.facebook.lite',
        'com.facebook.katana',
        'com.reddit.frontpage',
        'com.twitter.android',
    
    ])

export const GROUP_APP_VIDEO = new Set<string>([

        'com.google.android.apps.youtube.kids',
        'com.amazon.tahoe.freetime',
        'com.disney.datg.videoplatforms.android.watchdc',
        'com.orange.kidspiano.music.songs',
        'com.turner.cnvideoapp',
        'com.nickonline.android.nickapp',
        'com.nick.android.nickjr',
        'tv.twitch.android.app',
        'com.google.android.youtube',
        'com.netflix.mediaclient',
        'eu.hbogo.android',
        'com.hbo.broadband',
        'com.amazon.avod.thirdpartyclient',
        'com.vimeo.android.videoapp',
        'com.google.android.videos',
        'com.zhiliaoapp.musically',
        'com.miui.videoplayer',
        'com.tvp.vodtv.mobile',
        'pl.cda',
    
    ])

    

// export const TIMEZONES_ARRAY = [
//     "Africa/Abidjan", 
//     "Africa/Accra", 
//     "Africa/Addis_Ababa", 
//     "Africa/Algiers", 
//     "Africa/Asmara", 
//     "Africa/Asmera", 
//     "Africa/Bamako", 
//     "Africa/Bangui", 
//     "Africa/Banjul", 
//     "Africa/Bissau", 
//     "Africa/Blantyre", 
//     "Africa/Brazzaville", 
//     "Africa/Bujumbura", 
//     "Africa/Cairo", 
//     "Africa/Casablanca", 
//     "Africa/Ceuta", 
//     "Africa/Conakry", 
//     "Africa/Dakar", 
//     "Africa/Dar_es_Salaam", 
//     "Africa/Djibouti", 
//     "Africa/Douala", 
//     "Africa/El_Aaiun", 
//     "Africa/Freetown", 
//     "Africa/Gaborone", 
//     "Africa/Harare", 
//     "Africa/Johannesburg", 
//     "Africa/Juba", 
//     "Africa/Kampala", 
//     "Africa/Khartoum", 
//     "Africa/Kigali", 
//     "Africa/Kinshasa", 
//     "Africa/Lagos", 
//     "Africa/Libreville", 
//     "Africa/Lome", 
//     "Africa/Luanda", 
//     "Africa/Lubumbashi", 
//     "Africa/Lusaka", 
//     "Africa/Malabo", 
//     "Africa/Maputo", 
//     "Africa/Maseru", 
//     "Africa/Mbabane", 
//     "Africa/Mogadishu", 
//     "Africa/Monrovia", 
//     "Africa/Nairobi", 
//     "Africa/Ndjamena", 
//     "Africa/Niamey", 
//     "Africa/Nouakchott", 
//     "Africa/Ouagadougou", 
//     "Africa/Porto-Novo", 
//     "Africa/Porto_Novo", 
//     "Africa/Sao_Tome", 
//     "Africa/Timbuktu", 
//     "Africa/Tripoli", 
//     "Africa/Tunis", 
//     "Africa/Windhoek", 
//     "America/Adak", 
//     "America/Anchorage", 
//     "America/Anguilla", 
//     "America/Antigua", 
//     "America/Araguaina", 
//     "America/Argentina/Buenos_Aires", 
//     "America/Argentina/Catamarca", 
//     "America/Argentina/ComodRivadavia", 
//     "America/Argentina/Cordoba", 
//     "America/Argentina/Jujuy", 
//     "America/Argentina/La_Rioja", 
//     "America/Argentina/Mendoza", 
//     "America/Argentina/Rio_Gallegos", 
//     "America/Argentina/Salta", 
//     "America/Argentina/San_Juan", 
//     "America/Argentina/San_Luis", 
//     "America/Argentina/Tucuman", 
//     "America/Argentina/Ushuaia", 
//     "America/Aruba", 
//     "America/Asuncion", 
//     "America/Atikokan", 
//     "America/Atka", 
//     "America/Bahia", 
//     "America/Bahia_Banderas", 
//     "America/Barbados", 
//     "America/Belem", 
//     "America/Belize", 
//     "America/Beulah", 
//     "America/Blanc-Sablon", 
//     "America/Blanc_Sablon", 
//     "America/Boa_Vista", 
//     "America/Bogota", 
//     "America/Boise", 
//     "America/Buenos_Aires", 
//     "America/Cambridge_Bay", 
//     "America/Campo_Grande", 
//     "America/Cancun", 
//     "America/Caracas", 
//     "America/Catamarca", 
//     "America/Cayenne", 
//     "America/Cayman", 
//     "America/Center", 
//     "America/Chicago", 
//     "America/Chihuahua", 
//     "America/ComodRivadavia", 
//     "America/Coral_Harbour", 
//     "America/Cordoba", 
//     "America/Costa_Rica", 
//     "America/Creston", 
//     "America/Cuiaba", 
//     "America/Curacao", 
//     "America/Danmarkshavn", 
//     "America/Dawson", 
//     "America/Dawson_Creek", 
//     "America/Denver", 
//     "America/Detroit", 
//     "America/Dominica", 
//     "America/Edmonton", 
//     "America/Eirunepe", 
//     "America/El_Salvador", 
//     "America/Ensenada", 
//     "America/Fortaleza", 
//     "America/Fort_Wayne", 
//     "America/Glace_Bay", 
//     "America/Godthab", 
//     "America/Goose_Bay", 
//     "America/Grand_Turk", 
//     "America/Grenada", 
//     "America/Guadeloupe", 
//     "America/Guatemala", 
//     "America/Guayaquil", 
//     "America/Guyana", 
//     "America/Halifax", 
//     "America/Havana", 
//     "America/Hermosillo", 
//     "America/Indiana/Indianapolis", 
//     "America/Indiana/Knox", 
//     "America/Indiana/Marengo", 
//     "America/Indiana/Petersburg", 
//     "America/Indiana/Tell_City", 
//     "America/Indiana/Vevay", 
//     "America/Indiana/Vincennes", 
//     "America/Indiana/Winamac", 
//     "America/Indianapolis", 
//     "America/Inuvik", 
//     "America/Iqaluit", 
//     "America/Jamaica", 
//     "America/Jujuy", 
//     "America/Juneau", 
//     "America/Kentucky/Louisville", 
//     "America/Kentucky/Monticello", 
//     "America/Knox", 
//     "America/Knox_IN", 
//     "America/Kralendijk", 
//     "America/La_Paz", 
//     "America/La_Rioja", 
//     "America/Lima", 
//     "America/Los_Angeles", 
//     "America/Louisville", 
//     "America/Lower_Princes", 
//     "America/Maceio", 
//     "America/Managua", 
//     "America/Manaus", 
//     "America/Marengo", 
//     "America/Marigot", 
//     "America/Martinique", 
//     "America/Matamoros", 
//     "America/Mazatlan", 
//     "America/Mendoza", 
//     "America/Menominee", 
//     "America/Merida", 
//     "America/Metlakatla", 
//     "America/Mexico_City", 
//     "America/Miquelon", 
//     "America/Moncton", 
//     "America/Monterrey", 
//     "America/Montevideo", 
//     "America/Monticello", 
//     "America/Montreal", 
//     "America/Montserrat", 
//     "America/Nassau", 
//     "America/New_Salem", 
//     "America/New_York", 
//     "America/Nipigon", 
//     "America/Nome", 
//     "America/Noronha", 
//     "America/North_Dakota/Beulah", 
//     "America/North_Dakota/Center", 
//     "America/North_Dakota/New_Salem", 
//     "America/Ojinaga", 
//     "America/Panama", 
//     "America/Pangnirtung", 
//     "America/Paramaribo", 
//     "America/Petersburg", 
//     "America/Phoenix", 
//     "America/Port-au-Prince", 
//     "America/Porto_Acre", 
//     "America/Porto_Velho", 
//     "America/Port_au_Prince", 
//     "America/Port_of_Spain", 
//     "America/Puerto_Rico", 
//     "America/Rainy_River", 
//     "America/Rankin_Inlet", 
//     "America/Recife", 
//     "America/Regina", 
//     "America/Resolute", 
//     "America/Rio_Branco", 
//     "America/Rio_Gallegos", 
//     "America/Rosario", 
//     "America/Salta", 
//     "America/Santarem", 
//     "America/Santa_Isabel", 
//     "America/Santiago", 
//     "America/Santo_Domingo", 
//     "America/San_Juan", 
//     "America/San_Luis", 
//     "America/Sao_Paulo", 
//     "America/Scoresbysund", 
//     "America/Shiprock", 
//     "America/Sitka", 
//     "America/St_Barthelemy", 
//     "America/St_Johns", 
//     "America/St_Kitts", 
//     "America/St_Lucia", 
//     "America/St_Thomas", 
//     "America/St_Vincent", 
//     "America/Swift_Current", 
//     "America/Tegucigalpa", 
//     "America/Tell_City", 
//     "America/Thule", 
//     "America/Thunder_Bay", 
//     "America/Tijuana", 
//     "America/Toronto", 
//     "America/Tortola", 
//     "America/Tucuman", 
//     "America/Ushuaia", 
//     "America/Vancouver", 
//     "America/Vevay", 
//     "America/Vincennes", 
//     "America/Virgin", 
//     "America/Whitehorse", 
//     "America/Winamac", 
//     "America/Winnipeg", 
//     "America/Yakutat", 
//     "America/Yellowknife", 
//     "Antarctica/Casey", 
//     "Antarctica/Davis", 
//     "Antarctica/DumontDUrville", 
//     "Antarctica/Macquarie", 
//     "Antarctica/Mawson", 
//     "Antarctica/McMurdo", 
//     "Antarctica/Palmer", 
//     "Antarctica/Rothera", 
//     "Antarctica/South_Pole", 
//     "Antarctica/Syowa", 
//     "Antarctica/Troll", 
//     "Antarctica/Vostok", 
//     "Arctic/Longyearbyen", 
//     "Asia/Aden", 
//     "Asia/Almaty", 
//     "Asia/Amman", 
//     "Asia/Anadyr", 
//     "Asia/Aqtau", 
//     "Asia/Aqtobe", 
//     "Asia/Ashgabat", 
//     "Asia/Ashkhabad", 
//     "Asia/Baghdad", 
//     "Asia/Bahrain", 
//     "Asia/Baku", 
//     "Asia/Bangkok", 
//     "Asia/Beirut", 
//     "Asia/Bishkek", 
//     "Asia/Brunei", 
//     "Asia/Calcutta", 
//     "Asia/Chita", 
//     "Asia/Choibalsan", 
//     "Asia/Chongqing", 
//     "Asia/Chungking", 
//     "Asia/Colombo", 
//     "Asia/Dacca", 
//     "Asia/Damascus", 
//     "Asia/Dhaka", 
//     "Asia/Dili", 
//     "Asia/Dubai", 
//     "Asia/Dushanbe", 
//     "Asia/Gaza", 
//     "Asia/Harbin", 
//     "Asia/Hebron", 
//     "Asia/Hong_Kong", 
//     "Asia/Hovd", 
//     "Asia/Ho_Chi_Minh", 
//     "Asia/Irkutsk", 
//     "Asia/Istanbul", 
//     "Asia/Jakarta", 
//     "Asia/Jayapura", 
//     "Asia/Jerusalem", 
//     "Asia/Kabul", 
//     "Asia/Kamchatka", 
//     "Asia/Karachi", 
//     "Asia/Kashgar", 
//     "Asia/Kathmandu", 
//     "Asia/Katmandu", 
//     "Asia/Khandyga", 
//     "Asia/Kolkata", 
//     "Asia/Krasnoyarsk", 
//     "Asia/Kuala_Lumpur", 
//     "Asia/Kuching", 
//     "Asia/Kuwait", 
//     "Asia/Macao", 
//     "Asia/Macau", 
//     "Asia/Magadan", 
//     "Asia/Makassar", 
//     "Asia/Manila", 
//     "Asia/Muscat", 
//     "Asia/Nicosia", 
//     "Asia/Novokuznetsk", 
//     "Asia/Novosibirsk", 
//     "Asia/Omsk", 
//     "Asia/Oral", 
//     "Asia/Phnom_Penh", 
//     "Asia/Pontianak", 
//     "Asia/Pyongyang", 
//     "Asia/Qatar", 
//     "Asia/Qyzylorda", 
//     "Asia/Rangoon", 
//     "Asia/Riyadh", 
//     "Asia/Saigon", 
//     "Asia/Sakhalin", 
//     "Asia/Samarkand", 
//     "Asia/Seoul", 
//     "Asia/Shanghai", 
//     "Asia/Singapore", 
//     "Asia/Srednekolymsk", 
//     "Asia/Taipei", 
//     "Asia/Tashkent", 
//     "Asia/Tbilisi", 
//     "Asia/Tehran", 
//     "Asia/Tel_Aviv", 
//     "Asia/Thimbu", 
//     "Asia/Thimphu", 
//     "Asia/Tokyo", 
//     "Asia/Ujung_Pandang", 
//     "Asia/Ulaanbaatar", 
//     "Asia/Ulan_Bator", 
//     "Asia/Urumqi", 
//     "Asia/Ust-Nera", 
//     "Asia/Ust_Nera", 
//     "Asia/Vientiane", 
//     "Asia/Vladivostok", 
//     "Asia/Yakutsk", 
//     "Asia/Yekaterinburg", 
//     "Asia/Yerevan", 
//     "Atlantic/Azores", 
//     "Atlantic/Bermuda", 
//     "Atlantic/Canary", 
//     "Atlantic/Cape_Verde", 
//     "Atlantic/Faeroe", 
//     "Atlantic/Faroe", 
//     "Atlantic/Jan_Mayen", 
//     "Atlantic/Madeira", 
//     "Atlantic/Reykjavik", 
//     "Atlantic/South_Georgia", 
//     "Atlantic/Stanley", 
//     "Atlantic/St_Helena", 
//     "Australia/ACT", 
//     "Australia/Adelaide", 
//     "Australia/Brisbane", 
//     "Australia/Broken_Hill", 
//     "Australia/Canberra", 
//     "Australia/Currie", 
//     "Australia/Darwin", 
//     "Australia/Eucla", 
//     "Australia/Hobart", 
//     "Australia/LHI", 
//     "Australia/Lindeman", 
//     "Australia/Lord_Howe", 
//     "Australia/Melbourne", 
//     "Australia/North", 
//     "Australia/NSW", 
//     "Australia/Perth", 
//     "Australia/Queensland", 
//     "Australia/South", 
//     "Australia/Sydney", 
//     "Australia/Tasmania", 
//     "Australia/Victoria", 
//     "Australia/West", 
//     "Australia/Yancowinna", 
//     "Brazil/Acre", 
//     "Brazil/DeNoronha", 
//     "Brazil/East", 
//     "Brazil/West", 
//     "Canada/Atlantic", 
//     "Canada/Central", 
//     "Canada/East-Saskatchewan", 
//     "Canada/Eastern", 
//     "Canada/East_Saskatchewan", 
//     "Canada/Mountain", 
//     "Canada/Newfoundland", 
//     "Canada/Pacific", 
//     "Canada/Saskatchewan", 
//     "Canada/Yukon", 
//     "Chile/Continental", 
//     "Chile/EasterIsland", 
//     "Etc/GMT", 
//     "Etc/Greenwich", 
//     "Etc/UCT", 
//     "Etc/Universal", 
//     "Etc/UTC", 
//     "Etc/Zulu", 
//     "Europe/Amsterdam", 
//     "Europe/Andorra", 
//     "Europe/Athens", 
//     "Europe/Belfast", 
//     "Europe/Belgrade", 
//     "Europe/Berlin", 
//     "Europe/Bratislava", 
//     "Europe/Brussels", 
//     "Europe/Bucharest", 
//     "Europe/Budapest", 
//     "Europe/Busingen", 
//     "Europe/Chisinau", 
//     "Europe/Copenhagen", 
//     "Europe/Dublin", 
//     "Europe/Gibraltar", 
//     "Europe/Guernsey", 
//     "Europe/Helsinki", 
//     "Europe/Isle_of_Man", 
//     "Europe/Istanbul", 
//     "Europe/Jersey", 
//     "Europe/Kaliningrad", 
//     "Europe/Kiev", 
//     "Europe/Lisbon", 
//     "Europe/Ljubljana", 
//     "Europe/London", 
//     "Europe/Luxembourg", 
//     "Europe/Madrid", 
//     "Europe/Malta", 
//     "Europe/Mariehamn", 
//     "Europe/Minsk", 
//     "Europe/Monaco", 
//     "Europe/Moscow", 
//     "Europe/Nicosia", 
//     "Europe/Oslo", 
//     "Europe/Paris", 
//     "Europe/Podgorica", 
//     "Europe/Prague", 
//     "Europe/Riga", 
//     "Europe/Rome", 
//     "Europe/Samara", 
//     "Europe/San_Marino", 
//     "Europe/Sarajevo", 
//     "Europe/Simferopol", 
//     "Europe/Skopje", 
//     "Europe/Sofia", 
//     "Europe/Stockholm", 
//     "Europe/Tallinn", 
//     "Europe/Tirane", 
//     "Europe/Tiraspol", 
//     "Europe/Uzhgorod", 
//     "Europe/Vaduz", 
//     "Europe/Vatican", 
//     "Europe/Vienna", 
//     "Europe/Vilnius", 
//     "Europe/Volgograd", 
//     "Europe/Warsaw", 
//     "Europe/Zagreb", 
//     "Europe/Zaporozhye", 
//     "Europe/Zurich", 
//     "Indian/Antananarivo", 
//     "Indian/Chagos", 
//     "Indian/Christmas", 
//     "Indian/Cocos", 
//     "Indian/Comoro", 
//     "Indian/Kerguelen", 
//     "Indian/Mahe", 
//     "Indian/Maldives", 
//     "Indian/Mauritius", 
//     "Indian/Mayotte", 
//     "Indian/Reunion", 
//     "Mexico/BajaNorte", 
//     "Mexico/BajaSur", 
//     "Mexico/General", 
//     "Pacific/Apia", 
//     "Pacific/Auckland", 
//     "Pacific/Chatham", 
//     "Pacific/Chuuk", 
//     "Pacific/Easter", 
//     "Pacific/Efate", 
//     "Pacific/Enderbury", 
//     "Pacific/Fakaofo", 
//     "Pacific/Fiji", 
//     "Pacific/Funafuti", 
//     "Pacific/Galapagos", 
//     "Pacific/Gambier", 
//     "Pacific/Guadalcanal", 
//     "Pacific/Guam", 
//     "Pacific/Honolulu", 
//     "Pacific/Johnston", 
//     "Pacific/Kiritimati", 
//     "Pacific/Kosrae", 
//     "Pacific/Kwajalein", 
//     "Pacific/Majuro", 
//     "Pacific/Marquesas", 
//     "Pacific/Midway", 
//     "Pacific/Nauru", 
//     "Pacific/Niue", 
//     "Pacific/Norfolk", 
//     "Pacific/Noumea", 
//     "Pacific/Pago_Pago", 
//     "Pacific/Palau", 
//     "Pacific/Pitcairn", 
//     "Pacific/Pohnpei", 
//     "Pacific/Ponape", 
//     "Pacific/Port_Moresby", 
//     "Pacific/Rarotonga", 
//     "Pacific/Saipan", 
//     "Pacific/Samoa", 
//     "Pacific/Tahiti", 
//     "Pacific/Tarawa", 
//     "Pacific/Tongatapu", 
//     "Pacific/Truk", 
//     "Pacific/Wake", 
//     "Pacific/Wallis", 
//     "Pacific/Yap"
// ];

