import React from 'react'
import { Modal, ModalHeader, ModalBody,  Row, Col, Card,  CardBody, Button } from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { InjectedI18nProviderProps, withI18nProvider } from '../../providers/i18nProvider'
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService,  LicensesInfo } from '../../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { getStatusName, fd, getPlanName, formatAmount, formatInterval } from '../../lib/utils'
import I18nMessages from '../../components/I18nMessages'
import { InjectedBenHelperProps, withBenHelper } from '../../providers/benHelperProvider'
import Spinner from '../../components/Spinner'
import AsyncButton from '../../components/AsyncButton'
import getMessage from '../../language/getMessage';
import AsyncLink from '../../components/AsyncLink';

// npm i react-router-dom
// npm install -D @types/react-router-dom@5

type Props = InjectedBenHelperProps &
            InjectedI18nProviderProps &
            InjectedBenAccountProps &
            InjectedBenServiceProps &
            InjectedBenNotificationProps &
            RouteComponentProps &
            InjectedIntlProps &
{
    isOpen: boolean
    onToggle: () => void
    onCallUpgradePlan: () => void
}

const PlansInfoPage: React.FC<Props> = ({
    isOpen,
    onToggle,
    onCallUpgradePlan,
    benHelper,
    benService,
    benNotification,
    i18nProvider,
    history,
    intl
}) => {

    const [licenses, setLicenses] = React.useState<LicensesInfo>()
    const [isLoading, setLoading] = React.useState<boolean>(true)

    const [subscriptionOnOffProcessing, setSubscriptionOnOffProcessing] = React.useState(false)
    const [upgradeProcessing, setUpgradeProcessing] = React.useState(false)
    const [purchaseProcessing, setPurchaseProcessing] = React.useState(false)
    const [portalProcessing, setPortalProcessing] = React.useState(false)

    function subscriptionOnOffLink(e: React.MouseEvent) 
    {
        subscriptionOnOff()

        e.preventDefault()
        e.stopPropagation()
    }


    function subscriptionOnOff()
    {
        if( !licenses || !licenses.subscription )
            return

        setSubscriptionOnOffProcessing(true)

        const val = licenses.subscription.cancel_at_period_end ? 0 : 1

        benService.subscribtion(licenses.subscription.id, val).then( result => {

            if( licenses && licenses.subscription )
                licenses.subscription.cancel_at_period_end = val

            setLicenses(licenses)
        })
        .catch( () => {
            
            benNotification.notify({
                type: 'error',
                title: getMessage('side-effect.internal-error-title', intl),
                message: getMessage('side-effect.internal-error-message', intl)
              })

        })
        .finally( () => {
            setSubscriptionOnOffProcessing(false)
        })
    }

    function handleOnPurchaseClick () {
        setPurchaseProcessing(true)
        history.push('/account/purchase')
    }

    function handleOnUpgradeClick() {
        setUpgradeProcessing(true)
        onCallUpgradePlan()
    }

    // function handleOnPurchaseEduClick() {
    //     setPurchaseProcessing(true)
    //     history.push('/account/purchase')
    // }

    function handleOnPortal()
    {
        if( licenses )
        {
            setPortalProcessing(true)

            if( licenses.portal )
            {
                const url = licenses.portal.url
                window.location.href = url
            }
        }
    }

    
    // z jakiegos powodu przejscie BACK z stripe.com nie wylacza animacji, react w ogole nie wie, ze zmienil sie adres URL.
    // useLocation() jako dep do efektu nie odpala sie przy zmianie URLa
    React.useEffect(() => 
    {
        let mounted = true

        if( portalProcessing )
        {
            setTimeout( () => {
                if( mounted && portalProcessing ) 
                    setPortalProcessing(false) 
            }, 3000)
        }

        return () => { 
            mounted = false
        }
    }, [portalProcessing] )
    

    React.useEffect(() => {

        let mounted = true

        benService.listLicenses(false, benHelper.promoCode, intl.locale)
            .then(result => { 
                if( mounted ) {
                    setLicenses(result.data) 
                    setLoading(false)
                }
            })

        return () => { 
            mounted = false
        }            

    }, [benService, intl, benHelper.promoCode] )


    return (

        <Modal isOpen={isOpen} toggle={onToggle} size="lg">
            <ModalHeader>
                <Button color="light" size="xs" onClick={onToggle} className="button-x" >
                    X
                </Button>

                <div className="modal-large-title">
                    <I18nMessages id="plans-info-page.title" />
                </div>
            </ModalHeader>

            <ModalBody className="plan-info-body">

                <Card body className="shadow plan-info-card" >

                    <CardBody className="plan-info-card-body">

                        { isLoading && (
                            <Spinner/>
                        )}

                        {licenses && (
                        <>
                            <div className="plan-info-title">
                                {/* <I18nMessages id="plans-info-page.your-plan" /> */}
                                Twój wybrany plan kontroli rodzicielskiej
                            </div>

                            <div className="plan-info-row">
                                <div className="plan-info-msg1"> <I18nMessages id="plans-info-page.plan" /></div> 
                                <div className="plan-info-msg2"> { getPlanName(licenses.lic.accountType, intl) } </div> 
                            </div>

                            <div className="plan-info-row">
                                <div className="plan-info-msg1"><I18nMessages id="plans-info-page.max-devices" /></div>
                                <div className="plan-info-msg2"> {licenses.lic.devices } </div>
                            </div>
                        </>
                        )}


                        {licenses && !licenses.subscription && licenses.lic.validFrom && licenses.lic.validTo && (
                            <>
                                <div className="plan-info-row">
                                    <div className="plan-info-msg1" ><I18nMessages id="plans-info-page.valid-till" /></div>
                                    <div className="plan-info-msg2" >{ fd(licenses.lic.validTo, intl)} </div> 
                                </div>
                            </>
                        )}
                        
                        </CardBody>
                        
                        {licenses && licenses.hasUpgrades > 0 && (
                            <AsyncButton className='mt-2 flex'  onClick={handleOnUpgradeClick} showSpinner={upgradeProcessing} color="primary">
                                <I18nMessages id="plans-info-page.upgrade-plan" />
                            </AsyncButton>
                        )}

                        {licenses && licenses.hasPlans > 0 && (
                            <AsyncButton className='mt-2'  onClick={handleOnPurchaseClick} showSpinner={purchaseProcessing} color="primary">
                                <I18nMessages id="plans-info-page.extend-lic" />
                            </AsyncButton>
                        )}
                </Card>

                {/* <Card body className="shadow plan-info-card mt-4" >
                    <CardBody className="plan-info-card-body">

                        { isLoading && (
                            <Spinner/>
                        )}

                        {licenses && (
                        <>
                            <div className="plan-info-title">
                                Twój wybrany plan edukacyjny
                            </div>

                            <div className="plan-info-row">
                                <div className="plan-info-msg1"> <I18nMessages id="plans-info-page.plan" /></div> 
                                <div className="plan-info-msg2"> { getPlanName(licenses.lic.accountTypeEdu, intl) } </div> 
                            </div>
                        </>
                        )}

                        {licenses && !licenses.subscription && licenses.lic.validFromEdu && licenses.lic.validToEdu && (
                            <>
                                <div className="plan-info-row">
                                    <div className="plan-info-msg1" ><I18nMessages id="plans-info-page.valid-till" /></div>
                                    <div className="plan-info-msg2" >{ fd(licenses.lic.validToEdu, intl)} </div> 
                                </div>
                            </>
                        )}                        

                    </CardBody>

                    {licenses && licenses.hasPlansEdu > 0 && (
                        <AsyncButton className='mt-2' onClick={handleOnPurchaseEduClick} showSpinner={purchaseProcessing} color="primary">
                            <I18nMessages id="plans-info-page.extend-lic" />
                        </AsyncButton>
                    )}

                </Card> */}


                { licenses && licenses.subscription && (

                    <Card body className="shadow plan-info-card mt-4" >

                        <CardBody className="plan-info-card-body">

                            <div className="plan-info-title">
                                <I18nMessages id="plans-info-page.subscription-information" />
                            </div>
                                                            
                            <div className="plan-info-row">
                                <div className="plan-info-msg2" ><I18nMessages id="plans-info-page.subscription-created" /> </div>
                                <div className="plan-info-msg2" >{ fd( licenses.subscription.created, intl ) } </div>
                            </div>

                            <div className="plan-info-row ">
                                <div className="plan-info-msg2" ><I18nMessages id="plans-info-page.subscription-current-period" /> </div>
                                <div className="plan-info-msg2" >{ fd( licenses.subscription.current_period_start, intl ) } - { fd( licenses.subscription.current_period_end, intl ) }
                                </div>
                            </div>

                            <div className="plan-info-row">
                                
                                <div className="plan-info-msg2" > 
                                    { !licenses.subscription.cancel_at_period_end ? (
                                        <>
                                            <I18nMessages id="plans-info-page.subscription-renews-on" /> {fd( licenses.subscription.current_period_end, intl )}
                                            <AsyncLink showSpinner={subscriptionOnOffProcessing} onClick={subscriptionOnOffLink} className={"plan-info-msg1 plan-info-clickable-link"} >
                                                <I18nMessages id="plans-info-page.subscription-cancel-renewal" />
                                            </AsyncLink>
                                        </>
                                    ):(
                                        <>
                                            <I18nMessages id="plans-info-page.subscription-not-renewed" />
                                            <AsyncLink showSpinner={subscriptionOnOffProcessing} onClick={subscriptionOnOffLink} className={"plan-info-msg1 plan-info-clickable-link"} >
                                                <I18nMessages id="plans-info-page.subscription-enable-renewal" />
                                            </AsyncLink>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="plan-info-row">
                                <div className="plan-info-msg2" ><I18nMessages id="plans-info-page.subscription-price" /></div>
                                <div className="plan-info-msg2" >

                                    { intl.formatMessage({ id:'plans-info-page.subscription-price-amount' }, 
                                                { amount:formatAmount(licenses.subscription.amount / 100, licenses.subscription.currency), 
                                                period:formatInterval( licenses.subscription.interval, licenses.subscription.interval_count, intl) }) }

                                </div>
                            </div>

                            <div className="plan-info-row">
                                <div className="plan-info-msg1">
                                    {licenses.portal && (
                                        <AsyncLink onClick={handleOnPortal} showSpinner={portalProcessing} >
                                            <I18nMessages id="plans-info-page.subscriptions-portal" />
                                        </AsyncLink>
                                    )}
                                </div>
                            </div>
                            
                        </CardBody>
                    </Card>
                )}


                <Card body className="shadow plan-info-card mt-4">

                    <CardBody className="plan-info-card-body">

                        <div className="plan-info-title"><I18nMessages id="plans-info-page.recent-payments" /></div>

                            <Row className="mb-1">
                                <Col sm="4" className="plan-payment-row-header"><I18nMessages id="plans-info-page.date-of-payment" /></Col>
                                <Col sm="3" className="plan-payment-row-header"><I18nMessages id="plans-info-page.plan" /></Col>
                                <Col sm="3" className="plan-payment-row-header"><I18nMessages id="plans-info-page.transaction-status" /></Col>
                            </Row>

                            { (!licenses || licenses.purchases.length < 1) && (
                                <Row>
                                    <Col sm="12"><i><I18nMessages id="plans-info-page.no-recent-payments" /></i></Col>
                                </Row>
                            )}

                            {licenses && licenses.purchases.map((item, i) => (
                                <Row key={i}  >
                                    <Col sm="4" className="plan-payment-row"> { fd(item.created, intl) } </Col>
                                    <Col sm="3" className="plan-payment-row"> { getPlanName(item.accountType, intl) } </Col>
                                    <Col sm="3" className="plan-payment-row"> { getStatusName(item.status, intl)} </Col>
                                </Row>
                            ))}

                    </CardBody>

                </Card>

                <div className="plan-info-close-button">
                    <Button onClick={onToggle} color="light" >
                        <I18nMessages id="plans-info-page.btn-close" />
                    </Button>
                </div>

            </ModalBody>
        </Modal>
    )
}

export default withBenNotification( withBenHelper( withRouter( withBenService(withBenAccount(injectIntl( withI18nProvider(PlansInfoPage))))) ) )
