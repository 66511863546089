import React from 'react'
import Overlay from '../components/Overlay'
import { Button } from 'reactstrap'
import I18nMessages from '../components/I18nMessages'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { AccountType } from '../providers/providersDefs'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { isEduPath } from '../lib/utils'

type Props = InjectedBenAccountProps & RouteComponentProps

const LicenseOverlay: React.FC<Props> = ({
  benAccount,
  children,
  history
}) => {


  function handleOnPurchaseClick () {
    history.push('/account/purchase')
  }


  const showDemoExpired = () => {
    return benAccount.accountType === AccountType.demoExpired && benAccount.accountTypeEdu === AccountType.accountNone
  }

  const showEndOfLicInfo = () => {
    return !benAccount.accountType || benAccount.accountType === AccountType.demoExpired || benAccount.accountType === AccountType.licExpired
  }


  const LicenseI18n = () => {

    if ( showDemoExpired() )
      return (
        <div className="cart-purchase-expired-alert">
          <I18nMessages id="panel-page.purchase-expired-alert" />
          <Button className="ml-2" size="xs" color="primary" onClick={handleOnPurchaseClick}>
              <I18nMessages id="button-buy-label" />
          </Button>
        </div>
      )
    else
      return (
        <div>
          <I18nMessages id="panel-page.purchase-lic-expired-alert" />
          <Button className="ml-2" size="xs" color="primary" onClick={handleOnPurchaseClick}>
              <I18nMessages id="button-buy-label" />
          </Button>
        </div>
      )
  }


  if( !isEduPath() )
  {
    if ( showEndOfLicInfo() ) {
      return (
        <Overlay blurred contentComponent={LicenseI18n()}>
          {children}
        </Overlay>
      )
    }
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default withRouter(withBenAccount(LicenseOverlay))
