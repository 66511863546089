import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'


type Props = InjectedIntlProps & {
    isOpen: boolean
    onToggle: () => void
}

const DemoModePopup: React.FC<Props> = ({
    intl,
    isOpen,
    onToggle,
}) => {

    if( !isOpen )
    {
        return (
            <div/>
        )
    }

    return (

        <Modal isOpen={isOpen} toggle={onToggle} size="lg"  >
            <ModalHeader>
                <h4>Tryb demonstracyjny  <i className="simple-icon-book-open "/> </h4>
            </ModalHeader>

            <ModalBody>
                <i className="simple-icon-info pr-2 greencolor"/> Włączony jest tryb demonstracyjny, dostępne są przykładowe pytania i zdjęcia zdań z listy. Możesz w łatwy sposób sprawdzić, jak działa Asystent w praktyce. 
                W pełnej wersji możesz wpisać lub zrobić zdjęcie dowolnego zadania a my je rozwiążemy i wytłumaczymy krok po kroku.

                <Row className='mt-4'> 
                    <Col xs="3" sm="3" className='d-flex justify-content-center'></Col>
                    <Col xs="6" sm="6" className='d-flex justify-content-center'>
                        <Button color='primary' size='lg' onClick={ onToggle } > OK </Button>
                    </Col>
                    <Col xs="3" sm="3" className='d-flex justify-content-center'></Col>
                </Row>

            </ModalBody>
        </Modal>

    )
}

export default injectIntl(DemoModePopup)
