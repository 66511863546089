import React from 'react'
import { FormattedHTMLMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import AsyncButton from '../../../components/AsyncButton'
import { InjectedBenServiceProps, PlansItem, withBenService } from '../../../providers/benServiceProvider'
import { formatAmount, getCreditsInfoBuy, isAvosmart } from '../../../lib/utils'
import { InjectedBenNotificationProps, withBenNotification } from '../../../providers/notificationProvider'
import getMessage from '../../../language/getMessage'
import PurchasePageTile from '../../account/PurchasePageTile'
import { GroupPlansTypes } from '../../account/PurchasePage'


type Props = InjectedIntlProps & 
             InjectedBenNotificationProps &
             InjectedBenServiceProps & 
{
    isOpen: boolean
    plan: PlansItem
    onToggle: () => void
}

const EduBuyPopup: React.FC<Props> = ({
    intl,
    benNotification,
    benService,
    isOpen,
    plan,
    onToggle,
}) => {

    const [isLoading, setLoading] = React.useState(false)


    function buyLicense( licType: number, licYears: number, discount: number )
    {
        if( isAvosmart() )
        {
            // TBD
            // setCheckoutDialogVisible(true)
            // setCheckoutPlan(licType)
            // setCheckoutPlanYears(licYears)
        }
        else
        {
            setLoading(true)
  
            benService.buyLicense(plan.type, plan.intervalCnt, '', intl.locale)
                .then(result => {
                    const url = result.data.redirectUri;
                    setTimeout(() => {
                    window.location.href = url
                    }, 1000);
                })    
                .catch(() => {
                    benNotification.notify({
                    type: 'error',
                    title: getMessage('side-effect.internal-error-title', intl),
                    message: getMessage('side-effect.internal-error-message', intl)
                    })
                    setLoading(false)
                })
        }
    }    


    function getButtonBuyText() : JSX.Element
    {
        if( !plan )
            return <></>

        if( plan.discount )
        {
            const priceOld = formatAmount( plan.basePrice, plan.currency )
            const priceNew = formatAmount( plan.price, plan.currency )
            const strFormat = "Wybierz za <del> {priceOld} </del>  {priceNew} / rok"
            return  <>
                    <FormattedHTMLMessage
                        id='undefined'
                        defaultMessage={strFormat}
                        values={{ priceOld, priceNew }}
                    />
            </>
        }
        else
        {
            const price = formatAmount( plan.basePrice, plan.currency ) 
            const strFormat = "Wybierz za {price} / rok"
            return  <>
                    <FormattedHTMLMessage
                        id='undefined'
                        defaultMessage={strFormat}
                        values={{ price }}
                    />
            </>
        }
    }    



    return (

        <>
            <Modal isOpen={isOpen} toggle={onToggle} size="lg"  >
                <ModalHeader>
                    <h4>Edukacyjny asystent <i className="simple-icon-book-open "/> </h4>
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col className="mx-auto my-auto purchase-page d-flex justify-content-center">
                            
                            <PurchasePageTile 
                                        handleSelectPlan={buyLicense} 
                                        mbValue={'mb-8'} 
                                        mdValue={'8'} 
                                        plans={ [plan] } 
                                        plansTypes={GroupPlansTypes.EDU} />
                        </Col>
                    </Row>
                    <div className='credits-info mt-1 mb-3'>
                        * Liczba zadań jest szacunkowa. Jest ona uzależniona od stopnia skomplikowania zadania.
                    </div>


                    <Row>
                        <Col className="d-flex justify-content-center">
                            
                            <Button 
                                    color="light" 
                                    size="sm"
                                    className="mx-2 my-2" 
                                    onClick={(e) => { onToggle() }} >Anuluj</Button>

                        </Col>
                    </Row>
                    
                </ModalBody>
            </Modal>
        </>
    )
}

export default withBenNotification(injectIntl(withBenService(EduBuyPopup)))
