import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import EducationChartSubjectCnt from './EducationChartSubjectsCnt'
import EducationChartQuestionsCnt from './EducationChartQuestionsCnt'
import EducationChartSubjectsTime from './EducationChartSubjectsTime'
import { AiStats } from '../../../providers/aiServiceProvider'
import EducationChartTimeByDays from './EducationChartTimeByDays'

type Props = InjectedIntlProps & {
  aiStats : AiStats | null | undefined
}

const EducationCharts : React.FC<Props> = ({
  aiStats,
  intl
}) => {

    // console.log( "aiStats.questions.length", aiStats?.questions.length )
    // console.log( "aiStats.subjects.length",  aiStats?.subjects.length  )
    // console.log( "aiStats.time.length",      aiStats?.time.length      )

    return (

        <>
            { aiStats && aiStats.questions.length < 1 && aiStats.subjects.length < 1 && aiStats.time.length < 1 &&  (
                <Row>
                    <Col>                    
                        <Card>
                            <CardBody style={{height:"400px"}}>
                                <CardTitle tag="h2">
                                    Brak danych do wykresów
                                </CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}

            { aiStats && (aiStats.questions.length > 0 || aiStats.subjects.length > 0 || aiStats.time.length > 0 ) &&  (
                <>        
                    <Row>
                        <Col md="6" className="mb-4">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h3">
                                        Ilość rozwiązanych zadań wg przedmiotu
                                    </CardTitle>
                                    { aiStats && aiStats.subjects.length > 0 && (
                                    <EducationChartSubjectCnt aiStats={aiStats}></EducationChartSubjectCnt>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" className="mb-4">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h3">
                                        Ilość rozwiązanych zadań w ciągu ostatnich dni
                                    </CardTitle>
                                    { aiStats && aiStats.questions.length > 0 && (
                                        <EducationChartQuestionsCnt aiStats={aiStats}></EducationChartQuestionsCnt>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" className="mb-4">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h3">
                                        Czas nauki w rozbiciu na przedmioty
                                    </CardTitle>
                                    { aiStats && aiStats.time.length > 0 && (
                                        <EducationChartSubjectsTime aiStats={aiStats} ></EducationChartSubjectsTime>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" className="mb-4">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h3">
                                        Czas nauki w ciągu ostatnich dni
                                    </CardTitle>
                                    { aiStats && aiStats.time.length > 0 && (
                                        <EducationChartTimeByDays aiStats={aiStats} />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}


export default injectIntl(EducationCharts)
