import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { InjectedBenHelperProps, withBenHelper } from '../providers/benHelperProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { InjectedBenServiceProps, withBenService } from '../providers/benServiceProvider'
import { ActionCode } from '../providers/providersDefs'
import { withBenAccount, InjectedBenAccountProps } from '../providers/benAccountProvider'
import { Button, Card, CardBody } from 'reactstrap'
import queryString from 'querystring'

import appLogo from '../assets/media/logo.svg'
import rewardImg from '../assets/media/reward.svg'

import I18nMessages from '../components/I18nMessages'

type Props = InjectedBenHelperProps &  RouteComponentProps & InjectedBenServiceProps & InjectedBenNotificationProps & InjectedIntlProps & InjectedBenAccountProps


const RewardPage: React.FC<Props> = ({
    benHelper,
    benService,
    benAccount,
    benNotification,
    history,
    intl,
    location

}) => {

    const [isLoading, setLoading] = React.useState(false)
    const [rewardDays, setRewardDays] = React.useState(0)

    function checkParams() : string
    {
        var search = location.search
        if( search.startsWith('?'))
            search = search.substr(1)

        const params = queryString.parse(search)

        if( params['r'] ) 
        {
            var r = String( params['r'] )

            if( r.length > 100 )
                r = r.substr(0,100)

            return r
        }

        return ''
    }

    function onClickContinue() 
    {
        benHelper.setRewardId('')
        benHelper.setRewardResult(0)
        history.push('/')
    }


    function onClickCheck() 
    {
        setLoading(true)

        benService.reward(benHelper.rewardId)
            .then((r) => {
                benHelper.setRewardId('')
                benHelper.setRewardResult( r.code )
                setRewardDays(r.data.days)
            })
            .catch((r) => {
                benHelper.setRewardId('')
                benHelper.setRewardResult( r.code )
            })
            .finally( () => {
                //
            })
    }

    function describeError(err : number)
    {
        switch(err)
        {
            case ActionCode.ok: return <I18nMessages id="reward-page.error-ok" />
            case ActionCode.CODE_SUCCESS: return <I18nMessages id="reward-page.error-success" />
            case ActionCode.CODE_REWARD_TAKEN: return <I18nMessages id="reward-page.error-already-used" /> 
            case ActionCode.CODE_WRONG_REWARD: return <I18nMessages id="reward-page.error-invalid-code" /> 
            default:
                return <I18nMessages id="reward-page.error-connection" /> 
        }
    }


    React.useEffect(() => {
        
        const r = checkParams()

        if( !benService.isAuthorized ) {

            const r = checkParams()

            benHelper.setRewardId(r)

            history.push('/login')
        }
        else {

            if( benHelper.rewardId.length < 1 && benHelper.rewardResult === 0) 
            {
                if( ! (r.length >= 1 ) )
                {
                    history.push('/')
                }
                else 
                {
                    benHelper.setRewardId(r)
                }
            }            
        }
    
        return () => {
          document.body.classList.remove('background')
        }

    }, [benAccount])  // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        document.body.classList.add('h-100')
        return () => document.body.classList.remove('h-100')
    }, [])


    if( !benService.isAuthorized ) 
    {
        return (
            <div>
                Login...
            </div>
        )
    }

    return (

        <div className="add-device-page-container">
        <Card>
            <CardBody className="text-center">
            <div className="benjamin-logo-container">
                <img src={appLogo} className="login-logo" alt="logo" />
            </div>
            <h1 className="mb-1 p-0">
                
            </h1>
            <h4 className="set-primary-color" >
                <I18nMessages id="reward-page.additional-bonus" />
            </h4>
            <p>
                <img src={rewardImg} alt="reward"/>
            </p>
            <div className="">

                { benHelper.rewardId.length > 0 && benHelper.rewardResult === 0 && (
                    <>
                        <h2> <I18nMessages id="reward-page.get-additional-bonus" /> {benHelper.rewardId} </h2>
                        <p></p>

                        <div className="img">
                        </div>
                        
                        <Button disabled={isLoading} color="primary" onClick={onClickCheck}>
                            <I18nMessages id="reward-page.get-now" /> 
                        </Button>
                    </>
                )}


                { benHelper.rewardResult !== 0 && (

                    <>
                        { benHelper.rewardResult === ActionCode.CODE_SUCCESS && (
                            <h2>
                                { intl.formatMessage({ id:'reward-page.lic-was-extended-by-days' }, { rewardDays }) }
                            </h2>
                        )}

                        { benHelper.rewardResult !== ActionCode.CODE_SUCCESS && (
                            <div>
                                <h3>
                                    <I18nMessages id="reward-page.there-was-an-error" /> {describeError(benHelper.rewardResult)}
                                </h3>
                                <h4>
                                    <I18nMessages id="reward-page.lic-not-extended" /> 
                                </h4>
                            </div>
                        )}                    

                        <Button color="primary" onClick={onClickContinue}> <I18nMessages id="reward-page.button-close" />  </Button>
                    </>
                )}            

            </div>
            
            </CardBody>
        </Card>
        </div>

    )
    
}

export default withBenAccount( withBenHelper( withBenNotification(injectIntl(withRouter(withBenService(RewardPage))))))
