import React from 'react'
import { ChartData, ChartOptions } from 'chart.js'
import Chart from 'react-chartjs-2'
// import I18nMessages from '../../../components/I18nMessages'
import { PieChartDefaults } from '../../../lib'
import Spinner from '../../../components/Spinner'
import { AiStatQuestions, AiStats } from '../../../providers/aiServiceProvider'
import { toLocaleDate2 } from '../../../lib/utils'
import { InjectedIntlProps, injectIntl } from 'react-intl'

type Props = InjectedIntlProps & {
  aiStats : AiStats | null | undefined
}

const EducationChartQuestionsCnt : React.FC<Props> = ({
  aiStats,
  intl
}) => {

    function getChartDataQuestions(s : AiStats ) : ChartData 
    {
        var noGapArray : AiStatQuestions[] = []
        var lastTs = 0
        var now = Math.round( new Date().getTime() / 1000 )

        for( var i = 0; i<s.questions.length; i++)
        {
            if( lastTs === 0 )
            {
                noGapArray.push( s.questions[i] )
                lastTs = s.questions[i].dte
            }
            else
            {
                while( lastTs + 24*3600 < s.questions[i].dte )
                {
                    lastTs += 24*3600
                    noGapArray.push( {dte:lastTs, cnt:0} )
                }

                noGapArray.push( s.questions[i] )
                lastTs = s.questions[i].dte
            }
        }

        if( noGapArray.length < 1 )
        {
            lastTs = now
            noGapArray.push( {dte:lastTs, cnt:0} )
        }

        while( lastTs + 24*3600 < now )
        {
            lastTs += 24*3600
            noGapArray.push( {dte:lastTs, cnt:0} )
        }

        while( noGapArray.length < 14 )
        {
            let dte = noGapArray[0].dte - 24*3600
            noGapArray.unshift( {dte:dte, cnt:0} )
        }

        const data: ChartData = {
            labels:  noGapArray.map(item => toLocaleDate2( new Date(item.dte * 1000), intl, 6 )   ),
            datasets: [{
              borderWidth: 1,
              label: 'Ilość zadań',
              backgroundColor: "#F7D170",
              data: noGapArray.map(item => item.cnt)
            }]
        }
      
        return data
    }    


    const optionsQuestions: ChartOptions = {
        showLines: false,
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: false,
            text: 'Ilość zadań'
        },
        legend: {
            display: false,
            labels: {
                boxWidth: 50,
                fontSize: 10,
                fontColor: '#bbb',
                padding: 5,
            }
        },
        scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
                autoSkipPadding: 10
              }
            }],
            yAxes: [{
              ticks: {
                  beginAtZero:true,
                  min: 0
              }
            }]
         }

      }
 
  return (

    <React.Fragment>

        { aiStats === undefined && (
            <div className="chart-container d-flex align-items-center justify-content-center text-center">
                <Spinner/>
            </div>
        )}

        { ( aiStats && aiStats.questions.length === 0 ) && (
            <div className="chart-container d-flex align-items-center justify-content-center text-center">
                Brak danych. Dane jeszcze nie zostały zebrane.
            </div>
        )}

        { ( aiStats && aiStats.questions.length !== 0 ) && (

            <div className="chart-container">
                <Chart
                    type="bar"
                    legend={PieChartDefaults.legend}
                    options={optionsQuestions}
                    data={getChartDataQuestions(aiStats)}
                />
            </div>
        )}

    </React.Fragment>
  )
}

export default injectIntl(EducationChartQuestionsCnt)
