import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Button, Col, Modal, Row } from 'reactstrap'
import { Cropper, CropperRef, ImageRestriction } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'

// Cropper
// npm install --save react-advanced-cropper

type Props = InjectedIntlProps & {
    isOpen: boolean
    src: string
    canvasRef?: React.RefObject<HTMLCanvasElement>|undefined
    canvasRefThumb?: React.RefObject<HTMLCanvasElement>|undefined
    onToggle: () => void
    onAreaSelected: (imgData: string, imgThumbData: string) => void
}


const ImageCropPopup: React.FC<Props> = ({
    intl,
    isOpen,
    src,
    canvasRef,
    canvasRefThumb,
    onToggle,
    onAreaSelected
}) => {

    //const [isLoading, setIsLoading] = useState(false)
    const cropperRef = React.useRef<CropperRef>(null)
    const divCropperRef = React.useRef<HTMLDivElement>(null)

    // const [innerW, setInnerW] = useState(50)
    // const [innerH, setInnerH] = useState(50)

    /*
    React.useEffect( () => {
        console.log("React.useEffect inner")
    }, [innerH, innerW] )
   
    React.useEffect(  ()=> {
        const onResizeHandler = (event: UIEvent) => {
            let w = window.innerWidth
            let h = window.innerHeight
            // setInnerH(h)
            // setInnerW(w)
            console.log("event, inner: ", w,h)
        }
        window.addEventListener("resize", onResizeHandler );
        return () => {
            window.removeEventListener("resize", onResizeHandler );
        }
    }, [] )
    */

    // function getStencilProps2() : ArbitraryProps
    // {   
    //     let stencilProps = {
    //         handlers: false,
    //         lines: false,
    //         movable: false,
    //         resizable: false,
    //     }
    //     return stencilProps
    // }

    // const onCropperChange = (cropper: CropperRef) => 
    // {
	// }      


    function getImageData(canvasRef: React.RefObject<HTMLCanvasElement>|undefined, maxWidth:number=1200, maxHeight:number=1200) : string
    {
        if( !cropperRef.current )
            return ""

        let cropperCanvas = cropperRef.current.getCanvas()
        let cropperCoord  = cropperRef.current.getCoordinates()

        if( cropperCanvas && cropperCoord )
        {
            let w = cropperCoord.width
            let h = cropperCoord.height
            
            if (w > h) {
                if (w > maxWidth) {
                    h *= maxWidth / w
                    w = maxWidth
                }
            } else {
                if (h > maxHeight) {
                    w *= maxHeight / h
                    h = maxHeight
                }
            }

            w = Math.round(w)
            h = Math.round(h)

            if( w < 10 ) w = 10
            if( h < 10 ) h = 10

            var useVirtualCanvas = true
            let ctx : CanvasRenderingContext2D | null = null
            let canvas : HTMLCanvasElement | null = null

            if( !useVirtualCanvas && canvasRef !== undefined && canvasRef.current )
            {
                canvas = canvasRef.current
            }
            else
            {
                let canvasVirt = document.createElement('canvas') // new HTMLCanvasElement() // 
                canvas = canvasVirt
            }

            canvas.width  = w
            canvas.height = h

            ctx = canvas.getContext('2d');
            
            if( ctx )
            {
                ctx.drawImage(cropperCanvas, 0, 0, w, h);
                const imageData = canvas.toDataURL('image/jpeg', 0.7);
                return imageData
            }
        }

        return ""
    }    

    function onCancel()
    {
        onToggle()
    }

    function onSubmit()
    {
        let imgData      = getImageData( canvasRef )
        let imgThumbData = getImageData( canvasRefThumb, 200, 50 )
        onAreaSelected( imgData, imgThumbData )
        onToggle()
    }


    React.useEffect( () =>
    {
        const cropperElement = divCropperRef.current;

        const handleWheel = (event: WheelEvent) => {
            event.stopPropagation()
        }

        cropperElement?.addEventListener('wheel', handleWheel, { capture: true })

        return () => {
            cropperElement?.removeEventListener('wheel', handleWheel, { capture: true })
        }
    },[] )


    return (

        <>

        <Modal isOpen={isOpen} toggle={onToggle} size="lg" className='modal-fullscreen' />

        {isOpen && (
            <div className='modal-fullscreen'>
                <div className='modal-fullscreen-body'>

                    <div className='modal-fullscreen-header'>
                        <h4 >Zaznacz obszar z zadaniem, im dokladniej wybierzesz obszar, tym lepsza będzie odpowiedź</h4>
                    </div>                
                    <div ref={divCropperRef} className='modal-fullscreen-main'>
                        <Cropper src={src}
                                ref={cropperRef}
                                className={'cropper edu-cropper'}
                                imageRestriction={ImageRestriction.fitArea}
                                //disabled={isLoading}
                                backgroundClassName='cropper-bg'
                                //maxHeight={300}       // wielkosc zaznaczenia
                                //maxWidth={300}        // wielkosc zaznaczenia
                                // defaultSize={ {width: 300, height: 400} }    // domyalna wielkosc zaznaczenia
                                // defaultPosition={ {left:0, top: 0} }         // domyalna wielkosc zaznaczenia
                                //defaultVisibleArea={ { top:0, left:0, width:800, height: 800} } // wielkosc czarnego tła na ktorym jest obrazek, obrazek nie jest dopasowywany do tla
                                //onChange={onCropperChange}  
                                //transitions={!imgFromHistory}                                            
                                //stencilProps={imgFromHistory ? getStencilProps2() : undefined} 
                                //defaultPosition={cropperRef.current ? cropperRef.current.getDefaultState() : null }
                                //defaultPosition={getDefaultPosition2()}
                                />  
                    </div>
                    <div className='modal-fullscreen-footer'>
                        <Row> 
                            <Col xs="6" sm="6" className='d-flex justify-content-center'>
                                <Button color='light' size='xl' onClick={ () => onCancel() } > Anuluj </Button>
                            </Col>
                            <Col xs="6" sm="6" className='d-flex justify-content-center'>
                                <Button color='primary' size='xl' onClick={ () => onSubmit() } > Gotowe </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )}

      </>

    )
}

export default injectIntl(ImageCropPopup)
