import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/pl'

const messages: LanguageMessagesKeys = {
  // Common
  'side-effect.successful-title': 'Wszystko OK!',
  'side-effect.successful-message': 'Dane zostały poprawnie zapisane',
  'side-effect.internal-error-title': 'Ups... coś poszło nie tak',
  'side-effect.internal-error-message': 'Sprawdź połączenie z internetem i spróbuj ponownie',

  'monday-sshort-label': 'Pn',
  'tuesday-sshort-label': 'Wt',
  'wednesday-sshort-label': 'Śr',
  'thursday-sshort-label': 'Cz',
  'friday-sshort-label': 'Pt',
  'saturday-sshort-label': 'So',
  'sunday-sshort-label': 'N',

  'monday-short-label': 'Pn',
  'tuesday-short-label': 'Wt',
  'wednesday-short-label': 'Śr',
  'thursday-short-label': 'Cz',
  'friday-short-label': 'Pt',
  'saturday-short-label': 'So',
  'sunday-short-label': 'N',

  'monday-label': 'Poniedziałek',
  'tuesday-label': 'Wtorek',
  'wednesday-label': 'Środa',
  'thursday-label': 'Czwartek',
  'friday-label': 'Piątek',
  'saturday-label': 'Sobota',
  'sunday-label': 'Niedziela',

  'january-label': 'Styczeń',
  'february-label': 'Luty',
  'march-label': 'Marzec',
  'april-label': 'Kwiecień',
  'may-label': 'Maj',
  'june-label': 'Czerwiec',
  'july-label': 'Lipiec',
  'august-label': 'Sierpień',
  'september-label': 'Wrzesień',
  'october-label': 'Październik',
  'november-label': 'Listopad',
  'december-label': 'Grudzień',

  'interval-name-year'  : 'rok',
  'interval-name-month' : 'miesiąc',
  'interval-name-week'  : 'tydzień', 
  'interval-name-day'   : 'dzień',
  'interval-name-years' : 'lata',
  'interval-name-months': 'miesiące',
  'interval-name-weeks' : 'tygodnie',
  'interval-name-days'  : 'dni',

  'select-input.no-options-label': 'Brak opcji',
  'filter-input.label': 'Filtruj:',

  'no-data.label': 'Brak danych do wyświetlenia. Prosze wybrać inną datę lub zmienić wyszukiwaną frazę.',
  'no-data.label-mid': 'Brak danych do wyświetlenia. Prosze wybrać inną datę.',
  'no-data.label-short': 'Brak danych do wyświetlenia.',
  'empty-list-label': 'Lista jest pusta.',
  'overlay.empty-device-label': 'Do tego profilu nie dodano żadnego urządzenia. Dodaj nowe urządzenie.',
  'overlay.empty-profiles-label': 'Nie dodano żadnego profilu. Dodaj nowy profil.',
  'overlay.empty-device.add-device-label': 'Dodaj urządzenie',

  'confirmation-modal.cancel-button': 'Anuluj',
  'confirmation-modal.confirm-button': 'POTWIERDŹ',
  'confirmation-modal.button-yes': 'Tak',
  'confirmation-modal.button-no': 'Nie',

  // Date Picker
  'date-picker.select-date-label': 'Wybierz datę',

  // Date Filter
  'date-filter.select-label': 'Wybierz zakres',
  'date-filter.this-month': 'Ostatni miesiąc',
  'date-filter.this.week': 'Ostatni tydzień',
  'date-filter.this.day': 'Ostatnie 24 godziny',
  'date-filter.this.custom': 'Określony dzień',
  'date-filter.from-label': 'Od: ',
  'date-filter.to-label': 'Do: ',

  // Time Picker
  'time-picker.unlimied-label': 'Bez limitu',
  'time-picker.time-format': '{h}g {m}min',
  'time-picker.time-format-min': '{m}min',
  'time-picker.time-format-hour': '{h} godz',

  'time-picker.add-bonus-title': 'Dodaj bonus czasowy tylko na dzisiaj',
  'time-picker.no-bonus' : 'Bez bonusu',
  'time-picker.bonus': 'Bonus',
  'time-picker.add-bonus-button-set': 'Ustaw bonus',
  'time-picker.add-bonus-button-cancel' : 'Anuluj',


  // Profile image picker
  'profile-image-picker.date-range-label': 'Zakres dat',
  'profile-image-picker.girl-label': 'Dziewczyna',
  'profile-image-picker.boy-label': 'Chłopiec',

  // Profile List
  'profile-list.remove-device-label': 'Usuń urządzenie',
  'profile-list.move-device-label': 'Przenieś urządzenie do innego profilu',
  'profile-list.add-device-label': 'Dodaj urządzenie',

  'profile-list.add-profile-label': 'Dodaj profil',
  'profile-list.edit-profile-label': 'Edytuj profil',
  'profile-list.add-devices-label': 'Dodaj nowe urządzenie',
  'profile-list.remove-profile-label': 'Usuń profil',
  'profile-list.remove-profile-disabled-tooltip': 'Aby usunąć profil, musisz najpierw usunąć wszystkie urządzenia znajdujące się w profilu.',
  'profile-list.title': 'Lista profili',

  // Profile Form
  'profile-form.full-name-label': 'Imię',
  'profile-form.month-of-birth-label': 'Miesiąc urodzenia',
  'profile-form.year-of-birth-label': 'Rok urodzenia',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Anuluj',
  'profile-form-modal.create.submit-button': 'DODAJ PROFIL',
  'profile-form-modal.create.title': 'Dodaj profil dziecka',
  'profile-form-modal.edit.skip-button': 'Anuluj',
  'profile-form-modal.edit.submit-button': 'ZAPISZ',
  'profile-form-modal.edit.title': 'Edytuj profil dziecka',
  'profile-form-modal.max-profiles-error': 'Osiągnięto limit ilosci profili.',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Przenieś urządzenie do innego profilu',
  'profile-move-device-modal.cancel-button': 'Anuluj',
  'profile-move-device-modal.submit-button': 'Zapisz',
  'profile-move-device-modal.select-profile-label': 'Wybierz profil',

  // CallLog
  'call-log.duration-label': 'Czas połączenia:',
  'call-log.seconds-label': 's.',

  // Create Profile Page
  'create-profile-page.submit-button': 'DODAJ PROFIL',
  'create-profile-page.skip-button': 'Anuluj',
  'create-profile-page.title': 'Dodaj profil dziecka',
  'create-profile-page.description': 'Wpisz dane dziecka, którego urządzenia będą kontrolowane.',

  // Add device page
  'add-device-page.close-button': 'ZAMKNIJ',
  'add-device-page.description': 'Wybierz rodzaj urządzenia, na którym zainstalujesz aplikację',
  'add-device-page.title': 'Instrukcja dodawania urządzenia',
  'add-device-page.check': 'Sprawdź',
  'add-device-page.mobile-title': 'Telefon lub tablet z systemem Android',
  'add-device-page.mobile-description': 'Android w wersji 6 lub wyższej',
  'add-device-page.desktop-title': 'Komputer lub laptop z systemem Windows',
  'add-device-page.desktop-description': 'Wersja Windows 7 i wyższa',


  // Purchase Page
  'purchase-page.skip-label': 'Przejdź do panelu',
  'purchase-page.title': 'Wybierz najlepszy plan',
  'purchase-page.title.features': 'Wszystkie plany zawierają poniższe możliwości',
  'purchase-page.title.reviews': 'Za co użytkownicy kochają naszą aplikację',
  'purchase-page.subtitle': 'Zakupy bez ryzyka. Jeśli program nie spełni Twoich oczekiwań, zwrócimy wpłatę',
  'purchase-page.plan-highlight': 'Optymalny',
  'purchase-page.monthly': 'miesięcznie',
  'purchase-page.select': 'Wybierz',
  'purchase-page.close': 'Zamknij',
  'purchase-page.money-back.title': 'Bezpieczne zakupy',
  'purchase-page.money-back.desc': 'Jesli nie będziesz zadowolony zwrócimy wpłatę',
  'purchase-page.header.check-1': 'Najlepsza aplikacja kontroli rodzicielskiej',
  'purchase-page.header.check-2': 'Kontrola czasu dla gier, YouTube, stron',
  'purchase-page.header.check-3': 'Setki tysięcy zadowolonych klientów',
  'purchase-page.plan.year': 'rok',
  'purchase-page.plan.optimal': 'Optymalny',
  //

  'purchase-page.modal.2y-header': 'Specjalna oferta promocyjna!',
  'purchase-page.modal.2y-sub-header': 'Wybierz <b>plan na 2 lata</b> i zyskaj <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Wybierz <b>plan na 2 lata</b> i zyskaj <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Chroń do {devices} urządzeń',
  'purchase-page.modal.upgrade': 'Wybieram ze zniżką',
  'purchase-page.modal.no-thanks': 'Kontynuuj na 1 rok',
  //

  'purchase-plan.features.title.feature_1': 'Kontrola czasu dla gier i aplikacji',
  'purchase-plan.features.title.feature_2': 'Filtrowanie stron internetowych',
  'purchase-plan.features.title.feature_3': 'Blokowanie gier, aplikacji, stron',
  'purchase-plan.features.title.feature_4': 'Kontrola YouTube ',
  'purchase-plan.features.title.feature_5': 'Podgląd ekranów na żywo (tylko Windows)',
  'purchase-plan.features.title.feature_6': 'Zdalny dostęp',
  'purchase-plan.features.title.feature_7': 'Rozbudowane raporty',
  'purchase-plan.features.title.feature_8': 'Lokalizacja (tylko telefony)',
  'purchase-plan.features.title.feature_9': 'Sprawdź SMSy oraz kontakty telefoniczne',
  'purchase-plan.features.title.feature_10': 'Priorytetowa pomoc techniczna',

  'purchase-plan.features.desc.feature_1': 'Ustaw  limit czasu dla gier, aplikacji oraz internetu. Dodatkowo na harmonogramie możesz wybrać w jakich godzinach media będą dostępne.',
  'purchase-plan.features.desc.feature_2': 'Blokujemy wszystkie zabronione strony. Program posiada specjalny algorytm analizy stron, ogromną bazę stron i słów zabronionych, które są często aktualizowane.',
  'purchase-plan.features.desc.feature_3': 'Zablokuj wybrane przez Ciebie gry, aplikacje, które mają negatywny wpływ na Twoje dziecko. Dodatkowo każda nowa gra będzie wymagała Twojej akceptacji.',
  'purchase-plan.features.desc.feature_4': 'W panelu rodzica masz dostęp do wszystkich oglądanych przez dziecko filmów na Youtube. Możesz zablokować film lub całkowicie dostęp do serwisów video.',
  'purchase-plan.features.desc.feature_5': 'Możesz zdalnie podglądać ekran dziecka na żywo oraz sprawdzać zapisane aktywności w wybranym dniu. To pozwala na szczegółowy monitoring komputera.',
  'purchase-plan.features.desc.feature_6': 'Z dowolnego miejsca i urządzenia po zalogowaniu masz dostęp do panelu rodzica, gdzie możesz zmieniać ustawienia, sprawdzać, wprowadzać limity.',
  'purchase-plan.features.desc.feature_7': 'W panelu rodzica możesz łatwo sprawdzić każdą aktywność na komputerze lub telefonie chronionym, spędzony czas, odwiedzane strony i wiele więcej.',
  'purchase-plan.features.desc.feature_8': 'Na mapie możesz sprawdzić aktualną oraz wcześniejszą lokalizację dziecka. Możesz zobaczyć gdzie dziecko przebywało w danym dniu, godzinie.',
  'purchase-plan.features.desc.feature_9': 'Możesz w łatwy sposób zobaczyć w panelu rodzica jakie otrzymało smsy Twoje dziecko i wysyłało, z kim rozmawiało oraz jakie kontakty ma zapisane w telefonie.',
  'purchase-plan.features.desc.feature_10': 'W każdej chwili jesteśmy do Twojej dyspozycji aby pomóc, doradzić. Dostępne są także dla Ciebie poradniki video pomagające w korzystaniu z platformy {app}.',

   //

   'purchase-plan.raitings.desc.name_1' : 'Zosia',
   'purchase-plan.raitings.desc.name_2' : 'Oliwia',
   'purchase-plan.raitings.desc.name_3' : 'Mateusz',
   'purchase-plan.raitings.desc.name_4' : 'Łukasz',
   'purchase-plan.raitings.desc.name_5' : 'Alicja',
   'purchase-plan.raitings.desc.name_6' : 'Oliwier',
 
   'purchase-plan.raitings.desc.raiting_1': 'Bardzo skuteczny w swoim działaniu, chroni moje dziecko przed tym co szkodliwe w internecie.',
   'purchase-plan.raitings.desc.raiting_2': 'Tego właśnie szukałam! Ustawiam swoim córkom dostęp do internetu na max 1 godz. dziennie i {app}  pilnuje tego czasu za mnie.',
   'purchase-plan.raitings.desc.raiting_3': 'Polecam {app} ze względu na jego rozbudowane funkcje, nie tylko chroni dzieci w sieci, ale także, mam możliwość kontroli  czasu na gry komputerowe itp.',
   'purchase-plan.raitings.desc.raiting_4': 'Zależało nam z żoną nad kontrolą social mediów w życiu córki. Berniamin pozwala nam zachować balans między życiem realnym i tym wirtualnym córki.',
   'purchase-plan.raitings.desc.raiting_5': 'Mój syn za dużo czasu spędzał w internecie: laptop, tablet. Dzięki raportom wiem ile minut syn spędza w internecie, jakie strony odwiedza.',
   'purchase-plan.raitings.desc.raiting_6': 'Teraz mogę sprawdzać gdzie aktualnie jest mój syn, świetne rozwiązania.',

  //
  'account-settings-page.title': 'Ustawienia konta',
  'account-settings-page.automatic-update': 'Automatyczna aktualizacja aplikacji {app} w systemie Windows',
  'account-settings-page.timezone': 'Strefa czasowa : ',
  'account-settings-page.send-reports': 'Wysyłaj codzienne raporty aktywności',

  //
  'plans-info-page.title': 'Informacja o subskrybcji',
  'plans-info-page.plan': 'Plan: ',
  'plans-info-page.max-devices': 'Maksymalna Ilość urządzeń: ',
  'plans-info-page.valid-till': 'Data ważności: ',

  'plans-info-page.your-plan': 'Twój plan:',
  'plans-info-page.subscription-information': 'Informacja o subskrybcji',
  'plans-info-page.subscription-created': 'Subskrybcja utworzona: ',
  'plans-info-page.subscription-current-period': 'Aktualny okres rozliczeniowy: ',
  'plans-info-page.subscription-renews-on': 'Twój plan odnowi się ',
  'plans-info-page.subscription-cancel-renewal': 'Anuluj odnawianie',
  'plans-info-page.subscription-not-renewed': 'Twój plan NIE będzie automatycznie odnowiony na końcu aktualnego okresu rozliczeniowego',
  'plans-info-page.subscription-enable-renewal': 'Włącz odnawianie',
  'plans-info-page.subscription-price': 'Koszt subskrypcji: ',
  'plans-info-page.subscription-price-amount': '{amount} co {period}',  

  'plans-info-page.upgrade-plan': 'Rozszerz plan',
  'plans-info-page.extend-lic': 'Przedłuż plan',
  'plans-info-page.subscriptions-portal': 'Zarządzaj metodami płatności',
  'plans-info-page.recent-payments': 'Ostatnie transakcje',
  'plans-info-page.date-of-payment': 'Data transakcji: ',
  'plans-info-page.transaction-status': 'Status transakcji:',
  'plans-info-page.no-recent-payments': 'Brak ostatnich transakcji',
  'plans-info-page.btn-close': 'Zamknij',
  'plans-info-page.plan-devices': 'do {devices} urządzeń',
  'plans-info-page.plan-devices-types': 'komputery / tablety / telefony',

  //
  'upgrade-page.title' : 'Podwyższenie planu',
  'upgrade-page.devices' : 'Ilość urządzeń: ',
  'upgrade-page.valid-till' : 'Plan bedzie ważny do: ',
  'upgrade-page.confirm-upgrade': 'Twój plan będzie rozszerzony. Pobierzemy z karty {amount}',
  'upgrade-page.pay-now': 'Zapłać teraz {amount}',
  'upgrade-page.pay-then': 'Potem {amount} co {period}',

  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Zakup subskrypcji',
  'purchase-checkout.up-to-devices': 'Maksymalnie {devices} urządzeń',
  'purchase-checkout.subscribtion-price': '{amount} co {period}',
  'purchase-checkout.subscribe': 'Subskrybuj',
  'purchase-checkout.cancel': 'Anuluj',


  // Purchase Status Page
  'purchase-status-page.title': 'Sprawdzanie płatności',
  'purchase-status-page.status-pending': 'Płatność jest przetwarzana',
  'purchase-status-page.status-completed': 'Płatność została dokonana',
  'purchase-status-page.status-canceled': 'Płatność została anulowana',
  'purchase-status-page.status-refund-finalized': 'Zwrot został dokonany',
  'purchase-status-page.status-wating-for-conf': 'Płatność czeka na potwierdzenie',
  'purchase-status-page.status-created': 'Płatność została utworzona, czeka na potwierdzenie',
  'purchase-status-page.status-unkown': 'Status płatności jest nieznany.',
  'purchase-status-page.status-error': 'Błąd sprawdzania płatności',
  'purchase-status-pag.back-button': 'Powrót do strony głównej',
  'purchase-status-pag.invoice-link': 'Chcę otrzymać fakturę VAT',

  // Login Page
  'login-page.brandline': 'Zaloguj się',
  'login-page.brandline2': 'Filrowanie stron',
  'login-page.email-label': 'Adres e-mail',
  'login-page.login-button': 'ZALOGUJ SIĘ',
  'login-page.password-label': 'Hasło',
  'login-page.recovery-link': 'Zapomniałeś hasła?',
  'login-page.invalid-login-error': 'Wprowadzony adres e-mail lub hasło są nieprawidłowe.',

  // Register Page
  'register-page.create-account-label': 'Nie masz konta? Stwórz nowe konto',
  'register-page.description': 'Załóż darmowe konto',
  'register-page.email-label': 'Adres e-mail rodzica',
  'register-page.login-link-label': 'Masz konto? Zaloguj się',
  'register-page.marketing-checkbox-label': 'Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych',
  'register-page.password-label': 'Nowe hasło do konta {app}',
  'register-page.phoneNumber-label': 'Numer telefonu rodzica do powiadomień',
  'register-page.terms-privacy-checkbox-label': 'Zgadzam się z polityką prywatności oraz warunkami użytkowania',
  'register-page.register-button': 'UTWÓRZ KONTO',
  'register-page.register-success-title': 'Sukces!',
  'register-page.register-success-message': 'Rejestracja powiodła się. Sprawdź swoją skrzynkę pocztową aby potwierdzić email',
  'register-page.register-error-captcha': 'Błędna Captcha, spróbuj jeszcze raz.',
  'register-page.register-error-already-registered-label': 'Konto już istnieje, zaloguj się',
  'register-page.register-error-password-to-short-label': 'Hasło za krótkie.',
  'register-page.strong-pass': 'Twoje hasło jest zbyt proste i może być łatwe do odgadnięcia przez osoby nieupoważnione. '+
                               'Hasło powinno zawierać duże litery, małe litery, cyfry, oraz mieć długość co najmniej 8 znaków. '+
                               'Czy mimo to chcesz użyć prostego hasła?',

  // Forgot Password Page
  'forgot-password-page.headline-title': 'Zresetuj hasło',
  'forgot-password-page.email-label': 'Adres e-mail',
  'forgot-password-page.reset-button': 'ZRESETUJ HASŁO',
  'forgot-password-page.login-link-label': 'Powrót do logowania',
  'forgot-password-page.success-title': 'Hasło zresetowane',
  'forgot-password-page.success-message': 'Sprawdź skrzynkę pocztową, znajdziesz tam instrukcje resetowania hasła',
  'forgot-password-page.email-not-confirmed': 'Twój adres email nie został potwierdzony. Reset hasła nie jest możliwy.',

  //Update Password Page
  'update-password-page.headline-title': 'Ustawianie nowego hasła',
  'update-password-page.warning-title': 'UWAGA:',
  'update-password-page.warning-description': 'Ustawienie nowego hasła spowoduje utrate wcześniej zapisanych danych dotyczących lokalizacji. Pozostałe dane nie zostaną ustracone.',
  'update-password-page.warning-agreement': 'Rozumiem i akceptuję utratę danych lokalizacji.',
  'update-password-page.new-password': 'Nowe hasło:',
  'update-password-page.new-password-retype': 'Powtórz nowe hasło:',
  'update-password-page.submit': 'Ustaw nowe hasło',
  'update-password-page.alert-weak-password': 'Twoje hasło jest zbyt proste i może być łatwe do odgadnięcia przez osoby nieupoważnione. Hasło powinno zawierać duże litery, małe litery, cyfry, oraz mieć długość co najmniej 8 znaków. Czy mimo to chcesz użyć prostego hasła?',
  'update-password-page.alert-agreement': 'Akceptacja utraty danych jest wymagana.',
  'update-password-page.error-message': 'Błąd! Ustawianie nowego hasła nie powiodło się.',
  'update-password-page.success-message': 'Nowe hasło ustawione',
  'update-password-page.unknow-error-message': 'Coś poszło nie tak',
  'update-password-page.error-link': 'Błędny link',
  'update-password-page.error-posswords-not-equal': 'Hasła są różne',
  'update-password-page.error-posswords-length': 'Hasła są za krótkie',
  'update-password-page.error-wrong-code': 'Nieprawidłowy kod',
  'update-password-page.register-success-title': 'Sukces!',
  'update-password-page.register-success-message': 'Nowe hasło ustawione.',

  // Error Page
  'not-found-page.title': 'Ojej... wygląda że trafiłeś na błąd!',
  'not-found-page.error-code-text': 'Kod błędu',
  'not-found-page.go-back-home-button': 'WRÓĆ DO STRONY GŁÓWNEJ',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Twój plan kończy sie {date}. Przedłuż subskrypcję już dziś',
  'panel-page.purchase-demo-alert': 'Obecnie korzystasz z wersji demo.',
  'panel-page.purchase-expired-alert': 'Plan demo wygasł. Wykup subskrypcję',
  'panel-page.purchase-lic-expired-alert': 'Plan wygasł. Odnów subskrypcję',
  'panel-page.email-alert': 'Nie potwierdziłeś adresu e-mail. Sprawdź swoją skrzynkę pocztową',
  'panel-page.button-email-resend': 'Wyślij ponownie',
  'panel-page.email-alert-resend' : ' lub wyślij email ponownie.',
  'panel-page.email-alert-resend-title' : 'Potwierdzenie',
  'panel-page.email-alert-resend-msg' : 'Email został wysłany!',
  'panel-page.profile-delete-description': 'Czy jesteś pewien, że chcesz usunąć wybrany profil?',
  'panel-page.profile-delete-device-description': 'Czy jesteś pewien, że chcesz usunąć wybrane urządzenie? Dane zostaną utracone.',
  'panel-page.profile-delete-device-second-description': 'To spowoduje wyłączenie ochrony na urządzeniu. Czy na pewno chcesz kontynuowac?',

  'panel-page.email-confirmation-overlay-close': 'Zamknij',
  'panel-page.email-confirmation-overlay-postpone': 'Nie teraz',
  'panel-page.email-confirmation-overlay-resend': 'Wyślij ponownie',
  'panel-page.email-confirmation-overlay-header': 'Twój poniższy adres e-mail nie został potwierdzony',
  'panel-page.email-confirmation-overlay-desc': 'Jeśli w powyższym adresie e-mail popełniłeś błąd poinformuj nas natychmiast o tym. <br> Jeśli mail nie dotarł, wyślij ponownie i sprawdź inne katalogi w tym spam.',

  'panel-page.email-confirmation-code-success-header': 'Dziękujemy',
  'panel-page.email-confirmation-code-success-desc': 'Email został potwierdzony!',
  'panel-page.email-confirmation-code-header': 'Proszę wpisać 6 cyfrowy kod potwierdzający, który <br> został przesłany na Twój adres email.',
  'panel-page.email-confirmation-code-error-invalid-code': 'Podany kod jest niepoprawny',
  'panel-page.email-confirmation-code-error': 'Wystąpił nieoczekiwany błąd podczas weryfikacji kodu',

  // Summary Page
  'summary-page.title': 'Podsumowanie',
  'summary-page.top-apps.title': 'Najczęściej używane aplikacje',
  'summary-page.top-blocked.title': 'Najczęściej blokowane strony',
  'summary-page.top-domains.title': 'Najczęściej odwiedzane strony internetowe',
  'summary-page.top-search.title': 'Najczęściej wyszukiwane frazy',
  'summary-page.access-disable':"Czasowa deaktywacja",
  'internet-page.access-time-limit-description': 'Możesz wybrać dzienny limit dla urządzenia i określić w harmonogramie zakres godzin.',
  'internet-page.access-time-limit-set-label': 'Dzienny limit dostępu do internetu:',
  'disable-access.protection-enabled' : "Ochrona aktywna",
  'disable-access.protection-disabled-for': "Ochrona czasowo wyłączona. Pozostało: {time} min",
  "disable-access.device-offline":"Urządzenie niedostępne",
  "disable-access.protection-status":"Status ochrony",


  // Internet Page
  'internet-page.headline-title': 'Strony internetowe',
  'internet-page.access-time-page-title': 'Czas dostępu',
  'internet-page.settings-page-title': 'Ustawienia',
  'internet-page.statistics-page-title': 'Statystyki',
  'internet-page.searches-page-title': 'Wyszukiwania',

  'internet-page.statistics-page-www-ok': 'Dozwolona strona www',
  'internet-page.statistics-page-www-ok-white': 'Dozwolona strona www z białej listy',
  'internet-page.statistics-page-www-timelimit': 'Strona zablokowana z powodu ograniczenia czasu',
  'internet-page.statistics-page-www-userdef': 'Strona zablokowana zgodnie z definicjami użytkownika',
  'internet-page.statistics-page-www-content': 'Strona zablokowana, niedozwolone treści',


  'internet-page.white-black-list-page-title': 'Biała / Czarna lista',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filtrowanie',
  'internet-page.settings-page.white-list-only-label': 'Tylko strony z białej listy',
  'internet-page.settings-page.block-erotic-label': 'Blokuj dostęp do stron erotycznych',
  'internet-page.settings-page.block-offensive-label': 'Blokuj dostęp do stron ofensywnych, agresywnych',
  'internet-page.settings-page.block-file-sites-label': 'Blokuj dostęp do stron umożliwiających pobieranie plików z Internetu',
  'internet-page.settings-page.block-video-label': 'Blokuj dostęp do portali video',
  'internet-page.settings-page.block-social-label': 'Blokuj dostęp do portali społecznościowych',
  'internet-page.settings-page.block-doc-label': 'Blokuj pobieranie dokumentów z Internetu',
  'internet-page.settings-page.block-exe-label': 'Blokuj pobieranie programów z Internetu',
  'internet-page.settings-page.excludeYT-label': 'Nie wymuszaj bezpiecznego wyszukiwania na YouTube',
  'internet-page.settings-page.block-new-apps': 'Blokuj uruchomienie nowych aplikacji',
  'internet-page.settings-page.block-settings': 'Blokuj dostęp do ustawień',
  'internet-page.settings-page.custom-msg.card-tile': 'Komunikat o zablokowanej stronie',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Użyj własnego komunikatu o zablokowanej stronie',
  'internet-page.settings-page.save-settings-button-label': 'Zapisz ustawienia',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Dodaj do listy:',
  'internet-page.lists.add-button-label': 'Dodaj',
  'internet-page.lists.remove-button-label': 'Usuń',
  'internet-page.lists.white-list.card-title': 'Biała lista',
  'internet-page.lists.black-list.card-title': 'Czarna lista',
  'internet-page.lists.remove-prefix': 'Wpisany adres zaczyna sie od "{prefix}", rekomenujemy aby dodać tylko nazwę domeny. Czy chcesz usunąc "{prefix}" z adresu?',
  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Odwiedzane strony',

  'internet-page.searches.searches-pages-card-title': 'Wyszukiwane frazy',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Harmonogram',
  'internet-page.access-time.schedule-legend-label': 'Legenda',
  'internet-page.access-time.schedule-internet-available-label': 'Internet dostępny',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet zablokowany',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Rozmowy i SMS',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Rozmowy',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'SMSy',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Ostatnia zmiana:',
  'calls-text-messages-page.contacts-no-changes-label': 'Brak.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Lista kontaktów',

  // Localization Page
  'localization-page.headline-title': 'Lokalizacja',
  'localization-page.headline-title-off': 'Lokalizacja, śledzenie wyłączone',
  'localization-page.map-hours-filter-label': 'Wybrany zakres godzinowy:',
  'localization-page.gps-tracking-on':'Śledź położenie urządzenia',
  'localization-page.gps-view-speed': 'Pokaż prędkość',
  'localization-page.map-loading-label': 'Ładowanie mapy...',
  'localization-page.map-no-data': 'BRAK DANYCH',

  'localization-page.one-gps-was-off-since':"Lokalizacja w urządzeniu wyłączona od godzininy {lastOffStr}",
  'localization-page.one-gps-was-off-for':"Lokalizacja w urządzeniu była wyłączona przez {elapsedStr}",
  'localization-page.many-gps-was-off-since':"Lokalizacja w urządzeniu wyłączona od godzininy {lastOffStr}",
  'localization-page.many-gps-was-off-for':"Lokalizacja w urządzeniu była wyłączona przez {elapsedStr}",


  // Battery Page
  'battery-page.headline-title': 'Bateria',
  'battery-page.chart-description': 'Poziom naładowania baterii',

  // Access Time Page
  'access-time-page.headline-title': 'Czas dostępu',
  'access-time-page.nav-access-time-label-applications': 'Aplikacje',
  'access-time-page.nav-access-time-label-internet': 'Strony internetowe',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Lista filmów',
  'youtube-page.no-videos': 'Brak filmow w danym dniu',
  'youtube-page.block-videoid': 'Blokuj ten film',
  'youtube-page.block-channelid': 'Blokuj tego autora',
  'youtube-page.video-blocked': 'Zablokowany',

  // Screen Pages
  'screenview-page.headline-title': 'Podgląd ekranu',
  'screenview-page.reload-button': 'Odśwież',
  'screenview-page.chart-description': 'Aktualny widok ekranu',
  'screengrab-page.headline-title': 'Zapisane ekrany',
  'screengrab-page.chart-description': 'Zapisane ekrany',
  'screengrab-page.screen-save-time': 'Czas zapisu zrzutu ekranu',
  'screengrab-page.screen-save-time-desc': 'Jak często mają być zapisywane zrzuty ekranu',
  'screengrab-page.screen-save-time-disabled': 'wyłączone',
  'screengrab-page.no-screens-for-today': 'Brak zapisanych ekranów w tym dniu',
  'screengrab-page.no-screens-for-filter': 'Brak ekranów spelniających zadane kryteria',
  'screengrab-page.imgs-delete-description': 'Czy jesteś pewien, że chcesz usunąć wybrane obrazy?',
  'screengrab-page.imgs-delete-description-one': 'Czy jesteś pewien, że chcesz usunąć wybrany obraz?',
  'screengrab-page.imgs-delete-label': 'Usuń',
  'screengrab-page.imgs-delete-select': 'Zaznacz do usunięcia',
  'screengrab-page.imgs-delete-selected': 'Wybrano:',
  'screengrab-page.imgs-delete-limit': 'Osiągnięto limit wybranych screenów.',
  'screengrab-page.imgs-filter': 'Filtruj:',
  'screengrab-page.imgs-filter-communicators': 'Komunikatory',
  'screengrab-page.imgs-filter-social': 'Social Media',
  'screengrab-page.imgs-filter-video': 'Video',
  'screengrab-page.imgs-filter-others': 'Inne',

  'screes-pages.device-offline': ' Urządzenie jest wyłączone',
  'screes-pages.device-deactivated': 'Ochrona została czasowo deaktywowana',
  'screes-pages.device-timeout': 'Urządzenie nie odpowiada',
  'screes-pages.device-screenoff': 'Urządzenie ma wyłączony ekran',
  'screes-pages.device-notallowed': 'Brak uprawnień dla Screen Grab',
  'screes-pages.device-error':  'Wystąpił błąd',

  'lazy-image.img-delete-description': 'Czy jesteś pewien, że chcesz usunąć wybrany obraz?',

  // Applications Page
  'applications-page.headline-title': 'Aplikacje',
  'applications-page.nav-access-time-label': 'Czas dostępu',
  'applications-page.nav-statistics-label': 'Podsumowanie',
  'applications-page.nav-apps-list-label': 'Lista aplikacji',
  'applications-page.chart-activity-by-day-label': 'Aktywność w ciągu doby',
  'applications-page.chart-hour-label': 'Godzina',
  'applications-page.chart-activity-by-hour-minutes-label': 'Aktywność w danej godzinie w minutach',
  'applications-page.list-launched-count-label': 'Ilość uruchomień:',
  'applications-page.list-elapsed-time-label': 'Czas działania:',
  'applications-page.introduction': 'Lista zainstalowanych aplikacji',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Harmonogram',
  'applications-page.settings.schedule-legend-label': 'Legenda',
  'applications-page.settings.schedule-blocked-label': 'Aplikacje zablokowane',
  'applications-page.settings.schedule-available-label': 'Aplikacje dostępne',
  'applications-page.time-limit.description': 'Limit czasu dla wybranych aplikacji. Możesz wybrać aplikacje objęte limitem czasu. ',
  'applications-page.apps-headline-title': 'Lista dostępnych aplikacji',
  'applications-page.apps-first-seen-label': 'Pierwszy raz widziana:',
  'applications-page.apps-available-switch-label': 'Dostępna',
  'applications-page.apps-not-available-switch-label': 'Niedostępna',
  'applications-page.apps-schedule-switch-label': 'Harmonogram',
  'applications-page.apps-no-schedule-switch-label': 'Bez harmonogramu',
  'applications-page.apps-time-limit-switch-label': 'Limit czasu',
  'applications-page.apps-no-time-limit-switch-label': 'Bez limitu czasu',
  'applications-page.apps-show-uninstalled-label': 'Pokaż również odinstalowane aplikacje',
  'applications-page.apps-show-apps-list': 'Wybierz',

  'applications-page.used-count-zero-label': 'Nie uruchomiono ani razu',
  'applications-page.used-count-one-label': 'Uruchomiono {value} raz, łączny czas: {totalTime}',
  'applications-page.used-count-many-label': 'Uruchomiono {value} razy, łączny czas: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} g {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} sek',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} sek',
  'applications-page.total-time-label': 'Łączny czas ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'Link aktywacyjny został wykorzystany lub jest niepoprawny',
  'email-confirmation-page.successful-confirm': 'E-mail został potwierdzony. Dziękujemy!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Zmień hasło',
  'change-password-page.new-password-input-label': 'Nowe hasło',
  'change-password-page.new-password-repeated-input-label': 'Potwórz nowe hasło',
  'change-password-page.current-password-input-label': 'Obecne hasło',
  'change-password-page.back-button-label': 'Wróć',
  'change-password-page.change-button-label': 'ZMIEŃ',
  'change-password-page.successful-change-label': 'Hasło zostało poprawnie zmienione',
  'change-password-page.wrong-current-password-label': 'Błędne obecne hasło',
  'change-password-page.password-too-short-label': 'Nowe hasło jest za krótkie',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENU',
  'top-navigation.user-menu.account-label': 'Twoje konto',
  'top-navigation.user-menu.sign-out': 'Wyloguj się',
  'top-navigation.user-menu.subscription-label': 'Wybrany plan',
  'top-navigation.user-menu.change-password-label': 'Zmień hasło',
  'top-navigation.user-menu.add-device-label' : 'Dodaj urządzenie',

  // Sidebar
  'sidebar.menu-item.applications': 'Aplikacje',
  'sidebar.menu-item.calls-and-text-messages': 'Rozmowy i SMS',
  'sidebar.menu-item.internet': 'Strony internetowe',
  'sidebar.menu-item.localizations': 'Lokalizacje',
  'sidebar.menu-item.social-networks': 'Sieci społecznościowe',
  'sidebar.menu-item.summary': 'Podsumowanie',
  'sidebar.menu-item.battery': 'Bateria',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Podgląd ekranu',
  'sidebar.menu-item.screengrab': 'Zapisane ekrany',
  'sidebar.menu-item.access-time': 'Czas Dostępu',

  // Validator
  'phone-number.not-valid': 'Proszę podać poprawny numer telefony',
  'input.required-label': 'Pole jest wymagane',
  'input.select-required-label': 'Proszę wybrać',
  'email.not-valid-label': 'Proszę podać poprawny adres e-mail',
  'domain-exist-label': 'Domena juz została dodana',
  'domain-exist-other-label': 'Domena już została dodana do drugiej listy',
  'domain-not-valid': 'Domena nie jest poprawna',
  'password-not-match-label': 'Hasło nie pasuje',

  // misc
  'button-buy-label': 'Wybierz plan',

  'transactiion-status-0': 'Oczekująca',
  'transactiion-status-1': 'Zakończona',
  'transactiion-status--1': 'Anulowana',
  'transactiion-status--2': 'Anulowana - zwrot',
  'transactiion-status--3': 'Oczekująca na potwierdzenie',
  'transactiion-status--100': 'Rozpoczęta',
  'transactiion-status-other': 'Inny',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Sukces',
  'reward-page.error-already-used':"Ten kod już był wykorzystany",
  'reward-page.error-invalid-code':"Niepoprawny kod",
  'reward-page.error-connection'  : "Błąd połączenia",
  'reward-page.additional-bonus'  : "Dodatkowy bonus",
  'reward-page.get-additional-bonus'  : "Odbierz kupon promocyjny",
  'reward-page.get-now'  : "Odbieram",
  'reward-page.lic-was-extended-by-days'  : "Twój plan został przedłużony o {rewardDays} dni!",
  'reward-page.there-was-an-error'  : "Niestety wystąpił błąd.",
  'reward-page.lic-not-extended'  : "Twój plan nie został uaktualniony.",
  'reward-page.button-close' : 'Zamknij',

  // url item
  'url-item.copied': 'Skopiowano do schowka',
  'url-item.copy-link': 'Kopiuj link',

  // APK howto

  'apk-howto-google-play' : 'Google Play ogranicza możliwość korzystania z uprawnień wymaganych przez tę funkcjonalność, aby z niej korzystać:',
  'apk-howto-on-the-phone' : 'Na telefonie chronionym dziecka',
  'apk-howto-step1' : 'Odinstaluj aplikację {applite} jeśli została wcześniej pobrana ze sklepu Google Play',
  'apk-howto-step2' : 'Pobierz na chroniony telefon dziecka aplikację bezpośrednio z naszej strony',
  'apk-howto-step2B': 'lub zeskanuj poniższy kod QR na telefonie dziecka.',  
  'apk-howto-step3' : 'Uruchom i zainstaluj pobraną aplikację',
  'apk-howto-tutorial' : 'Poradnik, jak zainstalować aplikację na telefonie lub tablecie',
  
  // Cookies

  'cookies-info-uses': 'Ta strona używa plików cookie.',
  'cookies-info-privacy-policy': 'Więcej w polityce prywatności.',

  // Plan names

  'plan-name-demoExpired':  "Okres demo sie skończył",
  'plan-name-licExpired':   "Plan dobiegł końca wygasła",
  'plan-name-reward':       "Bonus",
  'plan-name-demo':         "Demo",
  'plan-name-minimal':      "Plan Podstawowy",
  'plan-name-regular':      "Plan Rozbudowany",
  'plan-name-premium':      "Plan Premium",
  'plan-name-edu':          "Pomoc w nauce",
  'plan-name-minimal-edu':  "Plan Podstawowy",
  'plan-name-regular-edu':  "Plan Rozbudowany",
  'plan-name-premium-edu':  "Plan Premium",

  'plan-name-demoExpired2': ' ',
  'plan-name-licExpired2': ' ',
  'plan-name-reward2': ' ',
  'plan-name-demo2': ' ',
  'plan-name-minimal2': ' ',
  'plan-name-regular2': ' ',
  'plan-name-premium2': ' ',
  'plan-name-edu2':     ' ',
  'plan-name-minimal-edu2':  'Pomoc w nauce',
  'plan-name-regular-edu2':  'Pomoc w nauce',
  'plan-name-premium-edu2':  'Pomoc w nauce',  

  // agreements

  'agreement-will-inform-user' : "Oświadczam, iż poinformuję użytkownika urządzenia, na kt&oacute;rym będzie zainstalowana aplikacja o tym, że jego aktywność oraz lokalizacja będą monitorowane, dostępne w panelu rodzica.",

  'agreement-privacy-policy' : 'Oświadczam, że przeczytałem i akceptuję <a href="{regulations}" target="_blank">regulamin</a> oraz <a href="{privacy}" target="_blank">politykę prywatności</a>. Oświadczam również, ze mam ukończone 18 lat. Będę dodawać do mojego konta tylko dzieci, które nie ukończyły 18 lat i pozostają pod moją władzą rodzicielską.',

  'agreement-marketing' : 'Wyrażam zgodę na przesyłanie informacji marketingowych przez {domain} dotyczących ofert promocyjnych na podany przeze mnie e-mail i numer telefonu oraz przekazanie danych naszym partnerom reklamowym wskazanym w polityce prywatności w celu reklamy online. <a href="{marketing}" target="_blank">Więcej.</a>',


  'gdpr-page1-title': 'Cenimy Twoją Prywatność',
  'gdpr-page1-content': 'Kliknij "AKCEPTUJ WSZYSTKO", aby wyrazić zgodę na używanie Plików Cookie do automatycznego zbierania i przetwarzania Twoich danych osobowych przez {domain}. Używamy Plików Cookie, aby poprawić jakość przeglądania, wyświetlać reklamy lub treści dostosowane do indywidualnych potrzeb użytkownika oraz analizować ruch na naszej stronie. Kliknięcie przycisku "AKCEPTUJ WSZYSTKO" oznacza, że wyrażasz zgodę na nasze używanie Plików Cookie.',

  'gdpr-page1-advanced': 'Więcej informacji',
  'gdpr-page1-agree': 'AKCEPTUJ WSZYSTKO',

  'gdpr-page2-title': 'Więcej	 Informacji',
  'gdpr-page2-content': 'Gdy przeglądasz naszą stronę internetową {domain} lub {panel}, możemy automatycznie zbierać dane o Twojej aktywności. Dane te są zbierane przy użyciu plików Cookies. Pliki Cookies są osadzone w przeglądarce Twojego komputera lub innego urządzenia podczas przeglądania strony. Pliki Cookies umożliwiają również zalogowanie się do {panel}, aby zmienić ustawienia konta i przeglądać aktywność chronionych Użytkowników. Pliki Cookies są również używane do personalizacji i profilowania reklam na podstawie Twojej aktywności na avosmart.com i wszystkich jego podstronach. Niektóre pliki Cookies są niezbędne do prawidłowego funkcjonowania serwisu, inne są opcjonalne. Wybierz, na które pliki Cookies wyrażasz zgodę. <a href="{privacy}" target="_blank">Więcej w naszej polityce prywatności.</a>',

  'gdpr-page2-necessary': 'Tylko niezbędne',
  'gdpr-page2-agree': 'AKCEPTUJĘ WSZYSTKIE',

}

export default {
  locale: 'pl-PL',
  name: 'Polski',
  data: appLocalData,
  messages
} as LanguageLocale
