import 'react-image-lightbox/style.css';
import { Marks } from 'rc-slider';
import React from 'react';
import { Button } from 'reactstrap'
import Lightbox from 'react-image-lightbox';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import I18nMessages from '../../components/I18nMessages';
import Spinner from '../../components/Spinner';
import ConfirmationModal from '../../containers/ConfirmationModal';
import DatePickerWithButtons from '../../containers/DatePickerWithButtons';
import TimeLimitPicker from '../../containers/TimeLimitPicker';
import getMessage from '../../language/getMessage';
import { GROUP_APP_MESSENGERS, GROUP_APP_SOCIAL, GROUP_APP_VIDEO, shouldDisplaySmsScreensAnyway, toLocaleDate, toLocaleDateTime, translateScreenErrorMessage } from '../../lib/utils';
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider';
import { InjectedBenServiceProps, ScreenThumbItem, ScreenHeader, withBenService, ScreenHeaderItem, ScreenItem } from '../../providers/benServiceProvider';
import { withI18nProvider } from '../../providers/i18nProvider';
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider';
import ScreenGrabThumb from './ScreenGrabThumb';
import ApkHowto from './ApkHowto';


type Props = InjectedBenAccountProps & InjectedBenServiceProps & InjectedBenNotificationProps & InjectedIntlProps & {}

const GROUP_SIZE = 20;
const MAX_DELETE_ITEMS = 100;

const getDaysBetweenTwoDates = (start: string | number | Date, end: string | number | Date) => {
  for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};



const ScreenGrabPage: React.FC<Props> = ({
  benAccount,
  benService,
  benNotification,
  intl
}) => {

  const [isLoading, setLoading] = React.useState(true)
  
  const [thumbsCache, setThumbsCache] = React.useState< Map<number, ScreenThumbItem> >( new Map<number, ScreenThumbItem>() )
  
  const [headerCopy, setHeaderCopy] = React.useState<ScreenHeader | null>(null)

  const requestThumbs = React.useRef<Set<number>>(new Set<number>())

  const [selectedIds, setSelectedIds] = React.useState<Set<number>>(new Set<number>())  

  const daysScreenHeaderItem = React.useRef< Map<number,ScreenHeaderItem[]> >( new Map<number,ScreenHeaderItem[]>() )

  const days = React.useRef< number[] > ( [] )

  const thumbTimer = React.useRef<NodeJS.Timeout | null>( null )

  const [currentDayItemsNoFilter, setCurrentDayItemsNoFilter] = React.useState<ScreenHeaderItem[]>([])

  const [currentDayItems, setCurrentDayItems] = React.useState<ScreenHeaderItem[]>([])
  
  const [selectMode, setSelectMode] = React.useState<boolean>(false);
 
  const [imgDeleteArray, setImgDeleteArray] = React.useState<number[] | null >(null)

    
  const [excludedDays, setExcludedDays] = React.useState<Date[]>([])
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const [minDate, setMinDate] = React.useState(new Date())
  
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [currentLightboxImage, setCurrentLightboxImage] = React.useState<ScreenItem | null>(null)
  const [currentLightboxIndex, setCurrentLightboxIndex] = React.useState<number>(0)
  
  const [screenPeriod, setScreenPeriod] = React.useState(0)

  const appFilterSupported = React.useRef( false )
  const [showMessengers, setShowMessengers] = React.useState(true)
  const [showSocial, setShowSocial] = React.useState(true)
  const [showVideo, setShowVideo] = React.useState(true)
  const [showOther, setShowOther] = React.useState(true)

  const { deviceId, profileId } = benAccount.currentProfile

  // const knowMessengers = new Set<string>(['com.google.android.apps.messaging', 'com.google.android.apps.tachyon'])
  // const knownSocial = new Set<string>(['com.facebook.katana', 'io.faceapp', 'com.instagram.android', ])
  // const knowVideo = new Set<string>(['com.google.android.youtube', 'com.google.android.videos', 'com.google.android.apps.youtube.music', 'com.tvp.vodtv.mobile', 'com.netflix.mediaclient', 
  //                    'eu.hbogo.android', 'com.zhiliaoapp.musically'])


  const SLIDER_MARKS: Marks = {
    0: <I18nMessages id="screengrab-page.screen-save-time-disabled" />,
    60: '1 min',
    120: '2 min',
    180: '3 min',
    240: '4 min',
    300: '5 min'
  };

  const labelFormater = (value: number) => {
    return SLIDER_MARKS[value];
  }


  const updateSaveScreenTime = (value: number) => {
    const { deviceId, profileId } = benAccount.currentProfile

    if ((value === 0 || value) && deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '') {
      benService.saveSettings(profileId, deviceId, { screenPeriod: value })

        .then(() => benNotification.notify({
          type: 'success',
          title: getMessage('side-effect.successful-title', intl),
          message: getMessage('side-effect.successful-message', intl)
        }))

        .catch(() => benNotification.notify({
          type: 'error',
          title: getMessage('side-effect.internal-error-title', intl),
          message: getMessage('side-effect.internal-error-message', intl)
        }))
    }
  }


  function deleteScreens( imgIds : number[] )
  {
    if( headerCopy === null )
      return

    if (deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '') 
    {
      benService.screenDelete(profileId, deviceId, imgIds)
    }

    const filtered = headerCopy.items.filter( (item) => {
      return !imgIds.includes(item.id) 
    } )

    setHeaderCopy( { total:filtered.length, items:filtered } )
  }


  function handleOnDeleteConfirm() {

    if( imgDeleteArray !== null )
      deleteScreens( imgDeleteArray )

    setImgDeleteArray( null )
  }

  function handleOnCancelConfirmationModal () {
    setImgDeleteArray(null)
  }


  function showDeleteDialog( ids : Set<number> )
  {
    const idsArray : number[] = []

    ids.forEach( (i) => { idsArray.push(i)})

    setImgDeleteArray(idsArray)
  }


  function handleScreenPeriodChange(value: number) {
    if (!isNaN(value) && value <= 24 * 60) {
      setTimeout(() => updateSaveScreenTime(value), 500)
    }
  }


  function id2idx(imgId : number)
  {
    for( let i = 0; i<currentDayItems.length; i++)
      if( currentDayItems[i].id === imgId )
        return i

    return -1
  }


  function loadLightboxImg(idx: number): void 
  {
    const { deviceId, profileId } = benAccount.currentProfile

    if (deviceId === null || profileId === null || deviceId === '' || profileId === '' || currentDayItems.length < 1 ) 
    {
      setIsOpen(false)
      setCurrentLightboxImage(null)
      return
    }
  
    if( idx < 0) idx = 0
    if( idx >= currentDayItems.length ) idx = currentDayItems.length-1

    const imgId = currentDayItems[idx].id

    benService.screenGet(profileId, deviceId, imgId)
      .then(result => {
        // uwaga na blad w C++ Boost, JSON wszystkie wartosci przesyla jako string
        if (Number(result.code) !== 0) 
        {
          resetState()
          setErrorMessage(result.status)
        }
        else 
        {
          setCurrentLightboxImage( result.data )
          setCurrentLightboxIndex( idx )         
        }
      })
  }


  const closeLightbox = () =>
  {
    setIsOpen(false)
    setCurrentLightboxImage(null)
  }


  const resetState = () => 
  {
    setThumbsCache(new Map<number, ScreenThumbItem>())
    setHeaderCopy(null)

    setLoading(false)

    requestThumbs.current.clear()    
    daysScreenHeaderItem.current.clear()
    days.current.length = 0
    thumbTimer.current = null
    setSelectedIds( new Set() )

    setCurrentDayItems([])

    setSelectMode( false )

    setMinDate(new Date())
    setSelectedDate(new Date())

    setIsOpen(false)
    setCurrentLightboxImage(null)

    appFilterSupported.current = false
  }


  const toggleSelectMode = () =>
  {
    setSelectedIds( new Set() )
    setSelectMode( !selectMode )
  }


  function matchesApp(app : string | null | undefined)
  {
    if( app === undefined || app === null || app === '' )
      return true

    if( GROUP_APP_MESSENGERS.has(app) ) return showMessengers
    if( GROUP_APP_SOCIAL.has(app) )     return showSocial
    if( GROUP_APP_VIDEO.has(app) )      return showVideo
    return showOther
  }


  const buildScreenHeaderItem = ( header : ScreenHeader | null ) => 
  {
    let filterSupported = false

    days.current.length = 0

    daysScreenHeaderItem.current.clear()

    if( header !== null )
    {
      header.items.forEach(item => {
        const date = new Date(item.ts * 1000)
        date.setHours(0, 0, 0, 0)
        const key = date.getTime()

        let i = daysScreenHeaderItem.current.get(key)

        if( i === undefined ) {
          i = []
          daysScreenHeaderItem.current.set(key, i)
          days.current.push(key)
        }

        i.push(item)

        if( !filterSupported && item.app !== undefined && item.app !== null && item.app !== '' )
          filterSupported = true

      });
    }

    appFilterSupported.current = filterSupported 
  }


  const updateMinMaxDates = () => 
  {
    if( days.current.length > 0 )
    {
      const lastDay = days.current[ days.current.length-1 ] 
      const firstDay = days.current[0];

      const maxDate = new Date()
      maxDate.setHours(0, 0, 0, 0)

      const minDate = new Date(lastDay)

      const excludedDays = getDaysBetweenTwoDates(minDate, maxDate).filter((day => {

        if( !days.current.includes( day.getTime() ) ) 
        {
          return new Date(day)
        }
        return false
      }))

      setMinDate(new Date(lastDay))
      setSelectedDate(new Date(firstDay));
      setExcludedDays(excludedDays)
    }
    else
    {
      const now = new Date()
      setMinDate(now)
      setSelectedDate(now);
      setExcludedDays([])
    }
  }

  
  const updateSelectedDate = (date: Date | null) =>
  {
    if (date !== null)
    {
      date.setHours(0, 0, 0, 0)
    }

    // const it = daysScreenHeaderItem.keys()
    // let r = it.next()
    // while(!r.done)
    // {
    //   console.log( r.value )
    //   r = it.next()
    // };

    setSelectedIds( new Set() )
    setSelectMode( false )

    if (date !== null) 
    {
      setSelectedDate(date)

      let i = daysScreenHeaderItem.current.get(date.getTime())

      if( i === undefined ) 
        i = [] 

      setCurrentDayItemsNoFilter( i )

      if( appFilterSupported.current )
      {      
        setCurrentDayItems( i.filter(  (item) => {

          return matchesApp(item.app)

        } ) )
      }
      else
      {
        setCurrentDayItems( i )
      }
    }
    else
    {
      setCurrentDayItemsNoFilter( [] )
      setCurrentDayItems( [] )
    }
  }


  React.useEffect( () => {

    if( shouldDisplaySmsScreensAnyway(benAccount) ) 
        return

    buildScreenHeaderItem(headerCopy)
    updateMinMaxDates()
    updateSelectedDate(selectedDate)

  }, [headerCopy] )  // eslint-disable-line react-hooks/exhaustive-deps


  React.useEffect( () => {

    if( shouldDisplaySmsScreensAnyway(benAccount) ) 
        return

    updateSelectedDate(selectedDate)

  }, [showVideo, showSocial, showMessengers, showOther] ) // eslint-disable-line react-hooks/exhaustive-deps

  
  React.useEffect(() => {
    let isMounted = true

    if( shouldDisplaySmsScreensAnyway(benAccount) ) 
        return

    resetState();

    setLoading(true)
    setErrorMessage(null)

    if (deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '') 
    {
      benService.loadSettings(profileId, deviceId)
        .then(result => {      
            if( !isMounted )
              return 

            setScreenPeriod(result.data.screenPeriod) 
        })
        .catch( () => {} )

      benService.screenHeaderList(profileId, deviceId, '', 0, 1000000)
        .then(result => {
          if( !isMounted )
            return

          if (Number(result.code) !== 0) 
          {
            resetState()
            setErrorMessage(result.status)            
          }
          else 
          {
            // C++ czasem zwraca takie:  {"cmd":"CSH","msgId":"myigkdh4o2rcszs","status":"OK","code":"0","data":{"total":"0","items":""}}
            if( Number(result.data.total) > 0 )
            {
              setHeaderCopy(result.data)
            }
            else
            {
              setHeaderCopy(null)
            }
            setLoading(false)
          }

        })
        .catch(() => {
          if (isMounted) {
            setHeaderCopy(null)
            setLoading(false)
            setIsOpen(false)
          }
        })
    } else {
      //console.log('Wrong deviceId')
    }

    return () => {
      isMounted = false
    }
  }, [benAccount, benService, deviceId, profileId])

  
  const toggleMessengers = () => 
  {
    setShowMessengers( !showMessengers )
  }

  const toggleSocial = () => 
  {
    setShowSocial( !showSocial )
  }

  const toggleVideo = () => 
  {
    setShowVideo( !showVideo )
  }

  const toggleOther = () => 
  {
    setShowOther( !showOther )
  }

  const onThumbImgClicked = (imgId: number) => 
  {
    const idx = id2idx( imgId )

    if( idx < 0 )
      return

    setIsOpen(true)
    loadLightboxImg(idx)
  }


  const readThumbs = () => 
  {
    if (deviceId === null || profileId === null || deviceId === '' || profileId === '' ) 
    {
      thumbTimer.current = null 
      return
    }

    const ids : number[] = []
    let cnt = 0

    requestThumbs.current.forEach( (i) => {
      if( thumbsCache.has(i) )
      {
        requestThumbs.current.delete(i)
      }
      else
      {
        if( cnt++ < GROUP_SIZE )
          ids.push(i)
      }
    })

    if( ids.length < 1 )
    {
      thumbTimer.current = null 
      return
    }

    //console.log('Requesting screens: ' + ids)

    benService.screenListByIds(profileId, deviceId, ids)
      .then(result => {
    
          thumbTimer.current = null 

          if (Number(result.code) !== 0) 
          {
            resetState()
            setErrorMessage(result.status)            
          }
          else 
          {
            result.data.items.forEach( (i) => {
              thumbsCache.set( i.id, i )
            })

            setThumbsCache( new Map(thumbsCache) )

            if( requestThumbs.current.size > 0 )
            {
              thumbTimer.current = setTimeout(() => readThumbs(), 50) 
            }
          }
        }
      )
      .catch( () => { thumbTimer.current = null } )
  }

  const onThumbRequestImg = (imgId: number, isVisible: boolean) => 
  {
    if( isVisible )
      requestThumbs.current.add(imgId)
    else
      requestThumbs.current.delete(imgId)

    if (deviceId !== null && profileId !== null && deviceId !== '' && profileId !== ''  ) 
    {
      if( requestThumbs.current.size > 0 && thumbTimer.current === null )
      {
        thumbTimer.current = setTimeout(() => readThumbs(), 600)
      }
    }
  }

  const onThumbRequestDelete = (imgId: number) => {

    const idsArray : number[] = [imgId]

    setImgDeleteArray(idsArray)
  }

  const onThumbSelected = (imgId: number, isSelected: boolean ) => 
  {
    if( isSelected )
    {
      if( selectedIds.size >= MAX_DELETE_ITEMS )
        return false

      selectedIds.add(imgId)
    }
    else
    {
      selectedIds.delete(imgId)
    }

    setSelectedIds( new Set(selectedIds) )

    return true
  }

  const lbImg = () => {

    if( currentLightboxImage === null )
      return ''

    return 'data:image/jpeg;base64,' + currentLightboxImage.jpeg
  }

  const lbTitle = () => {

    if( currentLightboxImage === null )
      return ''

    const d = new Date(currentLightboxImage.ts * 1000)

    return toLocaleDateTime(d, intl)
  }


  if( shouldDisplaySmsScreensAnyway(benAccount) )
  {
    return (
      <ApkHowto labelMessageId="screengrab-page.headline-title"/>
    )
  }


  return (
    <div className="dashboard-wrapper">

      <Row className="mb-4">
        <Col sm="12">
          <Card>
            <CardBody>
              <Row>
                <Col sm="6">
                  <CardTitle tag="h3">
                    <I18nMessages id="screengrab-page.screen-save-time" />
                  </CardTitle>
                  <I18nMessages id="screengrab-page.screen-save-time-desc" />
                </Col>
                <Col sm="6" className={'screen-list-time-picker'}>
                  <TimeLimitPicker
                    value={screenPeriod}
                    onChange={handleScreenPeriodChange}
                    max={300}
                    step={60}
                    sliderMarks={SLIDER_MARKS}
                    smallLabels={true}
                    labelFormater={labelFormater}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <h1 className="mb-0 p-0"><I18nMessages id="screengrab-page.headline-title" /></h1>
        <h1 className="mb-0 p-0">{ toLocaleDate( selectedDate, intl)}</h1>

        <div className="d-flex align-items-center">
          <DatePickerWithButtons
            minDate={minDate}
            excludeDates={excludedDays}
            onSelectedDateChanged={updateSelectedDate}
            selectedDate={selectedDate} />
        </div>
      </div>

      <Row className="mb-4">
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle tag="h5" className="screen-list-card-header">
                <Row>
                  <Col sm="4" className="d-none d-sm-block">
                    <I18nMessages id="screengrab-page.chart-description" />
                  </Col>
                  <Col xs="5" sm="4" className="text-sm-center">
                    {selectMode && (
                      <div>
                        {selectedIds.size > 0 && (
                          <button  className="btn btn-danger mr-3" onClick={ () => showDeleteDialog( selectedIds ) }>
                            <I18nMessages id="screengrab-page.imgs-delete-label" />
                          </button>
                        )}

                        <I18nMessages id="screengrab-page.imgs-delete-selected" />
                        <span className="font-weight-bold ml-2">
                          {selectedIds.size}/{currentDayItems.length}
                        </span>
                      </div>
                    )}
                  </Col>

                  {currentDayItems.length > 0 && (<Col xs="7" sm="4" className="text-right">
                    <button
                      onClick={() => toggleSelectMode()}
                      className={selectMode ? 'btn btn-danger' : 'btn btn-light'} >
                      <I18nMessages id="screengrab-page.imgs-delete-select" />
                    </button>
                  </Col>)}
                </Row>
                <Row>
                  <Col>
                      {selectedIds.size >= MAX_DELETE_ITEMS && (
                        <div className="mt-3 text-center"><I18nMessages id="screengrab-page.imgs-delete-limit" /></div>
                      )}
                  </Col>
                </Row>

                { appFilterSupported.current && (
                <Row className="mt-1">
                  <Col xs="3" md="2" className="mt-3 d-xs-block d-md-none">
                    <I18nMessages id="screengrab-page.imgs-filter"/>
                  </Col>
                  <Col xs="9" md="10">
                    <span className="mr-3 d-none d-md-inline-block ">
                      <I18nMessages id="screengrab-page.imgs-filter"/>
                    </span>
                    <Button className="m-1" color={ (showMessengers ? 'success' : 'light') } onClick={ () => toggleMessengers() }> <I18nMessages id="screengrab-page.imgs-filter-communicators" /> </Button>
                    <Button className="m-1"  color={ (showSocial     ? 'success' : 'light') } onClick={ () => toggleSocial() }>     <I18nMessages id="screengrab-page.imgs-filter-social" /> </Button>
                    <Button className="m-1"  color={ (showVideo      ? 'success' : 'light') } onClick={ () => toggleVideo() }>      <I18nMessages id="screengrab-page.imgs-filter-video" /> </Button>
                    <Button className="m-1"  color={ (showOther      ? 'success' : 'light') } onClick={ () => toggleOther() }>      <I18nMessages id="screengrab-page.imgs-filter-others" /> </Button>
                  </Col>
                </Row>
                )}

              </CardTitle>

              {isLoading && (
                <div>
                  <Spinner />
                </div>
              )}

              {errorMessage && (
                <h3> { translateScreenErrorMessage(errorMessage, intl) } </h3>
              )}

              {!isLoading && currentDayItems.length > 0 && (
                <Row className="mb-1 container-fluid">

                  { currentDayItems.map( (item) => {

                    return <ScreenGrabThumb 
                      key={item.id}
                      header={item}
                      thumbsCache={thumbsCache}
                      selectMode={selectMode}
                      onThumbRequestImg={onThumbRequestImg}
                      onThumbImgClicked={onThumbImgClicked}
                      onThumbSelected={onThumbSelected}
                      onThumbRequestDelete={onThumbRequestDelete}
                    />

                  } ) }                  

                </Row>
              )}

              { !isLoading && currentDayItemsNoFilter.length === 0 && !errorMessage && (
                <h3><I18nMessages id="screengrab-page.no-screens-for-today" /></h3>
              )}
              { !isLoading && currentDayItemsNoFilter.length > 0 && currentDayItems.length === 0 && !errorMessage && (
                <h3><I18nMessages id="screengrab-page.no-screens-for-filter" /></h3>
              )}


            </CardBody>
          </Card>
        </Col>
      </Row>

      <ConfirmationModal
        isOpen={ imgDeleteArray !== null }
        onCancel={handleOnCancelConfirmationModal}
        onConfirm={handleOnDeleteConfirm}>
          { imgDeleteArray !== null && imgDeleteArray.length > 1 && (
            <I18nMessages id="screengrab-page.imgs-delete-description" />
          ) }
          
          { imgDeleteArray !== null && imgDeleteArray.length <= 1 && (
            <I18nMessages id="screengrab-page.imgs-delete-description-one" />
          )}

      </ConfirmationModal>

      <div>
        {isOpen  && (
          <Lightbox
            mainSrc={ lbImg() }
            imageTitle={ lbTitle() }
            nextSrc={currentLightboxIndex < currentDayItems.length - 1 ? lbImg() : ''}
            prevSrc={currentLightboxIndex > 0 ? lbImg() : ''}
            onCloseRequest={ () => closeLightbox() }
            wrapperClassName="modal-wrapper"
            onMovePrevRequest={() => { loadLightboxImg(currentLightboxIndex-1) }}
            onMoveNextRequest={() => { loadLightboxImg(currentLightboxIndex+1) }}
          />
        )}
      </div>

    </div>
  )
}

export default injectIntl(withBenNotification(withBenService(withBenAccount(withI18nProvider(ScreenGrabPage)))))
