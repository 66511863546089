import React from 'react'
import { Modal, ModalBody, Button } from 'reactstrap'
import I18nMessage from '../components/I18nMessages'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { getBuildConfigStr, getCookie, setCookie, isWebView, translateByConfigStr } from '../lib/utils'
import LanguageDropdown from '../containers/LanguageDropdown'

import TagManager from 'react-gtm-module'
import ReactGA  from 'react-ga4'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'

declare function __ipStartMonitoring(arg: any) : void;

type Props = {
    inline?: boolean
}
& InjectedIntlProps & InjectedBenAccountProps

type ScreenSize = {
    width: number
    height: number
}

// Do not show RODO on WebView
const globalHide = isWebView()

const MiniRodo: React.FC<Props> = ({
  inline,
  intl,
  benAccount,
}) => { 

    const [showPopup, setShowPopup] = React.useState(false)

    const [showMoreOptions, setShowMoreOptions] = React.useState(false)

    const [screenSize, setScreenSize] = React.useState<ScreenSize>( {width:window.innerWidth, height: window.innerHeight } )

    React.useEffect( () => {

        const handleResize = () => {
          setScreenSize( {width:window.innerWidth, height:window.innerHeight })
        }    
        
        window.addEventListener('resize',  handleResize )

        var ga = isAllowedGA()

        if( ga === 0 ) {
            setShowPopup( true )
            benAccount.changeSettings({ ...benAccount.settings, cookieJustClicked: true })
        }

        if( ga > 0 && !globalHide )
            startAnalytics()
      
        return () => {
          window.removeEventListener('resize', handleResize )
        }
    
    },[])
    

    function getAllowCnt() : number
    {
        // let v = localStorage.getItem("GAC")
        let v = getCookie("GAC", "1")

        let vv : number = Number(v) || 1

        if(vv < 1)
            vv = 1

        //localStorage.setItem("GAC", String(vv+1))
        setCookie("GAC", String(vv+1), 365 * 10)

        return vv
    }

    function setAllowGA(allowed: boolean)
    {
        var ts : number = new Date().getTime() / 1000

        if( allowed )
        {
            ts += 5 * 365 * 24 * 3600
        }
        else
        {
            const cnt = getAllowCnt()
            if( cnt <= 1 )
                ts += 7 * 24 * 3600
            else if( cnt === 2 )
                ts += 30 * 24 * 3600
            else
                ts += 180 * 24 * 3600

            ts = -ts
        }

        //localStorage.setItem("GA", String(ts) )
        setCookie("GA", String(ts), 365 * 10)

        sessionStorage.setItem("GA", allowed ? "1" : "-1")

        const path       = window.location.pathname
        const serviceUrl = getBuildConfigStr('GDPR_SERVICE')

        fetch(serviceUrl,
        { 
            method: 'POST', 
            body: JSON.stringify({action:"setAllowed", allowed, path})
        })
        .catch( () => {})
    }

    function isAllowedGA() : number
    {
        var v = sessionStorage.getItem("GA")
        if( v === "1"  ) return 1
        if( v === "-1" ) return -1

        //v = localStorage.getItem("GA")
        v = getCookie("GA", null)

        if( !v ) return 0

        var ts : number = new Date().getTime() / 1000
        var n  : number = Number(v)

        if( n < 0 && ts < -n)
        {
            //const timeLeft = -n - ts
            //console.log("isAllowedGA <0  timeLeft " + timeLeft)
            return -1
        }

        if( n > 0 && ts < n )
        {
            //const timeLeft = n - ts
            //console.log("isAllowedGA >0  timeLeft " + timeLeft)
            return 1
        }
        
        return 0
    }

    function startAnalytics()
    {
        // console.log("Start Analytics...")

        if (process.env.REACT_APP_GA_ID) 
        {
            // https://github.com/codler/react-ga4/issues/57
            ReactGA.gtag("consent", "default", {
                ad_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied",
                analytics_storage: "denied",
              });

            ReactGA.gtag("consent", "update", {
                ad_storage: "granted",
                ad_user_data: "granted",
                ad_personalization: "granted",
                analytics_storage: "granted",
              });

            ReactGA.initialize(process.env.REACT_APP_GA_ID)

            // ReactGA.initialize(process.env.REACT_APP_GA_ID, {
            //     gtagOptions: {}
            // })
        }
        
        // https://www.evolvingdev.com/post/how-to-create-a-cookie-consent-banner-for-gtm-and-astro

        if (process.env.REACT_APP_GTM_ID) 
        {
            const tagManagerArgs = { 
                gtmId: process.env.REACT_APP_GTM_ID,
                consentSettings: {
                    ad_storage: 'granted',
                    ad_user_data: 'granted',
                    ad_personalization: 'granted',
                    analytics_storage: 'granted' }
                // dataLayer: { 
                //     ad_storage: 'granted',
                //     ad_user_data: 'granted',
                //     ad_personalization: 'granted',
                //     analytics_storage: 'granted' }
            }
            TagManager.initialize(tagManagerArgs)
        }

        // starts ipresso monitoring, see .env-avo, .env-ben, index.html
        // ipresso jest odpalane z GTM'a
        // __ipStartMonitoring(!0);
    }
    
    function onStartAnalytics()
    {
        setAllowGA(true)
        setShowPopup(false)

        startAnalytics()
    }

    function onNoAnalytics()
    {
        setAllowGA(false)
        setShowPopup(false)
    }

    function onShowMoreOptions()
    {
        setShowMoreOptions(true)
    }

    function onToggle()
    {
    }

    return (

        <React.Fragment>
            <Modal isOpen={!globalHide && showPopup} toggle={onToggle} size="lg" className="bottom-modal"   >
                <ModalBody>
                    <div className="fixed-top m-2"  >
                        <LanguageDropdown/>
                    </div>
                    { !showMoreOptions && (
                        <div className='bottom-modal-center'> 
                            <div className='bottom-modal-body'>

                                <p className="gdpr-txt-header mt-4"><I18nMessage id="gdpr-page1-title"/></p>
		                        {/* <p className="gdpr-txt"   > { translateByConfigStr("gdpr-page1-content", intl) }</p> */}
                                <p dangerouslySetInnerHTML={{ __html: unescape( translateByConfigStr("gdpr-page1-content", intl) ) }}/>

                                <div className='container mt-2'>
                                    <div className='row'>
                                        <div className='col-md-5 container-fluid mt-2' >
                                            <Button color="light" onClick={onShowMoreOptions} className='bottom-modal-btn'>
                                                <I18nMessage id="gdpr-page1-advanced"/>
                                            </Button>
                                        </div>
                                        <div className='col-md-5 container-fluid mt-2' >
                                            <Button color="primary" onClick={onStartAnalytics} className='bottom-modal-btn'>
                                                <I18nMessage id="gdpr-page1-agree"/>
                                            </Button>
                                        </div>
                                        <div className='col-md-2 container-fluid mt-2' > 
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    )}

                    { showMoreOptions && (

                        <div className='bottom-modal-center'> 
                            <div className='bottom-modal-body'>
    
                                <p className="gdpr-txt-header"><I18nMessage id="gdpr-page2-title"/></p>
		                        <p dangerouslySetInnerHTML={{ __html: unescape( translateByConfigStr("gdpr-page2-content", intl) ) }}/>                                    

                                <div className='container mt-2' >
                                    <div className='row'>
                                        <div className='col-md-6 container-fluid mt-2'>
                                            <Button color="light" onClick={onNoAnalytics} className='bottom-modal-btn'>
                                                <I18nMessage id="gdpr-page2-necessary"/>
                                            </Button>
                                        </div>
                                        <div className='col-md-6 container-fluid mt-2'>
                                            <Button color="primary" onClick={onStartAnalytics} className='bottom-modal-btn'>
                                            <I18nMessage id="gdpr-page2-agree"/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* { !globalHide && showPopup && (
                <div className={ inline && screenSize.height < 900 ?"rodo-inline" : "rodo-box" } >
                    <div>
                        <div>
                            <div className="rodo-x" onClick={onXClicked}>X</div> 
                            <I18nMessage id="cookies-info-uses"/> <a href={getBuildConfigStr("PRIVACY_URL")} target="_blank" rel="noopener noreferrer"><I18nMessage id="cookies-info-privacy-policy"/></a>
                        </div>
                    </div>
                </div>
            )} */}
        
        </React.Fragment>        
    )
}

export default injectIntl( withBenAccount(MiniRodo) )
