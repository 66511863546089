import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'


type Props = InjectedIntlProps & {
    isOpen: boolean
    onToggle: () => void
}

const ActivationCodePopup: React.FC<Props> = ({
    intl,
    isOpen,
    onToggle,
}) => {

    return (

        <>
            <Modal isOpen={isOpen} toggle={onToggle} size="lg"  >
                <ModalHeader>
                    <h4> Kod Pin <i className="simple-icon-people "/>  </h4>
                </ModalHeader>

                <ModalBody>
                    <p>
                        <i className="simple-icon-info pr-2 greencolor"/>
                        Kod pin jest dostępy w panelu rodzcia.
                    </p>

                    <p>
                        <i className="simple-icon-info pr-2 greencolor"/>
                        Wejdz na stronę panelu rodzica <a className='edu-link-style' href='/login'>panel.beniamin.pl</a>, zaloguj się, następnie otwórz zakładkę "Pomoc w nauce", 
                        tam znajdziesz kod pin, a także kod QR umożliwiający jego łatwe udostępnianie, 
                        oraz przyciski udostępniania kodu poprzez komunikator lub email.
                    </p>

                    <p>
                        <i className="simple-icon-info pr-2 greencolor"/>
                        Aby można było rozwiązywać zadania konieczne jest wykupienie planu edukacyjnego. 
                    </p>
                    
                    <p>
                        <i className="simple-icon-info pr-2 greencolor"/>
                        Jeśli nie posiadasz konta rodzica <a className='edu-link-style' href='/register'>zarejestruj się</a>
                    </p>

                    <Row className='mt-4'> 
                        <Col xs="3" sm="3" className='d-flex justify-content-center'></Col>
                        <Col xs="6" sm="6" className='d-flex justify-content-center'>
                            <Button color='primary' size='lg' onClick={ onToggle } > OK </Button>
                        </Col>
                        <Col xs="3" sm="3" className='d-flex justify-content-center'></Col>
                    </Row>

                </ModalBody>
            </Modal>
        </>
    )
}

export default injectIntl(ActivationCodePopup)
