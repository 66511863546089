import React from 'react'
import Spinner from '../components/Spinner'
import { useMountEffect } from '../lib/useMountEffect'
import { getCookies } from '../lib/utils'
import { APP_TYPE, AccountType, ActionCode, LoginType } from './providersDefs'

export enum ANALYTICS_NAMES {
  NONE = '',
  CREATE_PROFILE = 'createProfile',
  ADD_DEVICE = 'addDevice',
  SUMMARY = 'summary',
  VIDEO_INSTALL_ANDROID = 'videoA',
  VIDEO_INSTALL_WINDOWS = 'videoW'
}

export enum ANALYTICS_VALUES {
  PAGE_DISPLAY = 'd'
}


export enum ActionStatus {
  ok = 'OK',
  error = 'error',
  pending = 'pending',
  unauthorized = 'login'
}

export enum ActionName {
  analyticsEvent = 'ae',
  updatePassword = 'updatePassword',
  changePassword = 'changePassword',
  createProfile = 'createProfile',
  deleteProfile = 'deleteProfile',
  emailConfirm = 'emailConfirm',
  getApps = 'getApps',
  getAppsUsage = 'getAppsUsage',
  getCalls = 'getCalls',
  getContacts = 'getContacts',
  getGPS = 'getGPS',
  geo = 'geo',
  getProfiles = 'getProfiles',
  getSMSList = 'getSmses',
  getTop = 'getTop',
  getTopApps = 'getTopApps',
  getTopBlocked = 'getTopBlocked',
  getTopDomains = 'getTopDomains',
  getTopSearch = 'getTopSearch',
  getUrls = 'getUrls',
  getKeywords = 'getKeywords',
  loadLists = 'loadLists',
  loadSettings = 'loadSettings',
  logged = 'logged',
  login = 'login',
  login2 = 'login2',
  logoff = 'logoff',
  register = 'register',
  getAllAgreements = 'getAllAgreements',
  resetPassword = 'resetPassword',
  saveLists = 'saveLists',
  saveSettings = 'saveSettings',
  loadGlobalSettings = 'loadGlobalSettings',
  saveGlobalSettings = 'saveGlobalSettings',
  setApps = 'setApps',
  updateProfile = 'updateProfile',
  getPlans = 'getPlans',
  setLang = 'setLang',
  subscribtion = 'subscribtion',
  buyLicense = 'buyLicense',
  buyLicenseS = 'buyLicenseS',
  upgradeLicense = 'upgradeLicense',
  handleSessionId = 'handleSessionId',
  purchaseStatus = 'purchaseStatus',
  deleteDevice = 'deleteDevice',
  updateDevice = 'updateDevice',
  resendEmailConfirm = 'resendEmailConfirm',
  listLicenses = 'listLicenses',
  listUpgrades = 'listUpgrades',
  getHealth='getHealth',
  getYT='getYT',

  screenGrab='screenGrab',
  screenList='screenList',
  screenListByIds='screenListByIds',
  screenHeaderList='screenHeaderList',
  screenGet='screenGet',
  screenDelete='screenDelete',

  reward='reward',

  verifyConfirmCode='verifyConfirmCode',

  enableDisable='enableDisable'
}



export interface ActionRequest<T = {}> {
  action: ActionName
  data: T
}

export interface ActionResponse<T = undefined> {
  status: ActionStatus
  name: ActionName
  code: ActionCode
  data: T
}

export interface ActionResponseEx<T, T2> {
  status: ActionStatus
  name: ActionName
  code: ActionCode
  data: T
  dataEx: T2
}



export interface PlansItem {
  type: AccountType
  intervalCnt: number
  intervalName: string
  currency: string
  //language: string
  //name: string
  basePrice: number
  price: number
  priceInt: number
  discount: number
  monthly: number
  percent: number
  devices: number
  //highlight: string
  //texts: []
  eduTokens: number
}

export interface PlansData
{
  plans: PlansItem[]
  topups: PlansItem[]
}

export interface PaginatedActionResponse<T = any[]> {
  status: ActionStatus
  name: ActionName
  offset: number
  total: number
  data: T
}

export interface PaginatedActionResponseEx<T = any[], T2 = any[]> {
  status: ActionStatus
  name: ActionName
  offset: number
  total: number
  data: T
  dataEx: T2
}

export interface ActionResponseWithMax<T> extends ActionResponse<T> {
  max: number
}

export interface PaginatedActionResponseWithFallback<T> extends PaginatedActionResponse<T> {
  fb: boolean
}

export interface PaginatedActionResponseWithFallbackEx<T, T2> extends PaginatedActionResponseEx<T, T2> {
  fb: boolean
}


export type ContactData = {
  id: number
  itemId: number
  itemType: number
  lastContacted: number
  lastUpdated: 0
  name: string
  text: string
  text2: string
}

export interface GPSData {
  lat: number
  lng: number
  ts: number
  v: number
  a: number
}

export interface GPSDataEx  {
  ts1: number
  ts2: number
  idx1: number
  idx2: number
  elapsed: number
  lat: number
  lng: number
  latLen: number
  lngLen: number
}



export type UserData = {
  userName: string
  phoneNo: string
  email: string
  confirmed: boolean
  loginType: LoginType
  lang: string
  privileges: Privileges
  lic: {
    validFrom: number | null
    validTo: number | null
    accountType: AccountType
    cancel: number | null

    validFromEdu: number | null
    validToEdu: number | null
    accountTypeEdu: AccountType
    tokensEdu: number | null
    cancelEdu: number | null

    name: string | null
    devices: number | null
    status: number | null    
    subscription: number | null
  },
  ipx: string
}

export type Privileges = {
  getApps: number
  getAppsUsage: number
  getCalls: number
  getContacts: number
  getGPS: number
  getHealth: number
  getKeywords: number
  getSmses: number
  getTop: number
  getTopApps: number
  getTopBlocked: number
  getTopDomains: number
  getTopSearch: number
  getUrls: number
  loadLists: number
  loadSettings: number
  saveLists: number
  saveSettings: number
  setApps: number
}

export type SMSData = {
  body: string
  msgId: number
  msgType: number
  phoneNo: string
  threadId: number
  ts: number
}

export type TopApps = {
  pkg: string
  ico: string
  app: string
  elapsed: number
  cnt: number
  percent: number
}

export type TopBlocked = {
  domain: string
  percent: number
}

export type TopDomain = {
  domain: string
  percent: number
}

export type TopSearch = {
  keyword: string
  percent: number
}

export type TopBundle = {
  apps: ActionResponseWithMax<TopApps[]>
  domains: ActionResponseWithMax<TopDomain[]>
  blocked: ActionResponseWithMax<TopBlocked[]>
  search: ActionResponseWithMax<TopSearch[]>
}

export type AppData = {
  allowed: number
  device: number
  firstSeen: number
  flags: number
  ico: string
  id: number
  installed: number
  lastSeen: number
  name: string
  pkg: string
  schedule: number
  sys: number
  timeLimit: number
}

export type AppSettingData = {
  id: number
  allowed: number
  schedule: number
  timeLimit: number
}

export type AppUsageData = {
  count: number
  elapsed: number
  first: number
  from: number
  ico: string
  last: number
  name: string
  pkg: string
  to: number
}

export type CallData = {
  callId: number
  callType: number
  country: string
  duration: number
  formattedNumber: string
  location: string
  normalizedNumber: string
  number: string
  ts: number
}

export interface Device {
  id: string
  profileId: number
  friendlyName: string
  brand: string
  model: string
  osName: string
  osVer: string
  appVer:string
  appFeatures:string
  appType: APP_TYPE
  created: number
  lastUsed: number
}

export interface Geo {
  countryCode: string
  countryName: string
  area: string
  city: string
}

export interface Profile {
  devices: Device[]
  id: string
  img: number
  month: number
  name: string
  year: number
}

export type Profiles = Profile[]

export interface Settings {
  blockDoc: boolean
  blockErotic: boolean
  blockExe: boolean
  blockFileSites: boolean
  blockNewApps: boolean
  blockSettings: boolean
  enableGPSTracking: boolean
  blockOffensive: boolean
  blockSocial: boolean
  blockVideo: boolean
  excludeYT: boolean
  customMsg: string
  customMsgOn: boolean
  disabledTill: boolean
  enableSSL: boolean
  enableUserDef: boolean
  enableVocabulary: boolean
  forceSafeSearch: boolean
  scheduleOn: boolean
  skd1: string
  skd2: string
  skd3: string
  skd4: string
  skd5: string
  skd6: string
  skd7: string
  skda1: string
  skda2: string
  skda3: string
  skda4: string
  skda5: string
  skda6: string
  skda7: string
  timeBonus: number
  timeBonusDay: number
  screenPeriod: number
  timeLimit: number
  timeLimitWeek: string
  timeLimitNet: number
  timeLimitNetWeek: string
  ts: string
  vocabularyLevel: number
  whiteListOnly: boolean
  
}

export interface SettingsGlobal {
  automaticUpdates: boolean
  utc: Number
  timeZoneName: string
  sendReports: boolean
}

export interface UrlData {
  blocked: number
  domain: string
  ts: number
  url: string
}

export interface KeywordData {
  ts: number
  engine: string
  keyword: string
  url: string
}


export interface WebsitesLists {
  whiteList: string[]
  blackList: string[]
  videoIds: string[]
  channelIds: string[]
}

export interface Agreements {
  agreement: AgreementsData[]
}

export interface AgreementsData {
  cant_delete: number
  descr: string
  id: number
  name: string
  short_name: string
  required: string
}

export interface BuyLicense {
  redirectUri: string
}

export interface BuyLicenseS {
  cs:             string
  pk:             string
  email:          string
  description:    string  // nazwa planu
  devices:        number  // 3, 6, 9
  lang:           string  // en, pl
  currency:       string  // pln, usd, eur
  amount:         number  // w groszach/centach 10.22$  = 1022
  intervalName:   string  // day, week, month, year
  intervalCnt:    number  // ilosc dni w 'interval'
  country:        string
  countryCode:    string
}

export interface UpgradeLicense {
  redirectUri: string
}

export interface PurchaseStatus {
  status: number
  days: number
  plan: AccountType
  upgrade: number
  price: number
  currency: string
  lang: string
}


export interface LicInfo
{
  validFrom : number
  validTo : number
  accountType : number
  devices: number
  cancel: number

  validFromEdu : number
  validToEdu : number
  accountTypeEdu : number
  tokensEdu: number | null
  cancelEdu: number
  
  subscription: number
  status : number  
}

export interface LicPurchase
{
  row : number
  created: number
  validFrom: number
  validTo: number
  licDays: number
  accountType: AccountType
  status: number
}

export interface LicUpgrade
{
  currency: string
  price:    number
  priceInt: number
  type:     AccountType
  devices:  number
  till:     number

  subscriptionPrice?:    number
  subscriptionPriceInt?: number
  intervalCnt?:          number
  intervalName?:         string
  description?:          string
}

export interface LicPortal
{
  url: string
}

export interface LicSubscription
{
  id: string
  created: number
  current_period_start: number
  current_period_end: number
  cancel_at_period_end: number
  canceled_at: number
  amount: number
  currency: string
  interval: string
  interval_count: number
}

export interface LicensesInfo
{
  lic: LicInfo
  portal?: LicPortal
  purchases: LicPurchase[]
  subscription?: LicSubscription
  
  hasPlans: number
  hasUpgrades: number
  
  hasPlansEdu: number
  hasUpgradesEdu: number  
}

export interface UpgradesInfo
{
  upgrades: LicUpgrade[]
}

export interface HealthItem
{
  ts:       number
  battery:  number
  gps:      number
}

export interface YouTubeItem
{
  created:          number
  title:            string
  author:           string
  channeltitle:     string
  blocked:          number
  videoid:          string
  channelid:        string
  thumbnaildefault: string
  thumbnailmedium:  string
}


export interface ScreenItem
{
  ts: number
  userName: string
  jpeg: string
}


export interface ScreenThumbItem
{
  id: number
  ts: number
  userName: string
  jpeg: string
}

export interface ScreenHeaderItem
{
  id: number
  ts: number
  app: string
}


export interface ScreenThumb
{
  total: number
  items: ScreenThumbItem[]
}

export interface ScreenHeader
{
  total: number
  items: ScreenHeaderItem[]
}



export interface RewardItem
{
  days: number
}

export interface EnableDisableItem
{
  period: number
}

export interface BenServiceContextValue {

  isAuthorized: boolean
  userData?: UserData

  analyticsEvent:( eventId: number, eventName: string, eventData: string ) => Promise<ActionResponse>

  updatePassword: (newPassword: string, code: string) => Promise<ActionResponse>
  changePassword: (currentPassword: string, newPassword: string) => Promise<ActionResponse>
  createProfile: (profileName: string, profileImage: number, birthYear: number, birthMonth: number) => Promise<ActionResponse>
  deleteProfile: (profileId: string) => Promise<ActionResponse>
  emailConfirm: (code: string) => Promise<ActionResponse>
  getApps: (profile: string, device: string, includeSystem: number) => Promise<ActionResponse<AppData[]>>
  getAppsUsage: (profile: string, device: string, dateFrom: Date, dateTo: Date, offset: number, count: number) => Promise<PaginatedActionResponse<AppUsageData[]>>
  getCalls: (profile: string, device: string, dateFrom: Date, dateTo: Date, offset: number, count: number) => Promise<PaginatedActionResponse<CallData[]>>
  getContacts: (profile: string, device: string, offset: number, count: number) => Promise<PaginatedActionResponse<ContactData[]>>
  getGPS: (profile: string, device: string, dateFrom: Date, dateTo: Date, offset: number, count: number) => Promise<PaginatedActionResponseWithFallbackEx<GPSData[], GPSDataEx[]>>
  getProfiles: () => Promise<ActionResponse<Profiles>>
  geo:() => Promise<ActionResponse<Geo>>
  getSMSList: (profile: string, device: string, dateFrom: Date, dateTo: Date, offset: number, count: number) => Promise<PaginatedActionResponse<SMSData[]>>
  getTop: (profile: string, device: string, dateFrom: Date, dateTo: Date) => Promise<ActionResponse<TopBundle>>
  getTopApps: (profile: string, device: string, dateFrom: Date, dateTo: Date) => Promise<ActionResponseWithMax<TopApps[]>>
  getTopBlocked: (profile: string, device: string, dateFrom: Date, dateTo: Date) => Promise<ActionResponseWithMax<TopBlocked[]>>
  getTopDomains: (profile: string, device: string, dateFrom: Date, dateTo: Date) => Promise<ActionResponseWithMax<TopDomain[]>>
  getTopSearch: (profile: string, device: string, dateFrom: Date, dateTo: Date) => Promise<ActionResponseWithMax<TopSearch[]>>
  getUrls: (profile: string, device: string, dateFrom: Date, dateTo: Date, filter: string, offset: number, count: number) => Promise<PaginatedActionResponse<UrlData[]>>
  getKeywords: (profile: string, device: string, dateFrom: Date, dateTo: Date, filter: string, offset: number, count: number) => Promise<PaginatedActionResponse<KeywordData[]>>
  getUser: (language: string) => Promise<ActionResponse<UserData>>


  loadLists: (profile: string, device: string, lists: number[] | null) => Promise<ActionResponse<WebsitesLists>>
  saveLists: (profile: string, device: string, data: WebsitesLists, lists: number[] | null) => Promise<ActionResponse>

  loadSettings: (profile: string, device: string) => Promise<ActionResponse<Settings>>
  login: (email: string, password: string, language: string) => Promise<ActionResponse<UserData>>
  login2: (email: string, password: string, captcha: string, language: string, timeZoneName: string) => Promise<ActionResponse<UserData>>
  logout: () => Promise<ActionResponse>
  resetPassword: (email: string, language: string, captcha: string) => Promise<ActionResponse>
  register: (email: string, password: string, phoneNumber: string, language: string, timeZoneOffset: number, timeZoneName: string, captcha: string, userAgreements: any, doLogin:boolean) => Promise<ActionResponse>
  getAllAgreements: (language: string) => Promise<ActionResponse<Agreements>>

  saveSettings: (profile: string, device: string, data: Partial<Settings>) => Promise<ActionResponse>
  loadGlobalSettings: () => Promise<ActionResponse<SettingsGlobal>>
  saveGlobalSettings: (data: Partial<SettingsGlobal>) => Promise<ActionResponse>

  setApps: (profile: string, device: string, data: AppSettingData[]) => Promise<ActionResponse>
  updateProfile: (profileId: string, profileName?: string, profileImage?: number, birthYear?: number, birthMonth?: number) => Promise<ActionResponse>
  getPlans: (promoCode: string, language: string, years?: number) => Promise<ActionResponse<PlansData>>
  setLang: (lang: string) => Promise<ActionResponse>

  subscribtion: (subscriptionId: string, cancelAtPeriodEnd:number ) => Promise<ActionResponse>
  buyLicense: (licType: number, licYears: number, promoCode: string, language: string) => Promise<ActionResponse<BuyLicense>>
  buyLicenseS: (licType: number, licYears: number, promoCode: string, language: string) => Promise<ActionResponse<BuyLicenseS>>  

  upgradeLicense: (licType: number, promoCode: string, language: string) => Promise<ActionResponse<UpgradeLicense>>
  handleSessionId: (id: string) => Promise<ActionResponse>
  purchaseStatus: () => Promise<ActionResponse<PurchaseStatus>>
  deleteDevice: (deviceId: string) => Promise<ActionResponse>
  updateDevice: (deviceId: string | null, profileId: string) => Promise<ActionResponse>

  resendEmailConfirm: (language: string) => Promise<ActionResponse>
  listLicenses: (onlyValid:boolean, promoCode: string, language: string) => Promise<ActionResponse<LicensesInfo>>
  listUpgrades: (promoCode: string, language: string) => Promise<ActionResponse<UpgradesInfo>>
  getHealth: (profile: string, device:string, dateTo: Date) => Promise<ActionResponse<HealthItem[]>>
  getHealth2: (profile: string, device:string, dateFrom:Date, dateTo: Date) => Promise<ActionResponse<HealthItem[]>>
  getYoutube: (profile:string, device:string, dateFrom:Date, dateTo: Date, offset: number, count: number) => Promise<ActionResponse<YouTubeItem[]>>

  screenGrab: (profile: string, device:string) => Promise<ActionResponse<ScreenItem>>
  screenGet: (profile: string, device:string, id:number) => Promise<ActionResponse<ScreenItem>>
  screenDelete: (profile: string, device:string, ids:number[]) => Promise<ActionResponse>
  screenList: (profile: string, device:string, userName:string, offset:number, count:number) => Promise<ActionResponse<ScreenThumb>>
  screenListByIds: (profile: string, device:string, ids:number[]) => Promise<ActionResponse<ScreenThumb>>
  screenHeaderList: (profile: string, device:string, userName:string, offset:number, count:number) => Promise<ActionResponse<ScreenHeader>>

  reward: (rewardId: string) => Promise<ActionResponse<RewardItem>>

  verifyConfirmCode: (code: String) => Promise<ActionResponse>

  enableDisable: (profile: string, device:string, period: number) => Promise<ActionResponse<EnableDisableItem>>
}

export interface InjectedBenServiceProps {
  benService: BenServiceContextValue
}

export const BenServiceContext = React.createContext<BenServiceContextValue>({} as BenServiceContextValue)

export function withBenService<P extends InjectedBenServiceProps> (Component: React.ComponentType<P>) {
  return (props: Omit<P, keyof InjectedBenServiceProps>) => (
    <BenServiceContext.Consumer>
      {state => <Component {...props as P} benService={state}/>}
    </BenServiceContext.Consumer>
  )
}

export const BenServiceProvider: React.FC = ({
  children
}) => {

  const API_PATH = 'service'

  const [isLoading, setLoadingStatus] = React.useState(true)
  const [userData, setUserData] = React.useState<UserData | undefined>()

  function mapResponseToUserData (response: ActionResponse<UserData>): ActionResponse<UserData> {
    if ('email' in response.data && 'userName' in response.data) {
      setUserData(response.data)
    } else {
      setUserData(undefined)
    }

    return response
  }

  // Keep alive powoduje na mobile taki blad: Preflight request for request with keepalive specified is currently not supported
  // Uncaught (in promise) TypeError: Failed to fetch
  function request<R> (body: {}): Promise<R> {
    return fetch(`/${API_PATH}`, {
      //headers: { 'Connection': 'keep-alive', 'Keep-Alive': 'timeout=60, max=1000', 'Content-Type': 'application/json; charset=UTF-8' },
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      method: 'POST',
      credentials: 'include',
      //keepalive: true,
      body: JSON.stringify(body)
    })

    .then(result => result.json())
    .then((result: any) => {

      if (userData && result.status === ActionStatus.unauthorized) {
        setUserData(undefined)
      }

      if (result.status === ActionStatus.error) {
        throw result
      }

      return result
    })
  }

  const benServiceContextValue: BenServiceContextValue = {

    analyticsEvent:( eventId: number, eventName: string, eventData: string ) => {
      
      const body: ActionRequest<{ i: number, n: string, d: string }> = {
        action: ActionName.analyticsEvent,
        data: { i: eventId, n: eventName, d:eventData }
      }

      return request<ActionResponse>(body)
    },

    updatePassword: (newPassword: string, code: string) => {
      const body: ActionRequest<{ p: string, c: string }> = {
        action: ActionName.updatePassword,
        data: { p: newPassword, c: code }
      }

      return request<ActionResponse>(body)
    },

    changePassword: (currentPassword: string, newPassword: string) => {
      const body: ActionRequest<{ o: string, n: string }> = {
        action: ActionName.changePassword,
        data: { n: newPassword, o: currentPassword }
      }

      return request<ActionResponse>(body)
    },

    createProfile: (profileName: string, profileImage: number, birthYear: number, birthMonth: number) => {
      const body: ActionRequest<{ name: string, img: number, year: number, month: number }> = {
        action: ActionName.createProfile,
        data: { name: profileName, img: profileImage, year: birthYear, month: birthMonth }
      }

      return request<ActionResponse>(body)
    },

    deleteProfile: (profile: string) => {
      const body: ActionRequest<{ profile: string }> = {
        action: ActionName.deleteProfile,
        data: { profile }
      }

      return request<ActionResponse>(body)
    },

    emailConfirm: (code: string) => {
      const body: ActionRequest<{ c: string }> = {
        action: ActionName.emailConfirm,
        data: { c: code }
      }

      return request<ActionResponse>(body)
    },

    getApps: (profile: string, device: string, includeSystem = 1) => {
      const body: ActionRequest<{ profile: string, device: string, includeSystem: number, includeDeleted: number }> = {
        action: ActionName.getApps,
        data: { profile, device, includeSystem, includeDeleted : 0 }
      }

      return request<ActionResponse<AppData[]>>(body)
    },

    getAppsUsage: (profile: string, device: string, dateFrom: Date, dateTo: Date, offset: number, count: number) => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number, offset: number, count: number }> = {
        action: ActionName.getAppsUsage,
        data: {
          profile,
          device,
          offset,
          count,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<PaginatedActionResponse<AppUsageData[]>>(body)
    },

    getPlans: (promoCode: string, language: string, years: number = 1) => {
      const body: ActionRequest<{}> = {
        action: ActionName.getPlans,
        data: {
          l: language,
          pc: promoCode,
          ly: years
        }
      }

      return request<ActionResponse<PlansData>>(body)
    },

    setLang : (lang: string): Promise<ActionResponse> => {

      const body: ActionRequest<{}> = {
        action: ActionName.setLang,
        data: {
          l: lang,
        }
      }

      return request<ActionResponse>(body)
    },

    subscribtion: (subscriptionId: string, cancelAtPeriodEnd:number ) : Promise<ActionResponse> => {

      const body: ActionRequest<{}> = {
        action: ActionName.subscribtion,
        data: {
          s: subscriptionId,
          c: cancelAtPeriodEnd
        }
      }

      return request<ActionResponse>(body)
    },

    buyLicense: (licType: number, licYears: number, promoCode: string, language: string): Promise<ActionResponse<BuyLicense>> => {
      const body: ActionRequest<{}> = {
        action: ActionName.buyLicense,
        data: {
          cu: document.location.origin + '/account/purchase-status?session_id={CHECKOUT_SESSION_ID}',
          clu: document.location.origin + '/',
          l: language,
          lt: licType,
          ly: licYears,
          pc: promoCode
        }
      }

      return request<ActionResponse<BuyLicense>>(body)
    },

    buyLicenseS: (licType: number, licYears: number, promoCode: string, language: string): Promise<ActionResponse<BuyLicenseS>> => {
      const body: ActionRequest<{}> = {
        action: ActionName.buyLicenseS,
        data: {
          cu: document.location.origin + '/account/purchase-status?session_id={CHECKOUT_SESSION_ID}',
          clu: document.location.origin + '/',
          l: language,
          lt: licType,
          ly: licYears,
          pc: promoCode
        }
      }

      return request<ActionResponse<BuyLicenseS>>(body)
    },


    upgradeLicense: (licType: number, promoCode: string,  language: string): Promise<ActionResponse<UpgradeLicense>> => {
      const body: ActionRequest<{}> = {
        action: ActionName.upgradeLicense,
        data: {
          cu: document.location.origin + '/account/purchase-status?session_id={CHECKOUT_SESSION_ID}',
          clu: document.location.origin + '/',
          l: language,
          lt: licType,
          pc: promoCode
        }
      }

      return request<ActionResponse<UpgradeLicense>>(body)
    },

    handleSessionId: (id: string) => {

      const body: ActionRequest<{}> = {
        action: ActionName.handleSessionId,
        data: {
          id: id
        }
      }

      return request<ActionResponse>(body)      
    },

    purchaseStatus: () => {
      const body: ActionRequest<{}> = {
        action: ActionName.purchaseStatus,
        data: {}
      }

      return request<ActionResponse<PurchaseStatus>>(body)
    },

    deleteDevice: (deviceId: string) => {
      const body: ActionRequest<{}> = {
        action: ActionName.deleteDevice,
        data: {
          device: deviceId
        }
      }

      return request<ActionResponse>(body)
    },

    updateDevice: (deviceId: string | null, profileId: string) => {
      const body: ActionRequest<{}> = {
        action: ActionName.updateDevice,
        data: {
          device: deviceId,
          profile: profileId
        }
      }

      return request<ActionResponse>(body)
    },

    getCalls: (profile: string, device: string, dateFrom: Date, dateTo: Date, offset: number, count: number) => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number, offset: number, count: number }> = {
        action: ActionName.getCalls,
        data: {
          profile,
          device,
          offset,
          count,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<PaginatedActionResponse<CallData[]>>(body)
    },

    getContacts: (profile: string, device: string, offset: number, count: number) => {
      const body: ActionRequest<{ profile: string, device: string, offset: number, count: number }> = {
        action: ActionName.getContacts,
        data: { profile, device, offset, count }
      }

      return request<PaginatedActionResponse<ContactData[]>>(body)
    },

    getGPS: (profile: string, device: string, dateFrom: Date, dateTo: Date, offset: number, count: number) => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number, offset: number, count: number }> = {
        action: ActionName.getGPS,
        data: {
          profile,
          device,
          offset,
          count,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<PaginatedActionResponseWithFallbackEx<GPSData[], GPSDataEx[]>>(body)
    },

    geo: () : Promise<ActionResponse<Geo>> => {

      const body: ActionRequest = {
        action: ActionName.geo,
        data: {}
      }

      return request<ActionResponse<Geo>>(body)
    },

    getProfiles: (): Promise<ActionResponse<Profiles>> => {
      const body: ActionRequest = {
        action: ActionName.getProfiles,
        data: {}
      }

      return request<ActionResponse<Profiles>>(body)
    },

    getTop: (profile: string, device: string, dateFrom: Date, dateTo: Date) => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number }> = {
        action: ActionName.getTop,
        data: {
          profile,
          device,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<ActionResponse<TopBundle>>(body)
    },

    getTopApps: (profile: string, device: string, dateFrom: Date, dateTo: Date) => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number }> = {
        action: ActionName.getTopApps,
        data: {
          profile,
          device,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<ActionResponseWithMax<TopApps[]>>(body)
    },

    getTopBlocked: (profile: string, device: string, dateFrom: Date, dateTo: Date) => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number }> = {
        action: ActionName.getTopBlocked,
        data: {
          profile,
          device,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<ActionResponseWithMax<TopBlocked[]>>(body)
    },

    getSMSList: (profile: string, device: string, dateFrom: Date, dateTo: Date, offset: number, count: number) => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number, offset: number, count: number }> = {
        action: ActionName.getSMSList,
        data: {
          count,
          device,
          offset,
          profile,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<PaginatedActionResponse<SMSData[]>>(body)
    },

    getTopDomains: (profile: string, device: string, dateFrom: Date, dateTo: Date) => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number }> = {
        action: ActionName.getTopDomains,
        data: {
          profile,
          device,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<ActionResponseWithMax<TopDomain[]>>(body)
    },

    getTopSearch: (profile: string, device: string, dateFrom: Date, dateTo: Date) => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number }> = {
        action: ActionName.getTopSearch,
        data: {
          profile,
          device,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<ActionResponseWithMax<TopSearch[]>>(body)
    },

    getUrls: (profile: string, device: string, dateFrom: Date, dateTo: Date, filter: string, offset: number, count: number): Promise<PaginatedActionResponse<UrlData[]>> => {
      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number, filter: string, offset: number, count: number }> = {
        action: ActionName.getUrls,
        data: {
          count,
          device,
          filter,
          offset,
          profile,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request< PaginatedActionResponse<UrlData[]> >(body)
    },

    getKeywords: (profile: string, device: string, dateFrom: Date, dateTo: Date, filter: string, offset: number, count: number) : Promise<PaginatedActionResponse<KeywordData[]>> => {

      const body: ActionRequest<{ profile: string, device: string, dateFrom: number, dateTo: number, filter: string, offset: number, count: number }> = {
        action: ActionName.getKeywords,
        data: {
          count,
          device,
          filter,
          offset,
          profile,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request< PaginatedActionResponse<KeywordData[]> >(body)
    },

    getUser: (language: string): Promise<ActionResponse<UserData>> => {
      const body: ActionRequest = {
        action: ActionName.logged,
        data: {
          l: language
        }
      }

      return request<ActionResponse<UserData>>(body).then(mapResponseToUserData)
    },

    isAuthorized: !!userData,

    loadLists: (profile: string, device: string, lists: number[] | null): Promise<ActionResponse<WebsitesLists>> => {

      const body: ActionRequest<{ profile: string, device: string, lists: number[] | null }> = {
        action: ActionName.loadLists,
        data: { profile, device, lists }
      }

      return request<ActionResponse<Array<string[]>>>(body).then(result => ({
        ...result,
        data: {
          whiteList: result.data[0],
          blackList: result.data[1],
          videoIds: result.data[2],
          channelIds: result.data[3]
        }
      }))
    },

    saveLists: (profile: string, device: string, data: WebsitesLists, lists: number[] | null): Promise<ActionResponse> => {
      const body: ActionRequest<{ profile: string, device: string, lists: number[] | null, 0: string[], 1: string[] , 2: string[], 3: string[] }> = {
        action: ActionName.saveLists,
        data: { profile, device, lists, 0: data.whiteList, 1: data.blackList, 2: data.videoIds, 3:data.channelIds }
      }

      return request<ActionResponse>(body)
    },

    loadSettings: (profile: string, device: string): Promise<ActionResponse<Settings>> => {
      const body: ActionRequest<{ profile: string, device: string }> = {
        action: ActionName.loadSettings,
        data: { profile, device }
      }

      return request<ActionResponse<Settings>>(body)
    },

    login: (email: string, password: string, language: string): Promise<ActionResponse<UserData>> => {
      const body: ActionRequest = {
        action: ActionName.login,
        data: { u: email, p: password, l: language }
      }

      return request<ActionResponse<UserData>>(body).then(mapResponseToUserData)
    },

    login2: (email: string, password: string, captcha: string, language: string, timeZoneName: string): Promise<ActionResponse<UserData>> => {

      let cookies = getCookies()

      var ga           = ""
      var gid          = ""
      var gclid        = ""
      var utm_source   = ""
      var utm_medium   = ""
      var utm_campaign = ""
      var session_id   = ""
      var session_no   = ""

      //https://dodov.dev/blog/how-to-get-google-analytics-session-id-and-session-number
      //_ga_BLMB9TW0JF          GS1.1.1685126046.13.1.1685126484.0.0.0
      //_gat_UA-173400637-1     1

      var cookie

      for(cookie of cookies )
      {
        if( cookie.key === '_ga' )                ga = cookie.val
        else if( cookie.key === '_gid' )          gid = cookie.val
        else if( cookie.key === 'gclid' )         gclid = cookie.val
        else if( cookie.key === 'utm_source' )    utm_source = cookie.val
        else if( cookie.key === 'utm_medium' )    utm_medium = cookie.val
        else if( cookie.key === 'utm_campaign' )  utm_campaign = cookie.val
        else
        {
          if( cookie.key.startsWith('_ga_') )
          {
            const parts = cookie.val.split('.')
            if( parts.length > 5 && parts[0].startsWith('GS') )
            {
              session_id = parts[2]
              session_no = parts[3]
            }
          }
        }
      }

      // console.log("COOKIES:")
      // for(cookie of cookies ) {
      //   console.log(cookie.key + " = " + cookie.val )
      // }
      // console.log("session_id="+session_id+"  session_no="+session_no )

      const body: ActionRequest = {
        action: ActionName.login2,
        data: { u: email, p: password, c:captcha, l: language, tzn: timeZoneName, 
                ga:ga, gid:gid, gclid:gclid, session_id: session_id, session_no: session_no,
                utm_source:utm_source, utm_medium:utm_medium, utm_campaign:utm_campaign }
      }

      return request<ActionResponse<UserData>>(body).then(mapResponseToUserData)
    },

    logout: (): Promise<ActionResponse> => {
      const body: ActionRequest = {
        action: ActionName.logoff,
        data: {}
      }

      return request<ActionResponse>(body).then(response => {
        setUserData(undefined)
        return response
      })
    },

    register: (email: string, 
               password: string, 
               phoneNumber: string, 
               language: string, 
               timeZoneOffset: number,
               timeZoneName: string,
               captcha: string, 
               userAgreements: any, 
               doLogin:boolean): Promise<ActionResponse> => {

      let cookies = getCookies()

      var ga           = ""
      var gid          = ""
      var gclid        = ""
      var utm_source   = ""
      var utm_medium   = ""
      var utm_campaign = ""
      var session_id   = ""
      var session_no   = ""

      // https://dodov.dev/blog/how-to-get-google-analytics-session-id-and-session-number
      // _ga_BLMB9TW0JF          GS1.1.1685126046.13.1.1685126484.0.0.0
      // _gat_UA-173400637-1     1

      for(var cookie of cookies )
      {
        if( cookie.key === '_ga' )                ga = cookie.val
        else if( cookie.key === '_gid' )          gid = cookie.val
        else if( cookie.key === 'gclid' )         gclid = cookie.val
        else if( cookie.key === 'utm_source' )    utm_source = cookie.val
        else if( cookie.key === 'utm_medium' )    utm_medium = cookie.val
        else if( cookie.key === 'utm_campaign' )  utm_campaign = cookie.val
        else
        {
          if( cookie.key.startsWith('_ga_') )
          {
            const parts = cookie.val.split('.')
            if( parts.length > 5 && parts[0].startsWith('GS') )
            {
              session_id = parts[2]
              session_no = parts[3]
            }
          }
        }
      }

      const body: ActionRequest<{ 
                      u: string, 
                      p: string, 
                      t: string, 
                      c: string, 
                      l: string, 
                      agreements: any, 
                      dl: boolean, 
                      tzo: number, 
                      tzn: string,
                      ga: string, gid: string, gclid: string,
                      session_id: string, session_no:string, 
                      utm_source: string, utm_medium: string, utm_campaign: string}> = {
        action: ActionName.register,
        data: {
          u: email,
          p: password,
          t: phoneNumber,
          l: language,
          c: captcha,
          agreements: userAgreements,
          dl: doLogin,
          tzo: timeZoneOffset,
          tzn: timeZoneName,
          ga, gid, gclid, 
          session_id, session_no,
          utm_source, utm_medium, utm_campaign
        }
      }

      return request<ActionResponse>(body)
    },

    getAllAgreements: (language: string) => {
      const body: ActionRequest<{}> = {
        action: ActionName.getAllAgreements,
        data: {
          l: language
        }
      }

      return request<ActionResponse<Agreements>>(body)
    },

    resetPassword: (email: string, language: string, captcha: string): Promise<ActionResponse> => {
      const body: ActionRequest<{ e: string, l: string, c: string }> = {
        action: ActionName.resetPassword,
        data: { e: email, l: language, c: captcha }
      }

      return request<ActionResponse>(body)
    },



    saveSettings: (profile: string, device: string, data: Partial<Settings>): Promise<ActionResponse> => {
      const body: ActionRequest<{ profile: string, device: string, data: Partial<Settings> }> = {
        action: ActionName.saveSettings,
        data: { profile, device, data }
      }

      return request<ActionResponse>(body)
    },


    loadGlobalSettings: () : Promise<ActionResponse<SettingsGlobal>> => {
      const body: ActionRequest<{}> = {
        action: ActionName.loadGlobalSettings,
        data: { }
      }

      return request<ActionResponse<SettingsGlobal>>(body)
    },

    saveGlobalSettings: (data: Partial<SettingsGlobal>) : Promise<ActionResponse> => {
      const body: ActionRequest<{ data: Partial<SettingsGlobal> }> = {
        action: ActionName.saveGlobalSettings,
        data: { data }
      }

      return request<ActionResponse>(body)
    },

    setApps: (profile: string, device: string, data: AppSettingData[]): Promise<ActionResponse> => {
      const body: ActionRequest<{ profile: string, device: string, data: AppSettingData[] }> = {
        action: ActionName.setApps,
        data: { profile, device, data }
      }

      return request<ActionResponse>(body)
    },

    updateProfile: (profileId: string, profileName: string = '', profileImg: number = -1, birthYear: number = -1, birthMonth: number = -1) => {
      const body: ActionRequest<{ profile: string, profileName: string, profileImg: number, year: number, month: number }> = {
        action: ActionName.updateProfile,
        data: { profile: profileId, profileName, profileImg, year: birthYear, month: birthMonth }
      }

      return request<ActionResponse>(body)
    },


    resendEmailConfirm: (language: string) => {
      const body: ActionRequest<{ l: string }> = {
        action: ActionName.resendEmailConfirm,
        data: { l:language }
      }

      return request<ActionResponse>(body)
    },

    listLicenses: (onlyValid, promoCode: string, language) => {

      // Promise<ActionResponse<LicenseItem>>

      const body: ActionRequest<{}> = {
        action: ActionName.listLicenses,
        data: { v:onlyValid, l:language, pc:promoCode }
      }

      return request<ActionResponse<LicensesInfo>>(body)
    },

    listUpgrades: (promoCode: string, language) => {

      const body: ActionRequest<{}> = {
        action: ActionName.listUpgrades,
        data: { l:language, pc:promoCode }
      }

      return request<ActionResponse<UpgradesInfo>>(body)
    },

    getHealth: (profile: string, device:string, dateTo: Date) => {
      //Promise<ActionResponse<HealthItem>>

      const body: ActionRequest<{}> = {
        action: ActionName.getHealth,
        data: {
          profile: profile,
          device: device,
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<ActionResponse<HealthItem[]>>(body)

    },

    getHealth2: (profile: string, device:string, dateFrom:Date, dateTo: Date) => {
      //Promise<ActionResponse<HealthItem>>

      const body: ActionRequest<{}> = {
        action: ActionName.getHealth,
        data: {
          profile: profile,
          device: device,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000)
        }
      }

      return request<ActionResponse<HealthItem[]>>(body)
    },


    getYoutube: (profile:string, device:string, dateFrom:Date, dateTo: Date, offset: number, count: number) => {

      const body: ActionRequest<{}> = {
        action: ActionName.getYT,
        data: {
          profile: profile,
          device: device,
          dateFrom: Math.floor(dateFrom.getTime() / 1000),
          dateTo: Math.floor(dateTo.getTime() / 1000),
          offset: offset,
          count: count
        }
      }

      return request<ActionResponse<YouTubeItem[]>>(body)
    },



    screenGrab: (profile: string, device:string) => {

      const body: ActionRequest<{}> = {
        action: ActionName.screenGrab,
        data: {
          profile: profile,
          device: device
        }
      }

      return request<ActionResponse<ScreenItem>>(body)
    },

    screenGet: (profile: string, device:string, id:number) =>
    {
      const body: ActionRequest<{}> = {
        action: ActionName.screenGet,
        data: {
          profile: profile,
          device: device,
          id:id
        }
      }

      return request<ActionResponse<ScreenItem>>(body)
    },

    screenDelete: (profile: string, device:string, ids:number[]) =>
    {
      var idsStr = ''

      ids.forEach( (id) => {
        if( idsStr.length > 0 )
          idsStr += ','
        idsStr += id.toString()
      })

      const body: ActionRequest<{}> = {
        action: ActionName.screenDelete,
        data: {
          profile: profile,
          device: device,
          ids: idsStr
        }
      }

      return request<ActionResponse>(body)
    },

    screenList: (profile: string, device:string, userName:string, offset:number, count:number) =>
    {
      const body: ActionRequest<{}> = {
        action: ActionName.screenList,
        data: {
          profile: profile,
          device: device,
          userName: userName,
          offset: offset,
          count: count
        }
      }

      return request<ActionResponse<ScreenThumb>>(body)
    },

    screenListByIds: (profile: string, device:string, ids:number[]) =>
    {
      var idsStr = ''

      ids.forEach( (id) => {
        if( idsStr.length > 0 )
          idsStr += ','
        idsStr += id.toString()
      })

      const body: ActionRequest<{}> = {
        action: ActionName.screenListByIds,
        data: {
          profile: profile,
          device: device,
          ids: idsStr

        }
      }

      return request<ActionResponse<ScreenThumb>>(body)
    },

    screenHeaderList: (profile: string, device:string, userName:string, offset:number, count:number) =>
    {
      const body: ActionRequest<{}> = {
        action: ActionName.screenHeaderList,
        data: {
          profile: profile,
          device: device,
          userName: userName,
          offset: offset,
          count: count
        }
      }

      return request<ActionResponse<ScreenHeader>>(body)
    },

    reward: (rewardId: string) => {

      const body: ActionRequest<{}> = {
        action: ActionName.reward,
        data: {
            r: rewardId
        }
      }

      return request<ActionResponse<RewardItem>>(body)
    },

    verifyConfirmCode: (code: String) =>{

      const body: ActionRequest<{}> = {
        action: ActionName.verifyConfirmCode,
        data: {
            c: code
        }
      }

      return request<ActionResponse>(body)
    },

    enableDisable: (profile: string, device:string, period: number) => {

      const body: ActionRequest<{}> = {
        action: ActionName.enableDisable,
        data: {
          profile, device, period
        }
      }

      return request<ActionResponse<EnableDisableItem>>(body)
    },

    userData: userData
  }

  useMountEffect(() => {

    // console.log("***** BEN useMountEffect *****")

    benServiceContextValue
      .getUser('pl')
      .then(response => setUserData(response.data))
      .finally(() => setLoadingStatus(false))
  })

  return (
    <BenServiceContext.Provider value={benServiceContextValue}>
      {(benServiceContextValue.userData && benServiceContextValue.userData.ipx) && (
        <img src={benServiceContextValue.userData.ipx} alt="pixel" />
      )}
      { isLoading ? <Spinner /> : children }
    </BenServiceContext.Provider>
  )
}
