import React from 'react'
import { Row, Col, Card, CardBody, CustomInput, Button } from 'reactstrap'
// import I18nMessages, { getI18nMessage } from '../../../components/I18nMessages'
import { InjectedBenAccountProps, withBenAccount } from '../../../providers/benAccountProvider'
import Spinner from '../../../components/Spinner'
import QRCode from 'react-qr-code'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { InjectedBenNotificationProps, withBenNotification } from '../../../providers/notificationProvider'
import { InjectedI18nProviderProps, withI18nProvider } from '../../../providers/i18nProvider'
import { InjectedBenServiceProps, PlansItem, withBenService } from '../../../providers/benServiceProvider'
import { AccountType } from '../../../providers/providersDefs'
import { InjectedAiServiceProps, withAiService, AiStats } from '../../../providers/aiServiceProvider'
import { getCreditsLeftDescription, isAvosmart, isIOS2, setCookie, dashString } from '../../../lib/utils'
import getMessage from '../../../language/getMessage'
import ConfirmationModal from '../../../containers/ConfirmationModal'
import EducationCreditsPopup from './EducationCreditsPopup'
import '../../../assets/css/sass/edu.scss'
import ReactPlayer from 'react-player'
import EducationCharts from './EducationCharts'
import EduBuyPopup from './EduBuyPopup'


type Props = InjectedAiServiceProps & InjectedI18nProviderProps & InjectedBenAccountProps & InjectedBenServiceProps & InjectedBenNotificationProps & InjectedIntlProps

interface ShareLink {
    name: string;
    url: (url: string) => string;
    color: string;
}

const ShareIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
      <polyline points="16 6 12 2 8 6"></polyline>
      <line x1="12" y1="2" x2="12" y2="15"></line>
    </svg>
);

const shareLinks: ShareLink[] = [
    {
        name: 'WhatsApp',
        url: (url: string) => `https://wa.me/?text=${encodeURIComponent(url)}`,
        color: '#25D366'
    },
    {
        name: 'Email',
        url: (url: string) => `mailto:?subject=Adres%20astsenta%20w%20nauce%20Beniamin&body=${encodeURIComponent(url)}`,
        color: '#808080'
    }
];

const EducationPageSidebar: React.FC<Props> = ({
    benAccount,
    benNotification,
    benService,
    aiService,
    intl
}) => {

    // const [isMounted, setMounted] = React.useState(true)
    const mounted = React.useRef<boolean>( false );
  
    // const [isLoading, setLoading] = React.useState(false)
    const [planButton, setPlanButton] = React.useState<PlansItem|null>(null)
    const [topups, setTopups] = React.useState<PlansItem[]>([])
    const [stats, setStats] = React.useState<AiStats | null>(null)
    const [showHashModal, setShowHashModal] = React.useState(false)   
    const [showBuyCreditsModal, setShowBuyCreditsModal] = React.useState(false)    
    const [showAllUsers, setShowAllUsers] = React.useState(false)

    const [showBuyPopup, setShowBuyPopup] = React.useState(false)

    const [demoModeActive, setDemoModeActive] = React.useState(false)

    const hashPanel = 'panel'
    const hashDemo  = 'demo'

    function invalidateHash()
    {
        var profileId = benAccount.currentProfile.profileId ? Number(benAccount.currentProfile.profileId) : 0

        // console.log("invalidateHash for profileId=" + profileId)
    
        aiService.logged( hashPanel, profileId, 1 )
            .catch( () => {} )
    }

    function getUrl(withHash : boolean) : string
    {
        var url;

        var host = window.location.hostname
        var path = window.location.pathname

        if( host === 'localhost' )
        {
            if( navigator.userAgent.indexOf('Windows') > 0 && navigator.userAgent.indexOf('Firefox') > 0 )
                host = '192.168.1.131'
            else
                host = '192.168.50.121'
        }

        path = '/asystent'

        if( withHash && aiService.userData && aiService.userData.hash )
        {          
            if( window.location.port )
                url = window.location.protocol + "//" + host + ":" +window.location.port + path + "?hash="+aiService.userData.hash
            else
                url = window.location.protocol + "//" + host + path + "?hash="+aiService.userData.hash
        }
        else
        {
            if( window.location.port )
                url = window.location.protocol + "//" + host + ":" +window.location.port + path
            else
                url = window.location.protocol + "//" + host + path
        }

        return url
    }

    function getFormattedCode()
    {
        if( aiService.userData && aiService.userData.hash ) {
            return dashString(aiService.userData.hash, 3)
        }

        return "";
    }

    function loadStats( showAllUsers : boolean )
    {
        let profileId = benAccount.currentProfile.profileId ? Number(benAccount.currentProfile.profileId) : 0
        //let demoMode = benService.userData && !benService.userData.lic.accountTypeEdu

        let tokens = 0
        let demoMode = false
        
        if( benService.userData )
        {
            // console.log("benService.userData")
            // console.log("benService.userData.lic.accountType", benService.userData.lic.accountType)
            // console.log("benService.userData.lic.tokensEdu", benService.userData.lic.tokensEdu)

            tokens = benService.userData.lic.tokensEdu ? benService.userData.lic.tokensEdu : 0

            if( ( benService.userData.lic.accountType === AccountType.demo || benService.userData.lic.accountType === AccountType.demoExpired ) && tokens < 1 )
            {
                demoMode = true
            }
        } 
        // !benService.userData.lic.tokensEdu

        setDemoModeActive(demoMode)

        var hash = demoMode ? hashDemo : hashPanel
        var pid = showAllUsers ? -1 : profileId

        // console.log("loading stats for profileId=" + pid)
        aiService.stats( hash, pid, 30, 0, "subjects, questions, time" )
            .then( (r) => { 

                if( !mounted.current ) {
                    console.log("NM  EPS1!")
                    return
                }

                setStats(r.data)
             } )
            .catch( () => {

                if( !mounted.current ) {
                    console.log("NM  EPS2!")
                    return
                }

                setStats(null)

             } ) 
    }



    function buyLicense() 
    {
        if( planButton === null || planButton === undefined )
            return

        if( isAvosmart() )
        {
            // TBD
            // setCheckoutDialogVisible(true)
            // setCheckoutPlan(licType)
            // setCheckoutPlanYears(licYears)
        }
        else
        {
            // setLoading(true)
  
            benService.buyLicense(planButton.type, planButton.intervalCnt, '', intl.locale)
                .then(result => {
                    
                    if( !mounted.current ) {
                        console.log("NM  EPS3!")
                        return
                    }

                    const url = result.data.redirectUri;
                    setTimeout(() => {
                    window.location.href = url
                    }, 1000);
                })    
                .catch(() => {

                    if( !mounted.current ) {
                        console.log("NM  EPS4!")
                        return
                    }

                    benNotification.notify({
                    type: 'error',
                    title: getMessage('side-effect.internal-error-title', intl),
                    message: getMessage('side-effect.internal-error-message', intl)
                    })
                    // setLoading(false)
                })
        }
    }    


    React.useEffect(() => {
        
        mounted.current = true 
        //console.log("SM = true")

        return () => {
            mounted.current = false
            // console.log("SM = false")
        }
      }, [])    


    React.useEffect(() => {

        loadStats(showAllUsers)

    }, [showAllUsers] ) // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {

        // console.log("React.useEffect benAccount")

        // console.log( "benAccount.accountType ", benAccount.accountType )
        // console.log( "benAccount.accountType ", benAccount.accountType )
        // console.log( "benAccount.currentProfile ", benAccount.currentProfile )
        // console.log( "benService.userData ", benService.userData )

        // setLoading(true)

        var profileId = benAccount.currentProfile.profileId ? Number(benAccount.currentProfile.profileId) : 0

        // console.log("generating QR for profileId=" + profileId)

        aiService.logged( hashPanel, profileId, 0)
            .then( () => { 

                if( !mounted.current ) {
                    console.log("NM  EPS6!")
                    return
                }

                loadStats(showAllUsers) 
            } )
            .catch( () => {} )

        benService.getPlans('', intl.locale, 1)
            .then( (result) => { 

                if( !mounted.current ) {
                    console.log("NM  EPS7!")
                    return
                }

                // rezygnujemy z planu EDU
                // const plans = result.data.plans.filter(plan => ( plan.type === AccountType.edu ) )
                // if( plans.length > 0 ) {
                //     setPlanButton(plans[0])
                // }

                if( result.data.topups && result.data.topups.length > 0 )
                    setTopups(result.data.topups)
             } )
            .catch( () => {} )
            .finally( () => {

                if( !mounted.current ) {
                    console.log("NM  EPS8!")
                    return
                }
                // setLoading(false)
            
            })

        return () => { }

    }, [benAccount]) // eslint-disable-line react-hooks/exhaustive-deps


  
  function handleHashModalCancel()
  {
    setShowHashModal(false)
  }

  function handleHashModalProceed()
  {
    setShowHashModal(false)
    invalidateHash()
  }

  function onToggleTokensModal()
  {
    setShowBuyCreditsModal(!showBuyCreditsModal)
  }

  function showDemo(e: React.MouseEvent<HTMLButtonElement, MouseEvent> )
  {
    // force demo mode
    setCookie('logged', '', 0)

    let url = getUrl(false)
    window.open(url)
    e.preventDefault()
    e.stopPropagation()
    return false
  }

//   function renderCreditsInfo(dbg: any | undefined = undefined) : JSX.Element
//   {
//     if( !planButton )
//         return ( <></> )

//     return (
//         <div className='credits-info'>
//             <div>
//                {dbg?dbg:''}{ getCreditsInfo(planButton) }
//             </div>
//             <div>
//                 Liczba zadań jest szacunkowa. Jest ona uzależniona od stopnia skomplikowania zadania.
//             </div>
//         </div>
//     )
//   }

  function renderCharts() : JSX.Element
  {
    return (
        <>
            <hr className="hredu" />

            <CustomInput
                className='mb-4'
                id="showAllUsers"
                name="showAllUsers"
                type="checkbox"
                label="Pokaż dane dla wszystkich profili łącznie"
                checked={showAllUsers}
                onChange={v => setShowAllUsers(v.target.checked) } />

            { stats === null && (
                <div className="chart-container d-flex align-items-center justify-content-center text-center">
                    <Spinner/>
                </div>
            )}

            { benService.userData && demoModeActive && (
                <h2>Przykładowe dane</h2>
            )}

            { benService.userData && !demoModeActive && (
                <h2>Postępy w nauce</h2>
            )}

            <EducationCharts aiStats={stats}/>

        </>
    )
  }


  if( !benAccount.currentProfile.profileId ) {
    return ( <div>
        Brak profilu. Profil jest konieczny aby móc korzystać z panelu edukacyjnego.
    </div> )
  }


  return (
    <div className="dashboard-wrapper">

        { planButton && (
            <EduBuyPopup
                isOpen={showBuyPopup}
                onToggle={ () => { setShowBuyPopup(!showBuyPopup) } }
                plan={planButton}
            ></EduBuyPopup>
        )}

        <ConfirmationModal
            isOpen={showHashModal}
            onCancel={handleHashModalCancel}
            onConfirm={handleHashModalProceed}
        >Czy na pewno chcesz unieważnić aktualny kod dostępu i wygenerować nowy?</ConfirmationModal>

        <EducationCreditsPopup
            useAiService={false}
            topups={topups}
            isOpen={showBuyCreditsModal}
            onToggle={onToggleTokensModal} />

      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
          <h1 className="mb-0 p-0">
                Pomoc w nauce <span role='img' aria-label='Rocket'>🚀</span>
          </h1>      
      </div>

      <Card>
        <CardBody>

            { benService.userData && demoModeActive && ( <>

                <Row className="mb-4">
                    <Col md="6">
                        <h3>Edukacyjny asystent</h3>
                        <p className="edutxt">Wiemy, że nie zawsze łatwo jest pomóc dziecku w nauce wszystkich przedmiotów. Nasza aplikacja to narzędzie, które wspiera rodziców w skutecznej pomocy dzieciom w nauce.</p>
                        <h3 className="eduline-spacing">Wpisz lub zrób zdjęcie zadania</h3>
                        <p className="edutxt">Twoje dziecko ma problem z zadaniem? Wystarczy, że zeskanujesz je naszą aplikacją - działa to z każdym przedmiotem szkolnym, od matematyki po język polski. Możesz też ręcznie wprowadzić treść zadania.</p>

                        <div className="d-md-none">

                            <Button color="primary" 
                                    size="sm"
                                    className="mx-2 my-2" 
                                    onClick={(e) => { showDemo(e) }} >Zobacz demo</Button>

                            <Button color="primary" 
                                    size='sm' 
                                    className="mx-2 my-2" 
                                    onClick={ () => { setShowBuyCreditsModal(true) } }>Kup Punkty Wiedzy</Button>
                            
                            { planButton && (
                                <>
                                <Button 
                                    color="primary" 
                                    size="sm"
                                    className="mx-2 my-2" 
                                    onClick={ (e) => { setShowBuyPopup(true) } } >Wybierz Plan</Button>

                                    {/* <AsyncButton 
                                        showSpinner={isLoading} 
                                        color="primary" 
                                        size="sm"
                                        className="mx-2 my-2" 
                                        onClick={buyLicense} > { getButtonBuyText() } </AsyncButton>

                                    <div className='mt-1 mb-3'>{ renderCreditsInfo() }</div> */}
                                </>
                            )}
                             
                        </div>

                        <h3 className="eduline-spacing">Sprawdzaj</h3>
                        <p className="edutxt">Aplikacja natychmiast pokaże poprawne rozwiązanie. Często dostępnych jest kilka metod rozwiązania, co pozwala wybrać najbardziej zrozumiałą dla Twojego dziecka.</p>
                        <h3 className="eduline-spacing">Edukuj</h3>
                        <p className="edutxt">Asystent to nie tylko narzędzie do sprawdzania zadań. To platforma edukacyjna, która pomoże Ci zrozumieć proces rozwiązywania, abyś mógł lepiej wytłumaczyć go swojemu dziecku.</p>
                        <h3 className="eduline-spacing">Asystent pozwala</h3>
                    
                        <div>
                            <div className="py-1">
                                <i className="simple-icon-check pr-2 greencolor"></i>
                                Szybko sprawdzać poprawność zadań domowych.
                            </div>
                            <div className="py-1">
                                <i className="simple-icon-check pr-2 greencolor"></i>
                                Uzyskać szczegółowe wyjaśnienia krok po kroku dla każdego zadania.
                            </div>
                            <div className="py-1">
                                <i className="simple-icon-check pr-2 greencolor"></i>
                                Lepiej zrozumieć materiał szkolny, aby skuteczniej pomagać dziecku.
                            </div>
                            <div className="py-1">
                                <i className="simple-icon-check pr-2 greencolor"></i>
                                Śledzić postępy dziecka w różnych przedmiotach.
                            </div>
                        </div>

                        { planButton && !demoModeActive && (
                            <Col className='d-flex justify-content-center'>
                                <Button 
                                    color="primary" 
                                    size="lg"
                                    className="mx-2 my-2" 
                                    onClick={ (e) => { setShowBuyPopup(true) } } >Wybierz Plan</Button>

                                {/* <AsyncButton 
                                    showSpinner={isLoading} 
                                    color="primary" 
                                    size='lg' 
                                    className="my-4" 
                                    onClick={buyLicense}>{ getButtonBuyText() }</AsyncButton> */}
                            
                            </Col>
                        )}

                        { benService.userData && demoModeActive && (
                            <>
                                <div className="d-flex justify-content-center mt-3">
                                    <div className="d-none d-md-block">
                                        <Button color="primary" 
                                                size="lg"
                                                className="mx-2 my-2" 
                                                onClick={(e) => { showDemo(e) }} >Zobacz demo</Button>

                                        <Button color="primary" 
                                                size='lg' 
                                                className="mx-2 my-2" 
                                                onClick={ () => { setShowBuyCreditsModal(true) } }>Kup Punkty Wiedzy</Button>
                                        
                                        { planButton && (
                                            <>
                                            <Button 
                                                color="primary" 
                                                size="lg"
                                                className="mx-2 my-2" 
                                                onClick={ (e) => { setShowBuyPopup(true) } } >Wybierz Plan</Button>
                                                {/* <AsyncButton    
                                                    showSpinner={isLoading} 
                                                    color="primary" 
                                                    size="lg"
                                                    className="mx-2 my-2" 
                                                    onClick={buyLicense} > { getButtonBuyText() } </AsyncButton>
                                                <div className='mt-1 mb-2' >{ renderCreditsInfo() }</div> */}
                                            </>
                                        )}
                                        
                                    </div>
                                    
                                    <div className="d-md-none">
                                        <Button color="primary" 
                                                size="sm"
                                                className="mx-2 my-2" 
                                                onClick={(e) => { showDemo(e) }} >Zobacz demo</Button>

                                        <Button color="primary" 
                                                size='sm' 
                                                className="mx-2 my-2" 
                                                onClick={ () => { setShowBuyCreditsModal(true) } }>Kup Punkty Wiedzy</Button>
                                        
                                        { planButton && (
                                            <>
                                            <Button 
                                                color="primary" 
                                                size="sm"
                                                className="mx-2 my-2" 
                                                onClick={ (e) => { setShowBuyPopup(true) } } >Wybierz Plan</Button>

                                                {/* <AsyncButton    
                                                    showSpinner={isLoading} 
                                                    color="primary" 
                                                    size="sm"
                                                    className="mx-2 my-2" 
                                                    onClick={buyLicense}> { getButtonBuyText() } </AsyncButton>

                                                <div className=' mt-1 mb-5'>{ renderCreditsInfo() }</div> */}
                                            </>
                                        )}                                         
                                    </div>
                                </div>
                            </>
                        )}
                    </Col>


                    <Col>
                        <ReactPlayer    height="100%"
                                        width="100%"
                                        volume={0.4} // Ustawienie głośności na 40%
                                        controls={true} // Opcjonalnie: dodaj kontrolki
                                        url="/eduimg/crop-edu-tablet.mp4"
                                        playing={ !isIOS2() } />
                    </Col>
                </Row>

            </> )}


            { benService.userData && !demoModeActive && ( <>

                <Row>
                    <Col md="7">
                        <h3>Jak skorzystać z asystenta w nauce</h3>
                        <div className="edutxt mb-2">
                        Asystent edukacyjny znajduje się na osobnej stronie przeznaczonej dla dzieci, 
                        która wymaga zalogowania się poprzez 9 cyfrowy kod pin. 

                        <h3 className='d-flex justify-content-center my-2'>Kod pin {getFormattedCode()}</h3>

                        Wybierz jeden z poniższych sposobów, który dla Ciebie jest najwygodniejszy:
                        </div>
                        <div>
                            <div className="py-1 mb-2">
                                <div className="edutxt">
                                    <i className="simple-icon-check pr-2 greencolor"></i> Prześlij dziecku, poniższy, bezpośredni link do strony:
                                    <div className='edu-url-txt'>
                                        <a target='_blank' href={getUrl(true)}>{ getUrl(true) }</a>
                                    </div>
                                </div>
                            </div>

                            <div className="edutxt mb-2">
                                <i className="simple-icon-check pr-2 greencolor"></i>
                                Lub, na telefonie dziecka, zeskanuj poniższy kod QR
                            </div>

                            <div className='edu-url-txt'>
                                    <QRCode size={300}
                                            className='my-2'
                                            style={{ height: "auto", maxWidth: "150px", width: "150px" }}
                                            value={ getUrl(true) }
                                            viewBox={`0 0 32 32`}  />
                                </div>

                            {/* <hr className="hredu" /> */}
                            <div className="py-1 mb-2">
                                <div className="edutxt">
                                <i className="simple-icon-check pr-2 greencolor"></i>
                                    Możesz też wejść na poniższą stronę i zalogować się wpisując 9 cyfrowy kod pin: {getFormattedCode()}
                                        <div className='edu-url-txt'>
                                            <a target='_blank' href={getUrl(false)}>{getUrl(false)}</a>
                                        </div>
                                </div>
                            </div>
                            {/* <hr className="hredu" /> */}
                            <div className="edutxt">
                                <i className="simple-icon-check pr-2 greencolor"></i>Albo udostępnij adres poprzez komunikator
                            </div>
                            <div className='share-app edu-url-txt mb-4' >
                                {shareLinks.map((link, index) => (
                                    <a  key={index}
                                        href={link.url(getUrl(true))}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className='share-link'
                                        style={{ backgroundColor: link.color }}
                                        onMouseOver={(e) => e.currentTarget.style.opacity = '0.8'}
                                        onMouseOut={(e) => e.currentTarget.style.opacity = '1'} >
                                            <ShareIcon />
                                            <span className='ml-2' >{link.name}</span>
                                    </a>
                                ))}
                            </div>
                            {/* <hr className="hredu" /> */}
                            {/* <div className="py-1">
                                <div className="edutxt">
                                    <i className="simple-icon-check pr-2 greencolor"></i> Na urządzeniu dziecka zeskanuj QR kod, który znajduje się poniżej.
                                </div>
                                <div className='edu-url-txt'>
                                    <QRCode size={300}
                                            className='my-2'
                                            style={{ height: "auto", maxWidth: "150px", width: "150px" }}
                                            value={ getUrl(true) }
                                            viewBox={`0 0 32 32`}  />
                                </div>
                            </div> */}
                        </div>
                    </Col>

                    <Col className='desktop-only' md="5" >
                        <ReactPlayer
                                height={500}
                                volume={0.5} // Ustawienie głośności na 50%
                                controls={true} // Opcjonalnie: dodaj kontrolki
                                url="/eduimg/edu-mobile.mp4"
                                playing={ !isIOS2() } />
                    </Col>
                </Row>

                <hr className="hredu" />

                { benAccount.currentProfile.profileId && (
                    <>
                    <Row className='mb-4'>
                        <Col md="6">
                            Kliknij w przycisk obok jeśli chcesz ponownie wygenerować nowy kod dostępu i unieważnić aktualny. 
                            To spowoduje unieważnienie wszystkich dotychczasowych sesji.
                        </Col>
                        <Col md="6" className='d-flex justify-content-center'>
                            <div>
                                <Button color="primary" size='lg' className="my-4" onClick={ () => { setShowHashModal(true) } }>Generuj nowy kod</Button>
                            </div>
                        </Col>
                    </Row>

                    <hr className="hredu" />
                    
                    <Row>
                        <Col md="6" >
                        { aiService.userData && aiService.userData.hash && (
                            <div className='credits-info'>
                                { getCreditsLeftDescription( Number(aiService.userData.tokens), intl ) }*
                                <p className='mt-4'>* Liczba zadań jest szacunkowa. Jest ona uzależniona od stopnia skomplikowania zadania.</p>
                            </div>
                        )}
                        </Col>
                        <Col md="6" className='d-flex justify-content-center'>
                            <div>
                                <Button color="primary" size='lg' className="my-4" onClick={ () => { setShowBuyCreditsModal(true) } }>Kup Punkty Wiedzy</Button>
                            </div>
                        </Col>
                    </Row>
                    </>
                )}

                </> )}

                { renderCharts() }

        </CardBody>
      </Card>
    </div>
  )
}

export default withBenNotification(injectIntl(withI18nProvider(withBenService(withBenAccount(withAiService(EducationPageSidebar))))))
