import React, { ChangeEvent, useRef, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider'
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider'
import { Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import Spinner from '../../components/Spinner'
import { useMountEffect } from '../../lib/useMountEffect'
import appLogo from '../../assets/media/logo.svg'
import { arrayBufferToBase64, dashString, getBuildConfigStr, getCookie2, getCreditsLeftDescription, getCurrentYear, getPathWithoutParam, getUrlParam, isKnownGPTModel, justDigits, setCookie } from '../../lib/utils'
import { AiSolution, AiHistory, AiHistoryItem, AiStatusCode, InjectedAiServiceProps, withAiService, AiActionCode, AiStats, AiUserData } from '../../providers/aiServiceProvider'
import SubjectButton, { EduSubjects, getEduSubjectsFromStr, getSubjectName } from './SubjectButton'
import { MathJax } from 'better-react-mathjax'
import AsyncButton from '../../components/AsyncButton'
import { InjectedBenServiceProps, PlansItem, withBenService } from '../../providers/benServiceProvider'
import EducationCreditsPopup from '../panel/edu/EducationCreditsPopup'
import ImageCropPopup from './ImageCropPopup'
import DemoModePopup from './DemoModePopup'
import EducationCharts from '../panel/edu/EducationCharts'
import ActivationCodePopup from './ActivationCodePopup'
import MiniRodo from '../../components/MiniRodo'
import EduLoginPopup from './EduLoginPopup'

import '../../assets/css/sass/edu.scss'


// https://www.npmjs.com/package/react-qr-code
// npm install react-qr-code

// To dziala:
// npm install better-react-mathjax
// https://www.npmjs.com/package/better-react-mathjax

// To nie dziala...
// https://www.npmjs.com/package/mathjax-react?activeTab=readme
// npm install --save mathjax-react
// npm install --save mathjax-full


// https://www.npmjs.com/package/react-syntax-highlighter
// npm i @babel/core
// npm i react-syntax-highlighter
//
//
// npm install react-syntax-highlighter --save


type Props = InjectedBenNotificationProps
  & InjectedAiServiceProps
  & InjectedBenServiceProps
  & InjectedBenAccountProps
  & InjectedIntlProps
  & RouteComponentProps

type State =  {
    demoMode: boolean
    currentProfileId: number
}

const PageAssistant: React.FC<Props> = ({
    benNotification,
    benAccount,
    benService,
    aiService,
    intl,
    location
}) => {

    const TOKENS_THRESHOLD  = 15*1000
    const DEMO_HASH         = 'demo'
    const INITIAL_PROFILE_ID = -1
    const DEMO_PROFILE_ID    = 0

    const state = React.useRef<State>( {demoMode:false, currentProfileId:INITIAL_PROFILE_ID} );
    

    const [isProcessing, setIsProcessing] = useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [isLoading2, setLoading2] = React.useState(false)
    const [topups, setTopups] = React.useState<PlansItem[]>([])
    const [showBuyCreditsModal, setShowBuyCreditsModal] = React.useState(false)    
    const [selectedSubject, setSelectedSubject] = useState(EduSubjects.Math)
    const [codeValue, setCodeValue] = useState('')

    const [showCharts, setShowCharts] = useState(false)
    const [stats, setStats] = React.useState<AiStats | null>(null)

    const [demoModePopup, setDemoModePopup] = useState( false )
    const [pinCodePopup, setPinCodePopup] = useState( false )
    
    const [loginPopup, setLoginPopup] = useState( false )

    
    const [currentProfileId, setCurrentProfileId] = useState(INITIAL_PROFILE_ID)
    const [currentName, setCurrentName] = useState('')
    // const [currentEmail, setCurrentEmail] = useState('')

    const [lastSolution, setLastSolution] = useState<AiSolution>( new AiSolution() )
    const [history, setHistory] = useState<AiHistory|null>( null ) 
    const [historyForcedItem, setHistoryForcedItem] = useState<AiHistoryItem|null>( null ) 
    
    const [historyHasMore, setHistoryHasMore] = useState( false ) 

    // dziala tylko na cercie
    const [gptModel, setGptModel] = useState('')

    const inputFileRef = useRef<HTMLInputElement>(null)
    const [imageCropPopupVisible, setImageCropPopupVisible ] = useState( false )    

    const [selectedImgContent, setSelectedImgContent] =  useState('')

    const [imgData, setImgData] = useState<string>('')
    const [imgThumbData, setImgThumbData] = useState<string>('')    

    const divAnswerRef = useRef<HTMLDivElement>(null)
    
    const [questionTextState, setQuestionTextState] = useState('')
    const [imgQuestionTextState, setImgQuestionTextState] = useState('')

    const inputQuestionRef    = useRef<HTMLInputElement>(null)
    const inputImgQuestionRef = useRef<HTMLInputElement>(null)


    const chartsRef = React.useRef<HTMLDivElement>(null)
    
    

    function getInputText(obj : React.RefObject<HTMLInputElement> ) : string
    {
        if( !obj.current )
            return ""

        return obj.current.value
    }

    function setInputText(obj : React.RefObject<HTMLInputElement>, val : string )
    {
        if( !obj.current )
            return

        obj.current.value = val
    }


    const setQuestionText = (val : string) => { setInputText(inputQuestionRef, val ); setQuestionTextState(val) }
    const setImgQuestionText = (val : string) => { setInputText(inputImgQuestionRef, val ); setImgQuestionTextState(val) }

    const getQuestionText = () => { return getInputText(inputQuestionRef ) }
    const getImgQuestionText = () => { return getInputText(inputImgQuestionRef ) }


    function shouldShow(): boolean {
        
        return window.location.hostname.toLowerCase().indexOf('beniamin') >= 0 ||
               window.location.hostname === 'localhost' ||
               window.location.hostname.startsWith('192.168.')
    }


    function getModelColor() : string
    {
        if( !gptModel )
            return "#FFF"

        if( isKnownGPTModel(gptModel) )
            return "#CFC"

        return "#FCC"
    }


    function getCookieHash() : string
    {
        let hash : string = ''

        if(state.current.demoMode) {
            hash = DEMO_HASH
        } else {
            hash = getCookie2('hash')
        }

        // console.log('getCookieHash() ', hash)

        return hash
    }


    function doLogin()
    {
        setLoginPopup(true)
    }


    function doLogout()
    {
        aiService.logoff()
            .then( () => {})
            .catch( () => {} )
            .finally( () => {
                setTopups( [] )
                setHistory( null )
                setQuestionText('')
                setImgQuestionText('')
                setSelectedImgContent('')
                setImgData('')
                setImgThumbData('')
                setSelectedSubject( EduSubjects.None )

                setCodeValue('')
                setLastSolution( new AiSolution() )

                setCurrentProfileId(INITIAL_PROFILE_ID)
                state.current.currentProfileId = INITIAL_PROFILE_ID

                setCurrentName('')
                // setCurrentEmail('')

                const hash = getUrlParam('hash')
                if( hash )
                {
                    let path = getPathWithoutParam('hash')
                    window.history.replaceState(null, '', path)
                }
        
                const hash2 = getCookie2('hash')
                if( hash2 )
                {
                    setCookie('hash', '', 0)
                }
                
                setShowCharts( false )
                setStats(null)
            })        
    }


    function onLoginPopupClose( data : AiUserData | null, showPinCodeHint:boolean )
    {
        setLoginPopup(false)

        if( showPinCodeHint ) {
            setPinCodePopup(true)
            return
        }

        if(data)
        {
            onLoginSuccess(data)
        }
    }


    function onLoginSuccess( data : AiUserData )
    {
        benNotification.notify({
            type: 'success',
            title: 'Logowanie',
            message: 'Zostałeś poprawnie zalogowany'
        })

        state.current.demoMode = false

        setCookie('hash', data.hash, 365)
        setCookie('logged', '1', 3650)

        setCurrentProfileId(data.profileId)
        state.current.currentProfileId = data.profileId

        setCurrentName(data.name)
        // setCurrentEmail(data.email)

        setCodeValue('')

        setHistoryHasMore(false)

        setSelectedSubject(EduSubjects.Math)
    }


    function onActivateCode()
    {
        const hash = justDigits(codeValue)

        if( hash.length < 8 || hash.length > 15 )
        {
            benNotification.notify({
                type: 'error',
                title: "Błąd",
                message: "Podany kod nie jest poprawny"
            })

            return
        }

        setLoading2(true)        
        
        aiService.logged( hash, -1, 0 )
            .then((userData) => {
                onLoginSuccess(userData.data)
            })
            .catch(() => {
                benNotification.notify({
                    type: 'error',
                    title: "Błąd",
                    message: "Podany kod nie jest poprawny"
                })
            })
            .finally( () => {
                setLoading2(false)
            })
    }


    function formatResponse2(response:string) : JSX.Element
    {
        if( !response )
            return <React.Fragment></React.Fragment>


        // ```python
        // print("Hello, World!")
        // ``` 
        //
        // SyntaxHighlighter 
        

        let formatted : JSX.Element[] = []
        let key1=1
        
        let content = ''

        let parenesis = 0

        response.split('\n').forEach(line => {

            line = line.trim()

            content += line

            if( line === '\\[')
                parenesis++;

            if( line === '\\]')
                parenesis--;

            if( parenesis > 0)
                return

            formatted.push(<div key={key1++}> {content} </div> )

            content = ''
        }); 

        formatted.push(<div key={key1++}> {content} </div> )

        return <MathJax>{formatted}</MathJax>

        /*
            https://stackoverflow.com/questions/70706179/is-this-a-right-code-to-disable-mathjax-right-clicking-menu
            <script>
                MathJax = {
                    ...
                    options: {
                        enableMenu: false
                        ...
                    }
                }
            </script>
        */
    }


    function loadPlans()
    {
        if( topups.length < 1 )
        {
            setLoading(true)
            const hash = getCookie2('hash')
            aiService.getPlans( hash, intl.locale )
                .then( (result) => { 
                    if( result.data.topups && result.data.topups.length > 0 )
                    {
                        setTopups(result.data.topups)
                        setShowBuyCreditsModal(true)
                    }
                } )
                .catch( () => {} )
                .finally( () => {setLoading(false)} )
        }
        else
        {
            setShowBuyCreditsModal(true)
        }
    }


    function loadHistory( subject : string, append : boolean )
    {
        const bulkLen = 30

        let hash = getCookieHash()
        setHistoryHasMore( false )

        if( !hash )
        {
            setHistory( null )
            return
        }

        const from = history && append ? history.items.length : 0

        aiService.history( hash, state.current.currentProfileId, subject, from, bulkLen+1 )
            .then( result => {

                if( result.status === AiStatusCode.STATUS_OK ) {

                    if( result.data.items.length > bulkLen )
                    {
                        setHistoryHasMore( true )
                        result.data.items.pop()
                    }

                    if( append )
                    {
                        const newHistory : AiHistory = { items:[] }

                        if( history )
                            newHistory.items = newHistory.items.concat( history.items )

                        if( result.data )
                            newHistory.items = newHistory.items.concat( result.data.items )

                        setHistory( newHistory )
                    }
                    else
                    {
                        setHistory( result.data )                        

                        if( state.current.demoMode )
                        {
                            if( result.data.items.length > 0 )
                                forceQuestion( result.data.items[0], false )
                        }
                    }
                }
            })
            .catch( () => {
                setHistory( null )
            })
    }


    function renderHistory() : JSX.Element
    {
        let formatted : JSX.Element[] = []
        let maxElems = 50
        let key = 1

        if( history )
        {
            history.items.forEach(element => {

                if( maxElems-- < 0 )
                    return

                formatted.push (
                    <div key={key++} className="list-item" onClick={ () => { forceQuestion( element, true ) } }>
                        <div className="list-item-color"></div>
                        { element.question && (
                            <span className="container-last-questions">{element.question}</span>
                        )}
                        <br/>
                        { element.questionImgThumb && (
                            <img src={element.questionImgThumb} alt='' className='container-last-questions-thumb-img' />
                        ) }
                    </div>
                )
            });
        }

        return <React.Fragment>{formatted}</React.Fragment>;
    }


    function addHistoryIfNew(question: string,
                             imgThumbData: string,
                             questionHash: string)
    {
        let newItem = true
        let hist : AiHistory = history !== null ? history : { items:[] }

        for( let i = 0; i<hist.items.length; i++)
        {
            if( hist.items[i].questionHash === questionHash )
            {
                newItem = false
                break
            }
        }

        if( newItem )
        {
            hist.items.unshift( { 
                question:question, 
                answer:'', 
                created:Date.now()/1000, 
                lang:'', 
                subject:'', 
                profileId:0, 
                questionImg: '',
                questionImgThumb: imgThumbData,
                questionHash: questionHash } )
        }

        setHistory(hist)
    }


    function submitQuestion()
    {
        if( isProcessing )
            return

        if( imgData )
        {
            submitQuestionImg( getImgQuestionText(), imgData, imgThumbData, '' )
        }
        else
        {
            submitQuestionText( getQuestionText(), '' )
        }
    }


    function forceQuestion( historyItem: AiHistoryItem, submitQuestion : boolean )
    {
        if( isProcessing )
            return

        if( historyItem.questionImgThumb )
        {
            const hash = getCookieHash()

            setIsProcessing(true)

            aiService.historyItem( hash, historyItem.questionHash )
                .then( (r) => {

                    if(r.status !== AiStatusCode.STATUS_OK)
                    {
                        // console.log("******** ! r.status **********")
                    }
                    else
                    {
                        setQuestionText( '')
                        setImgQuestionText( r.data.question )
                        setImgThumbData(r.data.questionImgThumb)
                        setImgData( r.data.questionImg )
                        setHistoryForcedItem(historyItem)

                        if( submitQuestion ) {
                            submitQuestionImg( '', '', '', historyItem.questionHash )
                        }
                    }
                })
                .catch( (e) => {
                    console.log(e)
                })
                .finally( () => {
                    setIsProcessing(false)
                })            
        }
        else
        {
            setQuestionText(historyItem.question)

            setImgData('')
            setImgThumbData('')
            setImgQuestionText('')
            setHistoryForcedItem(historyItem)

            if( submitQuestion ) {
                submitQuestionText('', historyItem.questionHash)
            }
        }
    }


    function submitQuestionImg( imgQuestionText:string, imgData: string, imgThumbData:string, questionHash: string)
    {
        
        if( !questionHash && !imgData ) {
            benNotification.notify( { message: 'There is no image' }) 
            return
        }

        setIsProcessing( true )
        setLastSolution( new AiSolution() )

        const hash = getCookieHash()

        aiService.solve(hash, 
                        questionHash ? '' : imgQuestionText, 
                        questionHash ? '' : imgData, 
                        questionHash ? '' : imgThumbData, 
                        questionHash, 
                        getSubjectName(selectedSubject, true), 
                        gptModel )

            .then( result => {
                if( result.status === AiStatusCode.STATUS_OK )
                {
                    addHistoryIfNew( imgQuestionText, imgThumbData, result.data.questionHash )

                    setLastSolution( result.data )
                    setSelectedSubject( getEduSubjectsFromStr(result.data.subject) )

                    divAnswerRef.current?.scrollIntoView({ behavior: "smooth" }) 
                }
            } )
            .catch( (r) => {
                if( r.status === AiStatusCode.STATUS_ERROR && r.code === AiActionCode.CODE_INSUFFICIENT_TOKENS ) {
                    benNotification.notify( { message: "Nie masz wystarczajco dużo tokenów aby rozwiązać zadanie, dokup tokeny" } )
                }

                if( r.status === AiStatusCode.STATUS_LOGIN ) {
                    benNotification.notify( { message: "Nie jestes zalogowany" } )
                    setHistory(null)
                }
            } )
            .finally( () => { setIsProcessing(false)} )        
    }


    function submitQuestionText(question: string, questionHash: string )
    {
        setIsProcessing( true )
        setLastSolution( new AiSolution() )

        const hash = getCookieHash()

        aiService.solve(hash, 
                        questionHash ? '' : question, 
                        questionHash ? '' : '', 
                        questionHash ? '' : '', 
                        questionHash, 
                        getSubjectName(selectedSubject, true), 
                        gptModel )

            .then( result => {
                if( result.status === AiStatusCode.STATUS_OK )
                {
                    addHistoryIfNew(question, '', result.data.questionHash)

                    setLastSolution( result.data )
                    setSelectedSubject( getEduSubjectsFromStr(result.data.subject) )

                    divAnswerRef.current?.scrollIntoView({ behavior: "smooth" }) 
                }
            } )
            .catch( (r) => {
                if( r.status === AiStatusCode.STATUS_ERROR && r.code === AiActionCode.CODE_INSUFFICIENT_TOKENS ) {
                    benNotification.notify( { message: "Nie masz wystarczajco dużo tokenów aby rozwiązać zadanie, dokup tokeny" } )
                }

                if( r.status === AiStatusCode.STATUS_LOGIN ) {
                    benNotification.notify( { message: "Nie jesteś zalogowany" } )
                    setHistory(null)
                }
            } )
            .finally( () => { setIsProcessing(false)} )
    }


    function onSubjectButtonClicked(subject:EduSubjects)
    {
        setSelectedSubject(subject)
    }

    React.useEffect(() => {
        setCodeValue( dashString(codeValue,3) )
    }, [codeValue])

    function onToggleTokensModal()
    {
        setShowBuyCreditsModal(!showBuyCreditsModal)
    }

    function onToggleCropPopup()
    {
        setImageCropPopupVisible( !imageCropPopupVisible )
    }

    function onAreaSelectedPopup( imgData: string, imgThumbData: string )
    {
        setSelectedImgContent('')

        setQuestionText('')

        setImgData(imgData)
        setImgThumbData(imgThumbData)
        setImgQuestionText('')

        //submitQuestionImg('', imgData, imgThumbData, '')
        setImageCropPopupVisible(false)
    }


    function handleClickOnDemo() : boolean
    {
        if( state.current.demoMode )
        {
            benNotification.notify( { message: "Jest włączony tryb demo, w trybie można tylko przeglądać przygotowane pytania. Zaloguj się aby móc wpisać własne zadania." } )
            return true
        }

        return false
    }

    function onClickedNextQuestion( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) : boolean
    {
        if( history )
        {
            let foundIdx = -1

            for( let i = 0; i < history.items.length; i++ )
            {
                if( history.items[i] === historyForcedItem )
                {
                    foundIdx = i+1
                    break
                }
            }

            if( foundIdx >= history.items.length )
                foundIdx = history.items.length-1;

            if( foundIdx > 0 )
                forceQuestion(history.items[foundIdx], true)
        }

        e.preventDefault()
        e.stopPropagation()
        return false
    }

    // http://localhost:3000/asystent?hash=48237914
    function chandleSelectFile(e: ChangeEvent<HTMLInputElement>)
    {
        let file = e.target.files ? e.target.files[0] : null;
        if (file) 
        {
            file.arrayBuffer()
                .then( (buff) => {
                    arrayBufferToBase64(buff)
                        .then( (r) => {
                            setSelectedImgContent( r )
                            setImageCropPopupVisible(true)
                        })
                        .catch( (e) => {} )

                })
                .catch( (e) => {
                    //
                })
        }
    }


    function takeAPhoto()
    {
        if( handleClickOnDemo() )
            return

        if( inputFileRef.current )
            inputFileRef.current.click()
    }

    function cancelPhotoMode() 
    {
        setImgData('')
        setImgThumbData('')
        setSelectedImgContent('')

        setQuestionText('')
        setImgQuestionText('')
    }

    function handleHistoryScroll(e : React.UIEvent<HTMLDivElement>)
    {
        const div = e.currentTarget
        
        if( div.scrollTop + div.clientHeight === div.scrollHeight )
        {
            if( historyHasMore )
            {
                loadHistory( selectedSubject, true )
            }
        }
    }

    function showActivationCodeHint()
    {
        setPinCodePopup(true) 
    }

    function toggleShowCharts()
    {
        const show = !showCharts
        setShowCharts(show)

        if( show ) {
            setTimeout( () => {
                chartsRef.current?.scrollIntoView({ behavior: "smooth", block: 'center', inline: 'center' }) 
            }, 100 )
        }
    }

    function loadStats()
    {
        let hash = getCookieHash()

        // console.log( "loadStats() hash="+hash )

        if( !hash )
        {
            setStats(null)
            return
        }

        aiService.stats( hash, state.current.currentProfileId, 30, 0, "subjects, questions, time" )
            .then( (r) => setStats(r.data) )
            .catch( () => setStats(null) ) 
    }


    React.useEffect(() => {

        if( showCharts )
            loadStats()

    }, [showCharts, currentProfileId] )  // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {

        loadHistory(selectedSubject, false)

    }, [selectedSubject, currentProfileId] )  // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect( () => {

        aiService.updateContextSubject( selectedSubject )

    }, [aiService, selectedSubject] )

    
    React.useEffect( () => {

        if( benAccount.settings.cookieJustClicked ) {
            setDemoModePopup(false)
        }

    }, [benAccount.settings])


    useMountEffect(() => 
    {
        let hash : string = ''

        const phash = getUrlParam('hash')
        const chash = getCookie2('hash')

        if( phash )
            hash = phash
        else if (chash)
            hash = chash

        if( hash.length > 2 || getCookie2('logged') )
        {
            state.current.demoMode = false
        }
        else
        {
            state.current.demoMode = true
            setDemoModePopup( ! benAccount.settings.cookieJustClicked )
            setShowCharts( true )

            setCurrentProfileId(DEMO_PROFILE_ID)
            state.current.currentProfileId = DEMO_PROFILE_ID
        }

        if( state.current.demoMode || hash.length < 2 )
            return

        aiService.logged(hash, 0, 0)
            .then( (userData) => { 

                onLoginSuccess( userData.data )

                // setCookie('hash', userData.data.hash, 365)
                // setCookie('logged', '1', 3650)

                // setCurrentProfileId(userData.data.profileId)
                // state.current.currentProfileId = userData.data.profileId

                // setCurrentName(userData.data.name)
                // setCurrentEmail(userData.data.email)

                if( phash )
                {
                    const path = getPathWithoutParam('hash')
                    window.history.replaceState(null, '', path)
                }
            } )
            .catch( (e) => {
                let status : string = e.status !== undefined ? e.status : ""

                if( status === AiStatusCode.STATUS_LOGIN ) {
                    setCookie('hash', '', 0)

                    setCurrentProfileId(INITIAL_PROFILE_ID)
                    state.current.currentProfileId = INITIAL_PROFILE_ID

                    setCurrentName('')
                    // setCurrentEmail('')                    
                } else {
                    benNotification.notify( { message: "Błąd połączenie z serwerem" } )
                }
            } )
    })


    return (
        <>

        <EducationCreditsPopup
            useAiService={true}
            topups={topups}
            isOpen={showBuyCreditsModal}
            onToggle={onToggleTokensModal} />

        <ImageCropPopup
            src={selectedImgContent}
            isOpen={imageCropPopupVisible}
            onToggle={onToggleCropPopup} 
            onAreaSelected={onAreaSelectedPopup}/>

        <DemoModePopup
            isOpen={demoModePopup}
            onToggle={ () => { setDemoModePopup(!demoModePopup) } } />

        <ActivationCodePopup
            isOpen={pinCodePopup}
            onToggle={ () => { setPinCodePopup(!pinCodePopup) } } />


        <EduLoginPopup
            isOpen={loginPopup}
            onToggle={ (data, showPinCodeHint) => { onLoginPopupClose(data, showPinCodeHint)} } />

        { shouldShow() && (
        <div className="container" style={{paddingTop:'5px'}}>
            <div className="edu-header" style={{marginLeft:'5px', marginRight:'5px'}}>
                <div className="edu-logo">
                    <a href={getBuildConfigStr('MAIN_URL')}><img src={appLogo} alt={getBuildConfigStr('MAME')} className="edu-logo"/></a>
                </div>
                <div className="edu-description">
                    {/* Rozwiążemy zadania, sprawdziany. Nauczymy! */}

                    { !aiService.userData  && (
                        <Button size="xs" color='primary' className='ml-2' onClick={doLogin}>Zaloguj</Button>
                    )}

                    { aiService.userData  && (
                        <Button size="xs" color='light' className='ml-2' onClick={doLogout}>Wyloguj</Button>
                    )}
                </div>
            </div>

            <div className="edu-separator"/>
            
            <div className="edu-header">
               <div className='m-2'>
                    {/* <LanguageDropdown/>*/}
                </div>

                { aiService.userData && (
                    <div className="edu-description">
                        { getCreditsLeftDescription( aiService.userData ? aiService.userData.tokens : 0, intl ) }
                        { aiService.userData.tokens < TOKENS_THRESHOLD && (
                            <AsyncButton showSpinner={isLoading} color='primary' size='xs' onClick={loadPlans} className='ml-2'>Doładuj</AsyncButton>
                        )}
                    </div>
                )}
            </div>

            { !aiService.userData && (
            <div className="edu-card ">
                <Row>
                    <Col md="6">
                    <div className=''>
                        <h4>Masz kod pin? Wpisz go tutaj</h4>
                        <InputGroup size='sm'>
                            <InputGroupText>Kod pin</InputGroupText>
                            <Input  valid={codeValue.length>=10} 
                                    value={codeValue} 
                                    style={{maxWidth:"200px"}}
                                    onChange={ (e) => setCodeValue(e.target.value) } 
                                    onKeyPress={ (e) => {if(e.key==='Enter')onActivateCode()} } />
                            <InputGroupAddon addonType="append">
                                <AsyncButton showSpinner={isLoading2} disabled={codeValue.length<10} color='primary' onClick={onActivateCode}>Zaloguj</AsyncButton>
                            </InputGroupAddon>
                        </InputGroup>
                        <div className='mt-1 edu-link-style' onClick={showActivationCodeHint}> Gdzie znajdę kod pin </div>
                        </div> 
                    </Col>
                    <Col md="6" >
                        <div className="edu-activate-right congratulations-content mb-4">
                            <h4>Nie jestes zalogowany</h4>
                            <div>9 cyfrowy kod pin jest dostępy w panelu rodzcia.<br/>Jesli nie posiadasz konta rodzica <a href="/register" className="edu-link-style">Zarejestruj się</a></div>
                        </div>                        
                    </Col>
                </Row>
                
            </div>
            )}

            { !state.current.demoMode && (
                <div className="edu-card congratulations">
                    <div className="congratulations-content">
                        <h2>Nauka bez granic <span role='img' aria-label='Rocket'>🚀🚀</span> </h2>
                        <div>
                            { currentName && (
                                <>
                                    Witaj {currentName}!&nbsp;
                                </>
                            )}
                            Jesteśmy tu, aby pomóc Ci rozwiązać dowolne zadanie. Wpisz treść lub zrób zdjęcie, a my zajmiemy się resztą. Wyjaśnimy i krok po kroku poprowadzimy Cię do rozwiązania.
                        </div>
                    </div>
                </div>
            )}

            { state.current.demoMode && (
                <div className="edu-card congratulations">
                    <div className="congratulations-content">
                        {/* <h2>Nauka bez granic 🚀 </h2>
                        <div>Wpisz lub zrób zdjęcie dowolnego zadania z wybranego przedmiotu. Rozwiążemy i wytłumaczymy krok po kroku. Dodatkowo na wykresach sprawdzisz postęp w nauce.</div> */}
                        <h2>Tryb demo aktywny</h2>
                        <div>Na przygotowanych przykładach zobacz jak dziala "Pomoc w nauce", wybierz z listy przykładowe zadania wraz z rozwiązaniami krok po kroku.</div>
                    </div>
                </div>
            )}

            { ( state.current.demoMode || aiService.userData ) && (
                <>
                    <div className="subjects-container">
                        <div className="tabs-container">
                            <SubjectButton active={selectedSubject===EduSubjects.Math}          subjectType={EduSubjects.Math}         onClicked={onSubjectButtonClicked}/>
                            <SubjectButton active={selectedSubject===EduSubjects.Physics}       subjectType={EduSubjects.Physics}      onClicked={onSubjectButtonClicked}/>
                            <SubjectButton active={selectedSubject===EduSubjects.Chemistry}     subjectType={EduSubjects.Chemistry}    onClicked={onSubjectButtonClicked}/>
                            <SubjectButton active={selectedSubject===EduSubjects.English}       subjectType={EduSubjects.English}      onClicked={onSubjectButtonClicked}/>
                            <SubjectButton active={selectedSubject===EduSubjects.Programming}   subjectType={EduSubjects.Programming}  onClicked={onSubjectButtonClicked}/>
                            <SubjectButton active={selectedSubject===EduSubjects.Other}         subjectType={EduSubjects.Other}        onClicked={onSubjectButtonClicked}/>
                        </div>
                    </div>

                    {/* capture='environment' */}
                    <input type='file' ref={inputFileRef}  accept='image/*'  style={{display: "none"}}  onChange={ (e) => chandleSelectFile(e) }/> 
                    
                    <div className="form-list-section" ref={divAnswerRef}> 
                        <div className="edu-card form-container">

                            <Row>
                                <Col>
                                    <div >
                                        <h2>Zadaj pytanie</h2>    
                                    </div>
                                </Col>
                                <Col >
                                    { (!state.current.demoMode || true ) && (
                                        <div className='float-right mb-2'>
                                            <Button color='info' size="xs" className='desktop-only-block' disabled={isProcessing} onClick={ () => takeAPhoto() }>
                                                <div style={{ display: 'flex', alignItems:'center' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="5 5 35 40" style={{width: '20px' }} >
                                                        <rect x="4" y="10" width="32" height="24" rx="2" ry="2" fill="#ffffff"/>
                                                        <circle cx="20" cy="22" r="8" fill="#666"/>
                                                        <circle cx="20" cy="22" r="6" fill="#999"/>
                                                        <rect x="12" y="8" width="16" height="4" rx="2" ry="2" fill="#ffffff"/>
                                                    </svg>
                                                    <span className='ml-2'>Wczytaj zdjęcie zadania</span>
                                                </div>
                                            </Button>

                                            <Button color='info' size="xs" className='mobile-only' disabled={isProcessing} onClick={ () => takeAPhoto() }>
                                                <div style={{ display: 'flex', alignItems:'center' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="5 5 35 40" style={{width: '20px' }} >
                                                        <rect x="4" y="10" width="32" height="24" rx="2" ry="2" fill="#ffffff"/>
                                                        <circle cx="20" cy="22" r="8" fill="#666"/>
                                                        <circle cx="20" cy="22" r="6" fill="#999"/>
                                                        <rect x="12" y="8" width="16" height="4" rx="2" ry="2" fill="#ffffff"/>
                                                    </svg>
                                                    <span className='ml-2'>Zrób zdjęcie zadania</span>
                                                </div>
                                            </Button>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            
                            <div className="form-group">
                            { !imgData && (
                                <Input  innerRef={inputQuestionRef}
                                        className="edu-input-question"
                                        // disabled={isProcessing || demoMode}
                                        readOnly={isProcessing || state.current.demoMode}
                                        type="textarea"
                                        defaultValue={questionTextState}
                                        placeholder={"Wpisz tutaj swoje pytanie"} 
                                        onMouseDown={ () => handleClickOnDemo() }
                                        />
                            )}
                            
                            { imgData && (
                                <>
                                    <div className='flex-center edu-input-image' onClick={ () => takeAPhoto() }>
                                        <img src={imgData} className={'edu-cropper-img'} alt=""/>
                                    </div>
                                    <Input  innerRef={inputImgQuestionRef}
                                            className="edu-input-image-question"
                                            // disabled={isProcessing || demoMode}
                                            readOnly={isProcessing || state.current.demoMode}
                                            type="textarea"
                                            defaultValue={imgQuestionTextState}
                                            placeholder={"Wpisz dodatkowe sugestie dla asystenta np. rozwiąż tylko punkt a, pomiń kolejne zadania itp."} 
                                            onMouseDown={ () => handleClickOnDemo() } 
                                            />
                                </>
                            )}
                            </div>

                            <div className='flex-center'>
                                <AsyncButton    color="primary"
                                                size="lg"
                                                disabled={isProcessing}
                                                showSpinner={isProcessing}
                                                onClick={() => submitQuestion()}
                                                > Rozwiąż zadanie </AsyncButton>
                                
                                { !state.current.demoMode && imgData && (
                                    <AsyncButton    //color={isProcessing ? 'primary'  : 'light'}
                                                    className='ml-1'
                                                    color='light'
                                                    size="lg"  
                                                    disabled={isProcessing}
                                                    showSpinner={isProcessing}
                                                    onClick={cancelPhotoMode} > Włącz tryb tekstowy </AsyncButton>
                                )}

                                { state.current.demoMode && (
                                    <span className='ml-4'>
                                        <a href='#'  onClick={ (e)=> { onClickedNextQuestion(e) }}>Następne zadanie</a>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="edu-card list-container desktop-only-block" onScroll={handleHistoryScroll} >
                            <h2>
                                { state.current.demoMode ? "Przykładowe pytania" : "Ostatnie pytania" }
                            </h2>
                            { renderHistory() }
                        </div>
                    </div>

                    <div className="edu-card">
                        <div className="congratulations-content">

                            {/* Nazwa modelu: <input style={{ backgroundColor: getModelColor() }} onChange={ (e) => setGptModel(e.target.value) }></input> 
                            <i>Testowe, dziala tylko na cert, tło powinno być białe albo zielone, puste pole to domyślny model, zapytania z cache nie zmieniają modelu </i> 

                            <table><tbody>
                            <tr><td style={{whiteSpace:"nowrap", textAlign:"right", paddingRight:"15px"}}>aiSubject         </td><td style={{width:"100%"}}> '{ lastSolution.subject }'                            </td></tr>
                            <tr><td style={{whiteSpace:"nowrap", textAlign:"right", paddingRight:"15px"}}>Tokens            </td><td style={{width:"100%"}}> { lastSolution.diff } czyli ile to kosztowalo tokenow </td></tr>
                            <tr><td style={{whiteSpace:"nowrap", textAlign:"right", paddingRight:"15px"}}>Prompt            </td><td style={{width:"100%"}}> { lastSolution.diffP }                                </td></tr>
                            <tr><td style={{whiteSpace:"nowrap", textAlign:"right", paddingRight:"15px"}}>Completion        </td><td style={{width:"100%"}}> { lastSolution.diffC }                                </td></tr>
                            <tr><td style={{whiteSpace:"nowrap", textAlign:"right", paddingRight:"15px"}}>Model Reqested    </td><td style={{width:"100%"}}> '{ lastSolution.modelRq }'                            </td></tr>
                            <tr><td style={{whiteSpace:"nowrap", textAlign:"right", paddingRight:"15px"}}>Model Response    </td><td style={{width:"100%"}}> '{ lastSolution.modelRs }'                            </td></tr>
                            <tr><td style={{whiteSpace:"nowrap", textAlign:"right", paddingRight:"15px"}}>aiStatus          </td><td style={{width:"100%"}}> { lastSolution.status } ( 1-chat, 2-cache )           </td></tr>
                            </tbody></table> */}

                            <h2>Rozwiązanie krok po kroku</h2>
                            <div className="answ" >
                                { formatResponse2(lastSolution.solution) }
                                { isProcessing && <Spinner/> }
                            </div>
                        </div>
                    </div>

                    <div className="edu-card list-container mobile-only">
                        <h2>
                            { state.current.demoMode ? "Przykładowe pytania" : "Ostatnie pytania" }
                        </h2>
                        { renderHistory() }
                    </div>

                    <div className='edu-card my-4'>
                        <h2 className={ showCharts ? 'show-charts-button iconsminds-arrow-up-2' : 'show-charts-button iconsminds-arrow-down-2'} 
                            onClick={toggleShowCharts}> <span className='edu-link-style2'>Postępy w nauce</span></h2>

                        <div className={ showCharts ? 'show-charts-container show-charts-container-on' : 'show-charts-container show-charts-container-off'}>

                            { state.current.demoMode && (
                                <h3 className='my-4'>Tryb demo - przykładowe dane</h3>
                            ) }

                            <div ref={chartsRef}/>
                            <EducationCharts aiStats={stats}/>
                        </div>
                    </div>
                </>
            ) }

        <MiniRodo inline={true} />

        <footer>
            <div className="edu-separator"></div>
            <div className='edu-description-container m-2' >
                <div className="edu-description leftedu">
                    &copy; { getCurrentYear() } { getBuildConfigStr("NAME") }.
                </div>
                <div className="edu-description rightedu">
                    <a href={ getBuildConfigStr("PRIVACY_URL_EDU") }     target='_blank' className="edu-link-privacy">Polityka prywatności</a> | 
                    <a href={ getBuildConfigStr("REGULATIONS_URL_EDU") } target='_blank' className="edu-link-privacy">Regulamin</a>
                </div>
            </div>
        </footer>
    </div>
    )}
    </>
    )
}

export default withBenNotification(withBenAccount(withAiService(withBenService(withRouter(injectIntl(PageAssistant))))))
