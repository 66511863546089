import React from 'react'
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom'
import RestrictedRoute from './components/RestrictedRoute'
import { BenServiceProvider, BenServiceContext } from './providers/benServiceProvider'
import { AiServiceProvider } from './providers/aiServiceProvider'
import { I18nProvider } from './providers/i18nProvider'
import BenNotificationProvider from './providers/notificationProvider'
import BenAccountProvider from './providers/benAccountProvider'

import AccountPage from './routes/account'
import EmailConfirmPage from './routes/EmailConfirmPage'
import ForgotPassword from './routes/ForgotPassword'
import LoginPage from './routes/LoginPage'
import NotFoundPage from './routes/NotFoundPage'
import PanelPage from './routes/PanelPage'
import RegisterPage from './routes/RegisterPage'
import { BenHelperProvider } from './providers/benHelperProvider'
import RewardPage from './routes/RewardPage'
import PromoCode from './routes/PromoCode'
import UpdatePassword from './routes/UpdatePassword'
import PageClasses from './routes/edu/PageClasses'
import PageCheck from './routes/edu/PageCheck'
import PageAssistant from './routes/edu/PageAssistant'
import PageTesting from './routes/edu/PageTesting'
import { MathJaxContext } from 'better-react-mathjax'


const config = {
  options: 
  {
    enableMenu: false
  }
}

// TODO: Move App component to React.FC
class App extends React.Component {

  render () {
    return (
      <React.Fragment>
        <BenHelperProvider>
          <BenNotificationProvider>
            <I18nProvider>

                  

                    <MathJaxContext config={config}>

                          <BenServiceProvider>
                            <BenServiceContext.Consumer>
                              {benService => (
                                <BenAccountProvider benService={benService}>

                                  <AiServiceProvider>

                                          <Router>
                                            <Switch>
                                              <Redirect exact from="/" to="/panel" />
                                              <RestrictedRoute path="/panel" component={PanelPage} isAuthorized={benService.isAuthorized}/>
                                              <RestrictedRoute path="/account" component={AccountPage} isAuthorized={benService.isAuthorized} />
                                              <Route path="/login" component={LoginPage} />
                                              <Route path="/error" component={NotFoundPage} />
                                              <Route path="/register" component={RegisterPage} />
                                              <Route path="/forgot-password" component={ForgotPassword} />
                                              <Route path="/update-password" component={UpdatePassword} />
                                              <Route path="/emailconfirm" component={EmailConfirmPage} />
                                              <Route path="/reward" component={RewardPage} />
                                              <Route path="/promo" component={PromoCode} />
                                              
                                              <Route path="/edu" component={PageAssistant} />

                                              {/* <Route path="/classes" component={PageClasses} />
                                              <Route path="/klasa" component={PageClasses} />
                                              <Route path="/sprawdz" component={PageCheck} />
                                              <Route path="/check" component={PageCheck} /> */}

                                              <Route path="/asystent" component={PageAssistant} />
                                              <Route path="/assistant" component={PageAssistant} />

                                              {/* <Route path="/testing" component={PageTesting} /> */}

                                              <Redirect to="/error" />
                                            </Switch>
                                          </Router>
                                  </AiServiceProvider>

                                </BenAccountProvider>
                            )}
                            </BenServiceContext.Consumer>
                          </BenServiceProvider>

                    </MathJaxContext>
                  

            </I18nProvider>
          </BenNotificationProvider>
        </BenHelperProvider>
      </React.Fragment>
    )
  }
}

export default App
