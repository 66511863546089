import React from 'react'

import { ChartData, ChartOptions, ChartPluginsOptions } from 'chart.js'
import Chart from 'react-chartjs-2'
//import { Pie } from 'react-chartjs-2';
// import I18nMessages from '../../../components/I18nMessages'
import { getColorForChartBackground, PieChartDefaults } from '../../../lib'

import Spinner from '../../../components/Spinner'
import { AiStats } from '../../../providers/aiServiceProvider'
import { getEduSubjectsFromStr, getSubjectName } from '../../edu/SubjectButton'



type Props = {
  aiStats : AiStats | null | undefined
}



const EducationChartSubjectCnt : React.FC<Props> = ({
  aiStats
}) => {

  const chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  }

  const adjustPosition = (x: number, y: number, centerX: number, centerY: number, factor: number) => {
    const deltaX = x - centerX;
    const deltaY = y - centerY;
    return {
      x: centerX + deltaX * factor,
      y: centerY + deltaY * factor,
    };
  };

  const chartPlugins: ChartPluginsOptions = {
    plugins: [{
      id: 'customText',
      afterDraw(chart: any) {

        // console.log(chart.tooltip._active)

        if( chart.tooltip._active && chart.tooltip._active.length > 0 )
          return

        const { ctx, chartArea } = chart;
        const dataset = chart.data.datasets[0];
        const meta = chart.getDatasetMeta(0);
        const centerX = (chartArea.right-chartArea.left) / 2;
        const centerY = (chartArea.bottom-chartArea.top) / 2;
        
        let sum = 0
        dataset.data.forEach((v: number, index: number) =>
        {
          const arc = meta.data[index];
          if( !arc.hidden )
            sum += v
        })
  
        chart.data.labels.forEach((label: string, index: number) => {
          const arc = meta.data[index];
          let position = arc.tooltipPosition();
          position = adjustPosition( position.x, position.y, centerX, centerY, 1.4 )
          
          const value = dataset.data[index];

          if( !arc.hidden && value > 0.05 * sum )
          {    
            ctx.fillStyle = '#fff'; // Kolor tekstu
            ctx.font = 'bold 0.9rem Arial'; // 
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';


            const text = value.toString();
            const textWidth = ctx.measureText(text).width;
            const textHeight = 16; 

            ctx.fillStyle   = 'rgba(0, 0, 0, 0.6)';
            ctx.strokeStyle = "rgba(0, 0, 0, 1.0)";
            
            //ctx.fillRect(position.x - textWidth / 2 - 4, position.y - textHeight / 2 - 4, textWidth + 8, textHeight + 8);

            ctx.beginPath();
            ctx.roundRect(position.x - textWidth / 2 - 4, position.y - textHeight / 2 - 4, textWidth + 8, textHeight + 8, 5);
            //ctx.stroke();
            ctx.fill();
    
            ctx.fillStyle = '#fff'; 
            ctx.fillText(text, position.x, position.y);
          }          
        });
      }
    }]
  }

  function getChartDataSubjects(s : AiStats ) : ChartData 
  {
      const data: ChartData = {
          labels:  s.subjects.map(item => getSubjectName( getEduSubjectsFromStr(item.subject), false)),
          datasets: [{
            borderWidth: 1,
            label: 'Count',
            backgroundColor: s.subjects.map((_, index) => getColorForChartBackground(index)),
            data: s.subjects.map(item => item.cnt)
          }]
      }

      // console.log("getChartDataSubjects ", data)
    
      return data
  }
 
  return (

    <React.Fragment>

        { aiStats === undefined && (
            <div className="chart-container d-flex align-items-center justify-content-center text-center">
              <Spinner/>
            </div>
        )}

        { ( aiStats && aiStats.subjects.length === 0 ) && (
            <div className="chart-container d-flex align-items-center justify-content-center text-center">
                Brak danych. Dane jeszcze nie zostały zebrane.
            </div>
        )}

        { ( aiStats && aiStats.subjects.length !== 0 ) && (

            <div className="chart-container">
                <Chart
                    type="pie"
                    legend={PieChartDefaults.legend}
                    options={chartOptions}  // PieChartDefaults.options
                    plugins={chartPlugins.plugins}
                    data={getChartDataSubjects(aiStats)}
                />
            </div>
        )}     

    </React.Fragment>
  )
}

export default EducationChartSubjectCnt
