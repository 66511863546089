import React from 'react'
import Spinner from '../components/Spinner'
import { useMountEffect } from '../lib/useMountEffect'
import { AccountType } from '../providers/providersDefs'


export enum AiActionName {
    logged = 'logged',
    logoff = 'logoff',
    solve = 'solve',
    history = 'history',
    historyItem = 'historyItem',
    stats = 'stats',
    heartbeat = 'heartbeat',
    getPlans = 'getPlans',
    buyLicense = 'buyLicense',
    buyLicenseS = 'buyLicenseS',
}


export enum AiStatusCode {
    STATUS_OK         = "OK",
    STATUS_ERROR      = "error",
    STATUS_LOGIN      = "login",
    STATUS_PENDING    = "pending",
    STATUS_OFFLINE    = "offline"    
}

export enum AiActionCode {
    CODE_OK                     =  0,
    CODE_ERROR                  = -1,
    CODE_SUCCESS                = 1,
    CODE_NOT_LOGGED             = 2,
    CODE_LOGIN                  = 3,
    CODE_ACCESS_DENIED          = 5,
    CODE_WRONG_PASS             = 6,

    CODE_CAPTCHA                = 10,
    CODE_ALREADY_REGISTERED     = 11,
    CODE_REGISTER_ERROR         = 12,
    CODE_MAX_PROFILES           = 13,
    CODE_MAX_DEVICES            = 14,
    CODE_PROFILE_NOT_EMPTY      = 15,
    CODE_NOT_FOUND              = 16,
    CODE_PASS_TOO_SHORT         = 17,
    CODE_WRONG_LIC              = 18,
    CODE_PAY_ERROR              = 19,
    CODE_PAY_BUY_FAILED         = 20,
    CODE_IPRESSO_TOKEN          = 21,
    CODE_IPRESSO_NO_CONTACT_ID  = 22,
    CODE_IPRESSO_NO_AGREEMENTS  = 23,
    CODE_IPRESSO_NO_AGREEMENTS2 = 24,
    CODE_WRONG_REWARD           = 25,
    CODE_REWARD_TAKEN           = 26,
    CODE_PAY_PENDING            = 30,
    CODE_WRONG_CONFIRM_VAL      = 31,
    CODE_ACCOUNT_NOT_CONFIRMED  = 32,
    CODE_WRONG_CODE             = 33,
    CODE_ACCOUNT_STRIPE_FAILED  = 34,
    CODE_INSUFFICIENT_TOKENS    = 40
}
  
export interface AiActionRequest<T = {}> {
    action: AiActionName
    data: T
}
  
export interface AiActionResponse<T = undefined> {
    status: AiStatusCode
    code:   AiActionCode
    name:   AiActionName    
    data:   T
}

export type HeartBeatData = {
    elapsed: number
    subject: string
}

export type AiUserData = {
    hash:   string
    tokens: number
    email:  string
    name:   string
    profileId: number
    lang:   string
}

export class AiSolution  {
    status:     number
    answer:     string
    solution:   string
    subject:    string
    subjectRaw: string
    explanation: string
    tokens:     number
    diff:       number
    diffP:      number
    diffC:      number
    modelRq:    string
    modelRs:    string
    questionHash: string

    constructor() {
        this.status = 0
        this.answer = ''
        this.solution = ''
        this.subject = ''
        this.subjectRaw = ''
        this.explanation = ''
        this.tokens = 0
        this.diff = 0
        this.diffP = 0
        this.diffC = 0
        this.modelRq = ''
        this.modelRs = ''
        this.questionHash = ''
    }
}

export type AiHistoryItem = {
    created:            number
    profileId:          number
    lang:               string
    question:           string
    questionImg:        string
    questionImgThumb:   string
    questionHash:       string
    answer:             string
    subject:            string
}


export type AiHistory = {
    items : AiHistoryItem[]
}

export type AiStatSubject = {
    subject:    string
    cnt:        number
}

export type AiStatQuestions = {
    dte:        number
    cnt:        number
}

export type AiStatTime = {
    dte:        number
    subject:    string
    elapsed:    number
    cnt:        number
}

export type AiStats = {
    subjects:   AiStatSubject[] 
    questions:  AiStatQuestions[] 
    time: AiStatTime[] 
}


export type AiPlansItem = {
    type: AccountType
    intervalCnt: number
    intervalName: string
    currency: string
    basePrice: number
    price: number
    priceInt: number
    discount: number
    monthly: number
    percent: number
    devices: number
    eduTokens: number
}

export type AiPlansResponse = {

    topups: AiPlansItem[]
    plans: AiPlansItem[]
}


export interface AiBuyLicense {
    redirectUri: string
}
  
export interface AiBuyLicenseS {
    cs:             string
    pk:             string
    email:          string
    description:    string  // nazwa planu
    devices:        number  // 3, 6, 9
    lang:           string  // en, pl
    currency:       string  // pln, usd, eur
    amount:         number  // w groszach/centach 10.22$  = 1022
    intervalName:   string  // day, week, month, year
    intervalCnt:    number  // ilosc dni w 'interval'
    country:        string
    countryCode:    string
} 



/////////////

export class TimeElapsed
{
    start: Date
    x: number
    y: number
    moved: boolean

    subject: string

    constructor() 
    {
        this.start = new Date()
        this.x = -1
        this.y = -1
        this.moved = false

        this.subject = ''
    }

    public updateSubject(sub: string) : void
    {
        this.subject = sub
    }

    public getSubject() : string
    {
        return this.subject
    }

    public getElapsed() : number 
    {
        const now = new Date()
        const dt = Math.round( ( now.getTime() - this.start.getTime() ) / 1000 )

        this.start = now

        if( this.moved )
        {
            this.moved = false
            return dt
        }
        else
        {
            return 0
        }
    }

    public update(x:number, y:number) : void 
    {        
        if( this.x !== x || this.y !== y )
            this.moved = true

        this.x = x
        this.y = y        
    }

    public setMoved() : void 
    {
        this.moved = true
    }
}

export interface AiServiceContextValue {

    isAuthorized: boolean
    userData?:  AiUserData
    timeElapsed?: TimeElapsed
    
    updateContextSubject: ( subject: string ) => void

    getContextSubject: () => string

    logged: (   hash        : string,
                profileId   : number, 
                invalidateCurrentHash: number) => Promise<AiActionResponse<AiUserData>>

    logoff: () => Promise<AiActionResponse>

    solve: (    hash        : string,
                question    : string,
                img         : string,
                imgThumb    : string,
                questionHash: string,
                subject     : string,
                model       : string ) => Promise<AiActionResponse<AiSolution>>

    history: (  hash        : string,
                profileId   : number,
                subject     : string,
                from        : number,
                len         : number ) => Promise<AiActionResponse<AiHistory>>

    historyItem: (
                hash        : string,
                questionHash: string ) => Promise<AiActionResponse<AiHistoryItem>>


    stats: (    hash        : string,
                profileId   : number, 
                from        : number,
                len         : number,
                kind        : string ) => Promise<AiActionResponse<AiStats>>

    heartbeat: (hash        : string,
                elapsed     : number, 
                subject     : string ) => Promise<AiActionResponse>

    getPlans: ( hash        : string,
                l           : string ) => Promise<AiActionResponse<AiPlansResponse>>

    buyLicense: (hash       : string,
                licType     : number, 
                licYears    : number, 
                promoCode   : string, 
                language    : string) => Promise<AiActionResponse<AiBuyLicense>>

    buyLicenseS: (hash      : string,
                licType     : number, 
                licYears    : number, 
                promoCode   : string, 
                language    : string) => Promise<AiActionResponse<AiBuyLicenseS>>  
}

export interface InjectedAiServiceProps {
    aiService: AiServiceContextValue
}

type AiAccountProviderProps = {
     
}

export const AiServiceContext = React.createContext<AiServiceContextValue>( {} as AiServiceContextValue )


export const AiServiceProvider: React.FC<AiAccountProviderProps> = ({
        children
    }) => {
  
    const API_PATH = 'ai'    
  
    const [isLoading, setLoadingStatus] = React.useState(false)
    const [userData, setUserData] = React.useState<AiUserData | undefined>()
    const [selectedSubject, setSelectedSubject] = React.useState<string|null>(null)
    const [timeElapsed, setTimeElapsedObject] = React.useState<TimeElapsed|undefined>( undefined )


    function request<R> (body: {}): Promise<R> {
        return fetch(`/${API_PATH}`, {
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(body)
    })
    .then(result => result.json())
    .then((result: any) => {

        //console.log("aiServiceProvider  result.name=" + result.name + "  result.status=" + result.status)

        if ( result.name === AiActionName.logged )
        {
            if( result.status === AiStatusCode.STATUS_OK ) {
                // console.log("setUserData(result.data) = " + JSON.stringify(result.data) )
                setUserData(result.data)
            }
            else {
                // console.log("setUserData(undefined)" )
                setUserData(undefined)
            }
        }
        else if( result.name === AiActionName.solve )
        {
            if( result.status === AiStatusCode.STATUS_OK ) {
                if( userData )
                    setUserData( {...userData, tokens:result.data.tokens} )
            }
        }

        if( result.status === AiStatusCode.STATUS_LOGIN ) {
            // console.log("setUserData(undefined)" )
            setUserData(undefined)
        }

        if (result.status === AiStatusCode.STATUS_ERROR ||
            result.status === AiStatusCode.STATUS_LOGIN )
        {
            throw result
        }

        return result
    })}


    const aiServiceContextValue: AiServiceContextValue = {

        isAuthorized : !!userData,
        userData: userData,
        timeElapsed: timeElapsed,
        
        updateContextSubject: ( subject: string ) => 
        {
            timeElapsed?.updateSubject(subject)
            setSelectedSubject(subject)
        },

        getContextSubject: () => {
            return selectedSubject ? selectedSubject : ""
        },

        heartbeat: ( hash : string, elapsed: number, subject: string ) => {

            // console.log( "call aiServiceContextValue  elapsed=" + elapsed + " subject=" + subject )

            const body: AiActionRequest<{ hash:string, elapsed: number, subject:string }> = {
                action: AiActionName.heartbeat,
                data: { hash, elapsed, subject }
            }

            return request<AiActionResponse>(body)
        },

        
        logged: (hash: string, profileId: number, invalidateCurrentHash: number ) => {

            const body: AiActionRequest<{ hash: string, profileId:number, invalidate: number }> = {
                action: AiActionName.logged,
                data: { hash, profileId, invalidate: invalidateCurrentHash }
            }

            return request<AiActionResponse<AiUserData>>(body)
        },

        logoff: () => {

            const body: AiActionRequest<{  }> = {
                action: AiActionName.logoff,
                data: {  }
            }

            return request<AiActionResponse>(body)
        },
    
        solve: (hash        : string, 
                question    : string,
                img         : string, 
                imgThumb    : string,
                questionHash: string,
                subject     : string,
                model       : string ) => {

            const body: AiActionRequest<{   hash    : string, 
                                            q       : string,
                                            i       : string, 
                                            it      : string,
                                            h       : string,
                                            subject : string,
                                            model   : string }> = {
                action: AiActionName.solve,
                data: { hash,
                        q : question,
                        i : img, 
                        it: imgThumb,
                        h: questionHash,
                        subject,
                        model }
            }

            return request<AiActionResponse<AiSolution>>(body)
        },

        history: (  hash        : string,
                    profileId   : number,
                    subject     : string,
                    from        : number,
                    len         : number ) => {

            const body: AiActionRequest<{ hash      : string,
                                          profileId : number,
                                          subject   : string,
                                          from      : number,
                                          len       : number }> = {
                action: AiActionName.history,
                data: { hash, profileId, subject, from, len }
            }

            return request<AiActionResponse<AiHistory>>(body)
        },

        historyItem: ( hash : string, questionHash:string ) => {

            const body: AiActionRequest<{ hash : string, questionHash:string }> = {
                action: AiActionName.historyItem,
                data: { hash, questionHash }
            }

            return request<AiActionResponse<AiHistoryItem>>(body)
        },

        stats: (hash        : string,
                profileId   : number, 
                from        : number,
                len         : number,
                kind        : string ) => {

            const body: AiActionRequest<{ hash      : string,
                                          profileId : number,
                                          from      : number,
                                          len       : number,
                                          kind      : string }> = {
                action: AiActionName.stats,
                data: { hash, profileId, from, len, kind }
            }

            return request<AiActionResponse<AiStats>>(body)
        },

        getPlans: ( hash : string, l: string ) => {

            const body: AiActionRequest<{ hash : string, l : string }> = {
                action: AiActionName.getPlans,
                data: { hash, l }
                }

            return request<AiActionResponse<AiPlansResponse>>(body)
        },

        buyLicense:(hash        : string,
                    licType     : number, 
                    licYears    : number, 
                    promoCode   : string, 
                    language    : string) => {
            
            const body: AiActionRequest<{}> = {
                action: AiActionName.buyLicense,
                data: {
                    hash,
                    cu: document.location.origin + '/edu?session_id={CHECKOUT_SESSION_ID}',
                    clu: document.location.origin + '/edu',
                    l: language,
                    lt: licType,
                    ly: licYears,
                    pc: promoCode
                }
              }
        
              return request<AiActionResponse<AiBuyLicense>>(body)
        },

        buyLicenseS: (  hash        : string,
                        licType     : number, 
                        licYears    : number, 
                        promoCode   : string, 
                        language    : string) => {

            const body: AiActionRequest<{}> = {
                action: AiActionName.buyLicenseS,
                data: {
                    hash,
                    cu: document.location.origin + '/edu?session_id={CHECKOUT_SESSION_ID}',
                    clu: document.location.origin + '/edu',
                    l: language,
                    lt: licType,
                    ly: licYears,
                    pc: promoCode
                }
              }
        
            return request<AiActionResponse<AiBuyLicenseS>>(body)
        },
    }

    const tick = (timeElapsed:TimeElapsed) => 
    {
        const dt  = timeElapsed?.getElapsed()
        const sub = timeElapsed?.getSubject()

        // Beware of Clousure!
        // const sub = selectedSubject
        // console.log("tick: dt="+dt+" sub="+sub)

        if( dt !== undefined && dt > 0 )
        {
            aiServiceContextValue.heartbeat( "", dt, sub ? sub : "" )
                .catch( () => {} )
        }
    }


    const listener = (what:any, obj:any) => { 

        const path = window.location.pathname

        if( path.startsWith( "/edu" )       ||
            path.startsWith( "/classes" )   ||
            path.startsWith( "/klasa" )     ||
            path.startsWith( "/sprawdz" )   ||
            path.startsWith( "/check" )     ||
            path.startsWith( "/asystent" )  ||
            path.startsWith( "/assistant" ) ||
            path.startsWith( "/testing" )   ||
            false )
        {
            // if( what !== 'mousemove' ) {
            //     console.log( "activityHandler("+what+")")
            // }

            obj.setMoved()

            if( what === 'unload' || what === 'beforeunload' ) {
                tick(obj)
            }
        }        
    }


    useMountEffect(() => {

        // console.log("aiService useMountEffect")

        let obj = new TimeElapsed()
        setTimeElapsedObject( obj )

        let cbType = ['mousemove', 'touchstart', 'pageshow', 'pagehide', 'load', 'visibilitychange', 'unload']
        let cbCall : Array<any> = []

        for (const ct of cbType) 
        {
            let cc = () => { listener(ct, obj) }
            cbCall.push(cc)

            // Beware of Closure!
            // document.addEventListener( ct, cc )
            window.addEventListener(ct, cc )
        }

        // Beware of Closure!
        let timer = setInterval( () => { tick(obj) }, 60*1000 );

        return () => {

            // console.log("aiService useMountEffect UNMOUNT")

            for( let i = 0; i<cbType.length; i++)
            {
                // document.removeEventListener( cbType[i], cbCall[i] )
                window.removeEventListener( cbType[i], cbCall[i] )
            }

            clearInterval(timer)
        }
    })


    // Clousure: Tak też mozna...
    // useEventListener('mousemove', () => { listener('mousemove') });
    // useEventListener('touchstart', () => { listener('touchstart') });
    // useEventListener('pageshow', () => { listener('pageshow') });
    // useEventListener('pagehide', () => { listener('pagehide') });
    // useEventListener('load', () => { listener('load') });
    // useEventListener('unload', () => { listener('unload') });
    // useEventListener('beforeunload', () => { listener('beforeunload') });
    // useEventListener('visibilitychange', () => { listener('visibilitychange') });
    // useInterval( () => { tick() }, 60*1000 );

    //     console.log("***** AI useMountEffect *****")
    //     const paramsString = window.location.search.substring(1)
    //     var searchParams = new URLSearchParams(paramsString)
    //     const hash = searchParams.get('hash')
    //     aiServiceContextValue
    //         .logged(hash?hash:'')
    //         .then( response => setUserData(response.data) )
    //         .finally( () => setLoadingStatus(false) )
        
    return (
        <AiServiceContext.Provider value={aiServiceContextValue}>
            { isLoading ? <Spinner /> : children }
        </AiServiceContext.Provider>
    )
}


export function withAiService<P extends InjectedAiServiceProps> (Component: React.ComponentType<P>) {
    return (props: Omit<P, keyof InjectedAiServiceProps>) => (
        <AiServiceContext.Consumer>
            {state => <Component {...props as P} aiService={state}/>}
        </AiServiceContext.Consumer>
    )
}
