import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Modal, ModalHeader, ModalBody, NavLink, Alert, Button } from 'reactstrap'
import AsyncButton from '../components/AsyncButton'
import I18nMessages from '../components/I18nMessages'
import ProfileForm, { DateOfBirth } from './ProfileForm'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import { InjectedBenServiceProps, withBenService, ActionResponse } from '../providers/benServiceProvider'
import { ActionCode, AccountType } from '../providers/providersDefs'
import getMessage from '../language/getMessage'
import UpgradePage from '../routes/account/UpgradePage'


type InjectedProps = InjectedBenAccountProps
  & InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps

type ProfileModalProps = InjectedProps & {
  isOpen: boolean
  profileId?: string
  onToggle: () => void
  accountType: AccountType | undefined
}

const ProfileModal: React.FC<ProfileModalProps> = ({
  benAccount,
  benNotification,
  benService,
  intl,
  isOpen,
  profileId,
  onToggle,
  accountType,
}) => {

  const [isSubmitting, setSubmittingState] = React.useState(false)
  const [isMounted, setMounted] = React.useState(false)
  const [showUpgradePopup, setShowUpgradePopup] = React.useState(false)
  const [showUpgradeBtn, setShowUpgradeBtn] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null)

  function getDefaultName (id?: string): string | undefined {
    if (id) {
      const profile = benAccount.profile(id)
      return profile ? profile.name : undefined
    }
  }

  function getDefaultProfileImage (id?: string): number | undefined {
    if (id) {
      const profile = benAccount.profile(id)
      return profile && profile.img
    }
  }

  function getDefaultDateOfBirth (id?: string): { month: number, year: number } | undefined {
    if (id) {
      const profile = benAccount.profile(id)
      return profile && { month: profile.month, year: profile.year }
    }
  }

  function onCancelClicked()
  {
    setErrorMsg(null)
    onToggle();
  }

  function handleOnFormSubmit (profileName: string, dateOfBirth: DateOfBirth, profileImage: number) {
    let promise: Promise<ActionResponse>

    setSubmittingState(true)

    if (profileId) {
      promise = benService.updateProfile(profileId, profileName, profileImage, dateOfBirth.year, dateOfBirth.month)
    } else {
      promise = benService.createProfile(profileName, profileImage, dateOfBirth.year || 0, dateOfBirth.month || 0)
    }

    promise
      .then(() => benAccount.refreshProfiles().then(() => isMounted && onToggle()))

      .catch((res) => {
        if (res.code === ActionCode.maxProfilesError) {
          setErrorMsg('profile-form-modal.max-profiles-error')
          setShowUpgradeBtn(accountType !== AccountType.premium)
        }
        else {
          benNotification.notify({
            type: 'error',
            title: getMessage('side-effect.internal-error-title', intl),
            message: getMessage('side-effect.internal-error-message', intl)
          })
        }
      })

      .finally(() => isMounted && setSubmittingState(false))
  }

  function handleOnUpgradeClick() {
    setShowUpgradePopup(true)
  }

  function handleUpgradeInfoToggle() {
    setShowUpgradePopup(false)
  }

  React.useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [setMounted])

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader>
        <I18nMessages id={profileId ? 'profile-form-modal.edit.title' : 'profile-form-modal.create.title'} />
      </ModalHeader>

      <ModalBody>

        {errorMsg && (
          <React.Fragment>
            <Alert color="danger" isOpen={true}>
              {intl.formatHTMLMessage({ id: errorMsg })}
            </Alert>

              <div className="d-flex align-items-center justify-content-between">
                
                <NavLink onClick={onCancelClicked} className="cursor-pointer mx-auto">
                  <I18nMessages
                    id={profileId ? 'profile-form-modal.edit.skip-button' : 'profile-form-modal.create.skip-button'}
                  />
                </NavLink>

                {showUpgradeBtn && (
                  <Button style={{ marginTop: 5 }} onClick={handleOnUpgradeClick} color="primary" className="mx-auto">
                    <I18nMessages id="plans-info-page.upgrade-plan" />
                  </Button>
                )}

              </div>

              {showUpgradePopup && (
                <UpgradePage
                  isOpen={true}
                  onToggle={handleUpgradeInfoToggle}
                />
              )}

          </React.Fragment>
        )}

        {!errorMsg && (
          <ProfileForm
            defaultName={getDefaultName(profileId)}
            defaultDateOfBirth={getDefaultDateOfBirth(profileId)}
            defaultProfileImage={getDefaultProfileImage(profileId)}
            onSubmit={handleOnFormSubmit}
          >
            <div className="d-flex align-items-center justify-content-between">
              <NavLink onClick={onCancelClicked} className="cursor-pointer">
                <I18nMessages
                  id={profileId ? 'profile-form-modal.edit.skip-button' : 'profile-form-modal.create.skip-button'}
                />
              </NavLink>
              <AsyncButton showSpinner={isSubmitting} type="submit" color="primary">
                <I18nMessages
                  id={profileId ? 'profile-form-modal.edit.submit-button' : 'profile-form-modal.create.submit-button'}
                />
              </AsyncButton>
            </div>
          </ProfileForm>
        )}

      </ModalBody>
    </Modal>
  )
}

export default injectIntl(withBenNotification(withBenService(withBenAccount(ProfileModal))))
