import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/it'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'Tutto OK!',
  'side-effect.successful-message': 'I dati sono stati salvati correttamente',
  'side-effect.internal-error-title': 'Qualcosa è andato storto',
  'side-effect.internal-error-message': 'Riprova più tardi o controlla la tua connessione a Internet',

  'monday-sshort-label': 'Lu',
  'tuesday-sshort-label': 'Ma',
  'wednesday-sshort-label': 'Me',
  'thursday-sshort-label': 'Gi',
  'friday-sshort-label': 'Ve',
  'saturday-sshort-label': 'Sa',
  'sunday-sshort-label': 'Do',

  'monday-short-label': 'Lun',
  'tuesday-short-label': 'Mar',
  'wednesday-short-label': 'Mer',
  'thursday-short-label': 'Gio',
  'friday-short-label': 'Ven',
  'saturday-short-label': 'Sab',
  'sunday-short-label': 'Dom',

  'monday-label': 'Lunedì',
  'tuesday-label': 'Martedì',
  'wednesday-label': 'Mercoledì',
  'thursday-label': 'Giovedì',
  'friday-label': 'Venerdì',
  'saturday-label': 'Sabato',
  'sunday-label': 'Domenica',

  'january-label': 'Gennaio',
  'february-label': 'Febbraio',
  'march-label': 'Marzo',
  'april-label': 'Aprile',
  'may-label': 'Maggio',
  'june-label': 'Giugno',
  'july-label': 'Luglio',
  'august-label': 'Agosto',
  'september-label': 'Settembre',
  'october-label': 'Ottobre',
  'november-label': 'Novembre',
  'december-label': 'Dicembre',

  'interval-name-year': 'anno',
  'interval-name-month': 'mese',
  'interval-name-week': 'settimana',
  'interval-name-day': 'giorno',
  'interval-name-years': 'anni',
  'interval-name-months': 'mesi',
  'interval-name-weeks': 'settimane',
  'interval-name-days': 'giorni',

  'select-input.no-options-label': 'Nessuna opzione',
  'filter-input.label': 'Filtro:',

  'no-data.label': 'Nessun dato da visualizzare. Modifica i filtri o la data selezionata.',
  'no-data.label-mid': 'Nessun dato da visualizzare. Modifica la data selezionata.',
  'no-data.label-short': 'Nessun dato da visualizzare.',
  'empty-list-label': 'Elenco vuoto.',
  'overlay.empty-device-label': 'Non sono stati aggiunti dispositivi a questo profilo ancora.',
  'overlay.empty-profiles-label': 'Nessun profilo aggiunto ancora.',
  'overlay.empty-device.add-device-label': 'Aggiungi dispositivo',

  'confirmation-modal.cancel-button': 'Annulla',
  'confirmation-modal.confirm-button': 'CONFERMA',
  'confirmation-modal.button-yes': 'Sì',
  'confirmation-modal.button-no': 'No',

  // Date Picker
  'date-picker.select-date-label': 'Seleziona data',

  // Date Filter
  'date-filter.select-label': 'Seleziona intervallo',
  'date-filter.this-month': 'Questo mese',
  'date-filter.this.week': 'Questa settimana',
  'date-filter.this.day': '24h',
  'date-filter.this.custom': 'Seleziona giorno',
  'date-filter.from-label': 'Da: ',
  'date-filter.to-label': 'A: ',

  // Time Picker
  'time-picker.unlimied-label': 'Illimitato',
  'time-picker.time-format': '{h}h {m}min',
  'time-picker.time-format-min': '{m} min',
  'time-picker.time-format-hour': '{h} h',

  'time-picker.add-bonus-title': 'Aggiungi bonus tempo solo per oggi',
  'time-picker.no-bonus': 'Nessun bonus',
  'time-picker.bonus': 'Bonus',
  'time-picker.add-bonus-button-set': 'Imposta bonus',
  'time-picker.add-bonus-button-cancel': 'Annulla',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Intervallo di date',
  'profile-image-picker.girl-label': 'Ragazza',
  'profile-image-picker.boy-label': 'Ragazzo',

  // Profile List
  'profile-list.remove-device-label': 'Rimuovi dispositivo',
  'profile-list.move-device-label': 'Sposta dispositivo in un altro profilo',
  'profile-list.add-device-label': 'Aggiungi dispositivo',

  'profile-list.add-profile-label': 'Aggiungi profilo',
  'profile-list.edit-profile-label': 'Modifica profilo',
  'profile-list.add-devices-label': 'Aggiungi nuovo dispositivo',
  'profile-list.remove-profile-label': 'Rimuovi profilo',
  'profile-list.remove-profile-disabled-tooltip': 'Per eliminare il tuo profilo, devi prima rimuovere tutti i dispositivi collegati al profilo.',
  'profile-list.title': 'Lista dei profili',

  // Profile Form
  'profile-form.full-name-label': 'Nome',
  'profile-form.month-of-birth-label': 'Mese di nascita',
  'profile-form.year-of-birth-label': 'Anno di nascita',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Annulla',
  'profile-form-modal.create.submit-button': 'AGGIUNGI PROFILO',
  'profile-form-modal.create.title': 'Aggiungi profilo bambino',
  'profile-form-modal.edit.skip-button': 'Annulla',
  'profile-form-modal.edit.submit-button': 'SALVA',
  'profile-form-modal.edit.title': 'Modifica profilo bambino',
  'profile-form-modal.max-profiles-error': 'Limite di profilo raggiunto',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Sposta il dispositivo su un altro profilo',
  'profile-move-device-modal.cancel-button': 'Annulla',
  'profile-move-device-modal.submit-button': 'Salva',
  'profile-move-device-modal.select-profile-label': 'Scegli profilo',

  // CallLog
  'call-log.duration-label': 'Durata della chiamata:',
  'call-log.seconds-label': 's.',

  // Create Profile Page
  'create-profile-page.submit-button': 'AGGIUNGI PROFILO',
  'create-profile-page.skip-button': 'Annulla',
  'create-profile-page.title': 'Aggiungi profilo bambino',
  'create-profile-page.description': 'Chi sarà monitorato',

  // Add device page
  'add-device-page.close-button': 'CHIUDI',
  'add-device-page.description': "Scegli il tipo di dispositivo su cui installare l'app",
  'add-device-page.title': "Guida all'installazione",
  'add-device-page.check': 'Controlla il manuale',
  'add-device-page.mobile-title': 'Smartphone o tablet Android',
  'add-device-page.mobile-description': 'Versione Android 6 o superiore',
  'add-device-page.desktop-title': 'Desktop o laptop Windows',
  'add-device-page.desktop-description': 'Versione Windows 7 o superiore',


  // Purchase Page
  'purchase-page.skip-label': 'Vai al cruscotto',
  'purchase-page.title': 'Scegli il miglior piano',
  'purchase-page.title.features': 'Tutti i piani offrono le seguenti funzioni',
  'purchase-page.title.reviews': 'Perché i nostri utenti ci amano',
  'purchase-page.subtitle': 'Se il programma non soddisfa le tue aspettative, rimborsiamo il pagamento',
  'purchase-page.plan-highlight': 'Miglior rapporto qualità-prezzo',
  'purchase-page.monthly': 'mensile',
  'purchase-page.select': 'Scegli',
  'purchase-page.close': 'Chiudi',
  'purchase-page.money-back.title': 'Senza rischi',
  'purchase-page.money-back.desc': 'Se non sei soddisfatto, sarai rimborsato.',
  'purchase-page.header.check-1': 'Migliore app di controllo parentale',
  'purchase-page.header.check-2': 'Imposta il limite di tempo per i giochi, YouTube e siti web',
  'purchase-page.header.check-3': 'Centinaia di migliaia di clienti soddisfatti',
  'purchase-page.plan.year': 'anno',
  'purchase-page.plan.optimal': 'Miglior rapporto qualità-prezzo',


  'purchase-page.modal.2y-header': 'Offerta speciale!',
  'purchase-page.modal.2y-sub-header': 'Scegli il <b>piano di 2 anni e risparmia</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Scegli il piano <b>di 2 anni e risparmia</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Proteggi fino a {devices} dispositivi',
  'purchase-page.modal.upgrade': 'Scegli lo sconto',
  'purchase-page.modal.no-thanks': 'Continua con il piano di 1 anno',


  'purchase-plan.features.title.feature_1': 'Limite di tempo per app e giochi',
  'purchase-plan.features.title.feature_2': 'Filtraggio dei siti web',
  'purchase-plan.features.title.feature_3': 'Blocca app, giochi e siti web',
  'purchase-plan.features.title.feature_4': 'Monitoraggio Youtube',
  'purchase-plan.features.title.feature_5': 'Visualizzazione live dello schermo remoto',
  'purchase-plan.features.title.feature_6': 'Accesso remoto',
  'purchase-plan.features.title.feature_7': 'Rapporti dettagliati',
  'purchase-plan.features.title.feature_8': 'Localizzazione (solo telefono)',
  'purchase-plan.features.title.feature_9': 'Controlla messaggi e chiamate',
  'purchase-plan.features.title.feature_10': 'Supporto tecnico prioritario',

  'purchase-plan.features.desc.feature_1': 'Imposta limiti di tempo per app, giochi e accesso a Internet. Inoltre, programma le ore in cui tuo figlio può accedervi',
  'purchase-plan.features.desc.feature_2': "Tutti i siti web inappropriati vengono bloccati. Il programma utilizza un algoritmo che analizza i contenuti del sito web per qualsiasi materiale non appropriato. Raccoglie anche informazioni da un'immensa raccolta di database di siti web e parole inappropriati, entrambi aggiornati regolarmente.",
  'purchase-plan.features.desc.feature_3': 'Blocca app e siti web che potrebbero avere un impatto negativo sui tuoi figli. Inoltre, ogni nuovo gioco installato richiederà la tua autorizzazione.',
  'purchase-plan.features.desc.feature_4': "Nel cruscotto, monitora tutti i video che tuo figlio guarda su YouTube. Blocca qualsiasi video o blocca completamente l'accesso ai servizi video.",
  'purchase-plan.features.desc.feature_5': "Accedi da remoto a un flusso live dello schermo di tuo figlio e controlla la loro attività durante tutto il giorno. Questo permette di tenere d'occhio i contenuti a cui tuo figlio sta accedendo.",
  'purchase-plan.features.desc.feature_6': "Una volta effettuato l'accesso, è possibile accedere al cruscotto genitore da qualsiasi luogo e da qualsiasi dispositivo. Nel cruscotto, regola le impostazioni, controlla i dispositivi dei bambini e imposta limiti di tempo.",
  'purchase-plan.features.desc.feature_7': "Nel cruscotto genitore, monitora facilmente l'attività di un bambino sul suo computer o telefono, vedi quanto tempo ha passato davanti allo schermo, quali siti web ha visitato e molto altro.",
  'purchase-plan.features.desc.feature_8': 'Controlla la posizione corrente e le posizioni passate di tuo figlio sulla mappa. Vedi esattamente dove si trovava tuo figlio in un determinato giorno o a una certa ora.',
  'purchase-plan.features.desc.feature_9': 'Puoi facilmente controllare i messaggi inviati e ricevuti da tuo figlio, le telefonate effettuate e i contatti salvati utilizzando il pannello genitore.',
  'purchase-plan.features.desc.feature_10': 'Se avete bisogno di aiuto, siamo sempre disponibili. Ci sono anche molte guide video per aiutarvi a usare {app}.',



  'purchase-plan.raitings.desc.name_1': 'Sophia',
  'purchase-plan.raitings.desc.name_2': 'Olivia',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lucas',
  'purchase-plan.raitings.desc.name_5': 'Emma',
  'purchase-plan.raitings.desc.name_6': 'Emma',

  'purchase-plan.raitings.desc.raiting_1': 'Molto efficiente! Protegge completamente mio figlio dai contenuti dannosi su internet.',
  'purchase-plan.raitings.desc.raiting_2': "Proprio quello che stavo cercando! Ho impostato un limite giornaliero di un'ora per l'accesso di mia figlia a internet e Avosmart se ne occupa per me.",
  'purchase-plan.raitings.desc.raiting_3': 'Raccomando Avosmart perché ha una varietà di funzioni. I miei figli sono protetti quando usano internet e posso controllare quanto tempo passano a giocare, ecc.',
  'purchase-plan.raitings.desc.raiting_4': "Era importante per mia moglie e me poter tenere d'occhio le abitudini sui social media di nostra figlia. Avosmart ci aiuta a mantenere equilibrata la vita digitale e reale di nostra figlia.",
  'purchase-plan.raitings.desc.raiting_5': 'Mio figlio passava troppo tempo su internet, usando un laptop, un tablet, ecc.. Con i rapporti forniti da Avosmart, so esattamente quali siti web visita mio figlio e per quanto tempo.',
  'purchase-plan.raitings.desc.raiting_6': 'Ora posso controllare la posizione attuale di mio figlio in qualsiasi momento. Un servizio eccellente!',


  'account-settings-page.title': 'Impostazioni account',
  'account-settings-page.automatic-update': "Aggiornamenti automatici dell'applicazione Windows",
  'account-settings-page.timezone': 'Fuso orario: ',
  'account-settings-page.send-reports': 'Invia rapporti di attività giornalieri',


  'plans-info-page.title': "Informazioni sull'abbonamento",
  'plans-info-page.plan': ' Piano:',
  'plans-info-page.max-devices': 'Numero massimo di dispositivi:',
  'plans-info-page.valid-till': 'Data di scadenza:',

  'plans-info-page.your-plan': 'Il tuo piano:',
  'plans-info-page.subscription-information': "Informazioni sull'abbonamento",
  'plans-info-page.subscription-created': 'Abbonamento creato: ',
  'plans-info-page.subscription-current-period': 'Periodo di abbonamento attuale: ',
  'plans-info-page.subscription-renews-on': 'Il tuo piano si rinnova il ',
  'plans-info-page.subscription-cancel-renewal': 'Annulla il rinnovo',
  'plans-info-page.subscription-not-renewed': 'Il tuo piano NON sarà rinnovato alla fine del periodo corrente',
  'plans-info-page.subscription-enable-renewal': 'Abilita il rinnovo',
  'plans-info-page.subscription-price': "Prezzo dell'abbonamento: ",
  'plans-info-page.subscription-price-amount': '{amount} ogni {period}',


  'plans-info-page.upgrade-plan': 'Aggiorna il tuo piano',
  'plans-info-page.extend-lic': 'Estendi il tuo piano',
  'plans-info-page.subscriptions-portal': 'Gestisci i tuoi metodi di pagamento',
  'plans-info-page.recent-payments': 'Ultime transazioni',
  'plans-info-page.date-of-payment': 'Data della transazione:',
  'plans-info-page.transaction-status': 'Stato della transazione:',
  'plans-info-page.no-recent-payments': 'Nessuna ultima transazione',
  'plans-info-page.btn-close': 'Chiudi',
  'plans-info-page.plan-devices': 'fino a {devices} dispositivi',
  'plans-info-page.plan-devices-types': 'computer/tablet/telefoni',


  'upgrade-page.title': 'Aggiorna il piano',
  'upgrade-page.devices': 'Dispositivi: ',
  'upgrade-page.valid-till': 'Il tuo piano è valido fino al: ',
  'upgrade-page.confirm-upgrade': 'Il tuo piano sarà aggiornato, ti sarà addebitato {amount}',
  'upgrade-page.pay-now': 'Paga ora {amount}',
  'upgrade-page.pay-then': 'Poi {amount} ogni {period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': "Acquista l'abbonamento",
  'purchase-checkout.up-to-devices': 'Fino a {devices} dispositivi',
  'purchase-checkout.subscribtion-price': '{amount} ogni {period}',
  'purchase-checkout.subscribe': 'Abbonati',
  'purchase-checkout.cancel': 'Annulla',


  // Purchase Status Page
  'purchase-status-page.title': 'Verifica del pagamento',
  'purchase-status-page.status-pending': 'In sospeso',
  'purchase-status-page.status-completed': 'Completato',
  'purchase-status-page.status-canceled': 'Annullato',
  'purchase-status-page.status-refund-finalized': 'Rimborso finalizzato',
  'purchase-status-page.status-wating-for-conf': 'In attesa di conferma',
  'purchase-status-page.status-created': 'Creato',
  'purchase-status-page.status-unkown': 'Sconosciuto',
  'purchase-status-page.status-error': 'Errore',
  'purchase-status-pag.back-button': 'Torna alla pagina principale',
  'purchase-status-pag.invoice-link': 'Voglio ricevere una fattura IVA',

  // Login Page
  'login-page.brandline': 'Accedi',
  'login-page.brandline2': 'Filtraggio del sito web',
  'login-page.email-label': 'Indirizzo e-mail',
  'login-page.login-button': 'ACCEDI',
  'login-page.password-label': 'Password',
  'login-page.recovery-link': 'Hai dimenticato la password?',
  'login-page.invalid-login-error': "L'indirizzo email o la password inseriti non sono validi",

  // Register Page
  'register-page.create-account-label': 'Hai bisogno di un account? Crea un nuovo account',
  'register-page.description': 'Crea il tuo account gratis',
  'register-page.email-label': 'Indirizzo email',
  'register-page.login-link-label': 'Hai già un account? Accedi',
  'register-page.marketing-checkbox-label': 'Acconsento a ricevere materiale di marketing e promozionale',
  'register-page.password-label': 'Password',
  'register-page.phoneNumber-label': 'Numero di telefono',
  'register-page.terms-privacy-checkbox-label': 'Accetto la politica sulla privacy e i termini di uso',
  'register-page.register-button': 'REGISTRATI',
  'register-page.register-success-title': 'Successo',
  'register-page.register-success-message': "Registrazione avvenuta con successo. Controlla la tua casella di posta per confermare l'email",
  'register-page.register-error-captcha': 'Errore captcha. Riprova.',
  'register-page.register-error-already-registered-label': 'Già registrato. Prova a fare il login o recupera la password.',
  'register-page.register-error-password-to-short-label': 'Password troppo corta.',

  // Forgot Password Page
  'register-page.strong-pass': 'La tua password è troppo semplice e potrebbe essere facilmente decifrata da altre persone. La tua password dovrebbe contenere lettere maiuscole e minuscole così come numeri, e dovrebbe essere almeno di 8 caratteri. Sei sicuro di voler usare una password semplice?',
  'forgot-password-page.headline-title': 'Ricorda la password',
  'forgot-password-page.email-label': 'Indirizzo email',
  'forgot-password-page.reset-button': 'RECUPERA PASSWORD',
  'forgot-password-page.login-link-label': 'Torna alla pagina di login',
  'forgot-password-page.success-title': 'Reimpostazione della password',
  'forgot-password-page.success-message': 'Controlla la tua casella di posta per le istruzioni su come reimpostare la tua password',
  'forgot-password-page.email-not-confirmed': 'Il tuo indirizzo email non è verificato. La reimpostazione della password non è disponibile.',

  // Update Password Page
  'update-password-page.headline-title': 'Impostazione di una nuova password',
  'update-password-page.warning-title': 'NOTA:',
  'update-password-page.warning-description': 'Impostando una nuova password si perderanno i dati di posizione precedentemente salvati. I restanti dati non verranno persi. ',
  'update-password-page.warning-agreement': 'Capisco e accetto la perdita di dati di posizione.',
  'update-password-page.new-password': 'Nuova password:',
  'update-password-page.new-password-retype': 'Ripeti nuova password:',
  'update-password-page.submit': 'Imposta nuova password',
  'update-password-page.alert-weak-password': 'La tua password è troppo semplice e potrebbe essere facilmente indovinata da persone non autorizzate. La password dovrebbe contenere lettere maiuscole, lettere minuscole, numeri, ed essere lunga almeno 8 caratteri. Vuoi usare comunque una password semplice? ',
  'update-password-page.alert-agreement': "È richiesta l'accettazione della perdita di dati.",
  'update-password-page.error-message': "Errore! L'impostazione di una nuova password è fallita. ",
  'update-password-page.success-message': 'Nuova password impostata',
  'update-password-page.unknow-error-message': 'Qualcosa è andato storto',
  'update-password-page.error-link': 'Link errato',
  'update-password-page.error-posswords-not-equal': 'Password non uguali',
  'update-password-page.error-posswords-length': 'Password troppo corta',
  'update-password-page.error-wrong-code': 'Codice errato',
  'update-password-page.register-success-title': 'Successo!',
  'update-password-page.register-success-message': 'Nuova password impostata.',

  // Error Page
  'not-found-page.title': 'Oops... sembra che sia occorso un errore!',
  'not-found-page.error-code-text': 'Codice di errore',
  'not-found-page.go-back-home-button': 'TORNA ALLA HOME',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': "Il tuo piano termina il {date}. Per favore considera l'acquisto di un abbonamento oggi",
  'panel-page.purchase-demo-alert': 'Attualmente stai utilizzando la versione di prova.',
  'panel-page.purchase-expired-alert': "Attualmente stai utilizzando una versione di prova scaduta. Acquista l'abbonamento per continuare",
  'panel-page.purchase-lic-expired-alert': "Il tuo piano di abbonamento è scaduto. Acquista l'abbonamento per continuare",
  'panel-page.email-alert': 'Non hai confermato il tuo indirizzo email',
  'panel-page.button-email-resend': "Rinvia l'email",
  'panel-page.email-alert-resend': " o invia di nuovo l'email.",
  'panel-page.email-alert-resend-title': 'Conferma',
  'panel-page.email-alert-resend-msg': 'Email è stata inviata!',
  'panel-page.profile-delete-description': 'Sei sicuro di voler eliminare il profilo selezionato?',
  'panel-page.profile-delete-device-description': 'Sei sicuro di voler eliminare il dispositivo selezionato? I dati saranno persi.',
  'panel-page.profile-delete-device-second-description': 'La protezione sul dispositivo sarà disabilitata. Sei sicuro di voler continuare?',

  'panel-page.email-confirmation-overlay-close': 'Chiudi',
  'panel-page.email-confirmation-overlay-postpone': 'Più tardi',
  'panel-page.email-confirmation-overlay-resend': "Rinvia l'email",
  'panel-page.email-confirmation-overlay-header': 'Il tuo indirizzo email sottostante non è stato confermato',
  'panel-page.email-confirmation-overlay-desc': "Se hai commesso un errore con l'indirizzo email sopra, per favore faccelo sapere immediatamente. <br>Se non hai ricevuto l'email, per favore clicca su 'Rinvia' e controlla nelle altre cartelle email (incluso junk/spam).",

  'panel-page.email-confirmation-code-success-header': 'Grazie',
  'panel-page.email-confirmation-code-success-desc': "L'email è stata confermata!",
  'panel-page.email-confirmation-code-header': 'Inserisci il codice di conferma a 6 cifre che <br> è stato inviato al tuo indirizzo email.',
  'panel-page.email-confirmation-code-error-invalid-code': 'Il codice fornito non è valido',
  'panel-page.email-confirmation-code-error': 'Si è verificato un errore imprevisto durante la convalida del codice',

  // Summary Page
  'summary-page.title': 'Dashboard',
  'summary-page.top-apps.title': 'Le migliori app',
  'summary-page.top-blocked.title': 'Più bloccati',
  'summary-page.top-domains.title': 'Pagine web più visitate',
  'summary-page.top-search.title': 'Ricerca in alto',
  'summary-page.access-disable': 'Disattivazione temporanea',
  'internet-page.access-time-limit-set-label': "Limite giornaliero per l'accesso a internet:",
  'internet-page.access-time-limit-description': 'Imposta limiti di tempo giornalieri per ogni dispositivo e scegli quante ore di utilizzo di Internet sono consentite.',
  'disable-access.protection-enabled': 'La protezione è attiva',
  'disable-access.protection-disabled-for': 'La protezione è temporaneamente disabilitata, {time} minuti rimanenti',
  'disable-access.device-offline': 'Dispositivo non disponibile',
  'disable-access.protection-status': 'Stato della protezione',


  // Internet Page
  'internet-page.headline-title': 'Pagine web',
  'internet-page.access-time-page-title': 'Orario di accesso',
  'internet-page.settings-page-title': 'Impostazioni',
  'internet-page.statistics-page-title': 'Statistiche',
  'internet-page.searches-page-title': 'Ricerche',

  'internet-page.statistics-page-www-ok': 'Sito web approvato',
  'internet-page.statistics-page-www-ok-white': 'Sito web sicuro',
  'internet-page.statistics-page-www-timelimit': 'Sito web bloccato a causa dei limiti di tempo',
  'internet-page.statistics-page-www-userdef': "Sito web bloccato, definizioni dell'utente",
  'internet-page.statistics-page-www-content': 'Sito web bloccato, contenuto inappropriato',


  'internet-page.white-black-list-page-title': 'Siti web sicuri / bloccati',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filtri',
  'internet-page.settings-page.white-list-only-label': "Consenti solo l'accesso ai siti presenti nella lista sicura",
  'internet-page.settings-page.block-erotic-label': "Blocca l'accesso ai siti web erotici",
  'internet-page.settings-page.block-offensive-label': "Blocca l'accesso a siti web offensivi, aggressivi",
  'internet-page.settings-page.block-file-sites-label': "Blocca l'accesso a pagine che consentono il download di file da Internet",
  'internet-page.settings-page.block-video-label': "Blocca l'accesso ai portali video",
  'internet-page.settings-page.block-social-label': "Blocca l'accesso ai social network",
  'internet-page.settings-page.block-doc-label': 'Blocca il download di documenti',
  'internet-page.settings-page.block-exe-label': 'Blocca il download di programmi',
  'internet-page.settings-page.excludeYT-label': 'Non forzare la ricerca sicura su YouTube',
  'internet-page.settings-page.block-new-apps': 'Blocca le nuove applicazioni',
  'internet-page.settings-page.block-settings': "Blocca l'accesso alle impostazioni",
  'internet-page.settings-page.custom-msg.card-tile': 'Messaggio per le pagine bloccate',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Usa un messaggio personalizzato per i siti web bloccati',
  'internet-page.settings-page.save-settings-button-label': 'Salva le impostazioni',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Aggiungi alla lista:',
  'internet-page.lists.add-button-label': 'Aggiungi',
  'internet-page.lists.remove-button-label': 'Elimina',
  'internet-page.lists.white-list.card-title': 'Lista sicura',
  'internet-page.lists.black-list.card-title': 'Lista bloccata',
  'internet-page.lists.remove-prefix': "L'indirizzo inserito inizia con \"{prefix}\", si consiglia di aggiungere solo il nome del dominio. Vuoi rimuovere \"{prefix}\" dall'indirizzo?",

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Pagine visitate',

  'internet-page.searches.searches-pages-card-title': 'Frasi cercate',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Programma',
  'internet-page.access-time.schedule-legend-label': 'Legenda del programma',
  'internet-page.access-time.schedule-internet-available-label': 'Internet disponibile',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet bloccato',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Chiamate & messaggi di testo (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Chiamate',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Messaggi di testo (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Ultima modifica:',
  'calls-text-messages-page.contacts-no-changes-label': 'Vuoto.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Contatti',

  // Localization Page
  'localization-page.headline-title': 'Localizzazione',
  'localization-page.headline-title-off': 'Localizzazioni, il tracciamento GPS è disattivato',
  'localization-page.map-hours-filter-label': "Seleziona l'intervallo di tempo per visualizzare la cronologia della posizione",
  'localization-page.gps-tracking-on': 'Tracciamento GPS attivato',
  'localization-page.gps-view-speed': 'Mostra la velocità di spostamento',
  'localization-page.map-loading-label': 'Caricamento mappe...',
  'localization-page.map-no-data': 'NESSUN DATO',

  'localization-page.one-gps-was-off-since': 'I servizi di localizzazione del dispositivo sono stati disattivati dal {lastOffStr}',
  'localization-page.one-gps-was-off-for': 'I servizi di localizzazione del dispositivo sono stati disattivati per {elapsedStr}',
  'localization-page.many-gps-was-off-since': 'I servizi di localizzazione del dispositivo sono stati disattivati dal {lastOffStr}',
  'localization-page.many-gps-was-off-for': 'I servizi di localizzazione del dispositivo sono stati disattivati per {elapsedStr}',


  // Battery Page
  'battery-page.headline-title': 'Batteria',
  'battery-page.chart-description': 'Livello di carica della batteria',

  // Access Time Page
  'access-time-page.headline-title': 'Orario di accesso',
  'access-time-page.nav-access-time-label-applications': 'Applicazioni',
  'access-time-page.nav-access-time-label-internet': 'Pagine web',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Elenco dei video',
  'youtube-page.no-videos': 'Nessun video nella data selezionata',
  'youtube-page.block-videoid': 'Blocca questo video',
  'youtube-page.block-channelid': 'Blocca questo canale',
  'youtube-page.video-blocked': 'Bloccato',

  // Screen Pages
  'screenview-page.headline-title': 'Visualizzazione dello schermo',
  'screenview-page.reload-button': 'Ricarica',
  'screenview-page.chart-description': 'Visualizzazione corrente dello schermo',
  'screengrab-page.headline-title': 'Cattura schermo',
  'screengrab-page.chart-description': 'Cattura schermo',
  'screengrab-page.screen-save-time': 'Quanto spesso viene effettuata la cattura dello schermo',
  'screengrab-page.screen-save-time-desc': 'Scegli quanto spesso viene effettuato lo screenshot o scegli "disabilitato" per non avere nessuno screenshot effettuato',
  'screengrab-page.screen-save-time-disabled': 'disabilitato',
  'screengrab-page.no-screens-for-today': 'Nessuno screenshot effettuato in questo giorno',
  'screengrab-page.no-screens-for-filter': 'Nessuno schermo corrisponde ai criteri selezionati',
  'screengrab-page.imgs-delete-description': 'Sei sicuro di voler cancellare le immagini selezionate?',
  'screengrab-page.imgs-delete-description-one': "Sei sicuro di voler cancellare l'immagine selezionata?",
  'screengrab-page.imgs-delete-label': 'Cancella',
  'screengrab-page.imgs-delete-select': 'Selezionare per cancellare',
  'screengrab-page.imgs-delete-selected': 'Selezionato:',
  'screengrab-page.imgs-delete-limit': 'È stato raggiunto il limite di screenshot selezionati.',
  'screengrab-page.imgs-filter': 'Filtro:',
  'screengrab-page.imgs-filter-communicators': 'Comunicatori',
  'screengrab-page.imgs-filter-social': 'Social Media',
  'screengrab-page.imgs-filter-video': 'Video',
  'screengrab-page.imgs-filter-others': 'Altri',

  'screes-pages.device-offline': ' Il dispositivo è offline',
  'screes-pages.device-deactivated': 'La protezione è stata temporaneamente disattivata',
  'screes-pages.device-timeout': 'Il dispositivo non ha risposto',
  'screes-pages.device-screenoff': 'Lo schermo è spento',
  'screes-pages.device-notallowed': 'Screenshot non consentito sul dispositivo',
  'screes-pages.device-error': 'Si è verificato un errore',

  'lazy-image.img-delete-description': "Sei sicuro di voler cancellare l'immagine selezionata?",

  // Applications Page
  'applications-page.headline-title': 'Applicazioni',
  'applications-page.nav-access-time-label': 'Orario di accesso',
  'applications-page.nav-statistics-label': 'Sommario',
  'applications-page.nav-apps-list-label': 'Lista delle applicazioni',
  'applications-page.chart-activity-by-day-label': 'Attività durante il giorno',
  'applications-page.chart-hour-label': 'Ora',
  'applications-page.chart-activity-by-hour-minutes-label': 'Attività durante il giorno in minuti',
  'applications-page.list-launched-count-label': 'Contatore avvio:',
  'applications-page.list-elapsed-time-label': 'Tempo di esecuzione:',
  'applications-page.introduction': 'Applicazioni installate',

  // Applications Page / Settings
  'applications-page.settings.headline-title': "Programma l'intervallo di tempo",
  'applications-page.settings.schedule-legend-label': 'Legenda programmi',
  'applications-page.settings.schedule-blocked-label': 'App bloccate',
  'applications-page.settings.schedule-available-label': 'App disponibili',
  'applications-page.time-limit.description': "Imposta il limite di tempo per app specifiche. Scegli quante ore al giorno è consentito l'uso dell'applicazione.  ",
  'applications-page.apps-headline-title': 'App disponibili',
  'applications-page.apps-first-seen-label': 'Prima apparizione:',
  'applications-page.apps-available-switch-label': 'Disponibile',
  'applications-page.apps-not-available-switch-label': 'Non disponibile',
  'applications-page.apps-schedule-switch-label': 'Programmato',
  'applications-page.apps-no-schedule-switch-label': 'Non programmato',
  'applications-page.apps-time-limit-switch-label': 'Limite di tempo',
  'applications-page.apps-no-time-limit-switch-label': 'Nessun limite di tempo',
  'applications-page.apps-show-uninstalled-label': 'Mostra applicazioni disinstallate',
  'applications-page.apps-show-apps-list': 'Scegli app con limite di tempo',

  'applications-page.used-count-zero-label': 'Non avviato',
  'applications-page.used-count-one-label': 'Avviato {value} volta, tempo totale: {totalTime}',
  'applications-page.used-count-many-label': 'Avviato {value} volte, tempo totale: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} h {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} sec',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} sec',
  'applications-page.total-time-label': 'Tempo totale ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'Il link di attivazione è stato utilizzato o non è valido',
  'email-confirmation-page.successful-confirm': 'Email confermata. Grazie!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Cambia password',
  'change-password-page.new-password-input-label': 'Nuova password',
  'change-password-page.new-password-repeated-input-label': 'Ripeti nuova password',
  'change-password-page.current-password-input-label': 'Password attuale',
  'change-password-page.back-button-label': 'Indietro',
  'change-password-page.change-button-label': 'CAMBIA',
  'change-password-page.successful-change-label': 'Password è stata cambiata correttamente',
  'change-password-page.wrong-current-password-label': 'Password attuale errata',
  'change-password-page.password-too-short-label': 'La nuova password è troppo corta',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENU',
  'top-navigation.user-menu.account-label': 'Account',
  'top-navigation.user-menu.sign-out': 'Disconnetti',
  'top-navigation.user-menu.subscription-label': 'Piano di abbonamento',
  'top-navigation.user-menu.change-password-label': 'Cambia password',
  'top-navigation.user-menu.add-device-label': 'Aggiungi dispositivo',

  // Sidebar
  'sidebar.menu-item.applications': 'Applicazioni',
  'sidebar.menu-item.calls-and-text-messages': 'Chiamate e SMS',
  'sidebar.menu-item.internet': 'Pagine Web',
  'sidebar.menu-item.localizations': 'Localizzazioni',
  'sidebar.menu-item.social-networks': 'Social network',
  'sidebar.menu-item.summary': 'Cruscotto',
  'sidebar.menu-item.battery': 'Batteria',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Visione schermo',
  'sidebar.menu-item.screengrab': 'Schermata',
  'sidebar.menu-item.access-time': 'Tempo di accesso',

  // Validator
  'phone-number.not-valid': 'Inserisci un numero di telefono valido',
  'input.required-label': 'Campo obbligatorio',
  'input.select-required-label': 'Si prega di selezionare',
  'email.not-valid-label': 'Inserisci un indirizzo e-mail valido',
  'domain-exist-label': 'Dominio è stato aggiunto',
  'domain-exist-other-label': "Il dominio esiste su un'altra lista",
  'domain-not-valid': 'Il dominio non è valido',
  'password-not-match-label': 'Le password non corrispondono',

  // misc
  'button-buy-label': 'Scegli un piano',

  'transactiion-status-0': 'In attesa',
  'transactiion-status-1': 'Completato',
  'transactiion-status--1': 'Annullato',
  'transactiion-status--2': 'Annullato - rimborso',
  'transactiion-status--3': 'In attesa di conferma',
  'transactiion-status--100': 'Iniziato',
  'transactiion-status-other': 'Altro',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Successo',
  'reward-page.error-already-used': 'Questo codice è già stato utilizzato',
  'reward-page.error-invalid-code': 'Codice non corretto',
  'reward-page.error-connection': 'Errore di connessione',
  'reward-page.additional-bonus': 'Bonus',
  'reward-page.get-additional-bonus': 'Richiedi il coupon promozionale',
  'reward-page.get-now': 'Richiedi',
  'reward-page.lic-was-extended-by-days': 'Il tuo piano di abbonamento è stato prolungato di {rewardDays} giorni!',
  'reward-page.there-was-an-error': 'Purtroppo si è verificato un errore.',
  'reward-page.lic-not-extended': 'Il tuo piano di abbonamento non è stato prolungato.',
  'reward-page.button-close': 'Chiudi',

  // url item
  'url-item.copied': 'Copiato negli appunti',
  'url-item.copy-link': 'Copia il link',

  // APK howto

  'apk-howto-google-play': 'Google Play Store limita alcune autorizzazioni delle applicazioni che sono necessarie per utilizzare questa funzione.',
  'apk-howto-on-the-phone': 'Sul telefono del bambino con {applite} installato',
  'apk-howto-step1': 'Disinstalla {applite} se precedentemente scaricato da Google Play',
  'apk-howto-step2': "Scarica l'app direttamente sul telefono del bambino attraverso il nostro sito web",
  'apk-howto-step2B': 'o scansiona il codice QR qui sotto sul telefono del bambino',
  'apk-howto-step3': "Installa l'app scaricata sul dispositivo",
  'apk-howto-tutorial': "Una guida su come installare l'app su un telefono o tablet",

  // Cookies

  'cookies-info-uses': 'Questo sito web utilizza i cookie.',
  'cookies-info-privacy-policy': 'Più informazioni nella politica sulla privacy.',

  // Plan names

  'plan-name-demoExpired': 'Prova scaduta',
  'plan-name-licExpired': 'Piano scaduto',
  'plan-name-reward': 'Bonus',
  'plan-name-demo': 'Prova',
  'plan-name-minimal': 'Piano base',
  'plan-name-regular': 'Piano regolare',
  'plan-name-premium': 'Piano premium',
  'plan-name-edu': 'Aiuto di apprendimento',
  'plan-name-minimal-edu': 'Piano base',
  'plan-name-regular-edu': 'Piano regolare',
  'plan-name-premium-edu': 'Piano premium',

  'plan-name-demoExpired2': ' ',
  'plan-name-licExpired2': ' ',
  'plan-name-reward2': ' ',
  'plan-name-demo2': ' ',
  'plan-name-minimal2': ' ',
  'plan-name-regular2': ' ',
  'plan-name-premium2': ' ',
  'plan-name-edu2': ' ',
  'plan-name-minimal-edu2': "Aiuto per l'apprendimento",
  'plan-name-regular-edu2': "Aiuto per l'apprendimento",
  'plan-name-premium-edu2': "Aiuto per l'apprendimento",

  // agreements

  'agreement-will-inform-user': "Dichiaro che informerò l'utente sul cui dispositivo verrà installata l'app che la sua attività e la sua posizione saranno monitorate.",

  'agreement-privacy-policy': 'Dichiaro di aver letto e accettato i <a href="{regulations}" target="_blank">termini</a> e la <a href="{privacy}" target="_blank">politica sulla privacy</a>. Certifico di avere più di 18 anni e aggiungerò al mio account solo bambini che hanno meno di 18 anni e sono miei dipendenti legali.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': "Acconsento a ricevere contenuti di marketing da {domain} riguardanti offerte promozionali all'indirizzo email e numero di telefono che ho fornito, oltre all'inoltro dei dettagli ai partner pubblicitari del sito menzionati nella politica sulla privacy per lo scopo della pubblicità digitale. <a href=\"{marketing}\" target=\"_blank\">Ulteriori informazioni</a>",


  'gdpr-page1-title': 'Valorizziamo la Tua Privacy',
  'gdpr-page1-content': "Fai clic su \"ACCETTA TUTTO\" per acconsentire all'utilizzo dei Cookie per la raccolta e l'elaborazione automatica dei tuoi dati personali da parte di {domain}. Utilizziamo i Cookie per migliorare la qualità della navigazione, visualizzare annunci o contenuti su misura per le esigenze individuali degli utenti e analizzare il traffico sul nostro sito web. Facendo clic sul pulsante \"ACCETTA TUTTO\" acconsenti all'uso dei nostri Cookie.",

  'gdpr-page1-advanced': 'Ulteriori Informazioni',
  'gdpr-page1-agree': 'ACCETTA TUTTO',

  'gdpr-page2-title': 'Ulteriori Informazioni',
  'gdpr-page2-content': "Quando navighi sul nostro sito web {domain} o {panel}, possiamo raccogliere automaticamente dati sulla tua attività. Questi dati vengono raccolti utilizzando i Cookie. I Cookie sono incorporati nel browser del tuo computer o di altri dispositivi mentre navighi sul sito. I Cookie ti consentono anche di accedere a {panel} per modificare le impostazioni dell'account e visualizzare l'attività degli Utenti protetti. I Cookie vengono utilizzati anche per la personalizzazione e la profilazione degli annunci pubblicitari in base alla tua attività su avosmart.com e tutte le sue sottopagine. Alcuni Cookie sono essenziali per il corretto funzionamento del sito, mentre altri sono opzionali. Scegli a quali Cookie acconsenti. <a href=\"{privacy}\" target=\"_blank\">Maggiori informazioni nella nostra politica sulla privacy.</a>",

  'gdpr-page2-necessary': 'Solo essenziali',
  'gdpr-page2-agree': 'ACCETTA TUTTI',
}

export default {
  locale: 'it',
  name: 'Italiano',
  data: appLocalData,
  messages
} as LanguageLocale
