import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/ja'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'すべて順調！',
  'side-effect.successful-message': 'データが正しく保存されました',
  'side-effect.internal-error-title': '何かが間違っていました',
  'side-effect.internal-error-message': '後でもう一度試すか、インターネット接続を確認してください',

  'monday-sshort-label': '月',
  'tuesday-sshort-label': '火',
  'wednesday-sshort-label': '水',
  'thursday-sshort-label': '木',
  'friday-sshort-label': '金',
  'saturday-sshort-label': '土',
  'sunday-sshort-label': '日',

  'monday-short-label': '月',
  'tuesday-short-label': '火',
  'wednesday-short-label': '水',
  'thursday-short-label': '木',
  'friday-short-label': '金',
  'saturday-short-label': '土',
  'sunday-short-label': '日',

  'monday-label': '月曜日',
  'tuesday-label': '火曜日',
  'wednesday-label': '水曜日',
  'thursday-label': '木曜日',
  'friday-label': '金曜日',
  'saturday-label': '土曜日',
  'sunday-label': '日曜日',

  'january-label': '1月',
  'february-label': '2月',
  'march-label': '3月',
  'april-label': '4月',
  'may-label': '5月',
  'june-label': '6月',
  'july-label': '7月',
  'august-label': '8月',
  'september-label': '9月',
  'october-label': '10月',
  'november-label': '11月',
  'december-label': '12月',

  'interval-name-year': '年',
  'interval-name-month': '月',
  'interval-name-week': '週',
  'interval-name-day': '日',
  'interval-name-years': '年',
  'interval-name-months': '月',
  'interval-name-weeks': '週間',
  'interval-name-days': '日',

  'select-input.no-options-label': 'オプションなし',
  'filter-input.label': 'フィルター:',

  'no-data.label': '表示するデータがありません。フィルターまたは選択した日付を変更してください。',
  'no-data.label-mid': '表示するデータがありません。選択した日付を変更してください。',
  'no-data.label-short': '表示するデータがありません。',
  'empty-list-label': '空のリスト。',
  'overlay.empty-device-label': 'このプロファイルにはまだデバイスが追加されていません。',
  'overlay.empty-profiles-label': 'まだプロファイルが追加されていません。',
  'overlay.empty-device.add-device-label': 'デバイスを追加',

  'confirmation-modal.cancel-button': 'キャンセル',
  'confirmation-modal.confirm-button': '確認',
  'confirmation-modal.button-yes': 'はい',
  'confirmation-modal.button-no': 'いいえ',

  // Date Picker
  'date-picker.select-date-label': '日付を選択',

  // Date Filter
  'date-filter.select-label': '範囲を選択',
  'date-filter.this-month': '今月',
  'date-filter.this.week': '今週',
  'date-filter.this.day': '24時間',
  'date-filter.this.custom': '日を選択',
  'date-filter.from-label': 'から: ',
  'date-filter.to-label': 'まで: ',

  // Time Picker
  'time-picker.unlimied-label': '無制限',
  'time-picker.time-format': '{h}時間 {m}分',
  'time-picker.time-format-min': '{m} 分',
  'time-picker.time-format-hour': '{h} 時間',

  'time-picker.add-bonus-title': '今日だけ時間ボーナスを追加',
  'time-picker.no-bonus': 'ボーナスなし',
  'time-picker.bonus': 'ボーナス',
  'time-picker.add-bonus-button-set': 'ボーナスを設定',
  'time-picker.add-bonus-button-cancel': 'キャンセル',

  // Profile image picker
  'profile-image-picker.date-range-label': '日付範囲',
  'profile-image-picker.girl-label': '女の子',
  'profile-image-picker.boy-label': '男の子',

  // Profile List
  'profile-list.remove-device-label': 'デバイスを削除',
  'profile-list.move-device-label': 'デバイスを他のプロフィールに移動',
  'profile-list.add-device-label': 'デバイスを追加',

  'profile-list.add-profile-label': 'プロフィールを追加',
  'profile-list.edit-profile-label': 'プロフィールを編集',
  'profile-list.add-devices-label': '新しいデバイスを追加',
  'profile-list.remove-profile-label': 'プロフィールを削除',
  'profile-list.remove-profile-disabled-tooltip': 'プロフィールを削除するには、最初にプロフィールに接続されているすべてのデバイスを削除する必要があります。',
  'profile-list.title': 'プロフィールリスト',

  // Profile Form
  'profile-form.full-name-label': '名前',
  'profile-form.month-of-birth-label': '生月',
  'profile-form.year-of-birth-label': '生年',

  // Profile Form
  'profile-form-modal.create.skip-button': 'キャンセル',
  'profile-form-modal.create.submit-button': 'プロフィールを追加',
  'profile-form-modal.create.title': '子供のプロフィールを追加',
  'profile-form-modal.edit.skip-button': 'キャンセル',
  'profile-form-modal.edit.submit-button': '保存',
  'profile-form-modal.edit.title': '子供のプロフィールを編集',
  'profile-form-modal.max-profiles-error': 'プロフィールの上限に達しました',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'デバイスを他のプロフィールに移動',
  'profile-move-device-modal.cancel-button': 'キャンセル',
  'profile-move-device-modal.submit-button': '保存',
  'profile-move-device-modal.select-profile-label': 'プロフィールを選択',

  // CallLog
  'call-log.duration-label': '通話時間:',
  'call-log.seconds-label': '秒。',

  // Create Profile Page
  'create-profile-page.submit-button': 'プロフィールを追加',
  'create-profile-page.skip-button': 'キャンセル',
  'create-profile-page.title': '子供のプロフィールを追加',
  'create-profile-page.description': '誰が監視されるか',

  // Add device page
  'add-device-page.close-button': '閉じる',
  'add-device-page.description': 'アプリをインストールするデバイスの種類を選択',
  'add-device-page.title': 'インストールガイド',
  'add-device-page.check': 'マニュアルを確認',
  'add-device-page.mobile-title': 'Androidスマートフォンまたはタブレット',
  'add-device-page.mobile-description': 'Androidバージョン 6 以上',
  'add-device-page.desktop-title': 'Windowsデスクトップまたはラップトップ',
  'add-device-page.desktop-description': 'Windowsバージョン 7 以上',


  // Purchase Page
  'purchase-page.skip-label': 'ダッシュボードにアクセス',
  'purchase-page.title': '最適なプランを選択',
  'purchase-page.title.features': 'すべてのプランで以下の機能が提供されます',
  'purchase-page.title.reviews': '私たちのユーザーが私たちを愛している理由',
  'purchase-page.subtitle': 'プログラムが期待に沿わない場合は、支払額を返金します',
  'purchase-page.plan-highlight': '最高の価値',
  'purchase-page.monthly': '月額',
  'purchase-page.select': '選択する',
  'purchase-page.close': '閉じる',
  'purchase-page.money-back.title': 'リスクフリー',
  'purchase-page.money-back.desc': '満足できない場合は返金されます。',
  'purchase-page.header.check-1': 'ベストなペアレンタルコントロールアプリ',
  'purchase-page.header.check-2': 'ゲーム、YouTube、ウェブサイトの時間制限を設定',
  'purchase-page.header.check-3': '何十万人もの満足したお客様',
  'purchase-page.plan.year': '年',
  'purchase-page.plan.optimal': '最高の価値',


  'purchase-page.modal.2y-header': '特別オファー！',
  'purchase-page.modal.2y-sub-header': '選択してください <b>2年間プランで節約</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': '選択してください <b>2年間プランで節約</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': '{devices} 台までのデバイスを保護',
  'purchase-page.modal.upgrade': '割引を選択',
  'purchase-page.modal.no-thanks': '1年間プランを続ける',


  'purchase-plan.features.title.feature_1': 'アプリとゲームの時間制限',
  'purchase-plan.features.title.feature_2': 'ウェブサイトフィルタリング',
  'purchase-plan.features.title.feature_3': 'アプリ、ゲーム、ウェブサイトをブロック',
  'purchase-plan.features.title.feature_4': 'YouTubeモニタリング',
  'purchase-plan.features.title.feature_5': 'リモートスクリーンのライブビューイング',
  'purchase-plan.features.title.feature_6': 'リモートアクセス',
  'purchase-plan.features.title.feature_7': '詳細レポート',
  'purchase-plan.features.title.feature_8': '位置情報（電話のみ）',
  'purchase-plan.features.title.feature_9': 'メッセージと通話を確認',
  'purchase-plan.features.title.feature_10': '優先テクニカルサポート',

  'purchase-plan.features.desc.feature_1': 'アプリ、ゲーム、インターネットアクセスに時間制限を設定します。さらに、お子様がそれらにアクセスできる時間帯をスケジュールします。',
  'purchase-plan.features.desc.feature_2': 'すべての不適切なウェブサイトはブロックされます。プログラムはアルゴリズムを使用してウェブサイトの内容を分析し、不適切な素材を検出します。また、膨大なデータベースコレクションから不適切なウェブサイトや単語の情報を収集し、それらは定期的に更新されます。',
  'purchase-plan.features.desc.feature_3': 'お子様に悪影響を与える可能性のあるアプリやウェブサイトをブロックします。さらに、新しくインストールされたゲームは、あなたの承認が必要となります。',
  'purchase-plan.features.desc.feature_4': 'ダッシュボードで、お子様がYouTubeで視聴したすべてのビデオを監視できます。特定のビデオをブロックするか、ビデオサービスへの完全なアクセスをブロックします。',
  'purchase-plan.features.desc.feature_5': 'リモートでお子様の画面のライブフィードにアクセスし、一日の活動をチェックします。これにより、お子様がアクセスしているコンテンツを注意深く監視できます。',
  'purchase-plan.features.desc.feature_6': 'ログインすると、どこからでも、どのデバイスからでも親ダッシュボードにアクセスできます。ダッシュボードでは、設定の調整、子供のデバイスの監視、時間制限の設定が可能です。',
  'purchase-plan.features.desc.feature_7': '親ダッシュボードでは、子供のコンピューターや電話での活動を簡単に監視でき、スクリーンタイムの量、訪問したウェブサイトなどを確認できます。',
  'purchase-plan.features.desc.feature_8': '地図上で子供の現在地と過去の位置を確認します。特定の日や特定の時間に子供がいた場所を正確に把握できます。',
  'purchase-plan.features.desc.feature_9': '親パネルを使って、子供が送受信したメッセージ、電話の通話履歴、および保存された連絡先を簡単に確認できます。',
  'purchase-plan.features.desc.feature_10': '必要な場合は、いつでもご支援いたします。また、{app}の使用をサポートする多くのビデオガイドも用意しています。',



  'purchase-plan.raitings.desc.name_1': 'ソフィア',
  'purchase-plan.raitings.desc.name_2': 'オリビア',
  'purchase-plan.raitings.desc.name_3': 'マテオ',
  'purchase-plan.raitings.desc.name_4': 'ルーカス',
  'purchase-plan.raitings.desc.name_5': 'エマ',
  'purchase-plan.raitings.desc.name_6': 'エマ',

  'purchase-plan.raitings.desc.raiting_1': '非常に効率的です！子供を有害なインターネットコンテンツから完全に保護します。',
  'purchase-plan.raitings.desc.raiting_2': '求めていたのはこれだけ！娘たちのインターネット使用時間を1日1時間に設定しましたが、Avosmartがそれを管理してくれます。',
  'purchase-plan.raitings.desc.raiting_3': 'さまざまな機能があるのでAvosmartをお勧めします。子供たちはインターネットを利用する際に保護されており、私は彼らがどれだけゲームをするかなどの時間をコントロールすることができます。',
  'purchase-plan.raitings.desc.raiting_4': '妻と私にとって、娘のソーシャルメディアの習慣を監視できることは重要でした。Avosmartは娘のデジタル生活と現実生活のバランスを保つのに役立ちます。',
  'purchase-plan.raitings.desc.raiting_5': '息子は以前、インターネットに多くの時間を費やしていました。ノートパソコン、タブレットなどを使用して。Avosmartが提供するレポートを使って、息子がどのウェブサイトをどれくらいの時間訪問したかを正確に把握できます。',
  'purchase-plan.raitings.desc.raiting_6': '今ではいつでも息子の現在の位置を確認できます。素晴らしいサービスです！',


  'account-settings-page.title': 'アカウント設定',
  'account-settings-page.automatic-update': 'Windowsアプリケーションの自動更新',
  'account-settings-page.timezone': 'タイムゾーン：',
  'account-settings-page.send-reports': '毎日のアクティビティレポートを送信',


  'plans-info-page.title': 'サブスクリプション情報',
  'plans-info-page.plan': 'プラン：',
  'plans-info-page.max-devices': 'デバイスの最大数：',
  'plans-info-page.valid-till': '有効期限：',

  'plans-info-page.your-plan': 'あなたのプラン：',
  'plans-info-page.subscription-information': 'サブスクリプション情報',
  'plans-info-page.subscription-created': 'サブスクリプション作成日：',
  'plans-info-page.subscription-current-period': '現在のサブスクリプション期間：',
  'plans-info-page.subscription-renews-on': 'あなたのプランは更新されます',
  'plans-info-page.subscription-cancel-renewal': '更新をキャンセル',
  'plans-info-page.subscription-not-renewed': '現在の期間終了時にプランは更新されません',
  'plans-info-page.subscription-enable-renewal': '更新を有効にする',
  'plans-info-page.subscription-price': 'サブスクリプション価格：',
  'plans-info-page.subscription-price-amount': '{amount}ごとに{period}',


  'plans-info-page.upgrade-plan': 'プランをアップグレード',
  'plans-info-page.extend-lic': 'プランを延長',
  'plans-info-page.subscriptions-portal': '支払い方法を管理',
  'plans-info-page.recent-payments': '最新の取引',
  'plans-info-page.date-of-payment': '取引日：',
  'plans-info-page.transaction-status': '取引状況：',
  'plans-info-page.no-recent-payments': '最新の取引はありません',
  'plans-info-page.btn-close': '閉じる',
  'plans-info-page.plan-devices': '最大{devices}台のデバイスまで',
  'plans-info-page.plan-devices-types': 'コンピューター/タブレット/携帯電話',


  'upgrade-page.title': 'プランをアップグレード',
  'upgrade-page.devices': 'デバイス：',
  'upgrade-page.valid-till': 'プランの有効期限：',
  'upgrade-page.confirm-upgrade': 'プランがアップグレードされ、{amount}が請求されます',
  'upgrade-page.pay-now': '今すぐ支払う {amount}',
  'upgrade-page.pay-then': 'その後、{amount}ごとに{period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'サブスクリプションを購入',
  'purchase-checkout.up-to-devices': '最大{devices}台のデバイスまで',
  'purchase-checkout.subscribtion-price': '{amount}ごとに{period}',
  'purchase-checkout.subscribe': '登録する',
  'purchase-checkout.cancel': 'キャンセル',


  // Purchase Status Page
  'purchase-status-page.title': '支払いを確認中',
  'purchase-status-page.status-pending': '保留中',
  'purchase-status-page.status-completed': '完了',
  'purchase-status-page.status-canceled': 'キャンセルされました',
  'purchase-status-page.status-refund-finalized': '返金完了',
  'purchase-status-page.status-wating-for-conf': '確認待ち',
  'purchase-status-page.status-created': '作成済み',
  'purchase-status-page.status-unkown': '不明',
  'purchase-status-page.status-error': 'エラー',
  'purchase-status-pag.back-button': 'ホームページに戻る',
  'purchase-status-pag.invoice-link': 'VAT請求書を受け取りたい',

  // Login Page
  'login-page.brandline': 'ログイン',
  'login-page.brandline2': 'ウェブサイトのフィルタリング',
  'login-page.email-label': 'メールアドレス',
  'login-page.login-button': 'サインイン',
  'login-page.password-label': 'パスワード',
  'login-page.recovery-link': 'パスワードを忘れた？',
  'login-page.invalid-login-error': '入力されたメールアドレスまたはパスワードが無効です',

  // Register Page
  'register-page.create-account-label': 'アカウントが必要ですか？新しいアカウントを作成する',
  'register-page.description': '無料アカウントを作成',
  'register-page.email-label': 'メールアドレス',
  'register-page.login-link-label': 'アカウントをお持ちですか？ サインイン',
  'register-page.marketing-checkbox-label': 'マーケティングおよびプロモーションの材料を受け取ることに同意します',
  'register-page.password-label': 'パスワード',
  'register-page.phoneNumber-label': '電話番号',
  'register-page.terms-privacy-checkbox-label': 'プライバシーポリシーと利用規約に同意します',
  'register-page.register-button': '登録',
  'register-page.register-success-title': '成功',
  'register-page.register-success-message': '登録に成功しました。メールを確認してメールを確認してください',
  'register-page.register-error-captcha': 'キャプチャエラー。もう一度やり直してください。',
  'register-page.register-error-already-registered-label': '既に登録されています。ログインするか、パスワードを思い出してください。',
  'register-page.register-error-password-to-short-label': 'パスワードが短すぎます。',

  // Forgot Password Page
  'register-page.strong-pass': 'パスワードが簡単すぎて、他の人に簡単に解読される可能性があります。パスワードは大文字と小文字、数字を含み、少なくとも8文字以上である必要があります。本当に簡単なパスワードを使用しますか？',
  'forgot-password-page.headline-title': 'パスワードを思い出す',
  'forgot-password-page.email-label': 'メールアドレス',
  'forgot-password-page.reset-button': 'パスワードを想起する',
  'forgot-password-page.login-link-label': 'ログインページに戻る',
  'forgot-password-page.success-title': 'パスワードリセット',
  'forgot-password-page.success-message': 'パスワードをリセットする方法についての指示をメールで確認してください',
  'forgot-password-page.email-not-confirmed': 'メールアドレスが確認されていません。パスワードリセットは利用できません。',

  // Update Password Page
  'update-password-page.headline-title': '新しいパスワードの設定',
  'update-password-page.warning-title': '注意:',
  'update-password-page.warning-description': '新しいパスワードを設定すると、以前に保存されたロケーションデータが失われます。その他のデータは失われません。',
  'update-password-page.warning-agreement': 'ロケーションデータの損失を理解して承諾します。',
  'update-password-page.new-password': '新しいパスワード:',
  'update-password-page.new-password-retype': '新しいパスワードを再入力:',
  'update-password-page.submit': '新しいパスワードを設定',
  'update-password-page.alert-weak-password': 'パスワードが簡単すぎて、不正な人物によって簡単に推測される可能性があります。パスワードには大文字、小文字、数字を含め、少なくとも8文字以上にする必要があります。それでも簡単なパスワードを使用しますか？',
  'update-password-page.alert-agreement': 'データ損失の承諾が必要です。',
  'update-password-page.error-message': 'エラー！新しいパスワードの設定に失敗しました。',
  'update-password-page.success-message': '新しいパスワードが設定されました',
  'update-password-page.unknow-error-message': '何かが間違っています',
  'update-password-page.error-link': 'リンクが間違っています',
  'update-password-page.error-posswords-not-equal': 'パスワードが一致しません',
  'update-password-page.error-posswords-length': 'パスワードが短すぎます',
  'update-password-page.error-wrong-code': 'コードが間違っています',
  'update-password-page.register-success-title': '成功しました！',
  'update-password-page.register-success-message': '新しいパスワードが設定されました。',

  // Error Page
  'not-found-page.title': 'おっと…エラーが発生したようです！',
  'not-found-page.error-code-text': 'エラーコード',
  'not-found-page.go-back-home-button': 'ホームに戻る',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'プランは{date}に終了します。今日のご契約を検討してください',
  'panel-page.purchase-demo-alert': '現在、試用版を使用しています。',
  'panel-page.purchase-expired-alert': '試用版の有効期限が切れています。継続するにはサブスクリプションを購入してください',
  'panel-page.purchase-lic-expired-alert': 'サブスクリプションプランの有効期限が切れています。継続するにはサブスクリプションを購入してください',
  'panel-page.email-alert': 'メールアドレスが確認されていません',
  'panel-page.button-email-resend': 'メールを再送する',
  'panel-page.email-alert-resend': ' またはもう一度メールを送信してください。',
  'panel-page.email-alert-resend-title': '確認',
  'panel-page.email-alert-resend-msg': 'メールが送信されました！',
  'panel-page.profile-delete-description': '選択したプロフィールを削除してもよろしいですか？',
  'panel-page.profile-delete-device-description': '選択したデバイスを削除してもよろしいですか？データは失われます。',
  'panel-page.profile-delete-device-second-description': 'デバイスの保護が無効になります。続行してもよろしいですか？',

  'panel-page.email-confirmation-overlay-close': '閉じる',
  'panel-page.email-confirmation-overlay-postpone': '後で',
  'panel-page.email-confirmation-overlay-resend': 'メールを再送する',
  'panel-page.email-confirmation-overlay-header': '以下のメールアドレスが確認されていません',
  'panel-page.email-confirmation-overlay-desc': '上記のメールアドレスに誤りがある場合は、すぐにお知らせください。<br>メールが届かない場合は、「再送」をクリックして、他のメールフォルダー（スパム/迷惑メールを含む）を確認してください。',

  'panel-page.email-confirmation-code-success-header': 'ありがとうございます',
  'panel-page.email-confirmation-code-success-desc': 'メールが確認されました！',
  'panel-page.email-confirmation-code-header': 'メールアドレスに送信された6桁の確認コードを入力してください。',
  'panel-page.email-confirmation-code-error-invalid-code': '指定されたコードが無効です',
  'panel-page.email-confirmation-code-error': 'コードを検証中に予期しないエラーが発生しました',

  // Summary Page
  'summary-page.title': 'ダッシュボード',
  'summary-page.top-apps.title': 'トップアプリ',
  'summary-page.top-blocked.title': 'トップブロック',
  'summary-page.top-domains.title': 'トップ訪問ウェブページ',
  'summary-page.top-search.title': 'トップ検索',
  'summary-page.access-disable': '一時的な無効化',
  'internet-page.access-time-limit-set-label': 'インターネットアクセスの1日の制限:',
  'internet-page.access-time-limit-description': '各デバイスの1日の制限時間を設定し、利用を許可されたインターネットの時間を選択します。',
  'disable-access.protection-enabled': '保護はアクティブです',
  'disable-access.protection-disabled-for': '保護が一時的に無効化されています。残り {time} 分',
  'disable-access.device-offline': 'デバイスが利用できません',
  'disable-access.protection-status': '保護状態',


  // Internet Page
  'internet-page.headline-title': 'ウェブページ',
  'internet-page.access-time-page-title': 'アクセス時間',
  'internet-page.settings-page-title': '設定',
  'internet-page.statistics-page-title': '統計',
  'internet-page.searches-page-title': '検索',

  'internet-page.statistics-page-www-ok': '承認されたウェブサイト',
  'internet-page.statistics-page-www-ok-white': '安全なウェブサイト',
  'internet-page.statistics-page-www-timelimit': '時間制限のためにウェブサイトがブロックされました',
  'internet-page.statistics-page-www-userdef': 'ユーザー定義によるウェブサイトブロック',
  'internet-page.statistics-page-www-content': 'Webサイトがブロックされました。不適切な内容です',


  'internet-page.white-black-list-page-title': '安全 / ブロックされたWebサイト',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'フィルター',
  'internet-page.settings-page.white-list-only-label': '安全リストに記載されたサイトへのアクセスのみを許可する',
  'internet-page.settings-page.block-erotic-label': 'アダルトWebサイトへのアクセスをブロックする',
  'internet-page.settings-page.block-offensive-label': '攻撃的で侮辱的なWebサイトへのアクセスをブロックする',
  'internet-page.settings-page.block-file-sites-label': 'インターネットからファイルをダウンロードできるページへのアクセスをブロックする',
  'internet-page.settings-page.block-video-label': 'ビデオポータルへのアクセスをブロックする',
  'internet-page.settings-page.block-social-label': 'ソーシャルネットワークへのアクセスをブロックする',
  'internet-page.settings-page.block-doc-label': '文書のダウンロードをブロックする',
  'internet-page.settings-page.block-exe-label': 'プログラムのダウンロードをブロックする',
  'internet-page.settings-page.excludeYT-label': 'YouTubeでセーフサーチを強制しない',
  'internet-page.settings-page.block-new-apps': '新しいアプリケーションをブロックする',
  'internet-page.settings-page.block-settings': '設定へのアクセスをブロックする',
  'internet-page.settings-page.custom-msg.card-tile': 'ブロックされたページのメッセージ',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'ブロックされたWebサイトにカスタマイズメッセージを使用する',
  'internet-page.settings-page.save-settings-button-label': '設定を保存する',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'リストに追加:',
  'internet-page.lists.add-button-label': '追加',
  'internet-page.lists.remove-button-label': '削除',
  'internet-page.lists.white-list.card-title': '安全リスト',
  'internet-page.lists.black-list.card-title': 'ブロックリスト',
  'internet-page.lists.remove-prefix': '入力されたアドレスは「{prefix}」で始まります。ドメイン名のみを追加することをお勧めします。アドレスから「{prefix}」を削除しますか？',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': '訪問ページ',

  'internet-page.searches.searches-pages-card-title': '検索フレーズ',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'スケジュール',
  'internet-page.access-time.schedule-legend-label': 'スケジュールの凡例',
  'internet-page.access-time.schedule-internet-available-label': 'インターネット利用可能',
  'internet-page.access-time.schedule-internet-blocked-label': 'インターネットアクセスがブロックされました',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': '通話＆テキストメッセージ (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': '通話',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'テキストメッセージ (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': '最終変更日時:',
  'calls-text-messages-page.contacts-no-changes-label': '空です。',
  'calls-text-messages-page.contacts-phone-label': '電話。',
  'calls-text-messages-page.contacts-title': '連絡先',

  // Localization Page
  'localization-page.headline-title': '位置情報',
  'localization-page.headline-title-off': '位置情報、GPSトラッキングがオフになっています。',
  'localization-page.map-hours-filter-label': '位置情報履歴を表示するための時間範囲を選択してください',
  'localization-page.gps-tracking-on': 'GPSトラッキングが有効です',
  'localization-page.gps-view-speed': '移動速度を表示',
  'localization-page.map-loading-label': '地図を読み込んでいます...',
  'localization-page.map-no-data': 'データなし',

  'localization-page.one-gps-was-off-since': 'デバイスの位置情報サービスが{lastOffStr}からオフになっています',
  'localization-page.one-gps-was-off-for': 'デバイスの位置情報サービスが{elapsedStr}間オフになっています',
  'localization-page.many-gps-was-off-since': 'デバイスの位置情報サービスが{lastOffStr}からオフになっています',
  'localization-page.many-gps-was-off-for': 'デバイスの位置情報サービスが{elapsedStr}間オフになっています',


  // Battery Page
  'battery-page.headline-title': 'バッテリー',
  'battery-page.chart-description': 'バッテリー残量',

  // Access Time Page
  'access-time-page.headline-title': 'アクセス時間',
  'access-time-page.nav-access-time-label-applications': 'アプリケーション',
  'access-time-page.nav-access-time-label-internet': 'ウェブページ',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': '動画リスト',
  'youtube-page.no-videos': '選択した日付に動画がありません',
  'youtube-page.block-videoid': 'この動画をブロック',
  'youtube-page.block-channelid': 'このチャンネルをブロック',
  'youtube-page.video-blocked': 'ブロック済み',

  // Screen Pages
  'screenview-page.headline-title': '画面表示',
  'screenview-page.reload-button': '再読み込み',
  'screenview-page.chart-description': '現在の画面表示',
  'screengrab-page.headline-title': '画面キャプチャ',
  'screengrab-page.chart-description': 'スクリーンキャプチャ',
  'screengrab-page.screen-save-time': 'スクリーンキャプチャが取得される頻度',
  'screengrab-page.screen-save-time-desc': 'スクリーンキャプチャが取得される頻度を選択するか、「無効」を選択してスクリーンキャプチャを取得しないようにする',
  'screengrab-page.screen-save-time-disabled': '無効',
  'screengrab-page.no-screens-for-today': 'この日にスクリーンキャプチャは取得されません',
  'screengrab-page.no-screens-for-filter': '選択した条件に一致する画面はありません',
  'screengrab-page.imgs-delete-description': '選択した画像を削除してもよろしいですか？',
  'screengrab-page.imgs-delete-description-one': '選択した画像を削除してもよろしいですか？',
  'screengrab-page.imgs-delete-label': '削除',
  'screengrab-page.imgs-delete-select': '削除するには選択',
  'screengrab-page.imgs-delete-selected': '選択中:',
  'screengrab-page.imgs-delete-limit': '選択したスクリーンショットの制限に達しました。',
  'screengrab-page.imgs-filter': 'フィルター:',
  'screengrab-page.imgs-filter-communicators': 'コミュニケーター',
  'screengrab-page.imgs-filter-social': 'ソーシャルメディア',
  'screengrab-page.imgs-filter-video': 'ビデオ',
  'screengrab-page.imgs-filter-others': 'その他',

  'screes-pages.device-offline': 'デバイスはオフラインです',
  'screes-pages.device-deactivated': '保護が一時的に無効化されました',
  'screes-pages.device-timeout': 'デバイスが応答しませんでした',
  'screes-pages.device-screenoff': '画面がオフになっています',
  'screes-pages.device-notallowed': 'デバイスでスクリーンキャプチャは許可されていません',
  'screes-pages.device-error': 'エラーが発生しました',

  'lazy-image.img-delete-description': '選択した画像を削除してもよろしいですか？',

  // Applications Page
  'applications-page.headline-title': 'アプリケーション',
  'applications-page.nav-access-time-label': 'アクセス時間',
  'applications-page.nav-statistics-label': '概要',
  'applications-page.nav-apps-list-label': 'アプリリスト',
  'applications-page.chart-activity-by-day-label': '一日の活動',
  'applications-page.chart-hour-label': '時間',
  'applications-page.chart-activity-by-hour-minutes-label': '一日の活動（分単位）',
  'applications-page.list-launched-count-label': '起動回数：',
  'applications-page.list-elapsed-time-label': '実行時間：',
  'applications-page.introduction': 'インストールされたアプリ',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'スケジュールの時間範囲',
  'applications-page.settings.schedule-legend-label': 'スケジュールの凡例',
  'applications-page.settings.schedule-blocked-label': 'ブロックされたアプリ',
  'applications-page.settings.schedule-available-label': '利用可能なアプリ',
  'applications-page.time-limit.description': '特定のアプリに対して時間制限を設定します。 アプリ使用が許可される時間数を一日に設定してください。',
  'applications-page.apps-headline-title': '利用可能なアプリ',
  'applications-page.apps-first-seen-label': '最初に確認：',
  'applications-page.apps-available-switch-label': '利用可能',
  'applications-page.apps-not-available-switch-label': '利用不可',
  'applications-page.apps-schedule-switch-label': '予定済み',
  'applications-page.apps-no-schedule-switch-label': '未予定',
  'applications-page.apps-time-limit-switch-label': '時間制限',
  'applications-page.apps-no-time-limit-switch-label': '時間制限なし',
  'applications-page.apps-show-uninstalled-label': '未インストールのアプリを表示',
  'applications-page.apps-show-apps-list': '時間制限されたアプリを選択',

  'applications-page.used-count-zero-label': '起動されていません',
  'applications-page.used-count-one-label': '{value} 回起動されました, 合計時間: {totalTime}',
  'applications-page.used-count-many-label': '{value} 回起動されました, 合計時間: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} 時間 {minutes} 分',
  'applications-page.total-minutes-seconds-label': '{minutes} 分 {seconds} 秒',
  'applications-page.total-minutes-label': '{minutes} 分',
  'applications-page.total-seconds-label': '{seconds} 秒',
  'applications-page.total-time-label': '合計時間 ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'アクティベーションリンクは使用されているか無効です',
  'email-confirmation-page.successful-confirm': 'メールが確認されました。ありがとう!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'パスワードを変更する',
  'change-password-page.new-password-input-label': '新しいパスワード',
  'change-password-page.new-password-repeated-input-label': '新しいパスワードを再入力',
  'change-password-page.current-password-input-label': '現在のパスワード',
  'change-password-page.back-button-label': '戻る',
  'change-password-page.change-button-label': '変更',
  'change-password-page.successful-change-label': 'パスワードが正常に変更されました',
  'change-password-page.wrong-current-password-label': '現在のパスワードが間違っています',
  'change-password-page.password-too-short-label': '新しいパスワードが短すぎます',

  // TopNavigation
  'top-navigation.hamburger-menu': 'メニュー',
  'top-navigation.user-menu.account-label': 'アカウント',
  'top-navigation.user-menu.sign-out': 'サインアウト',
  'top-navigation.user-menu.subscription-label': 'サブスクリプションプラン',
  'top-navigation.user-menu.change-password-label': 'パスワードを変更する',
  'top-navigation.user-menu.add-device-label': 'デバイスを追加',

  // Sidebar
  'sidebar.menu-item.applications': 'アプリケーション',
  'sidebar.menu-item.calls-and-text-messages': '通話とテキスト (SMS)',
  'sidebar.menu-item.internet': 'ウェブページ',
  'sidebar.menu-item.localizations': 'ローカリゼーション',
  'sidebar.menu-item.social-networks': 'ソーシャルネットワーク',
  'sidebar.menu-item.summary': 'ダッシュボード',
  'sidebar.menu-item.battery': 'バッテリー',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': '画面ビュー',
  'sidebar.menu-item.screengrab': 'スクリーンショット',
  'sidebar.menu-item.access-time': 'アクセス時間',

  // Validator
  'phone-number.not-valid': '有効な電話番号を入力してください',
  'input.required-label': 'このフィールドは必須です',
  'input.select-required-label': '選択してください',
  'email.not-valid-label': '有効なメールアドレスを入力してください',
  'domain-exist-label': 'ドメインが追加されました',
  'domain-exist-other-label': 'ドメインは他のリストに存在します',
  'domain-not-valid': 'ドメインが無効です',
  'password-not-match-label': 'パスワードが一致しません',

  // misc
  'button-buy-label': 'プランを選択してください',

  'transactiion-status-0': '待機中',
  'transactiion-status-1': '完了',
  'transactiion-status--1': 'キャンセルされました',
  'transactiion-status--2': 'キャンセル – 返金',
  'transactiion-status--3': '確認待ち',
  'transactiion-status--100': '開始されました',
  'transactiion-status-other': 'その他',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': '成功',
  'reward-page.error-already-used': 'このコードは既に使用されています',
  'reward-page.error-invalid-code': 'コードが正しくありません',
  'reward-page.error-connection': '接続エラー',
  'reward-page.additional-bonus': 'ボーナス',
  'reward-page.get-additional-bonus': 'プロモーションクーポンを請求してください',
  'reward-page.get-now': '請求する',
  'reward-page.lic-was-extended-by-days': 'ご登録いただいているプランが{rewardDays}日間延長されました！',
  'reward-page.there-was-an-error': '残念ながら、エラーが発生しました。',
  'reward-page.lic-not-extended': 'ご登録いただいているプランは延長されていません。',
  'reward-page.button-close': '閉じる',

  // url item
  'url-item.copied': 'クリップボードにコピーしました',
  'url-item.copy-link': 'リンクをコピー',

  // APK howto

  'apk-howto-google-play': 'Google Play ストアは、この機能を利用するために必要な特定のアプリケーション許可を制限しています。',
  'apk-howto-on-the-phone': '{applite}がインストールされた子供のスマートフォンに',
  'apk-howto-step1': '以前にGoogle Playからダウンロードした場合は、{applite}をアンインストールしてください',
  'apk-howto-step2': '私たちのウェブサイトから直接、子供のスマートフォンにアプリをダウンロードしてください',
  'apk-howto-step2B': 'または、子供のスマートフォンで下のQRコードをスキャンしてください',
  'apk-howto-step3': 'ダウンロードしたアプリをデバイスにインストールしてください',
  'apk-howto-tutorial': 'スマートフォンやタブレットにアプリをインストールするためのガイド',

  // Cookies

  'cookies-info-uses': 'このウェブサイトはクッキーを使用しています。',
  'cookies-info-privacy-policy': 'プライバシーポリシーで詳しくご覧ください。',

  // Plan names

  'plan-name-demoExpired': '試用期間が終了しました',
  'plan-name-licExpired': 'プランの期限が切れました',
  'plan-name-reward': 'ボーナス',
  'plan-name-demo': 'トライアル',
  'plan-name-minimal': 'ベーシックプラン',
  'plan-name-regular': 'レギュラープラン',
  'plan-name-premium': 'プレミアムプラン',
  'plan-name-edu': '学習ヘルプ',
  'plan-name-minimal-edu': 'ベーシックプラン',
  'plan-name-regular-edu': 'レギュラープラン',
  'plan-name-premium-edu': 'プレミアムプラン',

  'plan-name-demoExpired2': ' ',
  'plan-name-licExpired2': ' ',
  'plan-name-reward2': ' ',
  'plan-name-demo2': ' ',
  'plan-name-minimal2': ' ',
  'plan-name-regular2': ' ',
  'plan-name-premium2': ' ',
  'plan-name-edu2': ' ',
  'plan-name-minimal-edu2': '学習の助け',
  'plan-name-regular-edu2': '学習の助け',
  'plan-name-premium-edu2': '学習の助け',

  // agreements

  'agreement-will-inform-user': 'アプリをインストールするデバイスのユーザーに対して、その活動と場所が監視されることを通知することを宣言します。',

  'agreement-privacy-policy': '<a href="{regulations}" target="_blank">利用規約</a>と<a href="{privacy}" target="_blank">プライバシーポリシー</a>を読み、同意したことを宣言します。18歳以上であり、アカウントに追加する子供は18歳未満であり、法的扶養家族であることを証明します。',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': '{domain}からのプロモーションオファーについて、提供したメールアドレスおよび電話番号にマーケティングコンテンツを受け取ることに同意します。また、プライバシーポリシーに記載されたサイトの広告パートナーに詳細を転送し、デジタル広告の目的で使用することできます。<a href="{marketing}" target="_blank">詳細</a>',


  'gdpr-page1-title': '私たちはお客様のプライバシーを大切にします',
  'gdpr-page1-content': '{domain} による個人データの自動収集および処理のために、Cookies の使用に同意するには、「すべてを受け入れる」をクリックしてください。私たちは、ブラウジングの質を向上させ、個々のユーザーのニーズに合わせた広告やコンテンツを表示し、ウェブサイトのトラフィックを分析するために Cookies を使用します。「すべてを受け入れる」をクリックすると、Cookies の使用に同意したことになります。',

  'gdpr-page1-advanced': '詳細情報',
  'gdpr-page1-agree': 'すべてを受け入れる',

  'gdpr-page2-title': '詳細	 情報',
  'gdpr-page2-content': '当社のウェブサイト {domain} または {panel} を閲覧する際に、お客様のアクティビティに関するデータを自動的に収集する場合があります。このデータは、Cookie を使用して収集されます。Cookie は、サイトを閲覧中にコンピュータやその他のデバイスのブラウザに組み込まれます。Cookie を使用すると、アカウント設定の変更や保護されたユーザーのアクティビティの表示のために {panel} にログインすることもできます。Cookie は、avosmart.com およびそのすべてのサブページでのアクティビティに基づいて広告のパーソナライゼーションやプロファイリングにも使用されます。一部のCookieはサイトの適切な機能に不可欠ですが、その他はオプションです。どのCookieに同意するかを選択してください。<a href="{privacy}" target="_blank">詳細は当社のプライバシーポリシーをご覧ください。</a>',

  'gdpr-page2-necessary': '必須のみ',
  'gdpr-page2-agree': 'すべて受け入れる',
}

export default {
  locale: 'ja',
  name: '日本語',
  data: appLocalData,
  messages
} as LanguageLocale
