import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/pt'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'Tudo OK!',
  'side-effect.successful-message': 'Os dados foram salvos corretamente',
  'side-effect.internal-error-title': 'Algo deu errado',
  'side-effect.internal-error-message': 'Por favor, tente novamente mais tarde ou verifique sua conexão com a internet',

  'monday-sshort-label': 'Se',
  'tuesday-sshort-label': 'Te',
  'wednesday-sshort-label': 'Qu',
  'thursday-sshort-label': 'Qu',
  'friday-sshort-label': 'Se',
  'saturday-sshort-label': 'Sá',
  'sunday-sshort-label': 'Do',

  'monday-short-label': 'Seg',
  'tuesday-short-label': 'Ter',
  'wednesday-short-label': 'Qua',
  'thursday-short-label': 'Qui',
  'friday-short-label': 'Sex',
  'saturday-short-label': 'Sáb',
  'sunday-short-label': 'Dom',

  'monday-label': 'Segunda-feira',
  'tuesday-label': 'Terça-feira',
  'wednesday-label': 'Quarta-feira',
  'thursday-label': 'Quinta-feira',
  'friday-label': 'Sexta-feira',
  'saturday-label': 'Sábado',
  'sunday-label': 'Domingo',

  'january-label': 'Janeiro',
  'february-label': 'Fevereiro',
  'march-label': 'Março',
  'april-label': 'Abril',
  'may-label': 'Maio',
  'june-label': 'Junho',
  'july-label': 'Julho',
  'august-label': 'Agosto',
  'september-label': 'Setembro',
  'october-label': 'Outubro',
  'november-label': 'Novembro',
  'december-label': 'Dezembro',

  'interval-name-year': 'ano',
  'interval-name-month': 'mês',
  'interval-name-week': 'semana',
  'interval-name-day': 'dia',
  'interval-name-years': 'anos',
  'interval-name-months': 'meses',
  'interval-name-weeks': 'semanas',
  'interval-name-days': 'dias',

  'select-input.no-options-label': 'Sem opções',
  'filter-input.label': 'Filtrar:',

  'no-data.label': 'Nenhum dado para mostrar. Altere os filtros ou a data selecionada.',
  'no-data.label-mid': 'Nenhum dado para mostrar. Altere a data selecionada.',
  'no-data.label-short': 'Nenhum dado para mostrar.',
  'empty-list-label': 'Lista vazia.',
  'overlay.empty-device-label': 'Nenhum dispositivo foi adicionado a este perfil ainda.',
  'overlay.empty-profiles-label': 'Nenhum perfil adicionado ainda.',
  'overlay.empty-device.add-device-label': 'Adicionar dispositivo',

  'confirmation-modal.cancel-button': 'Cancelar',
  'confirmation-modal.confirm-button': 'CONFIRMAR',
  'confirmation-modal.button-yes': 'Sim',
  'confirmation-modal.button-no': 'Não',

  // Date Picker
  'date-picker.select-date-label': 'Selecione a data',

  // Date Filter
  'date-filter.select-label': 'Selecione o intervalo',
  'date-filter.this-month': 'Este mês',
  'date-filter.this.week': 'Esta semana',
  'date-filter.this.day': '24h',
  'date-filter.this.custom': 'Selecione o dia',
  'date-filter.from-label': 'De: ',
  'date-filter.to-label': 'Até: ',

  // Time Picker
  'time-picker.unlimied-label': 'Ilimitado',
  'time-picker.time-format': '{h}h {m}min',
  'time-picker.time-format-min': '{m} min',
  'time-picker.time-format-hour': '{h} h',

  'time-picker.add-bonus-title': 'Adicionar bônus de tempo apenas para hoje',
  'time-picker.no-bonus': 'Sem bônus',
  'time-picker.bonus': 'Bônus',
  'time-picker.add-bonus-button-set': 'Definir bônus',
  'time-picker.add-bonus-button-cancel': 'Cancelar',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Intervalo de datas',
  'profile-image-picker.girl-label': 'Menina',
  'profile-image-picker.boy-label': 'Menino',

  // Profile List
  'profile-list.remove-device-label': 'Remover dispositivo',
  'profile-list.move-device-label': 'Mover dispositivo para outro perfil',
  'profile-list.add-device-label': 'Adicionar dispositivo',

  'profile-list.add-profile-label': 'Adicionar perfil',
  'profile-list.edit-profile-label': 'Editar perfil',
  'profile-list.add-devices-label': 'Adicionar novo dispositivo',
  'profile-list.remove-profile-label': 'Remover perfil',
  'profile-list.remove-profile-disabled-tooltip': 'Para excluir seu perfil, primeiro você deve remover todos os dispositivos vinculados ao perfil.',
  'profile-list.title': 'Lista de perfis',

  // Profile Form
  'profile-form.full-name-label': 'Nome',
  'profile-form.month-of-birth-label': 'Mês de nascimento',
  'profile-form.year-of-birth-label': 'Ano de nascimento',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Cancelar',
  'profile-form-modal.create.submit-button': 'ADICIONAR PERFIL',
  'profile-form-modal.create.title': 'Adicionar perfil infantil',
  'profile-form-modal.edit.skip-button': 'Cancelar',
  'profile-form-modal.edit.submit-button': 'SALVAR',
  'profile-form-modal.edit.title': 'Editar perfil da criança',
  'profile-form-modal.max-profiles-error': 'Limite de perfil atingido',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Mover dispositivo para outro perfil',
  'profile-move-device-modal.cancel-button': 'Cancelar',
  'profile-move-device-modal.submit-button': 'Salvar',
  'profile-move-device-modal.select-profile-label': 'Escolher perfil',

  // CallLog
  'call-log.duration-label': 'Duração da chamada:',
  'call-log.seconds-label': 's.',

  // Create Profile Page
  'create-profile-page.submit-button': 'ADICIONAR PERFIL',
  'create-profile-page.skip-button': 'Cancelar',
  'create-profile-page.title': 'Adicionar perfil da criança',
  'create-profile-page.description': 'Quem será monitorado',

  // Add device page
  'add-device-page.close-button': 'FECHAR',
  'add-device-page.description': 'Escolha o tipo de dispositivo para instalar o app',
  'add-device-page.title': 'Guia de Instalação',
  'add-device-page.check': 'Verifique o manual',
  'add-device-page.mobile-title': 'Smartphone ou tablet Android',
  'add-device-page.mobile-description': 'Versão Android 6 ou superior',
  'add-device-page.desktop-title': 'Desktop ou laptop Windows',
  'add-device-page.desktop-description': 'Versão Windows 7 ou superior',


  // Purchase Page
  'purchase-page.skip-label': 'Vá para o painel',
  'purchase-page.title': 'Escolha o melhor plano',
  'purchase-page.title.features': 'Todos os planos oferecem as seguintes funções',
  'purchase-page.title.reviews': 'Por que nossos usuários nos adoram',
  'purchase-page.subtitle': 'Se o programa não atender às suas expectativas, devolveremos o pagamento',
  'purchase-page.plan-highlight': 'Melhor valor',
  'purchase-page.monthly': 'mensal',
  'purchase-page.select': 'Escolher',
  'purchase-page.close': 'Fechar',
  'purchase-page.money-back.title': 'Sem riscos',
  'purchase-page.money-back.desc': 'Se você não estiver satisfeito, será reembolsado.',
  'purchase-page.header.check-1': 'Melhor aplicativo de controle parental',
  'purchase-page.header.check-2': 'Defina limite de tempo para jogos, YouTube e sites',
  'purchase-page.header.check-3': 'Centenas de milhares de clientes satisfeitos',
  'purchase-page.plan.year': 'ano',
  'purchase-page.plan.optimal': 'Melhor valor',


  'purchase-page.modal.2y-header': 'Oferta especial!',
  'purchase-page.modal.2y-sub-header': 'Escolha o <b>plano de 2 anos e economize</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Escolha o <b>plano de 2 anos e economize</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Proteja até {devices} dispositivos',
  'purchase-page.modal.upgrade': 'Escolha o desconto',
  'purchase-page.modal.no-thanks': 'Continue com o plano de 1 ano',


  'purchase-plan.features.title.feature_1': 'Limite de tempo para aplicativos e jogos',
  'purchase-plan.features.title.feature_2': 'Filtragem de sites',
  'purchase-plan.features.title.feature_3': 'Bloquear aplicativos, jogos e sites',
  'purchase-plan.features.title.feature_4': 'Monitoramento do Youtube',
  'purchase-plan.features.title.feature_5': 'Visualização de tela ao vivo',
  'purchase-plan.features.title.feature_6': 'Acesso remoto',
  'purchase-plan.features.title.feature_7': 'Relatórios detalhados',
  'purchase-plan.features.title.feature_8': 'Localização (apenas telefone)',
  'purchase-plan.features.title.feature_9': 'Verificar mensagens e chamadas',
  'purchase-plan.features.title.feature_10': 'Suporte técnico prioritário',

  'purchase-plan.features.desc.feature_1': 'Defina limites de tempo para aplicativos, jogos e acesso à internet. Além disso, agende quais horas seu filho está autorizado a acessá-los',
  'purchase-plan.features.desc.feature_2': 'Todos os sites inapropriados são bloqueados. O programa usa um algoritmo que analisa o conteúdo do site para qualquer material inadequado. Ele também coleta informações de uma imensa coleção de bancos de dados de sites e palavras inapropriadas, ambos atualizados regularmente.',
  'purchase-plan.features.desc.feature_3': 'Bloqueie aplicativos e sites que podem ter um impacto negativo em seus filhos. Além disso, qualquer novo jogo instalado exigirá sua autorização.',
  'purchase-plan.features.desc.feature_4': 'No painel, monitore todos os vídeos que seu filho assiste no YouTube. Bloqueie qualquer vídeo ou bloqueie completamente o acesso aos serviços de vídeo.',
  'purchase-plan.features.desc.feature_5': 'Acesse remotamente uma transmissão ao vivo da tela do seu filho e verifique a atividade dele ao longo do dia. Isso permite manter um controle rigoroso sobre o conteúdo que seu filho está acessando.',
  'purchase-plan.features.desc.feature_6': 'Depois de fazer login, é possível acessar o painel dos pais de qualquer lugar e de qualquer dispositivo. No painel, ajuste as configurações, monitore os dispositivos das crianças e defina limites de tempo.',
  'purchase-plan.features.desc.feature_7': 'No painel dos pais, monitore facilmente a atividade de uma criança no computador ou no telefone, veja quanto tempo de tela ela teve, quais sites ela visitou e muito mais.',
  'purchase-plan.features.desc.feature_8': 'Verifique a localização atual e as localizações anteriores do seu filho no mapa. Veja exatamente onde seu filho estava em qualquer dia ou em um horário específico.',
  'purchase-plan.features.desc.feature_9': 'Você pode facilmente verificar as mensagens enviadas e recebidas do seu filho, chamadas telefônicas e contatos salvos usando o painel dos pais.',
  'purchase-plan.features.desc.feature_10': 'Se precisar de ajuda, estamos sempre disponíveis. Também há muitos guias em vídeo para ajudá-lo a usar {app}.',



  'purchase-plan.raitings.desc.name_1': 'Sophia',
  'purchase-plan.raitings.desc.name_2': 'Olivia',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lucas',
  'purchase-plan.raitings.desc.name_5': 'Emma',
  'purchase-plan.raitings.desc.name_6': 'Emma',

  'purchase-plan.raitings.desc.raiting_1': 'Muito eficiente! Protege completamente meu filho de conteúdos prejudiciais na internet.',
  'purchase-plan.raitings.desc.raiting_2': 'Exatamente o que eu estava procurando! Defini um limite diário de uma hora para minhas filhas acessarem a internet e o Avosmart cuida disso para mim.',
  'purchase-plan.raitings.desc.raiting_3': 'Eu recomendo o Avosmart porque ele possui uma variedade de funções. Meus filhos estão protegidos ao usar a internet e eu consigo controlar quanto tempo eles passam jogando, etc.',
  'purchase-plan.raitings.desc.raiting_4': 'Era importante para minha esposa e eu conseguirmos observar os hábitos de mídia social da nossa filha. O Avosmart nos ajuda a manter a vida digital e real da nossa filha equilibrada.',
  'purchase-plan.raitings.desc.raiting_5': 'Meu filho costumava passar muito tempo na internet, usando um laptop, tablet, etc. Com os relatórios fornecidos pelo Avosmart, sei exatamente quais sites meu filho visita e por quanto tempo.',
  'purchase-plan.raitings.desc.raiting_6': 'Agora posso verificar a localização atual do meu filho a qualquer momento. Um serviço excelente!',


  'account-settings-page.title': 'Configurações da conta',
  'account-settings-page.automatic-update': 'Atualizações automáticas do aplicativo Windows',
  'account-settings-page.timezone': 'Fuso horário: ',
  'account-settings-page.send-reports': 'Enviar relatórios diários de atividades',


  'plans-info-page.title': 'Informações da assinatura',
  'plans-info-page.plan': ' Plano:',
  'plans-info-page.max-devices': 'Número máximo de dispositivos:',
  'plans-info-page.valid-till': 'Data de validade:',

  'plans-info-page.your-plan': 'Seu plano:',
  'plans-info-page.subscription-information': 'Informações da assinatura',
  'plans-info-page.subscription-created': 'Assinatura criada: ',
  'plans-info-page.subscription-current-period': 'Período da assinatura atual: ',
  'plans-info-page.subscription-renews-on': 'Seu plano renova em ',
  'plans-info-page.subscription-cancel-renewal': 'Cancelar renovação',
  'plans-info-page.subscription-not-renewed': 'Seu plano NÃO será renovado no final do período atual',
  'plans-info-page.subscription-enable-renewal': 'Habilitar renovação',
  'plans-info-page.subscription-price': 'Preço da assinatura: ',
  'plans-info-page.subscription-price-amount': '{quantia} a cada {período}',


  'plans-info-page.upgrade-plan': 'Atualize seu plano',
  'plans-info-page.extend-lic': 'Estenda seu plano',
  'plans-info-page.subscriptions-portal': 'Gerencie seus métodos de pagamento',
  'plans-info-page.recent-payments': 'Últimas transações',
  'plans-info-page.date-of-payment': 'Data da transação:',
  'plans-info-page.transaction-status': 'Status da transação:',
  'plans-info-page.no-recent-payments': 'Sem transações recentes',
  'plans-info-page.btn-close': 'Fechar',
  'plans-info-page.plan-devices': 'até {dispositivos} dispositivos',
  'plans-info-page.plan-devices-types': 'computadores/tablets/telefones',


  'upgrade-page.title': 'Atualizar o plano',
  'upgrade-page.devices': 'Dispositivos: ',
  'upgrade-page.valid-till': 'Seu plano é válido até: ',
  'upgrade-page.confirm-upgrade': 'Seu plano será atualizado, você será cobrado {quantia}',
  'upgrade-page.pay-now': 'Pagar agora {quantia}',
  'upgrade-page.pay-then': 'Em seguida {quantia} a cada {período}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Comprar assinatura',
  'purchase-checkout.up-to-devices': 'Até {dispositivos} dispositivos',
  'purchase-checkout.subscribtion-price': '{quantia} a cada {período}',
  'purchase-checkout.subscribe': 'Assinar',
  'purchase-checkout.cancel': 'Cancelar',


  // Purchase Status Page
  'purchase-status-page.title': 'Verificando pagamento',
  'purchase-status-page.status-pending': 'Pendente',
  'purchase-status-page.status-completed': 'Concluído',
  'purchase-status-page.status-canceled': 'Cancelado',
  'purchase-status-page.status-refund-finalized': 'Reembolso finalizado',
  'purchase-status-page.status-wating-for-conf': 'Esperando confirmação',
  'purchase-status-page.status-created': 'Criado',
  'purchase-status-page.status-unkown': 'Desconhecido',
  'purchase-status-page.status-error': 'Erro',
  'purchase-status-pag.back-button': 'Voltar para a página inicial',
  'purchase-status-pag.invoice-link': 'Quero receber uma fatura com IVA',

  // Login Page
  'login-page.brandline': 'Fazer login',
  'login-page.brandline2': 'Filtragem de websites',
  'login-page.email-label': 'endereço de e-mail',
  'login-page.login-button': 'ENTRAR',
  'login-page.password-label': 'Senha',
  'login-page.recovery-link': 'Esqueceu a senha?',
  'login-page.invalid-login-error': 'O endereço de e-mail ou senha que você entrou não é válido',

  // Register Page
  'register-page.create-account-label': 'Precisa de uma conta? Crie uma nova conta',
  'register-page.description': 'Crie sua conta gratuita',
  'register-page.email-label': 'Endereço de e-mail',
  'register-page.login-link-label': 'Já tem uma conta? Entrar',
  'register-page.marketing-checkbox-label': 'Eu concordo em receber materiais de marketing e promocionais',
  'register-page.password-label': 'Senha',
  'register-page.phoneNumber-label': 'Número de telefone',
  'register-page.terms-privacy-checkbox-label': 'Eu concordo com a política de privacidade e os termos de uso',
  'register-page.register-button': 'REGISTRAR',
  'register-page.register-success-title': 'Sucesso',
  'register-page.register-success-message': 'Registro bem-sucedido. Verifique sua caixa de entrada para confirmar o e-mail',
  'register-page.register-error-captcha': 'Erro de Captcha. Tente novamente.',
  'register-page.register-error-already-registered-label': 'Já registrado. Tente fazer login ou recuperar a senha.',
  'register-page.register-error-password-to-short-label': 'Senha muito curta.',

  // Forgot Password Page
  'register-page.strong-pass': 'Sua senha é muito simples e pode ser facilmente decifrada por outras pessoas. Sua senha deve conter letras maiúsculas e minúsculas, bem como números, e deve ter pelo menos 8 caracteres. Tem certeza de que deseja usar uma senha simples?',
  'forgot-password-page.headline-title': 'Recuperar senha',
  'forgot-password-page.email-label': 'endereço de e-mail',
  'forgot-password-page.reset-button': 'RECUPERAR SENHA',
  'forgot-password-page.login-link-label': 'Voltar à página de login',
  'forgot-password-page.success-title': 'Redefinição de senha',
  'forgot-password-page.success-message': 'Verifique sua caixa de entrada para obter instruções sobre como redefinir sua senha',
  'forgot-password-page.email-not-confirmed': 'Seu endereço de e-mail não está verificado. Redefinição de senha indisponível.',

  // Update Password Page
  'update-password-page.headline-title': 'Definindo uma nova senha',
  'update-password-page.warning-title': 'NOTA:',
  'update-password-page.warning-description': 'Configurar uma nova senha resultará na perda dos dados de localização previamente salvos. Os dados restantes não serão perdidos.',
  'update-password-page.warning-agreement': 'Entendo e aceito a perda dos dados de localização.',
  'update-password-page.new-password': 'Nova senha:',
  'update-password-page.new-password-retype': 'Repetir nova senha:',
  'update-password-page.submit': 'Configurar nova senha',
  'update-password-page.alert-weak-password': 'Sua senha é muito simples e pode ser facilmente adivinhada por pessoas não autorizadas. A senha deve conter letras maiúsculas, letras minúsculas, números e ter no mínimo 8 caracteres. Deseja usar uma senha simples assim mesmo?',
  'update-password-page.alert-agreement': 'Aceitação da perda de dados é necessária.',
  'update-password-page.error-message': 'Erro! A configuração de uma nova senha falhou.',
  'update-password-page.success-message': 'Nova senha configurada',
  'update-password-page.unknow-error-message': 'Algo deu errado',
  'update-password-page.error-link': 'Link incorreto',
  'update-password-page.error-posswords-not-equal': 'Senhas não coincidem',
  'update-password-page.error-posswords-length': 'Senhas muito curtas',
  'update-password-page.error-wrong-code': 'Código incorreto',
  'update-password-page.register-success-title': 'Sucesso!',
  'update-password-page.register-success-message': 'Nova senha configurada.',

  // Error Page
  'not-found-page.title': 'Oops... parece que ocorreu um erro!',
  'not-found-page.error-code-text': 'Código de erro',
  'not-found-page.go-back-home-button': 'VOLTAR PARA CASA',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Seu plano termina em {date}. Considere adquirir uma assinatura hoje.',
  'panel-page.purchase-demo-alert': 'Você está atualmente usando a versão de avaliação.',
  'panel-page.purchase-expired-alert': 'Você está atualmente usando uma versão de avaliação expirada. Compre assinaturas para continuar.',
  'panel-page.purchase-lic-expired-alert': 'Seu plano de assinatura expirou. Compre assinaturas para continuar.',
  'panel-page.email-alert': 'Você não confirmou seu endereço de email',
  'panel-page.button-email-resend': 'Reenviar o email',
  'panel-page.email-alert-resend': ' ou envie o email novamente.',
  'panel-page.email-alert-resend-title': 'Confirmação',
  'panel-page.email-alert-resend-msg': 'O email foi enviado!',
  'panel-page.profile-delete-description': 'Tem certeza de que deseja excluir o perfil selecionado?',
  'panel-page.profile-delete-device-description': 'Tem certeza de que deseja excluir o dispositivo selecionado? Os dados serão perdidos.',
  'panel-page.profile-delete-device-second-description': 'A proteção no dispositivo será desativada. Tem certeza de que deseja continuar?',

  'panel-page.email-confirmation-overlay-close': 'Fechar',
  'panel-page.email-confirmation-overlay-postpone': 'Depois',
  'panel-page.email-confirmation-overlay-resend': 'Reenviar o email',
  'panel-page.email-confirmation-overlay-header': 'Seu endereço de e-mail abaixo não foi confirmado',
  'panel-page.email-confirmation-overlay-desc': "Se você cometeu um erro com o endereço de e-mail acima, por favor nos avise imediatamente.  <br>Se você não recebeu o email, por favor clique em 'reenviar' e verifique suas outras pastas de email (incluindo lixo/spam).",

  'panel-page.email-confirmation-code-success-header': 'Obrigado',
  'panel-page.email-confirmation-code-success-desc': 'O email foi confirmado!',
  'panel-page.email-confirmation-code-header': 'Por favor, insira o código de confirmação de 6 dígitos que <br> foi enviado para o seu endereço de email.',
  'panel-page.email-confirmation-code-error-invalid-code': 'O código fornecido é inválido',
  'panel-page.email-confirmation-code-error': 'Ocorreu um erro inesperado ao validar o código',

  // Summary Page
  'summary-page.title': 'Painel de Controle',
  'summary-page.top-apps.title': 'Principais aplicativos',
  'summary-page.top-blocked.title': 'Principais bloqueados',
  'summary-page.top-domains.title': 'Principais páginas da web visitadas',
  'summary-page.top-search.title': 'Principais pesquisas',
  'summary-page.access-disable': 'Desativação temporária',
  'internet-page.access-time-limit-set-label': 'Limite diário para acesso à internet:',
  'internet-page.access-time-limit-description': 'Defina limites de tempo diários para cada dispositivo e escolha quantas horas de uso da internet são permitidas.',
  'disable-access.protection-enabled': 'Proteção está ativa',
  'disable-access.protection-disabled-for': 'Proteção está temporariamente desativada, {time} minutos restantes',
  'disable-access.device-offline': 'Dispositivo indisponível',
  'disable-access.protection-status': 'Status da proteção',


  // Internet Page
  'internet-page.headline-title': 'Páginas web',
  'internet-page.access-time-page-title': 'Tempo de acesso',
  'internet-page.settings-page-title': 'Configurações',
  'internet-page.statistics-page-title': 'Estatísticas',
  'internet-page.searches-page-title': 'Pesquisas',

  'internet-page.statistics-page-www-ok': 'Website aprovado',
  'internet-page.statistics-page-www-ok-white': 'Website seguro',
  'internet-page.statistics-page-www-timelimit': 'Website bloqueado devido a limites de tempo',
  'internet-page.statistics-page-www-userdef': 'Website bloqueado, definições do usuário',
  'internet-page.statistics-page-www-content': 'Website bloqueado, conteúdo inapropriado',


  'internet-page.white-black-list-page-title': 'Websites Seguros / Bloqueados',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filtros',
  'internet-page.settings-page.white-list-only-label': 'Permitir acesso apenas a sites na lista segura',
  'internet-page.settings-page.block-erotic-label': 'Bloquear acesso a websites eróticos',
  'internet-page.settings-page.block-offensive-label': 'Bloquear acesso a websites ofensivos, agressivos',
  'internet-page.settings-page.block-file-sites-label': 'Bloquear acesso a páginas que permitem baixar arquivos da internet',
  'internet-page.settings-page.block-video-label': 'Bloquear acesso a portais de vídeo',
  'internet-page.settings-page.block-social-label': 'Bloquear acesso a redes sociais',
  'internet-page.settings-page.block-doc-label': 'Bloquear downloads de documentos',
  'internet-page.settings-page.block-exe-label': 'Bloquear downloads de programas',
  'internet-page.settings-page.excludeYT-label': 'Não forçar pesquisa segura no YouTube',
  'internet-page.settings-page.block-new-apps': 'Bloquear novos aplicativos',
  'internet-page.settings-page.block-settings': 'Bloquear acesso às configurações',
  'internet-page.settings-page.custom-msg.card-tile': 'Mensagem para páginas bloqueadas',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Usar mensagem personalizada para websites bloqueados',
  'internet-page.settings-page.save-settings-button-label': 'Salvar configurações',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Adicionar à lista:',
  'internet-page.lists.add-button-label': 'Adicionar',
  'internet-page.lists.remove-button-label': 'Excluir',
  'internet-page.lists.white-list.card-title': 'Lista Segura',
  'internet-page.lists.black-list.card-title': 'Lista Bloqueada',
  'internet-page.lists.remove-prefix': 'O endereço digitado começa com "{prefix}", é recomendável adicionar apenas o nome do domínio. Você quer remover "{prefix}" do endereço?',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Páginas visitadas',

  'internet-page.searches.searches-pages-card-title': 'Frases pesquisadas',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Agenda',
  'internet-page.access-time.schedule-legend-label': 'Legenda da agenda',
  'internet-page.access-time.schedule-internet-available-label': 'Internet disponível',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet bloqueada',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Chamadas e Mensagens de Texto (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Chamadas',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Mensagens de Texto (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Última alteração:',
  'calls-text-messages-page.contacts-no-changes-label': 'Vazio.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Contatos',

  // Localization Page
  'localization-page.headline-title': 'Localização',
  'localization-page.headline-title-off': 'Localizações, rastreamento por GPS está desligado',
  'localization-page.map-hours-filter-label': 'Selecione o intervalo de tempo para ver o histórico de localização',
  'localization-page.gps-tracking-on': 'Rastreamento por GPS ativado',
  'localization-page.gps-view-speed': 'Mostrar velocidade de viagem',
  'localization-page.map-loading-label': 'Carregando mapas...',
  'localization-page.map-no-data': 'NENHUM DADO',

  'localization-page.one-gps-was-off-since': 'Serviços de localização do dispositivo desligados desde {lastOffStr}',
  'localization-page.one-gps-was-off-for': 'Serviços de localização do dispositivo desligados por {elapsedStr}',
  'localization-page.many-gps-was-off-since': 'Serviços de localização do dispositivo desligados desde {lastOffStr}',
  'localization-page.many-gps-was-off-for': 'Serviços de localização do dispositivo desligados por {elapsedStr}',


  // Battery Page
  'battery-page.headline-title': 'Bateria',
  'battery-page.chart-description': 'Nível de bateria',

  // Access Time Page
  'access-time-page.headline-title': 'Horário de Acesso',
  'access-time-page.nav-access-time-label-applications': 'Aplicativos',
  'access-time-page.nav-access-time-label-internet': 'Páginas da web',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Lista de vídeos',
  'youtube-page.no-videos': 'Nenhum vídeo na data selecionada',
  'youtube-page.block-videoid': 'Bloquear este vídeo',
  'youtube-page.block-channelid': 'Bloquear este canal',
  'youtube-page.video-blocked': 'Bloqueado',

  // Screen Pages
  'screenview-page.headline-title': 'Visualização da Tela',
  'screenview-page.reload-button': 'Recarregar',
  'screenview-page.chart-description': 'Visualização Atual da Tela',
  'screengrab-page.headline-title': 'Captura de Tela',
  'screengrab-page.chart-description': 'Captura de Tela',
  'screengrab-page.screen-save-time': 'Com que frequência a captura de tela é feita',
  'screengrab-page.screen-save-time-desc': 'Escolha a frequência com que a captura de tela é feita ou escolha "desativado" para não ter nenhuma captura de tela feita',
  'screengrab-page.screen-save-time-disabled': 'desativado',
  'screengrab-page.no-screens-for-today': 'Nenhuma captura de tela feita neste dia',
  'screengrab-page.no-screens-for-filter': 'Nenhuma tela corresponde aos critérios selecionados',
  'screengrab-page.imgs-delete-description': 'Tem certeza de que deseja excluir as imagens selecionadas?',
  'screengrab-page.imgs-delete-description-one': 'Tem certeza de que deseja excluir a imagem selecionada?',
  'screengrab-page.imgs-delete-label': 'Excluir',
  'screengrab-page.imgs-delete-select': 'Selecionar para excluir',
  'screengrab-page.imgs-delete-selected': 'Selecionado:',
  'screengrab-page.imgs-delete-limit': 'O limite de capturas de tela selecionadas foi alcançado.',
  'screengrab-page.imgs-filter': 'Filtro:',
  'screengrab-page.imgs-filter-communicators': 'Comunicadores',
  'screengrab-page.imgs-filter-social': 'Mídias Sociais',
  'screengrab-page.imgs-filter-video': 'Vídeo',
  'screengrab-page.imgs-filter-others': 'Outros',

  'screes-pages.device-offline': ' Dispositivo está offline',
  'screes-pages.device-deactivated': 'A proteção foi desativada temporariamente',
  'screes-pages.device-timeout': 'O dispositivo não respondeu',
  'screes-pages.device-screenoff': 'A tela está desligada',
  'screes-pages.device-notallowed': 'Captura de tela não permitida no dispositivo',
  'screes-pages.device-error': 'Ocorreu um erro',

  'lazy-image.img-delete-description': 'Tem certeza de que deseja excluir a imagem selecionada?',

  // Applications Page
  'applications-page.headline-title': 'Aplicativos',
  'applications-page.nav-access-time-label': 'Tempo de acesso',
  'applications-page.nav-statistics-label': 'Resumo',
  'applications-page.nav-apps-list-label': 'Lista de Apps',
  'applications-page.chart-activity-by-day-label': 'Atividade durante o dia',
  'applications-page.chart-hour-label': 'Hora',
  'applications-page.chart-activity-by-hour-minutes-label': 'Atividade durante o dia em minutos',
  'applications-page.list-launched-count-label': 'Contagem de lançamentos:',
  'applications-page.list-elapsed-time-label': 'Tempo de execução:',
  'applications-page.introduction': 'Aplicativos instalados',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Faixa de horário programada',
  'applications-page.settings.schedule-legend-label': 'Legenda de programação',
  'applications-page.settings.schedule-blocked-label': 'Apps bloqueados',
  'applications-page.settings.schedule-available-label': 'Apps disponíveis',
  'applications-page.time-limit.description': 'Defina o limite de tempo para aplicativos específicos. Escolha quantas horas por dia o uso do aplicativo é permitido.  ',
  'applications-page.apps-headline-title': 'Apps disponíveis',
  'applications-page.apps-first-seen-label': 'Primeira vez visto:',
  'applications-page.apps-available-switch-label': 'Disponível',
  'applications-page.apps-not-available-switch-label': 'Indisponível',
  'applications-page.apps-schedule-switch-label': 'Programado',
  'applications-page.apps-no-schedule-switch-label': 'Não programado',
  'applications-page.apps-time-limit-switch-label': 'Limite de tempo',
  'applications-page.apps-no-time-limit-switch-label': 'Sem limite de tempo',
  'applications-page.apps-show-uninstalled-label': 'Mostrar aplicativos desinstalados',
  'applications-page.apps-show-apps-list': 'Escolher aplicativos com tempo limitado',

  'applications-page.used-count-zero-label': 'Não lançado',
  'applications-page.used-count-one-label': 'Lançado {value} vez, tempo total: {totalTime}',
  'applications-page.used-count-many-label': 'Lançado {value} vezes, tempo total: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} h {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} seg',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} seg',
  'applications-page.total-time-label': 'Tempo total',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'O link de ativação foi usado ou é inválido',
  'email-confirmation-page.successful-confirm': 'Email foi confirmado. Obrigado!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Mudar senha',
  'change-password-page.new-password-input-label': 'Nova senha',
  'change-password-page.new-password-repeated-input-label': 'Repetir nova senha',
  'change-password-page.current-password-input-label': 'Senha atual',
  'change-password-page.back-button-label': 'Voltar',
  'change-password-page.change-button-label': 'MUDAR',
  'change-password-page.successful-change-label': 'A senha foi alterada corretamente',
  'change-password-page.wrong-current-password-label': 'Senha atual incorreta',
  'change-password-page.password-too-short-label': 'Nova senha é muito curta',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENU',
  'top-navigation.user-menu.account-label': 'Conta',
  'top-navigation.user-menu.sign-out': 'Sair',
  'top-navigation.user-menu.subscription-label': 'Plano de assinatura',
  'top-navigation.user-menu.change-password-label': 'Mudar senha',
  'top-navigation.user-menu.add-device-label': 'Adicionar dispositivo',

  // Sidebar
  'sidebar.menu-item.applications': 'Aplicativos',
  'sidebar.menu-item.calls-and-text-messages': 'Ligações e Mensagens(SMS)',
  'sidebar.menu-item.internet': 'Páginas Web',
  'sidebar.menu-item.localizations': 'Localizações',
  'sidebar.menu-item.social-networks': 'Redes sociais',
  'sidebar.menu-item.summary': 'Painel',
  'sidebar.menu-item.battery': 'Bateria',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Visualização de Tela',
  'sidebar.menu-item.screengrab': 'Captura de Tela',
  'sidebar.menu-item.access-time': 'Tempo de Acesso',

  // Validator
  'phone-number.not-valid': 'Insira um número de telefone válido',
  'input.required-label': 'Campo obrigatório',
  'input.select-required-label': 'Por favor selecione',
  'email.not-valid-label': 'Insira um endereço de e-mail válido',
  'domain-exist-label': 'Domínio foi adicionado',
  'domain-exist-other-label': 'Domínio existe em outra lista',
  'domain-not-valid': 'Domínio não é válido',
  'password-not-match-label': 'Senhas não correspondem',

  // misc
  'button-buy-label': 'Escolha um plano',

  'transactiion-status-0': 'Aguardando',
  'transactiion-status-1': 'Concluído',
  'transactiion-status--1': 'Cancelado',
  'transactiion-status--2': 'Cancelado – reembolso',
  'transactiion-status--3': 'Aguardando confirmação',
  'transactiion-status--100': 'Iniciado',
  'transactiion-status-other': 'Outro',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Sucesso',
  'reward-page.error-already-used': 'Este código já foi usado',
  'reward-page.error-invalid-code': 'Código incorreto',
  'reward-page.error-connection': 'Erro de conexão',
  'reward-page.additional-bonus': 'Bônus',
  'reward-page.get-additional-bonus': 'Resgatar cupom promocional',
  'reward-page.get-now': 'Resgatar',
  'reward-page.lic-was-extended-by-days': 'Seu plano de assinatura foi estendido por {rewardDays} dias!',
  'reward-page.there-was-an-error': 'Infelizmente, ocorreu um erro.',
  'reward-page.lic-not-extended': 'Seu plano de assinatura não foi estendido.',
  'reward-page.button-close': 'Fechar',

  // url item
  'url-item.copied': 'Copiado para a área de transferência',
  'url-item.copy-link': 'Copiar link',

  // APK howto

  'apk-howto-google-play': 'A Google Play Store restringe determinadas permissões de aplicativos necessárias para utilizar esta função.',
  'apk-howto-on-the-phone': 'No telefone da criança com {applite} instalado',
  'apk-howto-step1': 'Desinstale {applite} se foi baixado anteriormente pelo Google Play',
  'apk-howto-step2': 'Baixe o aplicativo diretamente no telefone da criança através do nosso site',
  'apk-howto-step2B': 'ou escaneie o código QR abaixo no telefone da criança',
  'apk-howto-step3': 'Instale o aplicativo baixado no dispositivo',
  'apk-howto-tutorial': 'Um guia sobre como instalar o aplicativo em um telefone ou tablet',

  // Cookies

  'cookies-info-uses': 'Este site usa cookies.',
  'cookies-info-privacy-policy': 'Mais na política de privacidade.',

  // Plan names

  'plan-name-demoExpired': 'Teste expirado',
  'plan-name-licExpired': 'Plano expirado',
  'plan-name-reward': 'Bônus',
  'plan-name-demo': 'Teste',
  'plan-name-minimal': 'Plano básico',
  'plan-name-regular': 'Plano regular',
  'plan-name-premium': 'Plano premium',
  'plan-name-edu': 'Ajuda de aprendizado',
  'plan-name-minimal-edu': 'Plano básico',
  'plan-name-regular-edu': 'Plano regular',
  'plan-name-premium-edu': 'Plano premium',

  'plan-name-demoExpired2': ' ',
  'plan-name-licExpired2': ' ',
  'plan-name-reward2': ' ',
  'plan-name-demo2': ' ',
  'plan-name-minimal2': ' ',
  'plan-name-regular2': ' ',
  'plan-name-premium2': ' ',
  'plan-name-edu2': ' ',
  'plan-name-minimal-edu2': 'Ajuda com aprendizado',
  'plan-name-regular-edu2': 'Ajuda com aprendizado',
  'plan-name-premium-edu2': 'Ajuda com aprendizado',

  // agreements

  'agreement-will-inform-user': 'Declaro que informarei ao usuário, no dispositivo em que o aplicativo será instalado, que suas atividades e localização serão monitoradas.',

  'agreement-privacy-policy': 'Declaro que li e aceito os <a href="{regulations}" target="_blank">termos</a> e a <a href="{privacy}" target="_blank">política de privacidade</a>. Certifico que tenho mais de 18 anos e que adicionarei à minha conta apenas crianças que são menores de 18 anos e são meus dependentes legais.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': 'Concordo em receber conteúdo de marketing de {domain} referente a ofertas promocionais para o endereço de e-mail e número de telefone que forneci, bem como encaminhar os detalhes aos parceiros de publicidade do site mencionados na política de privacidade para fins de publicidade digital. <a href="{marketing}" target="_blank">Mais</a>',


  'gdpr-page1-title': 'Valorizamos a Sua Privacidade',
  'gdpr-page1-content': 'Clique em "ACEITAR TUDO" para consentir com o uso de Cookies para a coleta e processamento automáticos de seus dados pessoais por {domain}. Usamos Cookies para melhorar a qualidade da navegação, exibir anúncios ou conteúdos adaptados às necessidades individuais dos usuários e analisar o tráfego em nosso site. Clicar no botão "ACEITAR TUDO" significa que você consente com o uso de Cookies por nós."',

  'gdpr-page1-advanced': 'Mais Informações',
  'gdpr-page1-agree': 'ACEITAR TUDO',

  'gdpr-page2-title': 'Mais	 Informações',
  'gdpr-page2-content': 'Quando você navega em nosso site {domain} ou {panel}, podemos coletar automaticamente dados sobre sua atividade. Esses dados são coletados usando Cookies. Cookies são incorporados no navegador do seu computador ou outro dispositivo enquanto você navega no site. Cookies também permitem que você faça login em {panel} para alterar as configurações da conta e ver a atividade de Usuários protegidos. Cookies também são usados para personalização e perfil de anúncios com base na sua atividade em avosmart.com e todas as suas subpáginas. Alguns Cookies são essenciais para o funcionamento adequado do site, enquanto outros são opcionais. Escolha quais Cookies você consente. <a href="{privacy}" target="_blank">Mais em nossa política de privacidade.</a>',

  'gdpr-page2-necessary': 'Essenciais Apenas',
  'gdpr-page2-agree': 'ACEITAR TODOS',
}

export default {
  locale: 'pt',
  name: 'Português',
  data: appLocalData,
  messages
} as LanguageLocale
