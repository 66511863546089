import React from 'react'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService } from '../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import I18nMessages from '../components/I18nMessages'
import Switch from 'rc-switch'
import { Row, Col} from 'reactstrap'


type Props = (
  InjectedBenAccountProps &
  InjectedBenNotificationProps &
  InjectedBenServiceProps &
  InjectedIntlProps
)


const TopDisableAccess: React.FC<Props> = ({
  benAccount,
  benNotification,
  benService,
  intl

}) => {    

  const [time,setTime] = React.useState(0)
  const [checked,setChecked] = React.useState(true)
  const [statusOk,setStatusOk] = React.useState(true)

  function handleOnSwitchChange()
  {
    const { profileId, deviceId } = benAccount.currentProfile

    if (profileId !== null && deviceId !== null) 
    {
      const time = checked ? 3600 : 0

      benService.enableDisable(profileId, deviceId, time)
        .then( (result) => {

          if (result.status !== "OK")
            setStatusOk(false)

        } ) // result => updateStatus(result.data.period)
        .catch( () => {

          setStatusOk(false)

        })

      updateStatus(time)
    }
  }

  function updateStatus(time:number){
    setChecked(time===0)
    setStatusOk(true)
    setTime(time)
  }


  React.useEffect(() => {
    let isMounted = true
    const { profileId, deviceId } = benAccount.currentProfile

    if (profileId !== null && deviceId !== null) {
      benService.enableDisable(profileId, deviceId, -1)
        .then(result => {

          if( !isMounted ) {
            console.log("NM  DA3!")
            return
          }

          if (result.status !== "OK")
            setStatusOk(false)
          else 
            updateStatus( Number(result.data.period) )
        })
        .catch( () => {

          if( !isMounted ) {
            console.log("NM  DA4!")
            return
          }

          setStatusOk(false)
        }) }

        return () => {
          isMounted = false
      }

  },[benAccount, benService])


  function formatTime(time:number): string
  {
    if (time===0)
      return intl.formatMessage({ id:'disable-access.protection-enabled' })

    const timeMin = Math.ceil(time/60)

    return intl.formatMessage({ id:'disable-access.protection-disabled-for' }, {time:timeMin })
  }


  return(
    <React.Fragment>
      <Row>
        <Col lg="2">
          { !statusOk && (
            <div className="topAppsTableText topAppsTableName">              
                <I18nMessages id="disable-access.device-offline" />              
            </div>
          )}
          { statusOk && (
            <div className="topAppsTableText topAppsTableName">
                <I18nMessages id="disable-access.protection-status" />
            </div>
          )}
        </Col>
          
          {statusOk && (
            <React.Fragment>
              <Col lg="1" >
                  <Switch
                      style={{float:"right"}}
                      checked = {checked}
                      onChange={handleOnSwitchChange}
                      className="custom-switch custom-switch-primary"
                  />
              </Col>
              <Col>
                <div className="topAppsTableText topAppsTableName">
                  {formatTime(time)}
                </div>
              </Col>
            </React.Fragment>
          )}
        
      </Row>
    </React.Fragment>
  )
}

export default injectIntl(withBenService(withBenNotification(withBenAccount(TopDisableAccess))))