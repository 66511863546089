import React from 'react'
import { injectIntl } from 'react-intl'



export enum EduSubjects
{
    Math        = 'Math',
    Physics     = 'Physics',
    Chemistry   = 'Chemistry',
    English     = 'English',
    Programming = 'Programming',
    Other       = 'Other',
    None        = 'none'
}

export function getEduSubjectsFromStr(str:string) : EduSubjects
{
    /*
        [ "mat",    "math" ] , 
        [ "fizy",   "physics" ], 
        [ "chem",   "chemistry" ], 
        [ "angi",   "english" ], 
        [ "prog",   "programming" ],
        [ "przyr",  "nature"],
        [ "geogr",  "geographics"],
        [ "",       "other"]
    */

    if( str.toLowerCase().startsWith('math') )          return EduSubjects.Math
    if( str.toLowerCase().startsWith('physics') )       return EduSubjects.Physics
    if( str.toLowerCase().startsWith('chemistry') )     return EduSubjects.Chemistry
    if( str.toLowerCase().startsWith('english') )       return EduSubjects.English
    if( str.toLowerCase().startsWith('programming') )   return EduSubjects.Programming
    return EduSubjects.Other
}

export function getSubjectName(theSubject: EduSubjects, defaultEmpty: boolean): string 
{
    switch(theSubject) {
        case EduSubjects.Math:          return "Matematyka"
        case EduSubjects.Physics:       return "Fizyka"
        case EduSubjects.Chemistry:     return "Chemia"
        case EduSubjects.English:       return "Angielski"
        case EduSubjects.Programming:   return "Programowanie"
        default:
            if( defaultEmpty )
                return ""
            else
                return "Inne"
    }
}

type Props = {
    subjectType: EduSubjects
    active: boolean
    onClicked: (subject: EduSubjects) => void
}

type ButtonDef = {
    title: string,
    icon: JSX.Element,
    stroke: string
}


const SubjectButton: React.FC<Props> = ({
    subjectType,
    active,
    onClicked
}) => {


    function getDef(what:EduSubjects) : ButtonDef {

        switch(what)
        {
            case EduSubjects.Math:
                return {
                    title: 'Matematyka',
                    icon: (
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.745 3A23.933 23.933 0 003 12c0 3.183.62 6.22 1.745 9M19.5 3c.967 2.78 1.5 5.817 1.5 9s-.533 6.22-1.5 9M8.25 8.885l1.444-.89a.75.75 0 011.105.402l2.402 7.206a.75.75 0 001.104.401l1.445-.889m-8.25.75l.213.09a1.687 1.687 0 002.062-.617l4.45-6.676a1.688 1.688 0 012.062-.618l.213.09" />
                    ),
                    stroke: '#34D399'
                }

            case EduSubjects.Physics:
                return {
                    title: 'Fizyka',
                    icon: (
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                    ),
                    stroke: '#EC4899'
                }

            case EduSubjects.Chemistry:
                return {
                    title: 'Chemia',
                    icon: (
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
                    ),
                    stroke: '#3B82F6'
                }

            case EduSubjects.English:
                return {
                    title: 'Angielski',
                    icon: (
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                    ),
                    stroke: '#F59E0B'
                }
            case EduSubjects.Programming:
                return {
                    title: 'Programowanie',
                    icon: (
                      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
                    ),
                    stroke: '#8B5CF6'
                }
            default:
                return {
                    title: 'Inne',
                    icon: (<path strokeLinecap="round" strokeLinejoin="round" d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
                      ),
                      stroke: '#34D399',
                }
        }
    }
    

    const definition = getDef(subjectType)

    function onButtonClicked()
    {
        onClicked(subjectType)
    }

    return (

        <div className={ active ? "tabedu active" : "tabedu" } onClick={ onButtonClicked } >
            <svg className="tab-icon" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke={definition.stroke} aria-hidden="true">
                {definition.icon}
            </svg>
            <div className="tab-title">{definition.title}</div>
            <div className="tab-indicator"></div>
        </div>
    
    )
}


export default injectIntl(SubjectButton)
