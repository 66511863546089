import React from 'react'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Formik, Field, Form } from 'formik'
import { Card, CardBody, Label, FormGroup, Col, Alert, Button } from 'reactstrap'
import AsyncButton from '../../components/AsyncButton'
import Spinner from '../../components/Spinner'
import I18nMessages from '../../components/I18nMessages'
import { InjectedBenServiceProps, withBenService } from '../../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider'
import { validateNotEmpty, useMountedState, validatePasswordMatch } from '../../lib'
import getMessage from '../../language/getMessage'
import { ActionCode } from '../../providers/providersDefs'
import appLogo from '../../assets/media/logo.svg'


type Props = InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps
  & RouteComponentProps

type FormValues = {
  currentPassword: string
  newPassword: string
  newPasswordRepeated: string
}

enum ChangePasswordStatus {
  OK,
  WRONG_PASS,
  INTERNAL_ERROR,
  PASS_TO_SHORT
}

const initialFormValues: FormValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordRepeated: ''
}

function isSubmitBtnDisabled (values: FormValues): boolean {
  return values.currentPassword.length === 0 || values.newPassword.length === 0
}

function mapActionCodeToStatus (code: ActionCode | undefined): ChangePasswordStatus {
  switch (code) {
    case ActionCode.ok: return ChangePasswordStatus.OK
    case ActionCode.wrongPassword: return ChangePasswordStatus.WRONG_PASS
    case ActionCode.passwordToShort: return ChangePasswordStatus.PASS_TO_SHORT

    default:
      return ChangePasswordStatus.INTERNAL_ERROR
  }
}

const ChangePasswordPage: React.FC<Props> = ({
  benNotification,
  benService,
  history,
  intl
}) => {

  const isMounted = useMountedState()
  const [isLoading, setLoading] = React.useState(false)
  const [status, setStatus] = React.useState<ChangePasswordStatus | undefined>(undefined)

  function handleOnFormikSubmit (values: FormValues) {
    setLoading(true)

    benService.changePassword(values.currentPassword, values.newPassword)
      .then(result => isMounted() && setStatus(mapActionCodeToStatus(result.code)))
      .catch(error => isMounted() && setStatus(mapActionCodeToStatus(error.code)))
      .finally(() => isMounted() && setLoading(false))
  }

  function handleBack()
  {
    history.push("/")
  }

  React.useEffect(() => {
    if (status === ChangePasswordStatus.INTERNAL_ERROR) {
      benNotification.notify({
        type: 'error',
        title: getMessage('side-effect.internal-error-title', intl),
        message: getMessage('side-effect.internal-error-message', intl)
      })
    }
  }, [benNotification, status, intl])

  React.useEffect(() => {
    document.body.classList.add('h-100')
    return () => document.body.classList.remove('h-100')
  }, [])

  return (
    <Col md="6" className="mx-auto my-auto">
      <Card>
        <CardBody>

          <Button color="light" size="xs" onClick={handleBack} className="button-x" >
                    X
          </Button>

          <div className="benjamin-logo-container">
            <img src={appLogo} className="login-logo" alt="logo" />
          </div>
          <p className="text-center mb-4">
            <I18nMessages id="change-password-page.change-password-label" />
          </p>

          {isLoading && <Spinner /> }

          {(!isLoading && status === ChangePasswordStatus.OK) && (
            <div className="text-center">
              <p className="text-success text-bigger">
                <I18nMessages id="change-password-page.successful-change-label" tagName="strong"/>
              </p>
              <NavLink to="/" className="btn btn-primary mt-4" isActive={() => false}>
                <I18nMessages id="change-password-page.back-button-label"/>
              </NavLink>
            </div>
          )}

          {!isLoading && (
            <React.Fragment>
              <Alert color="danger" isOpen={status === ChangePasswordStatus.PASS_TO_SHORT}>
                <I18nMessages id="change-password-page.password-too-short-label" />
              </Alert>
              <Alert color="danger" isOpen={status === ChangePasswordStatus.WRONG_PASS}>
                <I18nMessages id="change-password-page.wrong-current-password-label" />
              </Alert>
            </React.Fragment>
          )}

          {(!isLoading && status !== ChangePasswordStatus.OK) && (
            <Formik initialValues={initialFormValues} onSubmit={handleOnFormikSubmit}>
              {({ errors, touched, values }) => (
                <Form className="av-tooltip tooltip-label-bottom">

                  <FormGroup className="form-group has-float-label">
                    <Label>
                      <I18nMessages id="change-password-page.current-password-input-label" />
                    </Label>
                    <Field
                      name="currentPassword"
                      type="password"
                      className="form-control"
                      validate={(v: string) => validateNotEmpty(v, intl)}
                    />
                    {(errors.currentPassword && touched.currentPassword) && (
                      <div className="invalid-feedback d-block">{errors.currentPassword}</div>
                    )}
                  </FormGroup>

                  <FormGroup className="form-group has-float-label">
                    <Label>
                      <I18nMessages id="change-password-page.new-password-input-label" />
                    </Label>
                    <Field
                      name="newPassword"
                      type="password"
                      className="form-control"
                      validate={(v: string) => validateNotEmpty(v, intl)}
                    />
                    {(errors.newPassword && touched.newPassword) && (
                      <div className="invalid-feedback d-block">{errors.newPassword}</div>
                    )}
                  </FormGroup>

                  <FormGroup className="form-group has-float-label">
                    <Label>
                      <I18nMessages id="change-password-page.new-password-repeated-input-label" />
                    </Label>
                    <Field
                      name="newPasswordRepeated"
                      type="password"
                      className="form-control"
                      validate={(v: string) => validatePasswordMatch(v, values.newPassword, intl)}
                    />
                    {(errors.newPasswordRepeated && touched.newPassword && touched.newPasswordRepeated) && (
                      <div className="invalid-feedback d-block">{errors.newPasswordRepeated}</div>
                    )}
                  </FormGroup>

                  <div className="d-flex justify-content-between align-items-center">
                    <NavLink to="/">
                      <I18nMessages id="change-password-page.back-button-label" />
                    </NavLink>
                    <AsyncButton
                      color="primary"
                      disabled={isLoading || isSubmitBtnDisabled(values)}
                      showSpinner={isLoading}
                      type="submit"
                    ><I18nMessages id="change-password-page.change-button-label" />
                    </AsyncButton>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

export default withBenService(withBenNotification(withRouter(injectIntl(ChangePasswordPage))))
