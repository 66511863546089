import React from 'react'
import { Row, Col, Card, CardBody, CustomInput } from 'reactstrap'
import DatePickerWithButtons from '../../containers/DatePickerWithButtons'
import { Range } from 'rc-slider'
import I18nMessages, { getI18nMessage } from '../../components/I18nMessages'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import LocalizationMap, {GPSDataWithDate, GPSDataExWithDate}  from '../../containers/LocalizationMap'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider'
import { InjectedI18nProviderProps, withI18nProvider } from '../../providers/i18nProvider'
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService, GPSData, GPSDataEx, HealthItem } from '../../providers/benServiceProvider'
import { gMapURL, dateToAllDayFromToDate } from '../../lib'
import { describeTimeElapsedHMS } from '../../lib/utils'
import SudoMapSrc from '../../assets/media/sudo-map.png'


type Props = InjectedI18nProviderProps & InjectedBenAccountProps & InjectedBenServiceProps & InjectedBenNotificationProps & InjectedIntlProps

const rangeMarks: string[] = Array.from(Array(24).keys()).map(v => String(v))


const LocalizationPage: React.FC<Props> = ({
  benAccount,
  benNotification,
  benService,
  intl
}) => {

  const [selectedDate, setSelectedDate] = React.useState( new Date() )
  const [rangeValue, setRangeValue] = React.useState([0, 23])
  const [isLoading, setLoading] = React.useState(true)
  const [gpsList, setGPSList] = React.useState<GPSData[]>([])
  const [gpsListEx, setGPSListEx] = React.useState<GPSDataEx[]>([])
  const [isCurrentPosition, setIsCurrentPosition] = React.useState(false)
  const [gpsTrackingOn, setGpsTrackingOn] = React.useState(true)
  const [gpsOffWarning, setGpsOffWarning] = React.useState('')

  function handleDatePickerDateChange (date: Date | null) {
    if (date !== null) {
      setSelectedDate(date)
    }
  }

  
  function getGPSDataWithDate (data: GPSData[]): GPSDataWithDate[] {
    return data.map<GPSDataWithDate>(item => ({
      ...item, 
      date: new Date(item.ts*1000)
    }))
  }


  function getGPSDataExWithDate (data: GPSDataEx[]): GPSDataExWithDate[] {
    return data.map<GPSDataExWithDate>(item => ({
      ...item,
      date1: new Date(item.ts1*1000),
      date2: new Date(item.ts2*1000)
    }))
  }

  function updateGpsOffWarning(items : HealthItem[], from: Date, to:Date) 
  {
    const tsFrom = from.getTime() / 1000
    const tsTo = to.getTime() / 1000

    let turnOffCount = 0
    let turnOffTime = 0

    let lastState = true
    let lastOff = 0
    //let lastOn = 0

    for(var i = items.length-1; i>=0; i--)
    {
      const item = items[i]

      if(item.ts >= tsFrom && item.ts < tsTo )
      {
        const gpsOn = item.gps === 3       
        
        if( gpsOn !== lastState)
        {
          if(!gpsOn) {
            turnOffCount++
            lastOff = item.ts
          }
          else {
            turnOffTime += item.ts - lastOff
            //lastOn = item.ts
          }

          lastState = gpsOn
        }
      }
    }

    let message = ""

    var lastOffDate  = new Date(lastOff * 1000);

    var elapsedStr = describeTimeElapsedHMS(turnOffTime, intl)
    var lastOffStr = lastOffDate.toLocaleTimeString();

    if( turnOffCount === 1 ) {

      if( !lastState )
        message = intl.formatMessage({ id:'localization-page.one-gps-was-off-since' }, { lastOffStr })
      else
        message = intl.formatMessage({ id:'localization-page.one-gps-was-off-for' }, { elapsedStr })
    }
    else if( turnOffCount > 1 ) {
      if( !lastState)
        message = intl.formatMessage({ id:'localization-page.many-gps-was-off-since' }, { lastOffStr })  // przez ${elapsedStr}, był wyłączony
      else
        message = intl.formatMessage({ id:'localization-page.many-gps-was-off-for' }, { elapsedStr })
    }

    setGpsOffWarning(message)    
  }

  function onSaveNewLocationsChanged(value: boolean)
  {
    const { deviceId, profileId } = benAccount.currentProfile

    if (profileId && deviceId) {
      setGpsTrackingOn(value)
      benService.saveSettings(profileId, deviceId, {
        enableGPSTracking: value,
      })

      .then(() => benNotification.notify({
        className: 'filled',
        title: getI18nMessage('side-effect.successful-title', intl),
        message: getI18nMessage('side-effect.successful-message', intl),
        type: 'success'
      }))

      .catch(() => benNotification.notify({
        className: 'filled',
        title: getI18nMessage('side-effect.internal-error-title', intl),
        message: getI18nMessage('side-effect.internal-error-message', intl),
        type: 'error'
      }))
    }
  }

  function isValidProfile() : boolean {
    const { deviceId, profileId } = benAccount.currentProfile
    return deviceId !== null && profileId !== null && deviceId !== '' && profileId !== ''
  }

  React.useEffect(() => {
    const { deviceId, profileId } = benAccount.currentProfile
    const fromToDate = dateToAllDayFromToDate(selectedDate)
    let isMounted = true

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {
        setLoading(true)
        setGpsTrackingOn(true)
        setGpsOffWarning('')

        benService.getGPS(profileId, deviceId, fromToDate.from, fromToDate.to, 0, 10000)
            .then(result => {
                if (isMounted) {
                  setGPSList(result.data)
                  setGPSListEx(result.dataEx)
                  setIsCurrentPosition(!result.fb)
                }
            })
            .catch(() => {} )

        // benService.loadSettings(profileId, deviceId)
        //     .then(result => isMounted && setGpsTrackingOn(result.data.enableGPSTracking))                
        //     .catch(() => {} )

        setTimeout( () => {

          benService.getHealth2(profileId, deviceId, fromToDate.from, fromToDate.to)
            .then( result => {
              if (isMounted) {
                updateGpsOffWarning(result.data, fromToDate.from, fromToDate.to)
              }
            })
            .catch( () => {} )

        }, 500)

        if (isMounted) 
            setLoading(false)

    } else {
        setLoading(false)
    }

    return () => {
        isMounted = false
    }
  }, [benAccount, benService, selectedDate])  // eslint-disable-line react-hooks/exhaustive-deps



  React.useEffect(() => {
    const { deviceId, profileId } = benAccount.currentProfile
    let isMounted = true

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {

      setTimeout( () => {
        benService.loadSettings(profileId, deviceId)
            .then(result => isMounted && setGpsTrackingOn(result.data.enableGPSTracking))                
            .catch(() => {} )
      }, 100 )

    } else {
        //
    }

    return () => {
        isMounted = false
    }
  }, [benAccount, benService])  




  return (
    <div className="dashboard-wrapper">

      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">

          <h1 className="mb-0 p-0">
            { !!(isLoading || gpsTrackingOn) && (
              <I18nMessages id="localization-page.headline-title" />
            )}

            { !!(!isLoading && !gpsTrackingOn) && (
              <I18nMessages id="localization-page.headline-title-off"/>
              )}
          </h1>
        
        <div className="d-flex align-items-center">
          {/* <span className="mr-4"><I18nMessages id="date-picker.select-date-label" /></span> */}
          <DatePickerWithButtons onSelectedDateChanged = {handleDatePickerDateChange} selectedDate={selectedDate}/>
        </div>
      </div>

      <Card>
        <CardBody>
          <Row className="mb-4">
            <Col md="4">
              <I18nMessages tagName="p" id="localization-page.map-hours-filter-label" />
            </Col>

            <Col md="8">
              <Range
                activeDotStyle={{ display: 'none' }}
                allowCross={false}
                defaultValue={rangeValue}
                disabled={isLoading}
                dotStyle={{ display: 'none' }}
                marks={{ ...rangeMarks }}
                max={23}
                onAfterChange={setRangeValue}
              />
            </Col>
          </Row>

          { gpsOffWarning.length > 0 && (
            <div className="gps-warning" >{gpsOffWarning}</div> 
          )}

          { !isValidProfile() && (
            <img style={{objectFit: 'contain'}} className="map-item" src={SudoMapSrc} alt='Sudo' />
          )}

          
          { isValidProfile() && (
            <LocalizationMap
              isCurrentLocalization={isCurrentPosition}
              containerElement={<div className="map-item position-relative" />}
              data={ getGPSDataWithDate(gpsList) }
              dataEx={ getGPSDataExWithDate(gpsListEx) }
              googleMapURL={gMapURL}
              loadingElement={<div className="map-item">Google Maps loading</div>}
              mapElement={<div className="map-item" />}
              showLoading={isLoading}
              hoursFilter={[rangeValue[0], rangeValue[1]]}
            />
          )}

        <div className="save-new-locations-checkbox">
          <CustomInput
            id="saveNewLocations"
            name="blockNewApps"
            type="checkbox"
            label={<I18nMessages id="localization-page.gps-tracking-on" />}
            checked={gpsTrackingOn}
            onChange={v => onSaveNewLocationsChanged(v.target.checked)}
          />
        </div>

        </CardBody>
      </Card>
    </div>
  )
}

export default withBenNotification(injectIntl(withBenService(withBenAccount(withI18nProvider(LocalizationPage)))))
