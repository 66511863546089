import React from 'react'
import { ChartData, ChartOptions } from 'chart.js'
import Chart from 'react-chartjs-2'
// import I18nMessages from '../../../components/I18nMessages'
import { PieChartDefaults, getColorForChartBackground } from '../../../lib'
import Spinner from '../../../components/Spinner'
import { AiStats } from '../../../providers/aiServiceProvider'
import { describeTimeElapsedHM, toLocaleDate2 } from '../../../lib/utils'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { getEduSubjectsFromStr, getSubjectName } from '../../edu/SubjectButton'

type Props = InjectedIntlProps & {
  aiStats : AiStats | null | undefined
}

const EducationChartTimeByDays : React.FC<Props> = ({
  aiStats,
  intl
}) => {

    function findValue(dte:number, subject:string) : number
    {
        if( !aiStats )
            return 0

        let v = aiStats.time.find( item => item.dte === dte && item.subject === subject )

        if( !v ) {
            return 0
        }

        let elapsed = Math.round( v.elapsed / 60 )
        if( elapsed < 1 )
            elapsed = 1

        return elapsed
    }

    function getChartDataQuestions(s : AiStats ) : ChartData 
    {
        // console.log( '**** getChartDataQuestions **** ' )

        var noGapDates : number[] = []
        var subjects  : string[] = []
        var minTs = 0
        var maxTs = 0
        var now = Math.round( new Date().getTime() / 1000 )

        for( let i = 0; i<s.time.length; i++ )
        {
            if( s.time[i].subject === 'none' || s.time[i].subject === '')
                continue

            if( minTs === 0 || s.time[i].dte < minTs ) minTs = s.time[i].dte
            if( maxTs === 0 || s.time[i].dte > maxTs ) maxTs = s.time[i].dte

            if( subjects.indexOf(s.time[i].subject) < 0 )
                subjects.push(s.time[i].subject)
        }

        if( minTs === 0 ) {
            minTs = now
            maxTs = now
        }

        for( let t = minTs; t<=maxTs; t+= 24*3600) {
            noGapDates.push(t)
        }

        while( maxTs + 24*3600 < now ) {
            maxTs += 24*3600
            noGapDates.push( maxTs )
        }

        while( noGapDates.length < 14 ) {
            minTs -= 24*3600
            noGapDates.unshift( minTs )
        }

        let valueMap = new Map<string, number[]>();

        for( const subject of subjects ) {
            let values : number[] = noGapDates.map( dte => {
                return findValue(dte, subject)
            })
            valueMap.set(subject, values)
        }

        const data: ChartData = {
            labels:  noGapDates.map( v => { return toLocaleDate2( new Date(v * 1000), intl, 6 ) } ),
            datasets: subjects.map( (subject,idx) => ({
                borderWidth: 1,
                label: getSubjectName( getEduSubjectsFromStr(subject), false) ,
                backgroundColor: getColorForChartBackground(idx),
                data: valueMap.get(subject)
            }))
            
            // [{
            //   borderWidth: 1,
            //   label: 'mat',
            //   backgroundColor: getColorForChartBackground(0),
            //   data: [1,2,3,0]
            // },
            // {
            //     borderWidth: 1,
            //     label: 'fiz',
            //     backgroundColor: getColorForChartBackground(1),
            //     data: [0,3,1,0]
            // },
            // {
            //     borderWidth: 1,
            //     label: 'chem',
            //     backgroundColor: getColorForChartBackground(2),
            //     data: [0,0,3,2]
            // }            
            // ]
        }
      
        return data
    }    

    function tooltipTitleCallback (items: Chart.ChartTooltipItem[], data: ChartData): string 
    {
        const datasetIndex = items[0].datasetIndex
        const labelX = items[0].label || ''
        const dataset = data.datasets
      
        if (typeof datasetIndex !== 'undefined' && typeof dataset !== 'undefined') {
            return ( dataset[datasetIndex].label || '' ) + ', ' + labelX
        }
      
        return labelX
    }

    function tooltipLabelCallback (tooltipItem: Chart.ChartTooltipItem, tooltipData: ChartData): string 
    {
        const yLabel = typeof tooltipItem.yLabel === 'number' ? tooltipItem.yLabel : undefined
        // const datasetIndex = tooltipItem.datasetIndex
    
        return describeTimeElapsedHM( yLabel ? yLabel * 60 : 0, intl  )
    }

    const optionsQuestions: ChartOptions = {
        showLines: false,
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: false,
            text: 'Czas'
        },
        legend: {
            display: true,
            labels: {
                boxWidth: 50,
                fontSize: 10,
                fontColor: '#bbb',
                padding: 5,
            }
        },
        tooltips: {
            callbacks: {
              title: tooltipTitleCallback,
              label: tooltipLabelCallback
            }
        },
        scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: true,
                autoSkipPadding: 10
              }
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                  beginAtZero:true,
                  min: 0
              }
            }]
        }
    }
 
  return (

    <React.Fragment>

        { aiStats === undefined && (
            <div className="chart-container d-flex align-items-center justify-content-center text-center">
                <Spinner/>
            </div>
        )}

        { ( aiStats && aiStats.questions.length === 0 ) && (
            <div className="chart-container d-flex align-items-center justify-content-center text-center">
                Brak danych. Dane jeszcze nie zostały zebrane.
            </div>
        )}

        { ( aiStats && aiStats.questions.length !== 0 ) && (

            <div className="chart-container">
                <Chart
                    type="bar"
                    legend={PieChartDefaults.legend}
                    options={optionsQuestions}
                    data={getChartDataQuestions(aiStats)}
                />
            </div>
        )}

    </React.Fragment>
  )
}

export default injectIntl(EducationChartTimeByDays)
