import { Field, Form, Formik } from 'formik';
import React, { MouseEvent, useCallback } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Alert, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap';

import appLogo from '../assets/media/logo.svg'
import eyeslash from '../assets/media/eye-off.svg';
import eye from '../assets/media/eye.svg';
import AsyncButton from '../components/AsyncButton';
import I18nMessages from '../components/I18nMessages';
import CheckboxField from '../containers/CheckboxField';
import ConfirmationModal from '../containers/ConfirmationModal';
import getMessage from '../language/getMessage';
import { validateNotEmpty } from '../lib';
import { callWebViewSuccess, getAllowedLanguages, isWebView } from '../lib/utils';
import { Agreements, InjectedBenServiceProps, withBenService } from '../providers/benServiceProvider'
import { ActionCode } from '../providers/providersDefs'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider';
import queryString from 'querystring'
import { InjectedI18nProviderProps, withI18nProvider, Locale } from '../providers/i18nProvider';


// Assets

type Props = InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps
  & RouteComponentProps
  & InjectedI18nProviderProps

type ErrorAlert = {
  visible: boolean
  reason: string
}

type FormValues = {
  password: string,
  newPassword: string,
  agreement: boolean,
}

const initialValue: FormValues = {
  password: '',
  newPassword: '',
  agreement: false
}

const UpdatePassword: React.FC<Props> = ({
  benService,
  benNotification,
  history,
  intl,
  location,
  i18nProvider
}) => {
  const [confirmationStrongPassModal, setConfirmationStrongPassModal] = React.useState(false)

  const formikRef = React.useRef<Formik<FormValues>>(null)
  const [errorAlert, setErrorAlert] = React.useState<ErrorAlert>({ visible: false, reason: '' })
  const [isLoading, setIsLoading] = React.useState(false)
  const [isMounted, setMounted] = React.useState(false)
  const [allAgreements, setAllAgreements] = React.useState<Agreements>({ agreement: [] })
  const [isSendButtonClicked, setSendButtonClicked] = React.useState(false)
  const [passVisible, setPassVisible] = React.useState(false)

  const webView = isWebView() as boolean

  function validateCode(code: string) {
    return code && code.length > 9
  }

  function handleOnFormikSubmit(values: any) {
    setIsLoading(true)

    const password = String(values.password)
    const code = new URLSearchParams(window.location.search).get('code');
    if (!code || !validateCode(code)) {
      setErrorAlert({
        visible: true,
        reason: getMessage('update-password-page.error-link', intl)
      })
      setIsLoading(false)
      return
    }

    benService.updatePassword(password, code)
      .then(() => {
        benNotification.notify({
          type: 'success',
          title: getMessage('update-password-page.register-success-title', intl),
          message: getMessage('update-password-page.register-success-message', intl)
        })

        if (webView) {
          setTimeout(() => { callWebViewSuccess() }, 3000);
        }
        else {
          benService.getUser(intl.locale).then(() => history.push('/'))
        }
      })

      .catch(result => {
        switch (result.code) {
          case ActionCode.passwordToShort:
            isMounted && setErrorAlert({
              visible: true,
              reason: getMessage('register-page.register-error-password-to-short-label', intl)
            })
            break

          case ActionCode.CODE_WRONG_CODE:
            isMounted && setErrorAlert({
              visible: true,
              reason: getMessage('update-password-page.error-wrong-code', intl)
            })
            break

          default: return benNotification.notify({
            type: 'error',
            title: getMessage('side-effect.internal-error-title', intl),
            message: getMessage('side-effect.internal-error-message', intl)
          })
        }
      })
      .finally(() => isMounted && setIsLoading(false))
  }

  function isPasswordStrong(values: FormValues): boolean {
    let cntLower = 0
    let cntUpper = 0
    let cntDigit = 0
    let set = new Set();

    if (values.password.length < 8)
      return false

    for (let i = 0; i < values.password.length; i++) {
      let c = values.password.charAt(i)

      set.add(c)

      if (c >= '0' && c <= '9')
        cntDigit++
      else if (c >= 'a' && c <= 'z')
        cntLower++
      else if (c >= 'A' && c <= 'Z')
        cntUpper++
    }

    return cntDigit > 0 && cntLower > 0 && cntUpper > 0 && set.size > 4
  }

  function handleOnSubmitClick (values: FormValues, errors: any) {

    setSendButtonClicked(true)
    setErrorAlert({ visible: false, reason: '' })

    if (Object.keys(errors).length !== 0)
      return

    const password = String(values.password)
    const newPassword = String(values.newPassword)
    const code = new URLSearchParams(window.location.search).get('code');
    if (!code || !validateCode(code)) {
      setErrorAlert({
        visible: true,
        reason: getMessage('update-password-page.error-link', intl)
      })
      setIsLoading(false)
      return
    }

    if (password.length < 6) {
      setErrorAlert({
        visible: true,
        reason: getMessage('update-password-page.error-posswords-length', intl)
      })
      setIsLoading(false)
      return;
    }

    if (password !== newPassword) {
      setErrorAlert({
        visible: true,
        reason: getMessage('update-password-page.error-posswords-not-equal', intl)
      })
      setIsLoading(false)
      return;
    }

    if(!isPasswordStrong( values ) ) {
      setConfirmationStrongPassModal(true)
      return
    }

    processSubmit();
  }

  function onStrongPassModalOK() {
    setConfirmationStrongPassModal(false)
    processSubmit()
  }

  function onStrongPassModalCancel() {
    setConfirmationStrongPassModal(false)
  }

  function processSubmit() {
    formikRef.current && formikRef.current.submitForm()
  }

  function isSubmitBtnDisabled(values: FormValues): boolean {
    return false;
  }

  function handleOnToggleAlert() {
    setErrorAlert({ ...errorAlert, visible: !errorAlert.visible })
  }

  function agreementComponent (props: any): JSX.Element {
    return <CheckboxField
      {...props}
      label={props.label}
    />
  }

  function toggleVisibility(e: React.MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    setPassVisible(!passVisible)
  }

  function getValidLocale(lang:string) : Locale
  {
      const allLangs = getAllowedLanguages()
      let locale = allLangs[0] as Locale

      if( lang !== undefined && lang !== '' )
      {
        for( const code of allLangs )
        {
          if( lang.startsWith(code) )
          {
            locale = code as Locale
            break
          }
        }
      }

      return locale
  }

  React.useEffect(() => {
    document.body.classList.add('background')

    var search = location.search

    if( search.startsWith('?'))
        search = search.substr(1)

    const params = queryString.parse(search)

    if( params['lang'] ) 
    {
      const lang = params['lang'] as string
      i18nProvider.changeLocale( getValidLocale(lang) )
    }

    return () => {
      document.body.classList.remove('background')
    }
  }, [])

  React.useEffect(() => {
    setMounted(true)

    return () => {
      setMounted(false)
    }
  }, [])


  if (benService.isAuthorized) {
    return <Redirect to="/" />
  }

  return (
    <div className="fixed-background auto-scroll">
      <ConfirmationModal
        isOpen={confirmationStrongPassModal}
        onCancel={onStrongPassModalCancel}
        onConfirm={onStrongPassModalOK}
      ><I18nMessages id="register-page.strong-pass"/></ConfirmationModal>

      <main>
        <div className="container">
          <Row className="h-100">
            <Col md="8" lg="6" className="mx-auto my-auto">
              <Card>
                <CardBody>

                  {!webView && (
                    <div className="benjamin-logo-container">
                      <img src={appLogo} className="login-logo" alt="logo" />
                    </div>
                  )}

                  <div>
                    <p className="text-center mb-4 h5"><I18nMessages id="update-password-page.headline-title" /></p>

                    <Alert color="danger" isOpen={errorAlert.visible} toggle={handleOnToggleAlert}>
                      <span>{errorAlert.reason}</span>
                    </Alert>
                  </div>

                  <Formik ref={formikRef} initialValues={initialValue} onSubmit={handleOnFormikSubmit}>
                    {({ errors, touched, values, setFieldValue }) => (
                      <Form className="av-tooltip">

                        <div className="mb-4 p-3 tooltip-label-bottom update-password-warning-box">
                          <div className="mb-3">
                            <strong><I18nMessages id="update-password-page.warning-title" /></strong> <br/>
                            <I18nMessages id="update-password-page.warning-description" />
                          </div>
                          <FormGroup className="custom-form-check">
                              <Field
                                  id="agreement"
                                  name="agreement"
                                  component={agreementComponent}
                                  validate={(value: boolean) => validateNotEmpty(value, intl)}
                              />
                              <Label for="agreement">
                                <I18nMessages id="update-password-page.warning-agreement" />
                              </Label>
                              {(errors.agreement && (touched.agreement || isSendButtonClicked)) && (
                                <div style={{ marginTop:30 }} className="invalid-feedback d-block">
                                  {errors.agreement}
                                </div>
                              )}
                          </FormGroup>
                        </div>
                        <FormGroup >
                          <Label className="form-group has-float-label ">
                            <span className="show-pass-ico"> <img src={passVisible ? eye : eyeslash} onClick={(e) => toggleVisibility(e)} alt="eye" /> </span>
                            <I18nMessages id="update-password-page.new-password" />
                            <Field
                              name="password"
                              type={passVisible ? "input" : "password"}
                              className="form-control"
                              validate={(value: boolean) => validateNotEmpty(value, intl)}
                            />
                            {(errors.password && touched.password) && (
                              <div className="invalid-feedback d-block">
                                {errors.password}
                              </div>
                            )}
                          </Label>

                          <Label className="form-group has-float-label ">
                            <I18nMessages id="update-password-page.new-password-retype" />
                            <Field
                              name="newPassword"
                              type={passVisible ? "input" : "password"}
                              className="form-control"
                              validate={(value: boolean) => validateNotEmpty(value, intl)}
                            />
                            {(errors.newPassword && touched.newPassword) && (
                              <div className="invalid-feedback d-block">
                                {errors.password}
                              </div>
                            )}
                          </Label>
                        </FormGroup>

                        <div className="float-right">
                          <AsyncButton
                            disabled={isSubmitBtnDisabled(values)}
                            color="primary"
                            showSpinner={isLoading}
                            onClick={() => handleOnSubmitClick(values, errors)}
                          >
                            <I18nMessages id="update-password-page.submit" />
                          </AsyncButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </main>
    </div>
  )
}

export default withRouter(injectIntl(withI18nProvider(withBenNotification(withBenService(UpdatePassword)))))
