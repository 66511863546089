import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Button, Col, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { AiUserData, InjectedAiServiceProps, withAiService } from '../../providers/aiServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider'
import { dashString, justDigits } from '../../lib/utils'
import AsyncButton from '../../components/AsyncButton'


type Props =    InjectedBenNotificationProps & 
                InjectedIntlProps & 
                InjectedAiServiceProps &
{
    isOpen: boolean
    onToggle: (data : AiUserData | null, showPinCodeHint:boolean) => void
}

const EduLoginPopup: React.FC<Props> = ({
    benNotification,
    intl,    
    aiService,
    isOpen,
    onToggle,
}) => {

    const [isLoading, setLoading] = React.useState(false)
    const [codeValue, setCodeValue] = React.useState('')

    function doLogin()
    {
        const hash = justDigits(codeValue)

        if( hash.length < 8 || hash.length > 15 )
        {
            benNotification.notify({
                type: 'error',
                title: "Błąd",
                message: "Podany kod nie jest poprawny"
            })

            return
        }

        setLoading(true)

        aiService.logged( hash, -1, 0 )
            .then((userData) => {
                onToggle(userData.data, false)
            })
            .catch(() => {
                benNotification.notify({
                    type: 'error',
                    title: "Błąd",
                    message: "Podany kod nie jest poprawny"
                })
            })
            .finally( () => {
                setLoading(false)
            })        
    }

    React.useEffect(() => {
        setCodeValue( dashString(codeValue,3) )
    }, [codeValue])

    return (

        <>
            <Modal isOpen={isOpen} toggle={ () => onToggle(null, false) } size="lg"  >
                <ModalHeader>
                    <h3>Zaloguj się  <i className="simple-icon-user"/> </h3>
                </ModalHeader>

                <ModalBody>

                    <Row className='my-4'>
                        <Col className='d-flex justify-content-center'>
                            Aby zalogować się do panelu edukacyjnego wpisz 9-cyfrowy kod pin
                        </Col>
                    </Row>

                    <Row className='my-4'>
                        <Col className='d-flex justify-content-center'>
                            <div className='flex-center'>
                                <InputGroup size='sm'>
                                    <InputGroupText>Kod pin</InputGroupText>
                                    <Input  valid={codeValue.length>=10} 
                                            value={codeValue} 
                                            style={{maxWidth:"200px"}}
                                            onChange={ (e) => setCodeValue(e.target.value) } 
                                            onKeyPress={ (e) => {if(e.key==='Enter')doLogin()} } />
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>

                    <Row className='my-4'>
                        <Col className='d-flex justify-content-center'>
                            <div className='mt-1 edu-link-style' onClick={ () => onToggle(null, true)}> Gdzie znajdę kod pin ? </div>
                        </Col>
                    </Row>

                    <Row className='mt-4'>                         
                        <Col xs="2" sm="2" className='d-flex justify-content-center'></Col>
                        <Col xs="4" sm="4" className='d-flex justify-content-center'>
                            <Button color='light' size='lg' onClick={ () => onToggle(null, false) } disabled={isLoading} > Anuluj </Button>
                        </Col>
                        <Col xs="4" sm="4" className='d-flex justify-content-center'>
                            <AsyncButton showSpinner={isLoading} color='primary' size='lg' onClick={ doLogin } > Login </AsyncButton>
                        </Col>
                        <Col xs="2" sm="2" className='d-flex justify-content-center'></Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default injectIntl(withBenNotification(withAiService(EduLoginPopup)))

