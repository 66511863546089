import React from 'react'
import classnames from 'classnames'
import { RouteComponentProps, Switch, Route, Redirect, withRouter, useLocation } from 'react-router-dom'
import { Alert, Button } from 'reactstrap'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import I18nMessages from '../components/I18nMessages'
import EmptyDeviceOverlay from '../containers/EmptyDeviceOverlay'
import LicenseOverlay from '../containers/LicenseOverlay'
import ProfileCardsList from '../containers/ProfileCardsList'
import ProfileModal from '../containers/ProfileModal'
import ProfileMoveDeviceModal from '../containers/ProfileMoveDeviceModal'
import ConfirmationModal from '../containers/ConfirmationModal'
import Sidebar from '../containers/Sidebar'
import TopNavigation from '../containers/TopNavigation'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { InjectedBenServiceProps, Privileges, withBenService } from '../providers/benServiceProvider'
import { AccountType } from '../providers/providersDefs'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import { useEventListener } from '../lib'

import ApplicationsPage from './panel/applications'
import CallsTextMessages from './panel/callsTextMessages'
import Internet from './panel/internet'
import Localization from './panel/LocalizationPage'
import SummaryPage from './panel/SummaryPage'
import BatteryPage from './panel/BatteryPage'
import AccessTime from './panel/access-time/index'
import getMessage from '../language/getMessage'
import YoutubePage from './panel/YoutubePage'
import ScreenViewPage from './panel/ScreenViewPage'
import ScreenGrabPage from './panel/ScreenGrabPage'
import ScreenGrabTestPage from './panel/ScreenGrabTestPage'
import EmailConfirmationOverlay from '../containers/EmailConfirmationOverlay'
import LocalizationPageV2 from './panel/LocalizationPageV2'
import { isBeniamin, isEduPath, toLocaleDate } from '../lib/utils'
import AddDevicePage from './account/AddDevicePage'
import EducationPageSidebar from './panel/edu/EducationPageSidebar'

type PanelPageProps = InjectedBenAccountProps
  & RouteComponentProps
  & InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps

type PurchaseAlert = {
  visible: boolean
  reason: 'expired' | 'demo' | 'lic-expired' | 'ending-soon' | 'no-renew' | 'none'
  endDate: Date | null
}

type ProfileModalState = {
  profileId?: string
  visible: boolean
}

type ConfirmationModal = {
  profileId?: string
  visible: boolean
}

type ConfirmationDeleteDeviceModal = {
  deviceId?: string
  visible: boolean
}

type ConfirmationDeleteDeviceSecondModal = {
    deviceId?: string
    visible: boolean
}

type MoveDeviceModal = {
  deviceId: string | null
  profileId?: string
  visible: boolean
}

const MOBILE_MENU_BREAKPOINT = 768

const LIC_ENDING_DAYS_WARNING = 14

// gratisowych tokenow jest od 20 do 80 tys
const REDIRECT_EDU_MIN_TOKENS = 100*1000

const PanelPage: React.FC<PanelPageProps> = ({
  benAccount,
  benNotification,
  benService,
  intl,
  history
}) => {

  function isMobileMenuBreakpoint (): boolean {
    return window.innerWidth < MOBILE_MENU_BREAKPOINT
  }

  const hasEmptyProfiles = benAccount.profiles.length === 0
  const profileWithDevice = benAccount.profiles.find(profile => profile.devices.length > 0)

  const [isMounted, setMounted] = React.useState(false)
  const [isMainMenuVisible, setMainMenuVisibility] = React.useState(true)
  const [isMobileMenu, setMobileMenu] = React.useState(isMobileMenuBreakpoint())
  const [profileModalState, setProfileModalState] = React.useState<ProfileModalState>({ visible: false })
  const [confirmationModal, setConfirmationModal] = React.useState<ConfirmationModal>({ visible: false })
  const [confirmationDeleteDeviceModal, setConfirmationDeleteDeviceModal] = React.useState<ConfirmationDeleteDeviceModal>({ visible: false })
  const [confirmationDeleteDeviceSecondModal, setConfirmationDeleteDeviceSecondModal] = React.useState<ConfirmationDeleteDeviceSecondModal>({ visible: false })
  const [moveDeviceModal, setMoveDeviceModal] = React.useState<MoveDeviceModal>({ visible: false, deviceId: null })
  const [privileges, setPrivileges] = React.useState<Privileges>({ getApps: 0, getAppsUsage: 0, getCalls: 0, getContacts: 0, getGPS: 0, getHealth: 0, getKeywords: 0, getSmses: 0, getTop: 0, getTopApps: 0, getTopBlocked: 0, getTopDomains: 0, getTopSearch: 0, getUrls: 0, loadLists: 0, loadSettings: 0, saveLists: 0, saveSettings: 0, setApps: 0 })
  const [isAddDeviceVisible, setAddDeviceVisible] = React.useState(false)

  let location = useLocation()

  // Alerts
  const [purchaseAlert, setPurchaseAlert] = React.useState<PurchaseAlert>({ visible: false, reason: 'demo', endDate: new Date() })

  const appContainerClassName: string = classnames(
    'menu-default',
    'menu-sub-hidden',
    { 'menu-mobile': isMobileMenu },
    { 'sub-hidden': !isMobileMenu },
    { 'main-hidden': !isMainMenuVisible && !isMobileMenu },
    { 'main-show-temporary': isMainMenuVisible && isMobileMenu }
  )

  function handleOnWindowResize () {
    const mobileMenuBreakpoint = isMobileMenuBreakpoint()
    if (isMobileMenu !== mobileMenuBreakpoint) {
      setMobileMenu(mobileMenuBreakpoint)
    }
  }

  function handleDocumentClick (event: MouseEvent | TouchEvent | Event) {
    if (isMobileMenu && isMainMenuVisible) {
      const element = event.target && event.target as Element

      if (!element || element.closest('#hamburger-menu')) {
        return
      }

      setMainMenuVisibility(false)
    }
  }


  function handleOnShowAddDevice() {
    setAddDeviceVisible(true)
  }

  function handleOnHideAddDevice() {
    setAddDeviceVisible(false)
  }

  function handleOnPurchaseClick () {
    history.push('/account/purchase')
  }

  function handleOnHamburgerClick () {
    setMainMenuVisibility(!isMainMenuVisible)
  }

  function onMenuClicked(e: React.MouseEvent<HTMLElement, MouseEvent>)
  {
    if (isMobileMenu && isMainMenuVisible) {
      setMainMenuVisibility(false)
    }
  }

  function handleOnEditProfileClick (profileId: string) {
    setProfileModalState({
      ...profileModalState,
      profileId: profileId,
      visible: true
    })
  }

  function handleOnToggleProfileModal () {
    setProfileModalState({
      ...profileModalState,
      profileId: undefined,
      visible: false
    })
  }

  function handleOnToggleMoveDeviceModal () {
    setMoveDeviceModal({
      ...moveDeviceModal,
      deviceId: null,
      visible: false
    })
  }

  function handleOnPurchaseToggle () {
    setPurchaseAlert({
      ...purchaseAlert,
      visible: !purchaseAlert.visible
    })
  }

  function handleOnAddProfileClick () {
    setProfileModalState({
      ...profileModalState,
      profileId: undefined,
      visible: true
    })
  }

  //////

  function handleOnMoveDevice (deviceId: string, profileId: string) {
    setMoveDeviceModal({ visible: true, deviceId, profileId })
  }

  function handleOnDeleteProfileClick (profileId: string) {
    setConfirmationModal({ visible: true, profileId })
  }

  //////

  function handleOnDeleteDeviceClick (deviceId: string) {
    setConfirmationDeleteDeviceModal({ visible: true, deviceId })
  }

  function handleOnCancelConfirmationDeleteDeviceModal () {
    setConfirmationDeleteDeviceModal({ visible: false })
  }

  async function handleOnDeleteDevideConfirm () {
      setConfirmationDeleteDeviceModal( {visible:false} )
      setConfirmationDeleteDeviceSecondModal( {visible:true, deviceId:confirmationDeleteDeviceModal.deviceId} )
  }

  //////////

  function handleOnCancelConfirmationDeleteDeviceSecondModal () {
    setConfirmationDeleteDeviceSecondModal({ visible: false })
  }

  async function handleOnDeleteDevideSecondConfirm () {
    if (!confirmationDeleteDeviceSecondModal.deviceId) {
        return
    }

    try {
        await benService.deleteDevice(confirmationDeleteDeviceSecondModal.deviceId)
        await benAccount.refreshProfiles()

    } catch {
        benNotification.notify({
        type: 'error',
        title: getMessage('side-effect.internal-error-title', intl),
        message: getMessage('side-effect.internal-error-message', intl)
        })

    } finally {
        isMounted && setConfirmationDeleteDeviceSecondModal({ visible: false })
    }
}

  //////////

  function handleOnCancelConfirmationModal () {
    setConfirmationModal({ visible: false })
  }

  async function handleOnDeleteConfirm () {
    if (!confirmationModal.profileId) {
      return
    }

    try {
      await benService.deleteProfile(confirmationModal.profileId)
      await benAccount.refreshProfiles()

    } catch {
      benNotification.notify({
        type: 'error',
        title: getMessage('side-effect.internal-error-title', intl),
        message: getMessage('side-effect.internal-error-message', intl)
      })

    } finally {
      isMounted && setConfirmationModal({ visible: false })
    }
  }

  ///////


  function getDefaultPath() : string
  {
    if( isBeniamin() )
    {
      // przekierowanie do "/panel/education" blokuje przekierowanie do '/account/purchase'
      // a poniewaz benAccount.tokensEdu > 0 jest caly czas == true, to '/account/purchase' znika prawie calkowicie
      if( benAccount.accountTypeEdu === AccountType.edu || benAccount.tokensEdu > REDIRECT_EDU_MIN_TOKENS )
        return "/panel/education"
      else
        return "/panel/summary"
    }
    else
    {
      return "/panel/summary"
    }
  }


  useEventListener('resize', handleOnWindowResize)
  useEventListener('click', handleDocumentClick, document.querySelector('main'))

  React.useEffect(() => {
    setMounted(true)

    return () => {
      setMounted(false)
    }
  }, [] )


  React.useEffect( () => {

    if( isEduPath() && purchaseAlert.visible )
    {
      setPurchaseAlert({ visible: false, reason: 'none', endDate: null })
    }

  }, [location])


  React.useEffect(() => {

    switch (benAccount.accountType) {
      case AccountType.demoExpired:
        if( benAccount.accountTypeEdu === AccountType.accountNone ) {
          setPurchaseAlert({ visible: true, reason: 'expired', endDate: null })
        }
        break

      case AccountType.licExpired:
        if( benAccount.accountTypeEdu === AccountType.accountNone ) {
          setPurchaseAlert({ visible: true, reason: 'lic-expired', endDate: null })
        }
        break

      case AccountType.minimal:
      case AccountType.regular:
      case AccountType.premium:

        const diff = benAccount.planValidTill - (new Date().getTime() / 1000) ;
        let diffDays = Math.round( diff / (3600*24) )
        if( diffDays < 1 ) diffDays = 1

        //console.log( diff )
        //console.log( diffDays )

        if( benAccount.planValidTill > 0 && diff > 0 && diffDays <= LIC_ENDING_DAYS_WARNING )
        {
          if( benAccount.isSubscription )
          {
            if( benAccount.cancelAtPeriodEnd )
            {
              // Tutaj powinien byc alert 'uaktualnij subskrypcje'
              // setPurchaseAlert({ visible: true, reason: 'no-renew', endDate: new Date(benAccount.planValidTill * 1000) })
            }
            setPurchaseAlert({ visible: false, reason: 'none', endDate: null })
          }
          else
          {
            setPurchaseAlert({ visible: true, reason: 'ending-soon', endDate: new Date(benAccount.planValidTill * 1000) })
          }
        }
        else
        {
          setPurchaseAlert({ visible: false, reason: 'none', endDate: null })
        }

        break;

      default:
        setPurchaseAlert({ visible: !benAccount.hasValidPlan, reason: 'demo', endDate: null })
    }

  }, [benAccount.accountType, benAccount.hasValidPlan]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {

    if( isMounted )
      setPrivileges(benAccount.privileges)
      
  }, [isMounted, benAccount.privileges])

  React.useEffect(() => {

    const skipPurchasePageAnyway = false // isAvosmart()

    let replaceUrl = ''

    if (!benAccount.settings.skipProfileCreator) 
    {
      if (hasEmptyProfiles) 
      {
        //history.replace('/account/create-profile')
        replaceUrl = '/account/create-profile'
      } 
      else if (!profileWithDevice) 
      {
        // history.replace('/account/add-device')
        // replaceUrl = '/account/add-device'
      }
    }
    else if (!skipPurchasePageAnyway && !benAccount.settings.skipPurchase && !benAccount.hasValidPlan) 
    {
      //history.replace('/account/purchase')
      replaceUrl = '/account/purchase'
    }

    // console.log("replaceUrl", replaceUrl)

    if( replaceUrl )
      history.replace(replaceUrl)

  }, [benAccount, hasEmptyProfiles, profileWithDevice, history])


  return (
    <div id="app-container" className={appContainerClassName}>
      <ProfileModal
        isOpen={profileModalState.visible}
        profileId={profileModalState.profileId}
        onToggle={handleOnToggleProfileModal}
        accountType={benAccount.accountType}
      />

      <ConfirmationModal
        isOpen={confirmationModal.visible}
        onCancel={handleOnCancelConfirmationModal}
        onConfirm={handleOnDeleteConfirm}
      ><I18nMessages id="panel-page.profile-delete-description" /></ConfirmationModal>

      <ConfirmationModal
        isOpen={confirmationDeleteDeviceModal.visible}
        onCancel={handleOnCancelConfirmationDeleteDeviceModal}
        onConfirm={handleOnDeleteDevideConfirm}
      ><I18nMessages id="panel-page.profile-delete-device-description" /></ConfirmationModal>

      <ConfirmationModal
        reverse={true}
        isOpen={confirmationDeleteDeviceSecondModal.visible}
        onCancel={handleOnCancelConfirmationDeleteDeviceSecondModal}
        onConfirm={handleOnDeleteDevideSecondConfirm}
      ><I18nMessages id="panel-page.profile-delete-device-second-description" /></ConfirmationModal>

      <ProfileMoveDeviceModal
        currentProfileId={moveDeviceModal.profileId}
        isOpen={moveDeviceModal.visible}
        deviceId={moveDeviceModal.deviceId}
        onToggle={handleOnToggleMoveDeviceModal}
      />

      <AddDevicePage 
        isOpen={isAddDeviceVisible} 
        onToggle={handleOnHideAddDevice}
      />

      <TopNavigation onHamburgerMenuClick={handleOnHamburgerClick} />

      <Sidebar privileges={privileges} onMenuClicked={onMenuClicked}  />


        <main>
        <div className="container-fluid panel-page">
          <EmailConfirmationOverlay>
            <Alert color="warning" className="demo-warning mb-4" isOpen={purchaseAlert.visible} toggle={handleOnPurchaseToggle}>

              {purchaseAlert.reason === 'expired' && (
                  <I18nMessages id="panel-page.purchase-expired-alert" /> 
              )}
              {purchaseAlert.reason === 'lic-expired' && (
                  <I18nMessages id="panel-page.purchase-lic-expired-alert" />
              )}
              {purchaseAlert.reason === 'demo' && (
                  <I18nMessages id="panel-page.purchase-demo-alert" />
              )}

              {purchaseAlert.reason === 'ending-soon' && purchaseAlert.endDate != null && ((
                intl.formatMessage( {id:'panel-page.purchase-ending-soon-alert'}, {date:toLocaleDate( purchaseAlert.endDate, intl )} )
              ))}

              <Button className="mx-1" size="xs" color="primary" onClick={handleOnPurchaseClick }>
                <I18nMessages id="button-buy-label" />
              </Button>
            </Alert>

            <ProfileCardsList
              onAddProfileClick={handleOnAddProfileClick}
              onEditProfileClick={handleOnEditProfileClick}
              onDeleteProfileClick={handleOnDeleteProfileClick}
              onDeleteDeviceClick={handleOnDeleteDeviceClick}
              onMoveDeviceClick={handleOnMoveDevice}
            />

            <LicenseOverlay>
              <EmptyDeviceOverlay onShowAddDevice={handleOnShowAddDevice} >
                <Switch>
                  <Redirect exact from="/panel/" to={getDefaultPath()} />
                  
                  <Route path="/panel/education" component={EducationPageSidebar} />
                  <Route path="/panel/summary" component={SummaryPage} />
                  <Route path="/panel/internet" component={Internet} />
                  <Route path="/panel/applications" component={ApplicationsPage} />
                  <Route path="/panel/localization-old" component={Localization} />
                  <Route path="/panel/localization" component={LocalizationPageV2} />
                  <Route path="/panel/calls-and-text-messages" component={CallsTextMessages} />
                  <Route path="/panel/battery" component={BatteryPage} />
                  <Route path="/panel/access-time" component={AccessTime} />
                  <Route path="/panel/youtube" component={YoutubePage} />

                  <Route path="/panel/screenview" component={ScreenViewPage} />
                  <Route path="/panel/screengrab" component={ScreenGrabPage} />
                  <Route path="/panel/screengrabtest" component={ScreenGrabTestPage} />


                  <Redirect to="/error" />
                </Switch>
              </EmptyDeviceOverlay>
            </LicenseOverlay>
          </EmailConfirmationOverlay>
        </div>
      </main>
    </div>
  )
}

export default withRouter(injectIntl(withBenNotification(withBenService(withBenAccount(PanelPage)))))
