import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Col, Modal, ModalBody, Row } from 'reactstrap';

import ShieldIconPL from '../../assets/media/30-dni.svg';
import ShieldIconEN from '../../assets/media/30-days.svg';
import appLogo from '../../assets/media/logo.svg'
import I18nMessages from '../../components/I18nMessages';
import Spinner from '../../components/Spinner';
import ConfirmationModal from '../../containers/ConfirmationModal';
import getMessage from '../../language/getMessage';
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider';
import { InjectedBenHelperProps, withBenHelper } from '../../providers/benHelperProvider';
import { InjectedBenServiceProps, PlansData, PlansItem, withBenService } from '../../providers/benServiceProvider';
import { AccountType } from '../../providers/providersDefs'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider';
import GlideComponent from '../panel/components/carousel/GlideComponent';
import Rating from '../panel/components/Rating';
import TagManager from 'react-gtm-module'
import { formatAmount, getBuildConfigStr, getCreditsInfo, isAvosmart } from '../../lib/utils';
import PurchaseCheckoutPage from './PurchaseCheckout'
import PurchasePageTile from './PurchasePageTile';


export enum GroupPlansTypes {
  NONE = 0,
  PARENTAL_CONTROL = 1,
  EDU = 2
}

type ConfirmationModal = {
  visible: boolean
}

type PurchasePageProps = InjectedBenHelperProps & InjectedBenAccountProps & RouteComponentProps & InjectedBenServiceProps & InjectedIntlProps & InjectedBenNotificationProps

const showModalPercentValue: boolean = true;
const ratings = [
  {
    title: "purchase-plan.raitings.desc.name_1",
    desc: "purchase-plan.raitings.desc.raiting_1",
    rate: 5,
  },
  {
    title: "purchase-plan.raitings.desc.name_2",
    desc: "purchase-plan.raitings.desc.raiting_2",
    rate: 4,
  },
  {
    title: "purchase-plan.raitings.desc.name_3",
    desc: "purchase-plan.raitings.desc.raiting_3",
    rate: 5,
  },
  {
    title: "purchase-plan.raitings.desc.name_4",
    desc: "purchase-plan.raitings.desc.raiting_4",
    rate: 5,
  },
  {
    title: "purchase-plan.raitings.desc.name_5",
    desc: "purchase-plan.raitings.desc.raiting_5",
    rate: 5,
  },
  {
    title: "purchase-plan.raitings.desc.name_6",
    desc: "purchase-plan.raitings.desc.raiting_6",
    rate: 5,
  }
];

const features = [
  {
    left: {
      title: 'purchase-plan.features.title.feature_1',
      icon: 'iconsminds-24-hour',
      desc: 'purchase-plan.features.desc.feature_1'
    },
    right: {
      title: 'purchase-plan.features.title.feature_2',
      icon: 'iconsminds-laptop-secure',
      desc: 'purchase-plan.features.desc.feature_2'
    }
  },
  {
    left: {
      title: 'purchase-plan.features.title.feature_3',
      icon: 'iconsminds-security-block',
      desc: 'purchase-plan.features.desc.feature_3'
    },
    right: {
      title: 'purchase-plan.features.title.feature_4',
      icon: 'iconsminds-youtube',
      desc: 'purchase-plan.features.desc.feature_4'
    }
  },
  {
    left: {
      title: 'purchase-plan.features.title.feature_5',
      icon: 'iconsminds-monitor---laptop',
      desc: 'purchase-plan.features.desc.feature_5'
    },
    right: {
      title: 'purchase-plan.features.title.feature_6',
      icon: 'iconsminds-monitor---phone',
      desc: 'purchase-plan.features.desc.feature_6'
    }
  },
  {
    left: {
      title: 'purchase-plan.features.title.feature_7',
      icon: 'iconsminds-monitor-analytics',
      desc: 'purchase-plan.features.desc.feature_7'
    },
    right: {
      title: 'purchase-plan.features.title.feature_8',
      icon: 'simple-icon-location-pin',
      desc: 'purchase-plan.features.desc.feature_8'
    }
  },
  {
    left: {
      title: 'purchase-plan.features.title.feature_9',
      icon: 'simple-icon-call-in',
      desc: 'purchase-plan.features.desc.feature_9'
    },
    right: {
      title: 'purchase-plan.features.title.feature_10',
      icon: 'simple-icon-support',
      desc: 'purchase-plan.features.desc.feature_10'
    }
  }
]

const PurchasePage: React.FC<PurchasePageProps> = ({
  benHelper,
  benAccount,
  benService,
  benNotification,
  history,
  intl
}) => {
  const [isMounted, setMounted] = React.useState(false)
  const [isLoading, setLoading] = React.useState(true)
  const [plans, setPlans] = React.useState<PlansData>({ plans: [], topups:[] })

  const [groupPlans, setGroupPlans] = React.useState< PlansData[] >( [] )
  const [groupPlansTypes, setGroupPlansTypes] = React.useState<GroupPlansTypes>( GroupPlansTypes.NONE )

  const [selectedPlan2Y, setselectedPlan2Y] = React.useState<PlansItem>()
  const [confirmationModal, setConfirmationModal] = React.useState<ConfirmationModal>({ visible: false })

  const [isCheckoutDialogVisible, setCheckoutDialogVisible] = React.useState(false)
  const [checkoutPlan, setCheckoutPlan] = React.useState(0)
  const [checkoutPlanYears, setCheckoutPlanYears] = React.useState(0)

  const onToggleCheckoutDialog = () =>
  {
    setCheckoutDialogVisible(false)
    setCheckoutPlan(0)
    setCheckoutPlanYears(0)
  }
  
  
  function handleSkipClick() {
    benAccount.changeSettings({ ...benAccount.settings, skipPurchase: true })
    history.push('/')
  }

  async function handle2YearsPlanConfirm() 
  {
    try 
    {
      if (selectedPlan2Y) {
        buyLicense(selectedPlan2Y.type, selectedPlan2Y.intervalCnt)
      }
    }
    finally 
    {
      isMounted && setConfirmationModal({ visible: false })
    }
  }

  async function handleOnCancelConfirmationModal() 
  {
    try 
    {
      if (selectedPlan2Y) {
        buyLicense(selectedPlan2Y.type, 1)
      }
    }
    finally 
    {
      isMounted && setConfirmationModal({ visible: false })
    }
  }

  const handleSelectPlan = (licType: number, licYears: number, discount: number) => {
    if (discount === 1 || isAvosmart() ) {
      buyLicense(licType, licYears)
    }
    else {
      benService.getPlans(benHelper.promoCode, intl.locale, 2)
        .then(result => {
          if (isMounted) {
            const plan = result.data.plans.filter(plan => plan.type === licType)[0];
            setselectedPlan2Y(plan)
          }
        })
        .then(() => isMounted && setLoading(false))
      setConfirmationModal({ visible: true })
    }
  }

  React.useEffect(() => 
  {
    setMounted(true)
    return () => setMounted(false)
  }, [setMounted])

  
  React.useEffect(() => 
  {
    document.body.classList.add('h-100')
    return () => document.body.classList.remove('h-100')
  }, [])


  function createGroupPlans(plansData: PlansData)
  {
    let prot    : PlansItem[] = []
    let protEdu : PlansItem[] = []
    let edu     : PlansItem[] = []

    let types   : GroupPlansTypes = GroupPlansTypes.NONE

    for( const p of plansData.plans )
    {
      if( p.type >= AccountType.minimal && p.type <= AccountType.premium ) {
        prot.push(p)
        types |= GroupPlansTypes.PARENTAL_CONTROL
      }

      if( p.type === AccountType.edu ) {
        edu.push(p)
        types |= GroupPlansTypes.EDU
      }

      if( p.type >= AccountType.minimal_edu && p.type <= AccountType.premium_edu ) {
        protEdu.push(p)
        types |= GroupPlansTypes.PARENTAL_CONTROL
        types |= GroupPlansTypes.EDU
      }
    }

    let gp : PlansData[] = []

    if( prot.length > 0 && protEdu.length === 0 && edu.length === 0 )
    {
      for( const p of prot )
        gp.push( {plans:[p], topups:[]} )
    }
    else
    {
      if( prot.length > 0 )
        gp.push( {plans:prot, topups:[]} )

      if( protEdu.length > 0 )
        gp.push( {plans:protEdu, topups:[]} )

      if( edu.length > 0 )
        gp.push( {plans:edu, topups:[]} )
    }
      
    setGroupPlans(gp)
    setGroupPlansTypes(types)
  }


  function tmpGeneratePlan( type : AccountType, 
                            basePrice: number,
                            price: number,
                            discount: number,
                            percent: number,
                            devices: number,
                            tokens: number ) : PlansItem
  {
    return {
      type,
      intervalCnt: 1,
      intervalName: 'year',
      currency: 'PLN',
      basePrice,
      price,
      priceInt: price*100,
      discount,
      monthly : (Math.round(price*100/12)/100),
      percent,
      devices,
      eduTokens:tokens
    }
  }


  React.useEffect(() => 
  {
    let isMounted = true
    setLoading(true)

    benService.getPlans(benHelper.promoCode, intl.locale)
      .then(result => {
        if (isMounted) {

          /*
          if( document.location.hostname === 'localhost' )
          {
            result.data = { plans : [], topups:[] }

            // result.data.plans.push( tmpGeneratePlan(100,  99,  99,  1, 0,   3, 0 ) )
            // result.data.plans.push( tmpGeneratePlan(200,  120, 120, 1, 0,   6, 0 ) )
            // result.data.plans.push( tmpGeneratePlan(300,  150, 150, 0, 0,   9, 0 ) )
            
            result.data.plans.push( tmpGeneratePlan(100,  99,  99,  0, 0,   3, 0 ) )
            result.data.plans.push( tmpGeneratePlan(200,  120, 120, 0, 0,   6, 0 ) )
            result.data.plans.push( tmpGeneratePlan(300,  150, 150, 0, 0,   9, 0 ) )
            result.data.plans.push( tmpGeneratePlan(1100, 199, 120, 1, 20,  3, 1e6 ) )
            result.data.plans.push( tmpGeneratePlan(1200, 230, 200, 1, 20,  6, 1e6 ) )
            result.data.plans.push( tmpGeneratePlan(1300, 250, 210, 1, 20,  9, 1e6 ) )
            result.data.plans.push( tmpGeneratePlan(1000, 199, 199, 0, 0,   0, 1e6 ) )
            result.data.plans.push( tmpGeneratePlan(1000, 199, 99,  1, 50,  0, 1e6 ) )
          }
          */

          setPlans(result.data)
          createGroupPlans(result.data)
        }
      })
      .then(() => isMounted && setLoading(false))

    return () => {
      isMounted = false
    }
  }, [benAccount, benService, intl.locale, benHelper.promoCode])


  function buyLicense(licType: number, licYears: number) 
  {
    // console.log('buyLicense ' + licType + ' ' + licYears );
    
    if( isAvosmart() )
    {
      //benAccount.changeSettings({ ...benAccount.settings, skipPurchase: true, selectedPlan: licType, selectedPlanYears:licYears })      
      //history.push('/account/purchase-checkout')

      setCheckoutDialogVisible(true)
      setCheckoutPlan(licType)
      setCheckoutPlanYears(licYears)
    }
    else
    {
      setLoading(true)

      benService.buyLicense(licType, licYears, benHelper.promoCode, intl.locale)
        .then(result => {
          const url = result.data.redirectUri;
          TagManager.dataLayer({
            dataLayer: {
              'event': 'buy_license',
              'buyLicenseRedirectHostName': new URL(url).hostname
            }
          })

          setTimeout(() => {
            window.location.href = url
          }, 1000);
        })

        .catch(() => {
          benNotification.notify({
            type: 'error',
            title: getMessage('side-effect.internal-error-title', intl),
            message: getMessage('side-effect.internal-error-message', intl)
          })
          setLoading(false)
        })
    }
  }

  function getMD(): string {
    if (groupPlans.length === 1)
      return "8"

    if (groupPlans.length === 2)
      return "6"

    return "4"
  }

  function getMB(): string {

    if (groupPlans.length === 1)
      return "mb-8"

    if (groupPlans.length === 2)
      return "mb-6"

    return "mb-4"
  }

  function getPlanFeatures(devices: number): string {
    return intl.formatMessage({ id: 'plans-info-page.plan-devices' }, { devices })
  }

  function getModalSubtitle(amount: number, currency: string, baseAmount: number): string {
    //return intl.formatHTMLMessage({ id: 'purchase-page.modal.2y-sub-header' }, { amount, currency, baseAmount })
    return intl.formatHTMLMessage({ id: 'purchase-page.modal.2y-sub-header2' }, { baseAmountCurrency: formatAmount(baseAmount, currency), amountCurrency: formatAmount(amount, currency) })
  }

  function getShieldIcon()
  {
    if( intl.locale.toLowerCase().startsWith('pl') )
      return ShieldIconPL
    else
      return ShieldIconEN
  }


  return (
    <React.Fragment>
      <Col>
        <Row className="my-auto">
          <Col>
            <div style={{marginTop:'5px'}} className="benjamin-logo-container">
              <img src={appLogo} className="login-logo" alt="logo" />
            </div>

            <div className="purchase-page-header title card m-0 px-0">
              <div className="border-bottom container h1 text-center">
                <I18nMessages tagName="h1" id="purchase-page.title" />
              </div>

              <div className="container px-3 px-md-5 mt-3">
                <Row className="px-md-5">
                  <Col className="h6 header-list" xs="12" md="6">
                    <div className="mx-auto mb-xs-3" style={{ paddingLeft: '3px' }}>
                      <div className="py-2">
                        <i className="simple-icon-check pr-2"></i>
                        <I18nMessages tagName="span" id="purchase-page.header.check-1" />
                      </div>
                      <div className="py-2">
                        <i className="simple-icon-check pr-2"></i>
                        <I18nMessages tagName="span" id="purchase-page.header.check-2" />
                      </div>
                      <div className="py-2">
                        <i className="simple-icon-check pr-2"></i>
                        <I18nMessages tagName="span" id="purchase-page.header.check-3" />
                      </div>
                      <div className="py-2">
                        <i className="simple-icon-check pr-2"></i>
                        Pomoc w nauce. Rozwiązujemy zadania
                      </div>
                    </div>
                  </Col>
                  
                  <Col xs="12" md="6">
                    <div className="shield mx-auto h-mback h-mback-border-bottom">
                      <img src={getShieldIcon()} className="float-left mr-3" alt="30 days money back" />
                      <div className="shield-header h4 mb-0">
                        <I18nMessages tagName="span" id="purchase-page.money-back.title" />
                      </div>
                      <div className="small-text">
                        <I18nMessages tagName="span" id="purchase-page.money-back.desc" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <div className="mx-auto my-auto purchase-page container px-0 px-sm-2">
            <div className="purchase-plan-skip mb-4">
              <Button className="purchase-plan-skip" onClick={handleSkipClick} color="light">
                <div className="ml-4 mr-4"><I18nMessages id="purchase-page.skip-label" /></div>
              </Button>
            </div>

            {isLoading && (
              <Spinner />
            )}

            <Col className="mx-auto my-auto purchase-page">
              {!isLoading && (<Row className={getMB()}>

                {groupPlans.length === 1 && (<Col className="plan-details" md='2'></Col>)}

                {groupPlans.map( (group,idx) => { 
                  return  (
                    <PurchasePageTile key={idx}
                                      handleSelectPlan={handleSelectPlan} 
                                      mbValue={getMB()} 
                                      mdValue={getMD()} 
                                      plans={group.plans} 
                                      plansTypes={groupPlansTypes} />
                  )
                } ) }

              </Row>
              )}
            </Col>

            <div className="purchase-plan-skip purchase-plan-skip2 mt-4">
              <Button className="purchase-plan-skip" onClick={handleSkipClick} color="light">
                <div className="ml-4 mr-4"><I18nMessages id="purchase-page.skip-label" /></div>
              </Button>
            </div>

            <Modal isOpen={confirmationModal.visible} style={{marginTop: '5.5rem'}}>
              <ModalBody className="pl-0 pr-0 pb-0">
                <div>
                  {selectedPlan2Y && (
                    <div className="plan-2y-box text-center">
                      <div className="discount-ico mx-auto">
                        {showModalPercentValue && (<div className="mx-auto my-auto h2">-{selectedPlan2Y.percent}%</div>)}
                        {!showModalPercentValue && (<div className="single-percent mx-auto my-auto h2">%</div>)}
                      </div>

                      <div className="border-bottom font-weight-bold plan-2y-box-modal-header py-2 mt-3">
                        <I18nMessages tagName="span" id="purchase-page.modal.2y-header" />
                      </div>

                      <div className="h5 my-3 plan-2y-box-modal-subheader" dangerouslySetInnerHTML={{__html: getModalSubtitle(
                        selectedPlan2Y.price,
                        selectedPlan2Y.currency,
                        selectedPlan2Y.basePrice
                        )}}></div>


                      <div className="mx-5 price-monthly py-2 mb-5">
                        { formatAmount(selectedPlan2Y.monthly, selectedPlan2Y.currency) } <I18nMessages id="purchase-page.monthly" />
                      </div>

                      { selectedPlan2Y.eduTokens > 0 && (
                        <div style={{marginTop: '-30px'}} className='credits-info'>{ getCreditsInfo(selectedPlan2Y) } *</div>
                      )}

                      <div className="cancel-link font-weight-bold mb-3 mt-4">
                        <a onClick={handleOnCancelConfirmationModal} className="cursor-pointer">
                          <I18nMessages id="purchase-page.modal.no-thanks" />
                        </a>
                      </div>

                      <Button className="modal-box-button p-4 mt-2" onClick={handle2YearsPlanConfirm}>
                        <span className="font-weight-bold h4">
                          <I18nMessages id="purchase-page.modal.upgrade" />
                        </span>
                        <span className="ico-forward"></span>
                      </Button>
                    </div>


                  )}
                </div>
              </ModalBody>
            </Modal>
          </div>
        </Row>
        
        { (groupPlansTypes & GroupPlansTypes.EDU) > 0 && (
          <div className="container text-center credits-info" >* Liczba zadań jest szacunkowa. Jest ona uzależniona od stopnia skomplikowania zadania.</div>
        )}

        <Row className="my-auto">
          <Col>
            <div className="purchase-page-header title card card-body m-0 pb-0">
              <div className="container h1 text-center mt-3">
                <I18nMessages tagName="h1" id="purchase-page.title.features" />
              </div>

              <div className="container mt-3 px-md-3 px-0">
                {
                  features.map((item, index) =>
                  (
                    <Row key={'feature'+index}>
                      <Col xs="12" md="6">
                        <div className="feature-item mb-5 px-xs-0 px-md-2 px-lg-5">
                          <div className="title h4">
                          <i className={item.left.icon + ' pr-1'}></i>
                            {intl.formatMessage({ id: item.left.title })}
                          </div>
                          <div className="desc ml-sm-0 text-justify">
                            {intl.formatMessage({ id: item.left.desc}, {app:getBuildConfigStr('NAME') } )}
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" md="6">
                        <div className="feature-item mb-5 px-xs-0 px-md-2 px-lg-5">
                          <div className="title h4">
                            <i className={item.right.icon + ' pr-1'}></i>
                            {intl.formatMessage({ id: item.right.title })}
                          </div>
                          <div className="desc ml-sm-0 text-justify">
                            {intl.formatMessage({ id: item.right.desc }, {app:getBuildConfigStr('NAME') } )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
                  )
                }
              </div>

              <div className="container h1 text-center">
                <I18nMessages tagName="h1" id="purchase-page.title.reviews" />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="my-auto">
          <Col>
            <div className="dashboard-top-rated my-5 container">
              <GlideComponent settings={
                  {
                    gap: 5,
                    perView: 4,
                    type: "carousel",
                    breakpoints: {
                      480: { perView: 1 },
                      800: { perView: 2 },
                      1200: { perView: 3 }
                    },
                    hideNav: false
                  }
                }>
                  {ratings.map((item, index) => {
                    return (
                      <Card key={index} className="mx-3 glide__item">
                          <CardBody>
                            <h6 className="mb-1 h4 font-weight-bold">
                              {intl.formatMessage({ id: item.title } )}
                            </h6>

                            <div className="desc my-3">
                              {intl.formatMessage({ id: item.desc }, { app:getBuildConfigStr('NAME') } )}
                            </div>

                            <Rating total={5} rating={item.rate} interactive={false} />
                          </CardBody>
                      </Card>
                    );
                  })}
                </GlideComponent>
            </div>
          </Col>
        </Row>

      </Col>

      <PurchaseCheckoutPage
        isOpen={isCheckoutDialogVisible}
        onToggle={onToggleCheckoutDialog}
        plan={checkoutPlan}
        planYears={checkoutPlanYears} />
        
    </React.Fragment>
  )
}

export default withBenHelper(withRouter(withBenNotification(withBenAccount(injectIntl(withBenService(PurchasePage))))))
