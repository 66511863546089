import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Modal, ModalHeader, ModalBody, Button, Row, Col, Card, CardBody } from 'reactstrap'
import AsyncButton from '../../../components/AsyncButton'
import { InjectedBenAccountProps, withBenAccount } from '../../../providers/benAccountProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../../providers/notificationProvider'
import { InjectedBenServiceProps, withBenService, PlansItem } from '../../../providers/benServiceProvider'
import { InjectedAiServiceProps, withAiService } from '../../../providers/aiServiceProvider'
import getMessage from '../../../language/getMessage'
import { formatAmount, getCookie2, getCreditsInfoBuy, isAvosmart } from '../../../lib/utils'


type InjectedProps = InjectedBenAccountProps
  & InjectedBenServiceProps
  & InjectedAiServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps

type EducationCreditsPopupProps = InjectedProps & {
  topups: PlansItem[]
  isOpen: boolean
  useAiService: boolean
  onToggle: () => void
}

const EducationCreditsPopup: React.FC<EducationCreditsPopupProps> = ({
  benAccount,
  benNotification,
  benService,
  aiService,
  intl,
  topups,
  isOpen,
  useAiService,
  onToggle,
  }) => {

  const [isLoading, setLoading] = React.useState(false)
    
   


  function handleBuyPlan(plan:PlansItem)
  {
    if( isAvosmart() )
    {
        // TBD
        // setCheckoutDialogVisible(true)
        // setCheckoutPlan(licType)
        // setCheckoutPlanYears(licYears)
    }
    else
    {
        setLoading(true)

        // var promise : Promise<ActionResponse<BuyLicense>> | Promise<AiActionResponse<AiBuyLicense>>

        if( useAiService )
        {
            const hash = getCookie2('hash')
            aiService.buyLicense(hash, plan.type, 0, 'year', intl.locale)
                .then(result => {
                    const url = result.data.redirectUri;
                    setTimeout(() => {
                    window.location.href = url
                    }, 1000);
                })    
                .catch(() => {
                    benNotification.notify({
                    type: 'error',
                    title: getMessage('side-effect.internal-error-title', intl),
                    message: getMessage('side-effect.internal-error-message', intl)
                    })
                    setLoading(false)
                })
        }
        else
        {
            benService.buyLicense(plan.type, 0, 'year', intl.locale)
                .then(result => {
                    const url = result.data.redirectUri;
                    setTimeout(() => {
                    window.location.href = url
                    }, 1000);
                })    
                .catch(() => {
                    benNotification.notify({
                    type: 'error',
                    title: getMessage('side-effect.internal-error-title', intl),
                    message: getMessage('side-effect.internal-error-message', intl)
                    })
                    setLoading(false)
                })
        }
           
    }
  }

  function getPrice(plan:PlansItem) : string
  {
    return formatAmount(plan.price, plan.currency)
  }


  return (
    <Modal isOpen={isOpen} toggle={onToggle} size="lg">
      <ModalHeader>
        <Button color="light" size="xs" onClick={onToggle} className="button-x" >
            X
        </Button>
        Dokup Punkty Wiedzy
      </ModalHeader>


      <ModalBody className="plan-info-body">
       <Card body className="shadow plan-info-card" >
        <CardBody className="plan-info-card-body">

            <h4> Wybierz kwotę doładowania </h4>

            <Row className='my-4' > 
            { topups.map((item, index) => (
                <Col md="4" key={index} className='d-flex justify-content-center'>
                    <AsyncButton showSpinner={isLoading} color='primary' size='lg' className="m-2" onClick={ () => handleBuyPlan(item) } > {getPrice(item)} </AsyncButton>
                </Col>
            ))}
            </Row>

            <Row className='my-4'>
                { topups.map((item, index) => (
                    <Col md="4" key={index}>
                        <i>{ getCreditsInfoBuy(item) }*</i>
                    </Col>
                ))}
            </Row>            

            <Row className='my-4'>
                <Col>
                  <div className='credits-info'>
                    * Liczba zadań jest szacunkowa. Jest ona uzależniona od stopnia skomplikowania zadania.   
                  </div>
                </Col>
            </Row>

          </CardBody>
        </Card>
      </ModalBody>

        <Row className='my-4' > 
            <Col className='d-flex justify-content-center'>
                <AsyncButton showSpinner={isLoading} color='light' size='lg' className="m-2" onClick={ () => onToggle() } > Zamknij </AsyncButton>
            </Col>
        </Row>


    </Modal>
  )
}

export default injectIntl(withBenNotification(withBenService(withAiService(withBenAccount(EducationCreditsPopup)))))
